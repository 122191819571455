import { SVGProps } from "types";

const Send = (props: SVGProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4534 0.558454C19.8479 0.831834 20.0548 1.30439 19.9806 1.77695L17.4812 18.0236C17.4226 18.4024 17.1922 18.7344 16.8563 18.9218C16.5204 19.1093 16.1182 19.1327 15.7628 18.9843L11.0919 17.0433L8.41663 19.9372C8.06905 20.3161 7.52229 20.4411 7.04192 20.2536C6.56155 20.0661 6.24911 19.6014 6.24911 19.0859V15.8209C6.24911 15.6647 6.30769 15.5163 6.41314 15.403L12.9587 8.25998C13.1852 8.01394 13.1774 7.63511 12.943 7.40079C12.7087 7.16646 12.3299 7.15084 12.0838 7.37345L4.14017 14.4306L0.69167 12.7044C0.277693 12.4974 0.0121231 12.0834 0.000406773 11.6226C-0.0113095 11.1617 0.230828 10.7321 0.629183 10.5017L18.1256 0.503777C18.5434 0.265546 19.059 0.288978 19.4534 0.558454Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Send;
