import { SVGProps } from "types";

const BoxWebProgramming = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5372)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M9.50023 14.9995C9.37198 14.9995 9.24448 14.9508 9.14698 14.8533C8.95198 14.6583 8.95198 14.3418 9.14698 14.146L10.2937 12.9993L9.14698 11.8525C8.95198 11.6575 8.95198 11.341 9.14698 11.1453C9.34198 10.9503 9.65848 10.9503 9.85423 11.1453L11.3542 12.6453C11.5492 12.8403 11.5492 13.1568 11.3542 13.3525L9.85423 14.8525C9.75598 14.9508 9.62773 14.9995 9.50023 14.9995Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M14.4998 14.9995H12.4995C12.2235 14.9995 12 14.776 12 14.4993C12 14.2225 12.2235 13.999 12.5002 13.999H14.5005C14.7772 13.999 15.0007 14.2233 15.0007 14.4993C15.0007 14.7753 14.7765 14.9995 14.4998 14.9995Z"
          fill="var(--color-mauve)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5372">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxWebProgramming;
