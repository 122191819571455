import { ApolloProvider } from "@apollo/client";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { appWithTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import client from "common/apolloClient";
import Header from "components/Header";
import { PageError } from "contexts/PageError";
import useToggleBodyNoScrollAttribute from "hooks/useToggleBodyNoScrollAttribute";
import { StatusCode } from "types";
// eslint-disable-next-line
import "react-toastify/dist/ReactToastify.css";
// eslint-disable-next-line
import "styles/globals.css";
import nextI18nConfig from "../../next-i18next.config";

const ColorSchemeProvider = dynamic(() => import("contexts/ColorScheme"), {
  ssr: false,
});

const Footer = dynamic(() => import("components/Footer"));
const ContactForm = dynamic(() => import("components/ContactForm"));

function App({ Component, pageProps }: AppProps) {
  const [statusCode, setStatusCode] = useState<StatusCode | null>(null);
  const toggleBodyScrollAttribute = useToggleBodyNoScrollAttribute();
  const router = useRouter();

  const isCareerPage = router.pathname.endsWith("/career");

  useEffect(() => {
    toggleBodyScrollAttribute(false);
  }, [router.pathname, toggleBodyScrollAttribute]);

  return (
    <ApolloProvider client={client}>
      <ColorSchemeProvider>
        <PageError.Provider value={{ statusCode, setStatusCode }}>
          {!statusCode && <Header />}
          <Component {...pageProps} />
          <ToastContainer position="top-right" hideProgressBar />
          {!isCareerPage && !statusCode && <ContactForm />}
          {!statusCode && <Footer />}
        </PageError.Provider>
      </ColorSchemeProvider>
    </ApolloProvider>
  );
}
export default appWithTranslation(App, nextI18nConfig);
