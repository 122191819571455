import { SVGProps } from "types";

const BoxEye = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5428)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M15.9217 8.857C15.0322 7.459 13.5667 6.625 11.9999 6.625C10.4332 6.625 8.96769 7.459 8.07819 8.85625C7.97394 9.0205 7.97394 9.229 8.07819 9.39325C8.96769 10.7912 10.4332 11.6252 11.9999 11.6252C13.5667 11.6252 15.0322 10.7912 15.9217 9.394C16.0259 9.22975 16.0259 9.0205 15.9217 8.857ZM11.9999 10.1252C11.4479 10.1252 11.0002 9.6775 11.0002 9.1255C11.0002 8.5735 11.4479 8.12575 11.9999 8.12575C12.5519 8.12575 12.9997 8.5735 12.9997 9.1255C12.9997 9.6775 12.5519 10.1252 11.9999 10.1252Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M17.5005 8.125C17.2238 8.125 17.0002 8.3485 17.0002 8.62525V15.1255H6.99975V8.62525C6.99975 8.34925 6.77625 8.125 6.4995 8.125C6.2235 8.125 6 8.3485 6 8.62525V15.1255C6 15.6767 6.4485 16.1252 6.99975 16.1252H11.0002V17.125H9.87525C9.5985 17.125 9.375 17.3485 9.375 17.6252C9.375 17.9012 9.5985 18.1255 9.87525 18.1255H14.1255C14.4022 18.1255 14.6257 17.902 14.6257 17.6252C14.6257 17.3492 14.4022 17.125 14.1255 17.125H13.0005V16.1252H17.001C17.5522 16.1252 18.0007 15.6767 18.0007 15.1255V8.62525C18 8.3485 17.7765 8.125 17.5005 8.125Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5428">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxEye;
