import { SVGProps } from "types";

const BoxHyperlink = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5401)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M17.1255 8H14.3355L15.3353 8.99975H17.0002V14.4995H6.99975V8.99975H8.00475C7.815 8.6945 7.68975 8.36 7.6395 8H6.8745C6.3945 8 6 8.3945 6 8.8745V15.6245C6 16.1045 6.3945 16.4998 6.8745 16.4998H10.704C10.6455 16.7502 10.4895 17.1567 10.0732 17.573C10.002 17.6442 9.98025 17.7523 10.0192 17.8453C10.0575 17.939 10.149 17.9998 10.2502 17.9998H13.7505C13.8517 17.9998 13.9433 17.939 13.9815 17.8453C14.0198 17.7515 13.9987 17.6442 13.9275 17.573C13.512 17.1575 13.3522 16.751 13.29 16.4998H17.1255C17.6055 16.4998 18 16.1045 18 15.6245V8.8745C18 8.3945 17.6055 8 17.1255 8Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M11.8649 10.8663C11.4479 10.8663 11.0324 10.7051 10.7152 10.3878L9.08467 8.75209C8.77717 8.44834 8.60767 8.04034 8.60767 7.60534C8.60767 7.16959 8.77792 6.76159 9.08692 6.45559C9.67942 5.85034 10.7662 5.84284 11.3812 6.45709L11.6609 6.73759C11.8072 6.88384 11.8072 7.12159 11.6609 7.26784C11.5139 7.41409 11.2769 7.41334 11.1307 7.26784L10.8509 6.98809C10.5217 6.65884 9.93742 6.65959 9.61867 6.98509C9.44842 7.15309 9.35767 7.37134 9.35767 7.60534C9.35767 7.83934 9.44917 8.05759 9.61417 8.22109L11.2462 9.85834C11.5657 10.1778 12.0697 10.2033 12.4184 9.91759C12.5774 9.78709 12.8152 9.80959 12.9457 9.97009C13.0769 10.1306 13.0537 10.3668 12.8932 10.4981C12.5909 10.7448 12.2272 10.8663 11.8649 10.8663Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M13.7677 12.7666C13.332 12.7666 12.924 12.5964 12.618 12.2881L12.3495 12.0189C12.2032 11.8726 12.2032 11.6349 12.3502 11.4887C12.4965 11.3424 12.735 11.3424 12.8812 11.4894L13.1505 11.7594C13.3147 11.9259 13.5337 12.0167 13.7677 12.0167C14.0017 12.0167 14.22 11.9259 14.3835 11.7609C14.5515 11.5944 14.643 11.3762 14.643 11.1422C14.643 10.9082 14.5522 10.6899 14.3865 10.5264L12.7545 8.88915C12.447 8.58166 11.9137 8.55691 11.5837 8.82841C11.4247 8.95966 11.1885 8.9379 11.0557 8.77816C10.9237 8.61841 10.9462 8.38216 11.106 8.25016C11.721 7.74166 12.7132 7.78741 13.2847 8.35891L14.9152 9.99465C15.2227 10.2984 15.3922 10.7064 15.3922 11.1422C15.3922 11.5772 15.222 11.9851 14.913 12.2911C14.6115 12.5964 14.2035 12.7666 13.7677 12.7666Z"
          fill="var(--color-sky-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5401">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxHyperlink;
