import { SVGProps } from "types";

const BoxSetting = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5454)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M17.0655 11.2098C17.0452 11.2548 17.0258 11.2998 17.0002 11.3448V14.5H6.99975V8.99952H12.1403C12.1755 8.81952 12.255 8.64927 12.3705 8.49927C12.2557 8.34927 12.1755 8.17902 12.1403 7.99902H6.87525C6.3945 7.99977 6 8.39427 6 8.87427V15.6243C6 16.1043 6.3945 16.4995 6.8745 16.4995H10.704C10.6455 16.75 10.4895 17.1565 10.0732 17.5728C10.002 17.644 9.98025 17.752 10.0192 17.845C10.0575 17.9388 10.149 17.9995 10.2502 17.9995H13.7505C13.8517 17.9995 13.9433 17.9388 13.9815 17.845C14.0198 17.7513 13.9987 17.644 13.9275 17.5728C13.512 17.1573 13.3522 16.7508 13.29 16.4995H17.1255C17.6055 16.4995 18 16.1043 18 15.6243V10.969C17.7405 11.1693 17.4 11.2593 17.0655 11.2098Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M14.0003 11.9995C14.0003 12.8298 13.3305 13.4995 12.5003 13.4995H9.24976C8.55976 13.4995 7.99951 12.9393 7.99951 12.2493C7.99951 11.6245 8.46451 11.1093 9.06451 11.0193C9.27976 10.1493 10.0598 9.49902 10.9995 9.49902C11.5245 9.49902 11.9895 9.69927 12.3443 10.039L12.669 10.5993C12.9038 11.0043 13.3343 11.2345 13.779 11.224C13.92 11.4543 14.0003 11.7145 14.0003 11.9995Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M17.3513 8.7345C17.361 8.6565 17.3753 8.58 17.3753 8.49975C17.3753 8.4195 17.3618 8.343 17.3513 8.265L17.8013 7.9245C17.9018 7.84875 17.9303 7.70925 17.8665 7.6005L17.463 6.9C17.4 6.7905 17.2658 6.7455 17.1495 6.795L16.6313 7.014C16.5053 6.918 16.3703 6.8325 16.2218 6.77025L16.1528 6.21975C16.137 6.09375 16.0305 6 15.9045 6H15.096C14.97 6 14.8635 6.09375 14.8478 6.219L14.7788 6.7695C14.6303 6.83175 14.4953 6.9165 14.3693 7.01325L13.851 6.79425C13.7348 6.7455 13.6005 6.7905 13.5375 6.89925L13.1333 7.59975C13.0703 7.70925 13.098 7.848 13.1985 7.92375L13.6485 8.26425C13.6388 8.34225 13.6245 8.41875 13.6245 8.499C13.6245 8.57925 13.638 8.65575 13.6485 8.73375L13.1985 9.07425C13.098 9.15 13.0695 9.2895 13.1333 9.39825L13.5375 10.0987C13.6005 10.2082 13.7348 10.2532 13.851 10.2037L14.3693 9.98475C14.4953 10.0807 14.6303 10.1662 14.7788 10.2285L14.8478 10.779C14.8635 10.9042 14.97 10.998 15.096 10.998H15.9045C16.0305 10.998 16.137 10.9042 16.1528 10.779L16.2218 10.2285C16.3703 10.1662 16.5053 10.0815 16.6313 9.98475L17.1495 10.2037C17.2658 10.2525 17.4 10.2075 17.463 10.0987L17.8673 9.39825C17.9303 9.28875 17.9025 9.15 17.802 9.07425L17.3513 8.7345ZM15.5003 9.50025C14.9483 9.50025 14.5005 9.0525 14.5005 8.5005C14.5005 7.9485 14.9483 7.50075 15.5003 7.50075C16.0523 7.5 16.5 7.94775 16.5 8.49975C16.5 9.0525 16.0523 9.50025 15.5003 9.50025Z"
          fill="var(--color-sky-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5454">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSetting;
