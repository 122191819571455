import { SVGProps } from "types";

const IllustrationHacked = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6628)">
        <path
          d="M70.1644 26.5138C66.0915 24.9263 62.6505 22.0297 60.5461 18.1983C57.0072 11.7552 50.1597 7.3877 42.2893 7.3877C34.1201 7.3877 27.0533 12.0931 23.6449 18.941C21.5573 23.1349 18.0049 26.4278 13.6606 28.1811C7.24128 30.772 1.8523 36.6931 0.142148 45.9499C-0.0455393 46.9657 -0.0482893 48.01 0.139398 49.0258C2.79452 63.3897 14.3017 69.7256 24.7749 68.0455C30.6403 67.1046 36.5779 69.0611 40.9255 73.1093C49.7657 81.3407 63.9368 83.9214 77.6935 75.0979C79.3052 74.0642 81.4674 71.8951 82.5002 70.2828C94.3537 51.777 85.5731 32.5194 70.1644 26.5138Z"
          fill="url(#paint0_linear_2042_6628)"
        />
        <path
          opacity="0.1"
          d="M78.3751 17.1084V60.8076C78.3751 62.2307 77.2201 63.3857 75.797 63.3857H12.2032C10.7801 63.3857 9.62512 62.2307 9.62512 60.8076V17.1084C9.62512 15.6853 10.7801 14.5303 12.2032 14.5303H75.797C77.2201 14.5303 78.3751 15.6851 78.3751 17.1084Z"
          fill="#2626BC"
        />
        <path
          d="M53.7472 79.4913H34.2529L38.1514 64.0391H49.8487L53.7472 79.4913Z"
          fill="#9FB0FE"
        />
        <path
          d="M51.2065 69.4191H36.7936L38.1514 64.0391H49.8487L51.2065 69.4191Z"
          fill="#8399FE"
        />
        <path
          d="M78.3751 20.7178V64.417C78.3751 65.8401 77.2201 66.9951 75.797 66.9951H12.2032C10.7801 66.9951 9.62512 65.8401 9.62512 64.417V20.7178C9.62512 19.2946 10.7801 18.1396 12.2032 18.1396H75.797C77.2201 18.1396 78.3751 19.2946 78.3751 20.7178Z"
          fill="#FFA8CF"
        />
        <path
          d="M18.884 26.4189H69.1162V58.3337H18.884V26.4189Z"
          fill="#FFC4DF"
        />
        <path
          d="M78.3751 26.419V20.7178C78.3751 19.294 77.2208 18.1396 75.797 18.1396H12.2032C10.7794 18.1396 9.62512 19.294 9.62512 20.7178V26.419H78.3751Z"
          fill="#6583FE"
        />
        <path
          d="M9.62512 58.334V64.4166C9.62512 65.8405 10.7794 66.9948 12.2032 66.9948H75.797C77.2208 66.9948 78.3751 65.8405 78.3751 64.4166V58.334H9.62512Z"
          fill="#B7C5FF"
        />
        <path
          d="M16.1704 21.4072C15.6959 21.4072 15.311 21.7919 15.311 22.2666V22.2917C15.311 22.7664 15.6959 23.1511 16.1704 23.1511C16.645 23.1511 17.0298 22.7664 17.0298 22.2917V22.2666C17.0298 21.7919 16.645 21.4072 16.1704 21.4072Z"
          fill="#2626BC"
        />
        <path
          d="M19.4919 21.4072C19.0174 21.4072 18.6326 21.7919 18.6326 22.2666V22.2917C18.6326 22.7664 19.0174 23.1511 19.4919 23.1511C19.9665 23.1511 20.3513 22.7664 20.3513 22.2917V22.2666C20.3513 21.7919 19.9665 21.4072 19.4919 21.4072Z"
          fill="#2626BC"
        />
        <path
          d="M22.8132 21.4072C22.3387 21.4072 21.9539 21.7919 21.9539 22.2666V22.2917C21.9539 22.7664 22.3387 23.1511 22.8132 23.1511C23.2878 23.1511 23.6726 22.7664 23.6726 22.2917V22.2666C23.6726 21.7919 23.288 21.4072 22.8132 21.4072Z"
          fill="#2626BC"
        />
        <path
          d="M75.797 17.2803H12.2032C10.3078 17.2803 8.76575 18.8223 8.76575 20.7178V26.4192C8.76575 26.8939 9.15058 27.2786 9.62512 27.2786H77.5157V53.6006C77.5157 54.0753 77.9006 54.4599 78.3751 54.4599C78.8497 54.4599 79.2345 54.0753 79.2345 53.6006V20.7178C79.2345 18.8223 77.6924 17.2803 75.797 17.2803ZM77.5157 25.5598H10.4845V20.7178C10.4845 19.7701 11.2555 18.999 12.2032 18.999H75.797C76.7447 18.999 77.5157 19.7701 77.5157 20.7178V25.5598Z"
          fill="#2626BC"
        />
        <path
          d="M78.3751 57.4743H10.4845V31.1523C10.4845 30.6776 10.0997 30.293 9.62512 30.293C9.15058 30.293 8.76575 30.6776 8.76575 31.1523V64.4163C8.76575 66.3118 10.3078 67.8539 12.2032 67.8539H36.3025L33.5836 78.6306H30.949C30.4744 78.6306 30.0896 79.0152 30.0896 79.49C30.0896 79.9647 30.4744 80.3493 30.949 80.3493H57.0513C57.5258 80.3493 57.9106 79.9647 57.9106 79.49C57.9106 79.0152 57.5258 78.6306 57.0513 78.6306H54.4163L51.6974 67.8539H75.797C77.6924 67.8539 79.2345 66.3118 79.2345 64.4163V58.3337C79.2345 57.8591 78.8497 57.4743 78.3751 57.4743ZM52.6437 78.6306H35.3562L38.0751 67.8539H49.925L52.6437 78.6306ZM77.5157 64.4163C77.5157 65.3641 76.7447 66.1351 75.797 66.1351H12.2032C11.2555 66.1351 10.4845 65.3641 10.4845 64.4163V59.1931H77.5157V64.4163Z"
          fill="#2626BC"
        />
        <path
          d="M70.2939 53.1236C70.0739 53.1236 69.8539 53.0397 69.6862 52.8718C69.4911 52.6767 69.2319 52.5694 68.9562 52.5694C68.6805 52.5694 68.4214 52.6767 68.2263 52.8718C67.8908 53.2074 67.3465 53.2073 67.011 52.8719C66.843 52.704 66.7592 52.4842 66.7592 52.2642C66.7592 52.0444 66.843 51.8244 67.0108 51.6566C67.4133 51.2539 67.4133 50.5991 67.0108 50.1964C66.8429 50.0284 66.7592 49.8086 66.7592 49.5888C66.7592 49.3688 66.843 49.1488 67.011 48.981C67.3466 48.6457 67.891 48.6457 68.2263 48.9812C68.4214 49.1763 68.6805 49.2835 68.9562 49.2835C69.2319 49.2835 69.4911 49.1763 69.6862 48.9812C70.0217 48.6455 70.566 48.6457 70.9015 48.981C71.0694 49.149 71.1533 49.3688 71.1533 49.5888C71.1533 49.8086 71.0694 50.0286 70.9017 50.1964C70.4992 50.5991 70.4992 51.2539 70.9017 51.6566C71.0696 51.8245 71.1533 52.0444 71.1533 52.2642C71.1533 52.4842 71.0694 52.7042 70.9015 52.8719C70.7338 53.0397 70.5138 53.1236 70.2939 53.1236Z"
          fill="#4369FD"
        />
        <path
          d="M70.294 46.9593C70.074 46.9593 69.854 46.8755 69.6862 46.7075C69.4912 46.5125 69.232 46.405 68.9563 46.405C68.6806 46.405 68.4214 46.5125 68.2263 46.7075C67.8908 47.0432 67.3465 47.043 67.011 46.7077C66.8431 46.5398 66.7592 46.32 66.7592 46.1C66.7592 45.8801 66.8431 45.6601 67.0108 45.4924C67.2059 45.2973 67.3132 45.0379 67.3132 44.7623C67.3132 44.4866 67.2057 44.2274 67.0108 44.0325C66.8429 43.8645 66.759 43.6447 66.759 43.4247C66.759 43.2049 66.8429 42.9849 67.0107 42.8171C67.3462 42.4815 67.8905 42.4816 68.226 42.817C68.6287 43.2195 69.2834 43.2195 69.6861 42.817C70.0217 42.4816 70.5661 42.4816 70.9014 42.8171C71.0693 42.9851 71.153 43.2049 71.153 43.4247C71.153 43.6447 71.0691 43.8647 70.9012 44.0325C70.7063 44.2274 70.5989 44.4866 70.5989 44.7623C70.5989 45.0379 70.7063 45.2973 70.9012 45.4924C71.0691 45.6603 71.1529 45.8801 71.1529 46.1C71.1529 46.32 71.069 46.54 70.9011 46.7077C70.7338 46.8753 70.5138 46.9593 70.294 46.9593Z"
          fill="#4369FD"
        />
        <path
          d="M20.3817 42.4173C20.1617 42.4173 19.9418 42.3335 19.7741 42.1657C19.3714 41.7632 18.7167 41.7632 18.314 42.1657C17.9785 42.5012 17.4343 42.5012 17.0987 42.1655C16.9307 41.9976 16.847 41.7778 16.847 41.558C16.847 41.338 16.9309 41.118 17.0988 40.9502C17.2937 40.7553 17.4012 40.4959 17.4012 40.2204C17.4012 39.9447 17.2937 39.6854 17.0988 39.4905C16.9309 39.3226 16.847 39.1027 16.847 38.8827C16.847 38.6629 16.9309 38.4429 17.0987 38.2751C17.4342 37.9395 17.9785 37.9396 18.314 38.275C18.7167 38.6775 19.3714 38.6775 19.7741 38.275C20.1097 37.9396 20.6539 37.9396 20.9894 38.2751C21.1573 38.4431 21.241 38.6629 21.241 38.8827C21.241 39.1027 21.1572 39.3227 20.9892 39.4905C20.7943 39.6854 20.6869 39.9447 20.6869 40.2204C20.6869 40.4961 20.7943 40.7553 20.9892 40.9502C21.1572 41.1181 21.241 41.338 21.241 41.558C21.241 41.7778 21.1572 41.9978 20.9894 42.1655C20.8217 42.3335 20.6017 42.4173 20.3817 42.4173Z"
          fill="#4369FD"
        />
        <path
          d="M20.3817 36.2533C20.1617 36.2533 19.9418 36.1694 19.7741 36.0017C19.3714 35.5991 18.7167 35.5991 18.314 36.0017C17.9785 36.3372 17.4343 36.3372 17.0987 36.0015C16.9307 35.8336 16.847 35.6137 16.847 35.3939C16.847 35.1739 16.9309 34.9539 17.0988 34.7862C17.2937 34.5912 17.4012 34.3321 17.4012 34.0564C17.4012 33.7807 17.2937 33.5213 17.0988 33.3262C16.9309 33.1583 16.8472 32.9385 16.8472 32.7187C16.8472 32.4987 16.9311 32.2787 17.099 32.1109C17.4347 31.7756 17.9788 31.7756 18.3143 32.1111C18.5094 32.3062 18.7686 32.4134 19.0443 32.4134C19.32 32.4134 19.5792 32.3062 19.7743 32.1111C20.1098 31.7754 20.6541 31.7756 20.9896 32.1109C21.1575 32.2788 21.2414 32.4987 21.2414 32.7187C21.2414 32.9385 21.1575 33.1585 20.9898 33.3262C20.7947 33.5213 20.6874 33.7807 20.6874 34.0564C20.6874 34.3321 20.7948 34.5912 20.9898 34.7862C21.1577 34.9541 21.2415 35.1739 21.2415 35.3939C21.2415 35.6137 21.1577 35.8337 20.9899 36.0015C20.8217 36.1692 20.6017 36.2533 20.3817 36.2533Z"
          fill="#4369FD"
        />
        <path
          d="M22.8133 79.1504C22.5854 79.1504 22.3668 79.06 22.2056 78.8986L20.3158 77.0088C20.1546 76.8476 20.0642 76.6291 20.0642 76.4012C20.0642 76.1733 20.1548 75.9547 20.3158 75.7936L22.2056 73.9041C22.5413 73.5684 23.0854 73.5686 23.4209 73.9039L25.3107 75.7935C25.4719 75.9547 25.5625 76.1733 25.5625 76.4012C25.5625 76.6291 25.4719 76.8478 25.3109 77.0088L23.4211 78.8986C23.2599 79.0598 23.0414 79.1504 22.8133 79.1504ZM22.1391 76.4012L22.8133 77.0757L23.4878 76.4012L22.8133 75.727L22.1391 76.4012Z"
          fill="#6583FE"
        />
        <path
          d="M16.0196 75.5459C15.7917 75.5459 15.5731 75.4555 15.4119 75.2941L13.5221 73.4043C13.3609 73.2431 13.2705 73.0246 13.2705 72.7967C13.2705 72.5688 13.3611 72.3502 13.5221 72.1892L15.4119 70.2996C15.7476 69.9639 16.2917 69.9641 16.6272 70.2994L18.517 72.189C18.6782 72.3502 18.7688 72.5688 18.7688 72.7967C18.7688 73.0246 18.6782 73.2433 18.5172 73.4043L16.6274 75.2941C16.4662 75.4555 16.2476 75.5459 16.0196 75.5459ZM15.3454 72.7967L16.0196 73.4712L16.6941 72.7967L16.0196 72.1225L15.3454 72.7967Z"
          fill="#FF7EB8"
        />
        <path
          d="M70.989 80.1816C70.769 80.1816 70.5492 80.0977 70.3813 79.9298L68.4915 78.04C68.3303 77.8788 68.2399 77.6603 68.2399 77.4324C68.2399 77.2045 68.3304 76.9859 68.4917 76.8247L70.3814 74.9351C70.7169 74.5998 71.2611 74.5998 71.5966 74.9351L73.4864 76.8247C73.6476 76.9859 73.7382 77.2045 73.7382 77.4324C73.7382 77.6603 73.6476 77.879 73.4865 78.04L71.5968 79.9298C71.4288 80.0975 71.209 80.1816 70.989 80.1816ZM70.3147 77.4324L70.9892 78.1069L71.6636 77.4324L70.9892 76.758L70.3147 77.4324Z"
          fill="#6583FE"
        />
        <path
          d="M53.165 49.4147C53.072 49.5077 52.9911 49.6079 52.9199 49.7128L45.5841 42.377L52.9199 35.0412C52.9911 35.146 53.0722 35.2462 53.1652 35.3392C53.9585 36.1326 55.2449 36.1326 56.0382 35.3392C56.8316 34.5458 56.8316 33.2595 56.0382 32.4661C55.6159 32.0438 55.0543 31.8488 54.5013 31.8759C54.5285 31.323 54.3334 30.7613 53.9111 30.3392C53.1177 29.5458 51.8314 29.5458 51.038 30.3392C50.2447 31.1325 50.2447 32.4189 51.038 33.2122C51.131 33.3052 51.2312 33.3862 51.3361 33.4573L44.0001 40.7931L36.6643 33.4573C36.7692 33.3862 36.8695 33.305 36.9624 33.2122C37.7557 32.4189 37.7557 31.1325 36.9624 30.3392C36.169 29.5458 34.8827 29.5458 34.0895 30.3392C33.6672 30.7615 33.4721 31.3232 33.4992 31.8759C32.9463 31.8488 32.3846 32.0438 31.9623 32.4661C31.169 33.2595 31.169 34.5458 31.9623 35.3392C32.7557 36.1326 34.042 36.1326 34.8352 35.3392C34.9282 35.2462 35.0092 35.146 35.0805 35.0412L42.4163 42.377L35.0805 49.7128C35.0093 49.6079 34.9282 49.5075 34.8354 49.4147C34.042 48.6214 32.7557 48.6214 31.9625 49.4147C31.1691 50.2081 31.1691 51.4944 31.9625 52.2876C32.3848 52.7099 32.9465 52.905 33.4994 52.8778C33.4723 53.4308 33.6673 53.9924 34.0896 54.4147C34.883 55.2081 36.1693 55.2081 36.9625 54.4147C37.7559 53.6214 37.7559 52.3351 36.9625 51.5419C36.8695 51.4489 36.7693 51.3679 36.6645 51.2966L44.0001 43.9608L51.3359 51.2966C51.2311 51.3677 51.1307 51.4489 51.0379 51.5419C50.2445 52.3352 50.2445 53.6215 51.0379 54.4147C51.8313 55.2081 53.1176 55.2081 53.9109 54.4147C54.3332 53.9924 54.5283 53.4308 54.5012 52.8778C55.0541 52.905 55.6158 52.7099 56.0381 52.2876C56.8314 51.4942 56.8314 50.2079 56.0381 49.4147C55.2445 48.6214 53.9582 48.6214 53.165 49.4147Z"
          fill="#D2DEFF"
        />
        <path
          d="M50.5256 44.8241C51.5742 43.3817 52.1586 41.5801 52.0517 39.6369C51.8251 35.5132 48.448 32.1926 44.3212 32.0326C39.7214 31.854 35.9362 35.5303 35.9362 40.0903C35.9362 41.8599 36.5083 43.4944 37.4748 44.8241L36.4351 45.8637C36.2601 46.0387 36.2601 46.3223 36.4351 46.4973L37.247 47.3092C37.7706 47.8327 38.0648 48.5429 38.0648 49.2834C38.0648 49.6957 38.3991 50.03 38.8115 50.03H40.3793C40.7916 50.03 41.1259 49.6957 41.1259 49.2834V48.5347C41.1259 48.1026 41.5327 47.78 41.9507 47.8895C41.9552 47.8907 41.9599 47.8919 41.9643 47.8931C42.2603 47.9701 42.4698 48.2323 42.4698 48.5381V50.4445C42.4698 50.8569 42.8041 51.1912 43.2164 51.1912H44.7843C45.1966 51.1912 45.5309 50.8569 45.5309 50.4445V48.5381C45.5309 48.2323 45.7406 47.9701 46.0364 47.8931C46.0409 47.8919 46.0455 47.8907 46.05 47.8895C46.468 47.7798 46.8748 48.1026 46.8748 48.5347V49.2834C46.8748 49.6957 47.2091 50.03 47.6214 50.03H49.1893C49.6016 50.03 49.9359 49.6957 49.9359 49.2834C49.9359 48.5429 50.23 47.8327 50.7537 47.3092L51.5656 46.4973C51.7406 46.3223 51.7406 46.0387 51.5656 45.8637L50.5256 44.8241Z"
          fill="white"
        />
        <path
          d="M42.8579 40.6741C43.0329 39.5945 42.2996 38.5775 41.22 38.4025C40.1404 38.2274 39.1234 38.9608 38.9484 40.0403C38.7733 41.1199 39.5067 42.137 40.5862 42.312C41.6658 42.487 42.6829 41.7537 42.8579 40.6741Z"
          fill="#B7C5FF"
        />
        <path
          d="M51.4199 44.1064C51.3022 44.1064 51.1825 44.082 51.0683 44.0308C44.5367 41.0955 40.7372 33.1736 40.5785 32.8378C40.3759 32.4086 40.5596 31.8962 40.9888 31.6938C41.4175 31.4913 41.93 31.6745 42.1326 32.1035C42.1689 32.1802 45.8267 39.791 51.7728 42.4631C52.2057 42.6577 52.3989 43.1664 52.2043 43.5992C52.0612 43.9177 51.7478 44.1064 51.4199 44.1064Z"
          fill="#8399FE"
        />
        <path
          d="M45.0517 38.7471C44.8062 38.7471 44.5979 38.9418 44.5974 39.1872V39.1917C44.5974 40.841 45.9344 42.178 47.5837 42.178C49.2331 42.178 50.5701 40.841 50.5701 39.1917C50.5701 39.1902 50.5701 39.1886 50.5701 39.1872C50.5696 38.9418 50.3612 38.7471 50.1158 38.7471H45.0517Z"
          fill="#FF7EB8"
        />
        <path
          d="M43.6214 43.112L42.8291 44.3657C42.6405 44.6639 42.8548 45.053 43.2077 45.053H44.7922C45.1451 45.053 45.3594 44.6641 45.1709 44.3657L44.3785 43.112C44.203 42.8338 43.7972 42.8338 43.6214 43.112Z"
          fill="#B7C5FF"
        />
        <path
          d="M61.9516 12.9194C61.477 12.9194 61.0922 12.5348 61.0922 12.0601C61.0922 11.4889 60.6275 11.0242 60.0562 11.0242C59.5816 11.0242 59.1968 10.6395 59.1968 10.1648C59.1968 9.69008 59.5816 9.30542 60.0562 9.30542C60.6275 9.30542 61.0922 8.84067 61.0922 8.26953C61.0922 7.79481 61.477 7.41016 61.9516 7.41016C62.4261 7.41016 62.811 7.79481 62.811 8.26953C62.811 8.84067 63.2757 9.30542 63.8469 9.30542C64.3214 9.30542 64.7062 9.69008 64.7062 10.1648C64.7062 10.6395 64.3214 11.0242 63.8469 11.0242C63.2757 11.0242 62.811 11.4889 62.811 12.0601C62.811 12.5348 62.4263 12.9194 61.9516 12.9194Z"
          fill="#97FFD2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6628"
          x1="9.16868"
          y1="24.6046"
          x2="87.7776"
          y2="70.4598"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#FFDBED" />
          <stop offset="0.9993" stopColor="#D2DEFF" />
        </linearGradient>
        <clipPath id="clip0_2042_6628">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationHacked;
