import { SVGProps } from "types";

const BoxHierarchyStructure = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5363)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M8.3745 16H9.1245C9.60675 16 9.99975 15.6077 9.99975 15.1248H14.0002C14.0002 15.607 14.3925 16 14.8755 16H15.6255C16.1077 16 16.5007 15.6077 16.5007 15.1248V14.3748C16.5 13.8925 16.1077 13.5002 15.6255 13.5002H14.8755C14.3932 13.5002 14.0002 13.8925 14.0002 14.3755H12.375V12.4368C12.8805 12.2763 13.2502 11.8082 13.2502 11.2502C13.2502 10.561 12.6892 10 12 10C11.3108 10 10.7498 10.561 10.7498 11.2502C10.7498 11.8082 11.1195 12.2763 11.625 12.4368V14.3755H9.99975C9.99975 13.8932 9.6075 13.5002 9.1245 13.5002H8.3745C7.89225 13.5002 7.5 13.8925 7.5 14.3755V15.1255C7.5 15.6077 7.89225 16 8.3745 16Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM17.0002 15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5363">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxHierarchyStructure;
