import { SVGProps } from "types";

const IllustrationApproved = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M86.9044 50.173C84.8718 44.2244 80.3495 39.833 74.8986 37.7633C72.0747 36.6909 70.1985 34.0068 70.1571 30.9864C70.1562 30.9204 70.155 30.8542 70.1535 30.7881C69.8945 19.688 60.6165 10.7938 49.5153 10.9845C42.9678 11.0969 37.1767 14.3111 33.5472 19.2207C30.4217 23.4487 25.5567 26.0897 20.2991 26.0869C20.1447 26.0869 19.9902 26.0885 19.835 26.0919C9.19304 26.3246 0.143819 35.4553 0.00185036 46.0989C-0.1494 57.4307 8.99126 66.6644 20.2886 66.6644C29.0451 66.6644 36.5048 61.1168 39.3471 53.3446C40.0908 51.311 42.2951 50.2209 44.3801 50.8057C44.4061 50.8129 44.4322 50.8203 44.4583 50.8275C46.366 51.3541 47.6308 53.1718 47.4748 55.1448C47.2656 57.7888 47.5746 60.5168 48.4713 63.1879C51.9983 73.6954 63.4567 79.4618 73.9935 76.0235C84.772 72.5061 90.5587 60.8667 86.9044 50.173Z"
        fill="url(#paint0_linear_2042_6642)"
      />
      <path
        opacity="0.1"
        d="M79.2344 24.4187C79.2344 16.8617 73.1082 10.7354 65.5511 10.7354C59.7879 10.7354 54.8606 14.3 52.8442 19.3432H11.6775C10.0693 19.3432 8.76562 20.6469 8.76562 22.2549V60.2128C8.76562 61.8211 10.0693 63.1247 11.6775 63.1247H64.6919C66.3001 63.1247 67.6038 61.8211 67.6038 60.2128V37.9481C74.1869 36.9578 79.2344 31.278 79.2344 24.4187Z"
        fill="#2626BC"
      />
      <path
        d="M46.9194 77.9326H29.4497L31.7265 68.9064L32.9432 64.085H43.4259L44.6426 68.9064L46.9194 77.9326Z"
        fill="#9FB0FE"
      />
      <path
        d="M44.6426 68.9064H31.7266L32.9433 64.085H43.4259L44.6426 68.9064Z"
        fill="#8399FE"
      />
      <path
        d="M46.9199 78.7917H29.4491C29.1841 78.7917 28.934 78.6695 28.7712 78.4605C28.6085 78.2515 28.5511 77.9791 28.616 77.7222L31.3655 66.8258C31.4815 66.3655 31.9487 66.0871 32.409 66.2028C32.8691 66.3189 33.148 66.7861 33.0319 67.2462L30.5522 77.073H45.8169L43.315 67.1575C43.1988 66.6974 43.4777 66.2301 43.9378 66.1141C44.3983 65.9984 44.8653 66.2768 44.9813 66.7371L47.7531 77.7223C47.8181 77.9793 47.7607 78.2517 47.5979 78.4607C47.4351 78.6697 47.1849 78.7917 46.9199 78.7917Z"
        fill="#2626BC"
      />
      <path
        d="M64.6919 66.7337H11.6775C10.0693 66.7337 8.76562 65.43 8.76562 63.822V25.8641C8.76562 24.2558 10.0693 22.9521 11.6775 22.9521H64.6919C66.3001 22.9521 67.6036 24.2558 67.6036 25.8641V63.822C67.6036 65.43 66.2999 66.7337 64.6919 66.7337Z"
        fill="white"
      />
      <path
        d="M67.603 25.8634V43.9023C66.9332 44.0025 66.2493 44.0553 65.5513 44.0553C57.9936 44.0553 51.8669 37.9286 51.8669 30.3727C51.8669 27.6373 52.6686 25.0898 54.0522 22.9521H64.6917C66.3003 22.952 67.603 24.2565 67.603 25.8634Z"
        fill="#D2DEFF"
      />
      <path
        d="M46.2571 25.3818H60.3493V58.9722H46.2571V25.3818Z"
        fill="#FFDBED"
      />
      <path
        d="M60.3496 25.3818V43.0307C55.3709 40.9843 51.8672 36.0866 51.8672 30.3729C51.8672 28.6114 52.1994 26.9289 52.806 25.3818H60.3496Z"
        fill="#FFA8CF"
      />
      <path
        d="M62.7089 58.9725H43.8982V45.0656C43.8982 44.7742 44.1343 44.5381 44.4257 44.5381H62.1817C62.4731 44.5381 62.7092 44.7742 62.7092 45.0656V58.9725H62.7089Z"
        fill="#D2DEFF"
      />
      <path
        d="M62.7089 48.7547H43.8982V45.0656C43.8982 44.7742 44.1343 44.5381 44.4257 44.5381H62.1817C62.4731 44.5381 62.7092 44.7742 62.7092 45.0656V48.7547H62.7089Z"
        fill="#6583FE"
      />
      <path
        d="M67.6036 30.3718V25.8641C67.6036 24.2558 66.2999 22.9521 64.6919 22.9521H11.6775C10.0695 22.9521 8.76562 24.2558 8.76562 25.8641V30.3718H67.6036Z"
        fill="#6583FE"
      />
      <path
        d="M51.8671 31.2314H8.76562C8.29091 31.2314 7.90625 30.8466 7.90625 30.3721C7.90625 29.8975 8.29091 29.5127 8.76562 29.5127H51.8671C52.3418 29.5127 52.7264 29.8975 52.7264 30.3721C52.7264 30.8466 52.3418 31.2314 51.8671 31.2314Z"
        fill="#2626BC"
      />
      <path
        d="M8.76562 58.9727V63.8221C8.76562 65.4303 10.0693 66.7339 11.6775 66.7339H64.6919C66.2999 66.7339 67.6036 65.4302 67.6036 63.8221V58.9727H8.76562Z"
        fill="#B7C5FF"
      />
      <path
        d="M49.8804 78.792H26.4888C26.0141 78.792 25.6294 78.4072 25.6294 77.9326C25.6294 77.4581 26.0141 77.0732 26.4888 77.0732H49.8804C50.3552 77.0732 50.7398 77.4581 50.7398 77.9326C50.7398 78.4072 50.3552 78.792 49.8804 78.792Z"
        fill="#2626BC"
      />
      <path
        d="M14.6311 27.5331C14.1564 27.5331 13.7717 27.1483 13.7717 26.6737V26.6514C13.7717 26.1768 14.1564 25.792 14.6311 25.792C15.1058 25.792 15.4905 26.1768 15.4905 26.6514V26.6737C15.4905 27.1483 15.1058 27.5331 14.6311 27.5331Z"
        fill="#2626BC"
      />
      <path
        d="M17.6077 27.5331C17.1329 27.5331 16.7483 27.1483 16.7483 26.6737V26.6514C16.7483 26.1768 17.1329 25.792 17.6077 25.792C18.0824 25.792 18.467 26.1768 18.467 26.6514V26.6737C18.467 27.1483 18.0824 27.5331 17.6077 27.5331Z"
        fill="#2626BC"
      />
      <path
        d="M20.5845 27.5331C20.1098 27.5331 19.7251 27.1483 19.7251 26.6737V26.6514C19.7251 26.1768 20.1098 25.792 20.5845 25.792C21.0592 25.792 21.4438 26.1768 21.4438 26.6514V26.6737C21.4438 27.1483 21.059 27.5331 20.5845 27.5331Z"
        fill="#2626BC"
      />
      <path
        d="M64.6919 67.5937H11.6774C9.59802 67.5937 7.90625 65.9019 7.90625 63.8224V58.973C7.90625 58.4984 8.29091 58.1136 8.76562 58.1136H66.7442V41.9268C66.7442 41.4522 67.1289 41.0674 67.6036 41.0674C68.0783 41.0674 68.463 41.4522 68.463 41.9268V63.8224C68.463 65.9019 66.7712 67.5937 64.6919 67.5937ZM9.625 59.8323V63.8224C9.625 64.9542 10.5456 65.875 11.6774 65.875H64.6917C65.8235 65.875 66.744 64.9542 66.744 63.8224V59.8323H9.625Z"
        fill="#2626BC"
      />
      <path
        d="M8.76562 54.991C8.29091 54.991 7.90625 54.6061 7.90625 54.1316V25.8649C7.90625 23.7853 9.59802 22.0938 11.6774 22.0938H48.0253C48.5 22.0938 48.8847 22.4786 48.8847 22.9531C48.8847 23.4277 48.5 23.8125 48.0253 23.8125H11.6774C10.5456 23.8125 9.625 24.7332 9.625 25.8649V54.1316C9.625 54.6061 9.24034 54.991 8.76562 54.991Z"
        fill="#2626BC"
      />
      <path
        d="M65.551 41.7113C73.1081 41.7113 79.2343 35.5851 79.2343 28.028C79.2343 20.471 73.1081 14.3447 65.551 14.3447C57.9939 14.3447 51.8677 20.471 51.8677 28.028C51.8677 35.5851 57.9939 41.7113 65.551 41.7113Z"
        fill="#02FFB3"
      />
      <path
        d="M65.5512 42.5705C57.5324 42.5705 51.0085 36.0467 51.0085 28.0279C51.0085 20.009 57.5324 13.4854 65.5512 13.4854C73.5701 13.4854 80.0938 20.0092 80.0938 28.0279C80.0938 36.0469 73.5701 42.5705 65.5512 42.5705ZM65.5512 15.2041C58.48 15.2041 52.7273 20.9569 52.7273 28.0279C52.7273 35.099 58.4801 40.8518 65.5512 40.8518C72.6223 40.8518 78.375 35.099 78.375 28.0279C78.375 20.9569 72.6222 15.2041 65.5512 15.2041Z"
        fill="#2626BC"
      />
      <path
        d="M72.7139 35.2052C76.6754 31.2437 76.6754 24.8208 72.7139 20.8593C68.7524 16.8978 62.3295 16.8978 58.368 20.8593C54.4065 24.8208 54.4065 31.2437 58.368 35.2052C62.3295 39.1667 68.7524 39.1667 72.7139 35.2052Z"
        fill="#DCFDEE"
      />
      <path
        d="M63.5881 32.5989C63.2384 32.5989 62.903 32.4601 62.6557 32.2127L59.9602 29.517C59.4452 29.0021 59.4452 28.1673 59.9602 27.6524C60.4755 27.1373 61.3101 27.1374 61.825 27.6524L63.5881 29.4156L69.2777 23.726C69.793 23.2111 70.6276 23.2111 71.1426 23.726C71.6575 24.241 71.6575 25.0758 71.1426 25.5907L64.5205 32.2126C64.273 32.4601 63.9377 32.5989 63.5881 32.5989Z"
        fill="#FF7EB8"
      />
      <path
        d="M30.8021 41.9261H15.3368C14.8506 41.9261 14.4563 41.5318 14.4563 41.0456C14.4563 40.5593 14.8504 40.165 15.3368 40.165H30.8023C31.2885 40.165 31.6828 40.5593 31.6828 41.0456C31.6826 41.5318 31.2885 41.9261 30.8021 41.9261Z"
        fill="#B7C5FF"
      />
      <path
        d="M39.3762 41.9261H35.152C34.6658 41.9261 34.2715 41.5318 34.2715 41.0456C34.2715 40.5593 34.6656 40.165 35.152 40.165H39.3762C39.8624 40.165 40.2567 40.5593 40.2567 41.0456C40.2567 41.5318 39.8626 41.9261 39.3762 41.9261Z"
        fill="#FFA8CF"
      />
      <path
        d="M39.3763 45.4192H15.3368C14.8506 45.4192 14.4563 45.025 14.4563 44.5387C14.4563 44.0525 14.8504 43.6582 15.3368 43.6582H39.3763C39.8625 43.6582 40.2568 44.0525 40.2568 44.5387C40.2568 45.025 39.8627 45.4192 39.3763 45.4192Z"
        fill="#B7C5FF"
      />
      <path
        d="M16.314 38.2758C16.0861 38.2758 15.8675 38.1852 15.7065 38.0242L14.5071 36.8248C14.1715 36.4892 14.1715 35.945 14.5071 35.6095C14.8425 35.274 15.387 35.274 15.7223 35.6095L16.3139 36.2011L18.4245 34.0905C18.7598 33.755 19.3043 33.755 19.6397 34.0905C19.9753 34.4262 19.9753 34.9703 19.6397 35.3058L16.9215 38.0242C16.7606 38.1852 16.542 38.2758 16.314 38.2758Z"
        fill="#4369FD"
      />
      <path
        d="M20.2539 55.9837H15.3366C14.8503 55.9837 14.4561 55.5894 14.4561 55.1032C14.4561 54.6169 14.8502 54.2227 15.3366 54.2227H20.2539C20.7401 54.2227 21.1344 54.6169 21.1344 55.1032C21.1344 55.5894 20.7403 55.9837 20.2539 55.9837Z"
        fill="#B7C5FF"
      />
      <path
        d="M39.3762 55.9837H24.6039C24.1177 55.9837 23.7234 55.5894 23.7234 55.1032C23.7234 54.6169 24.1175 54.2227 24.6039 54.2227H39.3762C39.8625 54.2227 40.2567 54.6169 40.2567 55.1032C40.2567 55.5894 39.8626 55.9837 39.3762 55.9837Z"
        fill="#FFA8CF"
      />
      <path
        d="M16.3141 52.3325C16.0862 52.3325 15.8675 52.2419 15.7063 52.0808L14.507 50.8815C14.1715 50.5458 14.1715 50.0017 14.5071 49.6662C14.8425 49.3307 15.3868 49.3307 15.7225 49.6662L16.3139 50.2578L18.4245 48.1471C18.7599 47.8116 19.3044 47.8116 19.6397 48.1471C19.9754 48.4828 19.9754 49.027 19.6397 49.3625L16.9216 52.0808C16.7606 52.2419 16.542 52.3325 16.3141 52.3325Z"
        fill="#4369FD"
      />
      <path
        d="M35.9962 14.7142C35.7683 14.7142 35.5497 14.6236 35.3886 14.4626L33.4947 12.5689C33.3335 12.4076 33.2429 12.1892 33.2429 11.9611C33.2429 11.733 33.3333 11.5146 33.4947 11.3534L35.3886 9.45963C35.5498 9.29841 35.7683 9.20801 35.9962 9.20801C36.2241 9.20801 36.4427 9.29859 36.6038 9.45963L38.4975 11.3534C38.8332 11.689 38.8332 12.2332 38.4975 12.5687L36.6038 14.4624C36.4425 14.6236 36.2241 14.7142 35.9962 14.7142ZM35.3176 11.9611L35.996 12.6395L36.6742 11.9611L35.996 11.2827L35.3176 11.9611Z"
        fill="#FF7EB8"
      />
      <path
        d="M74.9889 52.2513C74.5141 52.2513 74.1295 51.8665 74.1295 51.3919C74.1295 50.8216 73.6654 50.3576 73.095 50.3576C72.6203 50.3576 72.2356 49.9728 72.2356 49.4982C72.2356 49.0237 72.6203 48.6388 73.095 48.6388C73.6653 48.6388 74.1295 48.1748 74.1295 47.6045C74.1295 47.1299 74.5141 46.7451 74.9889 46.7451C75.4636 46.7451 75.8482 47.1299 75.8482 47.6045C75.8482 48.1748 76.3121 48.6388 76.8826 48.6388C77.3573 48.6388 77.742 49.0237 77.742 49.4982C77.742 49.9728 77.3573 50.3576 76.8826 50.3576C76.3123 50.3576 75.8482 50.8216 75.8482 51.3919C75.8482 51.8665 75.4636 52.2513 74.9889 52.2513Z"
        fill="#6583FE"
      />
      <path
        d="M29.4189 17.8255C28.9441 17.8255 28.5595 17.4407 28.5595 16.9662C28.5595 16.3959 28.0956 15.9318 27.5251 15.9318C27.0504 15.9318 26.6658 15.547 26.6658 15.0724C26.6658 14.5979 27.0504 14.2131 27.5251 14.2131C28.0954 14.2131 28.5595 13.749 28.5595 13.1787C28.5595 12.7042 28.9441 12.3193 29.4189 12.3193C29.8936 12.3193 30.2782 12.7042 30.2782 13.1787C30.2782 13.749 30.7421 14.2131 31.3126 14.2131C31.7873 14.2131 32.172 14.5979 32.172 15.0724C32.172 15.547 31.7873 15.9318 31.3126 15.9318C30.7423 15.9318 30.2782 16.3959 30.2782 16.9662C30.2782 17.4407 29.8936 17.8255 29.4189 17.8255Z"
        fill="#97FFD2"
      />
      <path
        d="M64.1847 74.6048C63.7099 74.6048 63.3253 74.22 63.3253 73.7455C63.3253 73.1752 62.8612 72.7111 62.2908 72.7111C61.8161 72.7111 61.4314 72.3263 61.4314 71.8517C61.4314 71.3772 61.8161 70.9924 62.2908 70.9924C62.8611 70.9924 63.3253 70.5283 63.3253 69.958C63.3253 69.4835 63.7099 69.0986 64.1847 69.0986C64.6594 69.0986 65.044 69.4835 65.044 69.958C65.044 70.5283 65.5079 70.9924 66.0784 70.9924C66.5531 70.9924 66.9378 71.3772 66.9378 71.8517C66.9378 72.3263 66.5531 72.7111 66.0784 72.7111C65.5081 72.7111 65.044 73.1752 65.044 73.7455C65.044 74.22 64.6594 74.6048 64.1847 74.6048Z"
        fill="#FF7EB8"
      />
      <path
        d="M56.2098 52.5495C56.7404 52.5495 57.1706 52.1193 57.1706 51.5887C57.1706 51.0581 56.7404 50.6279 56.2098 50.6279C55.6792 50.6279 55.249 51.0581 55.249 51.5887C55.249 52.1193 55.6792 52.5495 56.2098 52.5495Z"
        fill="white"
      />
      <path
        d="M53.3036 52.5495C53.8342 52.5495 54.2643 52.1193 54.2643 51.5887C54.2643 51.0581 53.8342 50.6279 53.3036 50.6279C52.7729 50.6279 52.3428 51.0581 52.3428 51.5887C52.3428 52.1193 52.7729 52.5495 53.3036 52.5495Z"
        fill="white"
      />
      <path
        d="M50.3971 52.5495C50.9277 52.5495 51.3578 52.1193 51.3578 51.5887C51.3578 51.0581 50.9277 50.6279 50.3971 50.6279C49.8664 50.6279 49.4363 51.0581 49.4363 51.5887C49.4363 52.1193 49.8664 52.5495 50.3971 52.5495Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2042_6642"
          x1="2.04699"
          y1="19.8226"
          x2="88.4968"
          y2="69.584"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#D2DEFF" />
          <stop offset="0.9993" stopColor="#DCFDEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IllustrationApproved;
