import { SVGProps } from "types";

const BoxSlider = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5384)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M13.7498 13.9998H10.2495C9.83551 13.9998 9.49951 13.6638 9.49951 13.2498V12.25C9.49951 11.836 9.83551 11.5 10.2495 11.5H13.7498C14.1638 11.5 14.4998 11.836 14.4998 12.25V13.2498C14.4998 13.6638 14.1645 13.9998 13.7498 13.9998Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M12.6247 11C12.5152 11 12.405 10.964 12.3127 10.8905L12 10.64L11.6872 10.8897C11.472 11.0622 11.157 11.0277 10.9845 10.8117C10.812 10.5965 10.8465 10.2815 11.0625 10.109L11.6872 9.60871C11.8702 9.46246 12.129 9.46246 12.312 9.60871L12.9367 10.109C13.1527 10.2815 13.1872 10.5957 13.0147 10.8117C12.9165 10.9355 12.771 11 12.6247 11Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M12 15.9993C11.8897 15.9993 11.7787 15.9625 11.688 15.8898L11.0632 15.3895C10.8472 15.217 10.8127 14.9028 10.9852 14.6868C11.1577 14.4708 11.4727 14.4363 11.688 14.6088L12 14.8593L12.3127 14.6095C12.528 14.437 12.843 14.4715 13.0155 14.6875C13.188 14.9028 13.1535 15.2178 12.9375 15.3903L12.3127 15.8905C12.2212 15.9633 12.1102 15.9993 12 15.9993Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5384">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSlider;
