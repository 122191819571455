import { SVGProps } from "types";

const BoxAlgorithm = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5367)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M17.8538 11.6468L15.993 9.786C15.9937 9.77325 16.0005 9.76275 16.0005 9.75V8.25C16.0005 7.974 15.777 7.74975 15.5002 7.74975H10.5V6.50025C10.5 6.2235 10.2765 6 9.99975 6H6.4995C6.2235 6 6 6.2235 6 6.50025V10.0005C6 10.2757 6.2235 10.5 6.4995 10.5H9.99975C10.2765 10.5 10.5 10.2758 10.5 9.99975V8.7495H15V9.75C15 9.76275 15.006 9.77325 15.0075 9.786L13.1467 11.6468C12.9517 11.8418 12.9517 12.1582 13.1467 12.354L15.0075 14.2147C15.0068 14.2267 15 14.2373 15 14.25V15.2498H10.4392C10.2112 14.25 9.318 13.5 8.25 13.5C7.0095 13.5 6 14.5095 6 15.75C6 16.9905 7.0095 18 8.25 18C9.31875 18 10.2112 17.25 10.44 16.2502H15.501C15.7777 16.2502 16.0012 16.026 16.0012 15.75V14.25C16.0012 14.2373 15.9953 14.2267 15.9938 14.214L17.8545 12.3533C18.0495 12.1583 18.0495 11.8418 17.8538 11.6468ZM9.50025 9.50025H6.99975V6.99975H9.4995V9.50025H9.50025ZM8.25 17.0002C7.56075 17.0002 6.99975 16.4392 6.99975 15.75C6.99975 15.0608 7.56 14.4998 8.25 14.4998C8.93925 14.4998 9.50025 15.0608 9.50025 15.75C9.50025 16.4392 8.93925 17.0002 8.25 17.0002Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5367">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxAlgorithm;
