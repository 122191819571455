import { SVGProps } from "types";

const BoxOptions = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5385)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M16.5 6H7.5C6.67275 6 6 6.67275 6 7.5V9C6 9.82725 6.67275 10.5 7.5 10.5H16.5C17.3273 10.5 18 9.82725 18 9V7.5C18 6.67275 17.3273 6 16.5 6ZM17.0002 9C17.0002 9.276 16.776 9.50025 16.5 9.50025H12.4995V6.99975H16.5C16.776 6.99975 17.0002 7.224 17.0002 7.5V9Z"
          fill="var(--color-cyber-yellow)"
        />
        <path
          d="M14.7502 9.00027C14.6774 9.00027 14.6054 8.97927 14.5424 8.93727L13.4174 8.18727C13.2449 8.07252 13.1984 7.83926 13.3132 7.66751C13.4279 7.49501 13.6612 7.44851 13.8329 7.56326L14.7502 8.17452L15.6674 7.56326C15.8392 7.44776 16.0724 7.49501 16.1872 7.66751C16.3019 7.84001 16.2554 8.07252 16.0829 8.18727L14.9579 8.93727C14.8949 8.97927 14.8229 9.00027 14.7502 9.00027Z"
          fill="var(--color-cyber-yellow)"
        />
        <path
          d="M16.5 18.0002H7.5C6.67275 18.0002 6 17.3275 6 16.5002V12.0002C6 11.7242 6.22425 11.5 6.50025 11.5H17.5005C17.7765 11.5 18 11.7242 18 12.0002V16.5002C18 17.3275 17.3273 18.0002 16.5 18.0002ZM6.99975 12.5005V16.5002C6.99975 16.7762 7.224 17.0005 7.5 17.0005H16.5C16.776 17.0005 17.0002 16.7762 17.0002 16.5002V12.5005H6.99975Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M15 15.501C14.5868 15.501 14.25 15.1642 14.25 14.751C14.25 14.3377 14.5868 14.001 15 14.001C15.4132 14.001 15.75 14.3377 15.75 14.751C15.75 15.1642 15.4132 15.501 15 15.501Z"
          fill="var(--color-shandy)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5385">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxOptions;
