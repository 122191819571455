import { SVGProps } from "types";

const IllustrationUiDesign = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6602)">
        <path
          d="M65.7604 29.9086C65.7274 29.9084 65.6942 29.9082 65.6612 29.9082C60.1554 29.8996 54.9388 27.5288 51.32 23.3794C45.929 17.1981 37.9825 13.3037 29.1273 13.3431C13.0549 13.4144 -0.205387 26.9179 0.00240946 42.9891C0.209175 58.9711 13.229 71.8632 29.2602 71.8632C33.7879 71.8632 38.075 70.8342 41.9011 68.9977C45.2 67.4142 49.131 67.8317 52.0362 70.0567C55.7939 72.9347 60.4907 74.648 65.5878 74.6565C77.8566 74.677 87.9283 64.6844 87.9996 52.4158C88.0715 40.0322 78.1268 29.9814 65.7604 29.9086Z"
          fill="url(#paint0_linear_2042_6602)"
        />
        <path
          opacity="0.1"
          d="M78.375 16.3125V65.168C78.375 66.5911 77.22 67.7461 75.7969 67.7461H12.2031C10.78 67.7461 9.625 66.5911 9.625 65.168V16.3125C9.625 14.8894 10.78 13.7344 12.2031 13.7344H75.7969C77.22 13.7344 78.375 14.8894 78.375 16.3125Z"
          fill="#2626BC"
        />
        <path
          d="M78.375 19.9219V68.7773C78.375 70.2005 77.22 71.3555 75.7969 71.3555H12.2031C10.78 71.3555 9.625 70.2005 9.625 68.7773V19.9219C9.625 18.4987 10.78 17.3438 12.2031 17.3438H75.7969C77.22 17.3438 78.375 18.4987 78.375 19.9219Z"
          fill="white"
        />
        <path
          d="M15.8875 25.623H72.1124V44.2136H15.8875V25.623Z"
          fill="#D2DEFF"
        />
        <path
          d="M78.375 25.6231V19.9219C78.375 18.4981 77.2207 17.3438 75.7969 17.3438H12.2031C10.7793 17.3438 9.625 18.4981 9.625 19.9219V25.6231H78.375Z"
          fill="#6583FE"
        />
        <path
          d="M30.1576 48.1788H15.9047C15.5512 48.1788 15.2646 47.8923 15.2646 47.5388V36.061C15.2646 35.7074 15.5512 35.4209 15.9047 35.4209H30.1576C30.5112 35.4209 30.7977 35.7074 30.7977 36.061V47.5388C30.7977 47.8921 30.5112 48.1788 30.1576 48.1788Z"
          fill="#FFC4DF"
        />
        <path
          d="M28.7535 54.4183H17.309C16.8227 54.4183 16.4285 54.024 16.4285 53.5377C16.4285 53.0515 16.8226 52.6572 17.309 52.6572H28.7535C29.2397 52.6572 29.634 53.0515 29.634 53.5377C29.634 54.024 29.2397 54.4183 28.7535 54.4183Z"
          fill="#B7C5FF"
        />
        <path
          d="M51.1264 48.1788H36.8735C36.5199 48.1788 36.2334 47.8923 36.2334 47.5388V36.061C36.2334 35.7074 36.5199 35.4209 36.8735 35.4209H51.1264C51.4799 35.4209 51.7664 35.7074 51.7664 36.061V47.5388C51.7664 47.8921 51.4799 48.1788 51.1264 48.1788Z"
          fill="#D2DEFF"
        />
        <path
          d="M49.7222 54.4183H38.2777C37.7915 54.4183 37.3972 54.024 37.3972 53.5377C37.3972 53.0515 37.7913 52.6572 38.2777 52.6572H49.7222C50.2084 52.6572 50.6027 53.0515 50.6027 53.5377C50.6027 54.024 50.2084 54.4183 49.7222 54.4183Z"
          fill="#B7C5FF"
        />
        <path
          d="M72.0951 48.1788H57.8422C57.4887 48.1788 57.2021 47.8923 57.2021 47.5388V36.061C57.2021 35.7074 57.4887 35.4209 57.8422 35.4209H72.0951C72.4487 35.4209 72.7352 35.7074 72.7352 36.061V47.5388C72.7352 47.8921 72.4487 48.1788 72.0951 48.1788Z"
          fill="#97FFD2"
        />
        <path
          d="M70.691 54.4183H59.2465C58.7602 54.4183 58.366 54.024 58.366 53.5377C58.366 53.0515 58.7601 52.6572 59.2465 52.6572H70.691C71.1772 52.6572 71.5715 53.0515 71.5715 53.5377C71.5715 54.024 71.1772 54.4183 70.691 54.4183Z"
          fill="#B7C5FF"
        />
        <path
          d="M72.0953 34.5615H57.8424C57.0155 34.5615 56.3428 35.2342 56.3428 36.0611V47.5388C56.3428 48.3657 57.0155 49.0384 57.8424 49.0384H72.0953C72.9222 49.0384 73.5949 48.3657 73.5949 47.5388V36.0611C73.5947 35.2342 72.922 34.5615 72.0953 34.5615ZM71.876 40.9405H63.3646V36.2803H71.876V40.9405ZM58.0615 36.2803H61.6458V47.3196H58.0615V36.2803ZM63.3647 47.3196V42.6594H71.8761V47.3196H63.3647Z"
          fill="#01ECA5"
        />
        <path
          d="M67.5972 55.9229H62.3402C61.854 55.9229 61.4597 56.317 61.4597 56.8034C61.4597 57.2896 61.8538 57.6839 62.3402 57.6839H67.5972C68.0834 57.6839 68.4777 57.2896 68.4777 56.8034C68.4775 56.317 68.0834 55.9229 67.5972 55.9229Z"
          fill="#01ECA5"
        />
        <path
          d="M26.9844 65.5922H19.0781C18.7933 65.5922 18.5625 65.3614 18.5625 65.0766V62.2119C18.5625 61.9271 18.7933 61.6963 19.0781 61.6963H26.9844C27.2692 61.6963 27.5 61.9271 27.5 62.2119V65.0766C27.5 65.3614 27.2692 65.5922 26.9844 65.5922Z"
          fill="#FFC4DF"
        />
        <path
          d="M48.1348 65.5922H40.2285C39.9437 65.5922 39.7129 65.3614 39.7129 65.0766V62.2119C39.7129 61.9271 39.9437 61.6963 40.2285 61.6963H48.1348C48.4196 61.6963 48.6504 61.9271 48.6504 62.2119V65.0766C48.6504 65.3614 48.4196 65.5922 48.1348 65.5922Z"
          fill="#D2DEFF"
        />
        <path
          d="M69.2854 65.5922H61.3792C61.0944 65.5922 60.8635 65.3614 60.8635 65.0766V62.2119C60.8635 61.9271 61.0944 61.6963 61.3792 61.6963H69.2854C69.5702 61.6963 69.801 61.9271 69.801 62.2119V65.0766C69.801 65.3614 69.5702 65.5922 69.2854 65.5922Z"
          fill="#97FFD2"
        />
        <path
          d="M44.2313 66.1109L49.6093 80.5833C49.8356 81.1924 50.6715 81.2574 50.9891 80.6905L54.0459 75.2375C54.1145 75.1149 54.2157 75.0139 54.3382 74.9451L59.7913 71.8883C60.3582 71.5705 60.2932 70.7349 59.6841 70.5085L45.2117 65.1305C44.6 64.9031 44.0041 65.4992 44.2313 66.1109Z"
          fill="#FFA8CF"
        />
        <path
          d="M15.311 21.4707V21.4958C15.311 21.9705 15.6957 22.3552 16.1704 22.3552C16.6451 22.3552 17.0298 21.9705 17.0298 21.4958V21.4707C17.0298 20.996 16.6451 20.6113 16.1704 20.6113C15.6957 20.6113 15.311 20.996 15.311 21.4707Z"
          fill="#2626BC"
        />
        <path
          d="M18.6326 21.4707V21.4958C18.6326 21.9705 19.0172 22.3552 19.4919 22.3552C19.9667 22.3552 20.3513 21.9705 20.3513 21.4958V21.4707C20.3513 20.996 19.9667 20.6113 19.4919 20.6113C19.0172 20.6113 18.6326 20.996 18.6326 21.4707Z"
          fill="#2626BC"
        />
        <path
          d="M21.9539 21.4707V21.4958C21.9539 21.9705 22.3385 22.3552 22.8132 22.3552C23.288 22.3552 23.6726 21.9705 23.6726 21.4958V21.4707C23.6726 20.996 23.288 20.6113 22.8132 20.6113C22.3385 20.6113 21.9539 20.996 21.9539 21.4707Z"
          fill="#2626BC"
        />
        <path
          d="M75.7969 16.4844H12.2031C10.3077 16.4844 8.76562 18.0264 8.76562 19.9219V25.6231C8.76562 26.0979 9.15028 26.4825 9.625 26.4825H72.474C72.9487 26.4825 73.3334 26.0979 73.3334 25.6231C73.3334 25.1484 72.9487 24.7638 72.474 24.7638H10.4844V19.9219C10.4844 18.9742 11.2554 18.2031 12.2031 18.2031H75.7969C76.7446 18.2031 77.5156 18.9742 77.5156 19.9219V68.777C77.5156 69.7247 76.7446 70.4958 75.7969 70.4958H60.867C60.6879 70.1357 60.38 69.8502 59.9832 69.7029L45.5108 64.3249C44.9089 64.1008 44.253 64.2443 43.7991 64.6984C43.345 65.1525 43.2018 65.8084 43.4256 66.4101L44.9439 70.4958H12.2031C11.2554 70.4958 10.4844 69.7247 10.4844 68.777V30.3568C10.4844 29.882 10.0997 29.4974 9.625 29.4974C9.15028 29.4974 8.76562 29.882 8.76562 30.3568V68.777C8.76562 70.6724 10.3077 72.2145 12.2031 72.2145H45.5826L48.8037 80.8827C49.0265 81.482 49.5608 81.8852 50.1985 81.9346C50.2422 81.938 50.2856 81.9395 50.3288 81.9395C50.9156 81.9395 51.4477 81.63 51.7387 81.1106L54.7822 75.6814L60.2116 72.6378C60.4074 72.5282 60.5712 72.3828 60.702 72.2145H75.7969C77.6923 72.2145 79.2344 70.6724 79.2344 68.777V19.9219C79.2344 18.0264 77.6923 16.4844 75.7969 16.4844ZM53.9175 74.1955C53.6568 74.3418 53.442 74.5568 53.2962 74.8169L50.3418 80.0874L45.1103 66.0095L59.1883 71.241L53.9175 74.1955Z"
          fill="#2626BC"
        />
        <path
          d="M42.1219 80.5477C41.6472 80.5477 41.2625 80.163 41.2625 79.6883C41.2625 79.1182 40.7988 78.6543 40.2285 78.6543C39.7538 78.6543 39.3691 78.2696 39.3691 77.7949C39.3691 77.3202 39.7538 76.9355 40.2285 76.9355C40.7986 76.9355 41.2625 76.4717 41.2625 75.9014C41.2625 75.4266 41.6472 75.042 42.1219 75.042C42.5966 75.042 42.9813 75.4266 42.9813 75.9014C42.9813 76.4715 43.445 76.9355 44.0153 76.9355C44.49 76.9355 44.8746 77.3202 44.8746 77.7949C44.8746 78.2696 44.49 78.6543 44.0153 78.6543C43.4452 78.6543 42.9813 79.118 42.9813 79.6883C42.9813 80.163 42.5966 80.5477 42.1219 80.5477Z"
          fill="#97FFD2"
        />
        <path
          d="M30.1578 34.5615H15.9049C15.078 34.5615 14.4053 35.2342 14.4053 36.0611V47.5388C14.4053 48.3657 15.078 49.0384 15.9049 49.0384H30.1578C30.9847 49.0384 31.6574 48.3657 31.6574 47.5388V36.0611C31.6572 35.2342 30.9845 34.5615 30.1578 34.5615ZM29.9385 36.2803V39.1367H16.124V36.2803H29.9385ZM16.124 40.8554H25.5227V47.3195H16.124V40.8554ZM27.2414 47.3196V40.8556H29.9385V47.3196H27.2414Z"
          fill="#FF7EB8"
        />
        <path
          d="M25.6597 55.9229H20.4027C19.9165 55.9229 19.5222 56.317 19.5222 56.8034C19.5222 57.2896 19.9163 57.6839 20.4027 57.6839H25.6597C26.1459 57.6839 26.5402 57.2896 26.5402 56.8034C26.54 56.317 26.1459 55.9229 25.6597 55.9229Z"
          fill="#FF7EB8"
        />
        <path
          d="M42.2104 11.1718L40.3206 9.28203C39.9853 8.94636 39.4408 8.94636 39.1054 9.28203L37.2157 11.1718C37.0544 11.333 36.9639 11.5515 36.9639 11.7794C36.9639 12.0073 37.0544 12.2259 37.2157 12.3871L39.1054 14.2767C39.2734 14.4445 39.4932 14.5283 39.713 14.5283C39.9328 14.5283 40.1528 14.4445 40.3206 14.2767L42.2104 12.3871C42.3716 12.2259 42.4621 12.0073 42.4621 11.7794C42.4621 11.5515 42.3716 11.333 42.2104 11.1718ZM39.7128 12.4538L39.0384 11.7794L39.7128 11.1049L40.3873 11.7794L39.7128 12.4538Z"
          fill="#FF7EB8"
        />
        <path
          d="M51.1263 34.5615H36.8734C36.0465 34.5615 35.3738 35.2342 35.3738 36.0611V47.5388C35.3738 48.3657 36.0465 49.0384 36.8734 49.0384H51.1263C51.9532 49.0384 52.6259 48.3657 52.6259 47.5388V36.0611C52.6257 35.2342 51.953 34.5615 51.1263 34.5615ZM50.907 41.1992H46.4061V36.2803H50.9072V41.1992H50.907ZM44.6873 36.2803V41.1992H37.0925V36.2803H44.6873ZM37.0925 42.9179H40.9061V47.3195H37.0925V42.9179ZM42.6248 47.3196V42.9181H50.9072V47.3196H42.6248Z"
          fill="#6583FE"
        />
        <path
          d="M46.6285 55.9229H41.3715C40.8852 55.9229 40.491 56.317 40.491 56.8034C40.491 57.2896 40.8851 57.6839 41.3715 57.6839H46.6285C47.1147 57.6839 47.509 57.2896 47.509 56.8034C47.5088 56.317 47.1147 55.9229 46.6285 55.9229Z"
          fill="#6583FE"
        />
        <path
          d="M31.527 7.95295C30.957 7.95295 30.4932 7.48923 30.4932 6.91895C30.4932 6.44423 30.1085 6.05957 29.6338 6.05957C29.1591 6.05957 28.7744 6.44423 28.7744 6.91895C28.7744 7.48905 28.3105 7.95295 27.7402 7.95295C27.2655 7.95295 26.8809 8.3376 26.8809 8.81232C26.8809 9.28704 27.2655 9.6717 27.7402 9.6717C28.3103 9.6717 28.7744 10.1354 28.7744 10.7057C28.7744 11.1804 29.1591 11.5651 29.6338 11.5651C30.1085 11.5651 30.4932 11.1804 30.4932 10.7057C30.4932 10.1356 30.9569 9.6717 31.527 9.6717C32.0017 9.6717 32.3864 9.28704 32.3864 8.81232C32.3864 8.3376 32.0017 7.95295 31.527 7.95295Z"
          fill="#6583FE"
        />
        <path
          d="M63.2052 11.1718L61.3155 9.28203C60.9801 8.94636 60.4356 8.94636 60.1003 9.28203L58.2105 11.1718C58.0493 11.333 57.9587 11.5515 57.9587 11.7794C57.9587 12.0073 58.0493 12.2259 58.2105 12.3871L60.1003 14.2767C60.2682 14.4445 60.4881 14.5283 60.7079 14.5283C60.9277 14.5283 61.1477 14.4445 61.3155 14.2767L63.2052 12.3871C63.3664 12.2259 63.457 12.0073 63.457 11.7794C63.457 11.5515 63.3666 11.333 63.2052 11.1718ZM60.7079 12.4538L60.0334 11.7794L60.7079 11.1049L61.3823 11.7794L60.7079 12.4538Z"
          fill="#6583FE"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6602"
          x1="-2.43959"
          y1="33.8888"
          x2="90.9927"
          y2="59.5731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6602">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationUiDesign;
