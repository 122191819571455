import { noop } from "lodash";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { ColorScheme, SystemColorScheme, SetState } from "types";

export const COLOR_SCHEME_LOCAL_STORAGE_KEY = "color-scheme";
export const DEFAULT_COLOR_SCHEME: ColorScheme = "system";

export type ColorSchemeContextType = [
  colorScheme: ColorScheme,
  systemColorScheme: SystemColorScheme,
  setColorScheme: SetState<ColorScheme, false>
];

export type ColorSchemeProviderProps = {
  children?: ReactNode;
};

export const ColorSchemeContext = createContext<ColorSchemeContextType>([
  "system",
  "light",
  noop,
]);

const parseColorScheme = (): ColorScheme => {
  try {
    return (
      (localStorage.getItem(COLOR_SCHEME_LOCAL_STORAGE_KEY) as ColorScheme) ??
      DEFAULT_COLOR_SCHEME
    );
  } catch (_error) {
    return DEFAULT_COLOR_SCHEME;
  }
};

const darkColorSchemeMediaQuery = () => {
  return matchMedia("(prefers-color-scheme: dark)");
};

export const useColorScheme = () => useContext(ColorSchemeContext);

export const ColorSchemeProvider = ({ children }: ColorSchemeProviderProps) => {
  const [colorScheme, _setColorScheme] = useState(parseColorScheme());

  const [systemColorScheme, setSystemColorScheme] = useState<SystemColorScheme>(
    darkColorSchemeMediaQuery().matches ? "dark" : "light"
  );

  const setColorScheme = useCallback((scheme: ColorScheme) => {
    localStorage.setItem(COLOR_SCHEME_LOCAL_STORAGE_KEY, scheme);
    _setColorScheme(scheme);
  }, []);

  useLayoutEffect(() => {
    const parsedColorScheme = parseColorScheme();

    const isSystem = parsedColorScheme === "system";
    const colorSchemeMedia = darkColorSchemeMediaQuery();

    const currentColorScheme = isSystem
      ? colorSchemeMedia.matches
        ? "dark"
        : "light"
      : colorScheme;

    const setColorSchemeAttributes = (scheme = currentColorScheme) => {
      document.body.setAttribute("data-color-scheme", scheme);

      document.body.toggleAttribute(
        "data-color-scheme-dark",
        scheme === "dark"
      );
    };

    const handleColorSchemeChange = (event: MediaQueryListEvent) => {
      setColorSchemeAttributes(event.matches ? "dark" : "light");
      setSystemColorScheme(event.matches ? "dark" : "light");
    };

    if (isSystem) {
      colorSchemeMedia.addEventListener("change", handleColorSchemeChange);
    }

    setColorSchemeAttributes();
    _setColorScheme(parsedColorScheme);

    return () => {
      colorSchemeMedia.removeEventListener("change", handleColorSchemeChange);
    };
  }, [colorScheme]);

  return (
    <ColorSchemeContext.Provider
      value={[colorScheme, systemColorScheme, setColorScheme]}
    >
      {children}
    </ColorSchemeContext.Provider>
  );
};

export default ColorSchemeProvider;
