import { DependencyList, useEffect, useState } from "react";
import { BREAKPOINTS } from "common/constants";
import { Breakpoint, BreakpointCallback } from "types";

export const useScreenMedia = (
  breakpoint: Breakpoint
): MediaQueryList | undefined => {
  const [media, setMedia] = useState<MediaQueryList>();

  useEffect(() => {
    setMedia(window.matchMedia(`(min-width: ${BREAKPOINTS[breakpoint]})`));
  }, [breakpoint]);

  return media;
};

const useScreenMediaQuery = (
  breakpoint: Breakpoint,
  callback?: BreakpointCallback,
  deps?: DependencyList
): [matches: boolean, media: MediaQueryList | undefined] => {
  const media = useScreenMedia(breakpoint);
  const [matches, setMatches] = useState(media?.matches ?? false);

  useEffect(() => {
    const handleMatch = (event: MediaQueryListEvent) => {
      callback?.(event.matches);
      setMatches(event.matches);
    };

    media?.addEventListener("change", handleMatch);
    const matches = media?.matches ?? false;

    callback?.(matches);
    setMatches(matches);

    return () => {
      media?.removeEventListener("change", handleMatch);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [media, ...(deps ?? [])]);

  return [matches, media];
};

export default useScreenMediaQuery;
