import { SVGProps } from "types";

const Location = (props: SVGProps) => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.82623 16.9589C4.1226 14.8636 0.333496 9.7854 0.333496 6.93296C0.333496 3.47219 3.13893 0.666748 6.59971 0.666748C10.0592 0.666748 12.8659 3.47219 12.8659 6.93296C12.8659 9.7854 9.04745 14.8636 7.3732 16.9589C6.97177 17.4583 6.22766 17.4583 5.82623 16.9589ZM6.59971 9.0217C7.75178 9.0217 8.68845 8.08503 8.68845 6.93296C8.68845 5.78089 7.75178 4.84423 6.59971 4.84423C5.44764 4.84423 4.51097 5.78089 4.51097 6.93296C4.51097 8.08503 5.44764 9.0217 6.59971 9.0217Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Location;
