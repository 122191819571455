import { SVGProps } from "types";

const BoxWebpage = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5362)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M8.49976 8.875H9.49951V17.125H8.49976V8.875Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M8.87476 11.001H17.625V12.0007H8.87476V11.001Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M8.87476 14.001H17.625V15.0007H8.87476V14.001Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5362">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxWebpage;
