import { SVGProps } from "types";

const BoxLogOut = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5357)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M14.0002 12.5002C13.449 12.5002 13.0005 12.0517 13.0005 11.5005C13.0005 10.9492 13.4482 10.5 14.0002 10.5C14.5515 10.5 15 10.9485 15 11.4998C15 12.051 14.5515 12.5002 14.0002 12.5002Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M15.6255 15.0005H12.375C12.168 15.0005 12 14.8325 12 14.6255V14.3757C12 13.6175 12.6165 13.001 13.3747 13.001H14.625C15.3832 13.001 15.9997 13.6175 15.9997 14.3757V14.6255C16.0005 14.8325 15.8325 15.0005 15.6255 15.0005Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M10.9095 12.5053L10.1595 11.6301C10.0575 11.5108 9.891 11.4681 9.74475 11.5221C9.59775 11.5768 9.4995 11.7171 9.4995 11.8738V12.2488H7.9995C7.7235 12.2496 7.5 12.4738 7.5 12.7498C7.5 13.0258 7.72425 13.2501 8.00025 13.2501H9.50025V13.6251C9.50025 13.7818 9.59775 13.9221 9.7455 13.9768C9.78825 13.9926 9.83175 14.0001 9.876 14.0001C9.98325 14.0001 10.0882 13.9536 10.161 13.8696L10.911 12.9943C11.0302 12.8533 11.0302 12.6463 10.9095 12.5053Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5357">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxLogOut;
