import { SVGProps } from "types";

const BoxLogIn = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5377)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M9.00001 13.5005H8.49976C8.22376 13.5005 7.99951 13.2762 7.99951 13.0002C7.99951 12.7242 8.22376 12.5 8.49976 12.5H9.00001C9.27601 12.5 9.50026 12.7242 9.50026 13.0002C9.50026 13.2762 9.27601 13.5005 9.00001 13.5005Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M13.41 12.7563L12.66 11.8811C12.558 11.7618 12.3915 11.7191 12.2452 11.7731C12.0975 11.8278 12 11.9681 12 12.1256V12.5006H11.0002C10.7242 12.5006 10.5 12.7248 10.5 13.0008C10.5 13.2768 10.7235 13.5003 11.0002 13.5003H12V13.8753C12 14.0321 12.0975 14.1723 12.2452 14.2271C12.2872 14.2428 12.3315 14.2503 12.375 14.2503C12.4822 14.2503 12.5872 14.2038 12.66 14.1198L13.41 13.2446C13.53 13.1036 13.53 12.8966 13.41 12.7563Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M15 16H12.5002C12.2242 16 12 15.7758 12 15.4998C12 15.2238 12.2242 15.0002 12.5002 15.0002H15V11.0005H12.5002C12.2242 11.0005 12 10.7762 12 10.5002C12 10.2242 12.2242 10 12.5002 10H15C15.5512 10 15.9997 10.4485 15.9997 10.9998V15.0002C16.0005 15.5515 15.552 16 15 16Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5377">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxLogIn;
