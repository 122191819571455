import { SVGProps } from "types";

const BoxBullets = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5442)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M10.5 14.4995C9.94874 14.4995 9.50024 14.051 9.50024 13.4998C9.50024 12.9485 9.94874 12.5 10.5 12.5C11.0512 12.5 11.4997 12.9485 11.4997 13.4998C11.4997 14.051 11.0512 14.4995 10.5 14.4995Z"
          fill="var(--color-orange-soda)"
        />
        <path
          d="M10.5 18.0005C9.94874 18.0005 9.50024 17.552 9.50024 17.0007C9.50024 16.4495 9.94874 16.001 10.5 16.001C11.0512 16.001 11.4997 16.4495 11.4997 17.0007C11.4997 17.552 11.0512 18.0005 10.5 18.0005Z"
          fill="var(--color-orange-soda)"
        />
        <path
          d="M17.5005 14.0005H13.5C13.2233 14.0005 12.9998 13.7762 12.9998 13.5002C12.9998 13.2242 13.2233 13 13.5 13H17.5005C17.7765 13 18 13.2242 18 13.5002C18 13.7762 17.7765 14.0005 17.5005 14.0005Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.5005 15.9998H13.5C13.2233 15.9998 12.9998 15.7755 12.9998 15.4995C12.9998 15.2235 13.224 15 13.5 15H17.5005C17.7765 15 18 15.2242 18 15.5002C18 15.7762 17.7765 15.9998 17.5005 15.9998Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.5005 8.49975H13.5C13.2233 8.49975 12.9998 8.2755 12.9998 7.9995C12.9998 7.7235 13.224 7.5 13.5 7.5H17.5005C17.7765 7.5 18 7.7235 18 8.00025C18 8.277 17.7765 8.49975 17.5005 8.49975Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.5005 10.4995H13.5C13.2233 10.4995 12.9998 10.2753 12.9998 9.99927C12.9998 9.72327 13.2233 9.49902 13.5 9.49902H17.5005C17.7773 9.49902 18.0008 9.72327 18.0008 9.99927C18.0008 10.2753 17.7765 10.4995 17.5005 10.4995Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.5005 17.9995H13.5C13.2233 17.9995 12.9998 17.7753 12.9998 17.4993C12.9998 17.2233 13.2233 16.999 13.5 16.999H17.5005C17.7773 16.999 18.0008 17.2233 18.0008 17.4993C18.0008 17.7753 17.7765 17.9995 17.5005 17.9995Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M8.7495 11.4998C7.23375 11.4998 6 10.2667 6 8.75025C6 7.23375 7.23375 6 8.7495 6C10.2653 6 11.4998 7.233 11.4998 8.75025C11.4998 10.2675 10.266 11.4998 8.7495 11.4998Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5442">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxBullets;
