import { SVGProps } from "types";

const IllustrationFolder = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6638)">
        <path
          d="M70.1644 26.5128C66.0915 24.9254 62.6505 22.0288 60.5461 18.1973C57.0072 11.7542 50.1597 7.38672 42.2893 7.38672C34.1201 7.38672 27.0533 12.0921 23.6449 18.94C21.5573 23.1339 18.0049 26.4269 13.6606 28.1802C7.24128 30.771 1.8523 36.6921 0.142148 45.9489C-0.0455393 46.9647 -0.0482893 48.009 0.139398 49.0248C2.79452 63.3888 14.3017 69.7246 24.7749 68.0445C30.6403 67.1037 36.5779 69.0601 40.9255 73.1083C49.7657 81.3397 63.9368 83.9204 77.6935 75.0969C79.3052 74.0632 81.4674 71.8942 82.5002 70.2818C94.3537 51.776 85.5731 32.5186 70.1644 26.5128V26.5128Z"
          fill="url(#paint0_linear_2042_6638)"
        />
        <path
          opacity="0.1"
          d="M78.3752 16.2607V59.96C78.3752 61.3831 77.2202 62.5381 75.7971 62.5381H12.2034C10.7802 62.5381 9.62524 61.3831 9.62524 59.96V16.2607C9.62524 14.8376 10.7802 13.6826 12.2034 13.6826H75.7971C77.2202 13.6826 78.3752 14.8376 78.3752 16.2607V16.2607Z"
          fill="#2626BC"
        />
        <path
          d="M65.6649 76.1007C65.437 76.1007 65.2184 76.0102 65.0573 75.8491L63.1636 73.9554C62.8279 73.6197 62.8279 73.0756 63.1636 72.7401L65.0573 70.8464C65.2186 70.6851 65.437 70.5947 65.6649 70.5947C65.8928 70.5947 66.1115 70.6853 66.2725 70.8464L68.1664 72.7401C68.3276 72.9013 68.4182 73.1197 68.4182 73.3478C68.4182 73.5759 68.3278 73.7944 68.1664 73.9556L66.2725 75.8493C66.1113 76.0102 65.8928 76.1007 65.6649 76.1007ZM64.9867 73.3476L65.6649 74.026L66.3433 73.3476L65.6649 72.6693L64.9867 73.3476Z"
          fill="#6583FE"
        />
        <path
          d="M23.9572 77.1605C23.4826 77.1605 23.0978 76.7757 23.0978 76.3011C23.0978 75.7308 22.6338 75.2668 22.0635 75.2668C21.5889 75.2668 21.2041 74.8819 21.2041 74.4074C21.2041 73.9328 21.5889 73.548 22.0635 73.548C22.6338 73.548 23.0978 73.084 23.0978 72.5137C23.0978 72.0391 23.4826 71.6543 23.9572 71.6543C24.4317 71.6543 24.8166 72.0391 24.8166 72.5137C24.8166 73.084 25.2806 73.548 25.8509 73.548C26.3255 73.548 26.7103 73.9328 26.7103 74.4074C26.7103 74.8819 26.3255 75.2668 25.8509 75.2668C25.2806 75.2668 24.8166 75.7308 24.8166 76.3011C24.8166 76.7758 24.4317 77.1605 23.9572 77.1605Z"
          fill="#97FFD2"
        />
        <path
          d="M74.0753 81.3968C73.6006 81.3968 73.2159 81.012 73.2159 80.5374C73.2159 79.9672 72.7518 79.5031 72.1814 79.5031C71.7067 79.5031 71.322 79.1183 71.322 78.6437C71.322 78.1692 71.7067 77.7843 72.1814 77.7843C72.7517 77.7843 73.2159 77.3203 73.2159 76.75C73.2159 76.2755 73.6006 75.8906 74.0753 75.8906C74.55 75.8906 74.9347 76.2755 74.9347 76.75C74.9347 77.3203 75.3986 77.7843 75.969 77.7843C76.4437 77.7843 76.8284 78.1692 76.8284 78.6437C76.8284 79.1183 76.4437 79.5031 75.969 79.5031C75.3987 79.5031 74.9347 79.9672 74.9347 80.5374C74.9347 81.012 74.55 81.3968 74.0753 81.3968Z"
          fill="#FF7EB8"
        />
        <path
          d="M53.7472 78.6437H34.2529L36.7936 68.5714L38.1514 63.1914H49.8487L51.2065 68.5714L53.7472 78.6437Z"
          fill="#9FB0FE"
        />
        <path
          d="M51.2066 68.5714H36.7937L38.1515 63.1914H49.8488L51.2066 68.5714Z"
          fill="#8399FE"
        />
        <path
          d="M78.3752 19.8701V63.5693C78.3752 64.9925 77.2202 66.1475 75.7971 66.1475H12.2034C10.7802 66.1475 9.62524 64.9925 9.62524 63.5693V19.8701C9.62524 18.447 10.7802 17.292 12.2034 17.292H75.7971C77.2202 17.292 78.3752 18.447 78.3752 19.8701V19.8701Z"
          fill="white"
        />
        <path
          d="M21.2834 17.292H66.7169V39.4417H21.2834V17.292Z"
          fill="#DCFDEE"
        />
        <path
          d="M78.3752 25.5714V19.8701C78.3752 18.4463 77.2209 17.292 75.7971 17.292H12.2034C10.7796 17.292 9.62524 18.4463 9.62524 19.8701V25.5714H78.3752Z"
          fill="#FF7EB8"
        />
        <path
          d="M9.62524 57.4863V63.569C9.62524 64.9928 10.7796 66.1471 12.2034 66.1471H75.7971C77.2209 66.1471 78.3752 64.9928 78.3752 63.569V57.4863H9.62524Z"
          fill="#B7C5FF"
        />
        <path
          d="M16.1707 20.5596C15.6961 20.5596 15.3113 20.9444 15.3113 21.4189V21.4439C15.3113 21.9184 15.6961 22.3032 16.1707 22.3032C16.6452 22.3032 17.03 21.9184 17.03 21.4439V21.4189C17.03 20.9444 16.6452 20.5596 16.1707 20.5596V20.5596Z"
          fill="#2626BC"
        />
        <path
          d="M19.4922 20.5596C19.0176 20.5596 18.6328 20.9444 18.6328 21.4189V21.4439C18.6328 21.9184 19.0176 22.3032 19.4922 22.3032C19.9667 22.3032 20.3516 21.9184 20.3516 21.4439V21.4189C20.3516 20.9444 19.9667 20.5596 19.4922 20.5596V20.5596Z"
          fill="#2626BC"
        />
        <path
          d="M22.8135 20.5596C22.3389 20.5596 21.9541 20.9444 21.9541 21.4189V21.4439C21.9541 21.9184 22.3389 22.3032 22.8135 22.3032C23.288 22.3032 23.6729 21.9184 23.6729 21.4439V21.4189C23.6729 20.9444 23.2882 20.5596 22.8135 20.5596V20.5596Z"
          fill="#2626BC"
        />
        <path
          d="M75.7971 16.4326H12.2034C10.3079 16.4326 8.76587 17.9747 8.76587 19.8701V63.5688C8.76587 65.4643 10.3079 67.0063 12.2034 67.0063H49.9256L52.6449 77.7831H35.3556L37.1441 70.6951C37.2602 70.235 36.9814 69.7677 36.5211 69.6517C36.061 69.5356 35.5937 69.8144 35.4776 70.2747L33.5831 77.7831H30.9491C30.4745 77.7831 30.0897 78.1679 30.0897 78.6424C30.0897 79.117 30.4745 79.5018 30.9491 79.5018H34.2525H53.748H57.0514C57.5261 79.5018 57.9108 79.117 57.9108 78.6424C57.9108 78.1679 57.5261 77.7831 57.0514 77.7831H54.4174L51.6982 67.0063H75.7971C77.6926 67.0063 79.2346 65.4643 79.2346 63.5688V57.4862C79.2346 57.0116 78.85 56.6268 78.3752 56.6268C78.3589 56.6268 78.3438 56.6307 78.3278 56.6316C78.3118 56.6307 78.2965 56.6268 78.2804 56.6268H10.4846V26.4309H77.5159V52.7529C77.5159 53.2274 77.9005 53.6123 78.3752 53.6123C78.85 53.6123 79.2346 53.2274 79.2346 52.7529V19.8701C79.2346 17.9747 77.6926 16.4326 75.7971 16.4326V16.4326ZM77.5159 58.3455V63.5688C77.5159 64.5165 76.7448 65.2876 75.7971 65.2876H12.2034C11.2557 65.2876 10.4846 64.5165 10.4846 63.5688V58.3455H77.5159ZM10.4846 24.7122V19.8703C10.4846 18.9226 11.2557 18.1515 12.2034 18.1515H75.7971C76.7448 18.1515 77.5159 18.9226 77.5159 19.8703V24.7122H10.4846Z"
          fill="#2626BC"
        />
        <path
          d="M22.2252 33.5216L21.4942 32.3024C21.4011 32.147 21.2331 32.0518 21.052 32.0518H18.0784C17.7936 32.0518 17.5627 32.2826 17.5627 32.5674V40.8174H30.3514V34.2877C30.3514 34.0029 30.1206 33.7721 29.8358 33.7721H22.6674C22.4863 33.7721 22.3184 33.677 22.2252 33.5216V33.5216Z"
          fill="#01ECA5"
        />
        <path
          d="M29.8358 43.3096H18.0784C17.7936 43.3096 17.5627 43.0787 17.5627 42.7939V36.0908C17.5627 35.806 17.7936 35.5752 18.0784 35.5752H29.8358C30.1206 35.5752 30.3514 35.806 30.3514 36.0908V42.7939C30.3514 43.0787 30.1206 43.3096 29.8358 43.3096Z"
          fill="#97FFD2"
        />
        <path
          d="M29.9112 48.4456H18.4667C17.9805 48.4456 17.5862 48.0513 17.5862 47.5651C17.5862 47.0788 17.9803 46.6846 18.4667 46.6846H29.9112C30.3974 46.6846 30.7917 47.0788 30.7917 47.5651C30.7917 48.0513 30.3976 48.4456 29.9112 48.4456Z"
          fill="#B7C5FF"
        />
        <path
          d="M26.8174 51.7112H21.5604C21.0742 51.7112 20.6799 51.3169 20.6799 50.8307C20.6799 50.3445 21.074 49.9502 21.5604 49.9502H26.8174C27.3037 49.9502 27.6979 50.3445 27.6979 50.8307C27.6979 51.3169 27.3038 51.7112 26.8174 51.7112Z"
          fill="#6583FE"
        />
        <path
          d="M42.048 33.5216L41.317 32.3024C41.2238 32.147 41.0559 32.0518 40.8747 32.0518H37.9011C37.6163 32.0518 37.3855 32.2826 37.3855 32.5674V40.8174H50.1742V34.2877C50.1742 34.0029 49.9434 33.7721 49.6586 33.7721H42.4902C42.309 33.7721 42.1411 33.677 42.048 33.5216V33.5216Z"
          fill="#01ECA5"
        />
        <path
          d="M49.6588 43.3096H37.9014C37.6166 43.3096 37.3857 43.0787 37.3857 42.7939V36.0908C37.3857 35.806 37.6166 35.5752 37.9014 35.5752H49.6588C49.9436 35.5752 50.1744 35.806 50.1744 36.0908V42.7939C50.1744 43.0787 49.9436 43.3096 49.6588 43.3096Z"
          fill="#97FFD2"
        />
        <path
          d="M49.7342 48.4456H38.2897C37.8035 48.4456 37.4092 48.0513 37.4092 47.5651C37.4092 47.0788 37.8033 46.6846 38.2897 46.6846H49.7342C50.2204 46.6846 50.6147 47.0788 50.6147 47.5651C50.6147 48.0513 50.2206 48.4456 49.7342 48.4456Z"
          fill="#B7C5FF"
        />
        <path
          d="M46.6404 51.7112H41.3834C40.8972 51.7112 40.5029 51.3169 40.5029 50.8307C40.5029 50.3445 40.897 49.9502 41.3834 49.9502H46.6404C47.1266 49.9502 47.5209 50.3445 47.5209 50.8307C47.5209 51.3169 47.1268 51.7112 46.6404 51.7112Z"
          fill="#6583FE"
        />
        <path
          d="M61.8709 33.5216L61.14 32.3024C61.0468 32.147 60.8789 32.0518 60.6977 32.0518H57.7241C57.4393 32.0518 57.2085 32.2826 57.2085 32.5674V40.8174H69.9972V34.2877C69.9972 34.0029 69.7664 33.7721 69.4816 33.7721H62.3132C62.1319 33.7721 61.9641 33.677 61.8709 33.5216V33.5216Z"
          fill="#01ECA5"
        />
        <path
          d="M69.4816 43.3096H57.7241C57.4393 43.3096 57.2085 43.0787 57.2085 42.7939V36.0908C57.2085 35.806 57.4393 35.5752 57.7241 35.5752H69.4816C69.7664 35.5752 69.9972 35.806 69.9972 36.0908V42.7939C69.9972 43.0787 69.7664 43.3096 69.4816 43.3096Z"
          fill="#97FFD2"
        />
        <path
          d="M69.5572 48.4456H58.1127C57.6265 48.4456 57.2322 48.0513 57.2322 47.5651C57.2322 47.0788 57.6263 46.6846 58.1127 46.6846H69.5572C70.0434 46.6846 70.4377 47.0788 70.4377 47.5651C70.4377 48.0513 70.0434 48.4456 69.5572 48.4456Z"
          fill="#B7C5FF"
        />
        <path
          d="M66.4634 51.7112H61.2064C60.7202 51.7112 60.3259 51.3169 60.3259 50.8307C60.3259 50.3445 60.72 49.9502 61.2064 49.9502H66.4634C66.9496 49.9502 67.3439 50.3445 67.3439 50.8307C67.3439 51.3169 66.9496 51.7112 66.4634 51.7112Z"
          fill="#6583FE"
        />
        <path
          d="M60.3258 12.1086C60.0979 12.1086 59.8793 12.018 59.7182 11.8569L57.8245 9.96321C57.4888 9.62754 57.4888 9.08338 57.8245 8.74788L59.7182 6.85416C60.0536 6.51866 60.5981 6.51866 60.9334 6.85416L62.8271 8.74788C63.1628 9.08355 63.1628 9.62771 62.8271 9.96321L60.9334 11.8569C60.7724 12.018 60.5539 12.1086 60.3258 12.1086V12.1086ZM59.6476 9.35529L60.3258 10.0337L61.004 9.35529L60.3258 8.67707L59.6476 9.35529Z"
          fill="#97FFD2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6638"
          x1="2.30571"
          y1="18.5265"
          x2="87.4902"
          y2="74.1916"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6638">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationFolder;
