import { SVGProps } from "types";

const BoxSplit = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5382)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M16.5 6H7.5C6.67275 6 6 6.67275 6 7.5V9.50025V12V14.0002C6 14.8275 6.67275 15.5002 7.5 15.5002H16.5C17.3273 15.5002 18 14.8275 18 14.0002V12V9.50025V7.5C18 6.67275 17.3273 6 16.5 6ZM6.99975 7.5C6.99975 7.224 7.224 6.99975 7.5 6.99975H16.5C16.776 6.99975 17.0002 7.224 17.0002 7.5V9H6.99975V7.5ZM17.0002 14.0002C17.0002 14.2762 16.776 14.5005 16.5 14.5005H7.5C7.224 14.5005 6.99975 14.2762 6.99975 14.0002V12.5002H17.0002V14.0002Z"
          fill="var(--color-cyber-yellow)"
        />
        <path
          d="M14.4998 14.625H12.4995V16.0095C12.4995 16.2855 12.2752 16.5098 11.9992 16.5098C11.7232 16.5098 11.499 16.2855 11.499 16.0095V14.625H9.49875C9.2235 14.625 9 14.8492 9 15.1252V16.5C9 17.3273 9.67275 18 10.5 18H13.5C14.3273 18 15 17.3273 15 16.5V15.1252C15 14.8492 14.7765 14.625 14.4998 14.625Z"
          fill="var(--color-shandy)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5382">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSplit;
