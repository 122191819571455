import { SVGProps } from "types";

const IllustrationColorPalette = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6612)">
        <path
          d="M70.1644 26.5138C66.0915 24.9263 62.6505 22.0297 60.5461 18.1983C57.0072 11.7552 50.1597 7.3877 42.2893 7.3877C34.1201 7.3877 27.0533 12.0931 23.6449 18.941C21.5573 23.1349 18.0049 26.4278 13.6606 28.1811C7.24128 30.772 1.8523 36.6931 0.142148 45.9499C-0.0455393 46.9657 -0.0482893 48.01 0.139398 49.0258C2.79452 63.3897 14.3017 69.7256 24.7749 68.0455C30.6403 67.1046 36.5779 69.0611 40.9255 73.1093C49.7657 81.3407 63.9368 83.9214 77.6935 75.0979C79.3052 74.0642 81.4674 71.8951 82.5002 70.2828C94.3537 51.777 85.5731 32.5194 70.1644 26.5138Z"
          fill="url(#paint0_linear_2042_6612)"
        />
        <path
          opacity="0.1"
          d="M28.292 70.5751H12.8168C11.8665 70.5751 11.0959 69.8048 11.0959 68.8543V13.7892C11.0959 12.8389 11.8663 12.0684 12.8168 12.0684H28.2919C29.2422 12.0684 30.0127 12.8387 30.0127 13.7892V68.8543C30.0127 69.8046 29.2423 70.5751 28.292 70.5751Z"
          fill="#2626BC"
        />
        <path
          opacity="0.1"
          d="M51.7378 70.5751H36.2627C35.3124 70.5751 34.5419 69.8048 34.5419 68.8543V13.7892C34.5419 12.8389 35.3122 12.0684 36.2627 12.0684H51.7378C52.6881 12.0684 53.4586 12.8387 53.4586 13.7892V68.8543C53.4584 69.8046 52.6881 70.5751 51.7378 70.5751Z"
          fill="#2626BC"
        />
        <path
          opacity="0.1"
          d="M75.1837 70.5751H59.7086C58.7583 70.5751 57.9878 69.8048 57.9878 68.8543V13.7892C57.9878 12.8389 58.7581 12.0684 59.7086 12.0684H75.1837C76.134 12.0684 76.9045 12.8387 76.9045 13.7892V68.8543C76.9044 69.8046 76.134 70.5751 75.1837 70.5751Z"
          fill="#2626BC"
        />
        <path
          d="M28.292 74.1884H12.8168C11.8665 74.1884 11.0959 73.4181 11.0959 72.4676V17.4025C11.0959 16.4522 11.8663 15.6816 12.8168 15.6816H28.2919C29.2422 15.6816 30.0127 16.452 30.0127 17.4025V72.4676C30.0127 73.4179 29.2423 74.1884 28.292 74.1884Z"
          fill="white"
        />
        <path
          d="M28.2919 75.0478H12.8168C11.3942 75.0478 10.2366 73.8904 10.2366 72.4676V17.4025C10.2366 15.9798 11.394 14.8223 12.8168 14.8223H28.2919C29.7145 14.8223 30.8721 15.9797 30.8721 17.4025V62.0104C30.8721 62.4851 30.4872 62.8698 30.0127 62.8698C29.5381 62.8698 29.1533 62.4851 29.1533 62.0104V17.4025C29.1533 16.9274 28.7669 16.541 28.2919 16.541H12.8168C12.3417 16.541 11.9553 16.9274 11.9553 17.4025V72.4676C11.9553 72.9427 12.3417 73.329 12.8168 73.329H28.2919C28.7669 73.329 29.1533 72.9427 29.1533 72.4676V66.7493C29.1533 66.2746 29.5381 65.8899 30.0127 65.8899C30.4872 65.8899 30.8721 66.2746 30.8721 66.7493V72.4676C30.8721 73.8902 29.7147 75.0478 28.2919 75.0478Z"
          fill="#2626BC"
        />
        <path
          d="M25.3943 27.438H15.7143C15.4292 27.438 15.198 27.2068 15.198 26.9216V20.0485C15.198 19.7634 15.4292 19.5322 15.7143 19.5322H25.3941C25.6793 19.5322 25.9104 19.7634 25.9104 20.0485V26.9216C25.9104 27.207 25.6794 27.438 25.3943 27.438Z"
          fill="#FFC4DF"
        />
        <path
          d="M25.3943 37.894H15.7143C15.4292 37.894 15.198 37.6628 15.198 37.3777V30.5046C15.198 30.2195 15.4292 29.9883 15.7143 29.9883H25.3941C25.6793 29.9883 25.9104 30.2195 25.9104 30.5046V37.3777C25.9104 37.663 25.6794 37.894 25.3943 37.894Z"
          fill="#FFA8CF"
        />
        <path
          d="M25.3943 48.3509H15.7143C15.4292 48.3509 15.198 48.1197 15.198 47.8346V40.9616C15.198 40.6765 15.4292 40.4453 15.7143 40.4453H25.3941C25.6793 40.4453 25.9104 40.6765 25.9104 40.9616V47.8347C25.9104 48.1197 25.6794 48.3509 25.3943 48.3509Z"
          fill="#FF7EB8"
        />
        <path
          d="M25.3943 58.8071H15.7143C15.4292 58.8071 15.198 58.5759 15.198 58.2908V51.4177C15.198 51.1325 15.4292 50.9014 15.7143 50.9014H25.3941C25.6793 50.9014 25.9104 51.1325 25.9104 51.4177V58.2908C25.9104 58.5759 25.6794 58.8071 25.3943 58.8071Z"
          fill="#FF5BA8"
        />
        <path
          d="M18.892 62.6448H22.2167C22.5995 62.6448 22.9097 62.3346 22.9097 61.9518C22.9097 61.569 22.5995 61.2588 22.2167 61.2588H18.892C18.5092 61.2588 18.199 61.569 18.199 61.9518C18.199 62.3346 18.5092 62.6448 18.892 62.6448Z"
          fill="#B7C5FF"
        />
        <path
          d="M51.7378 74.1884H36.2627C35.3124 74.1884 34.5419 73.4181 34.5419 72.4676V17.4025C34.5419 16.4522 35.3122 15.6816 36.2627 15.6816H51.7378C52.6881 15.6816 53.4586 16.452 53.4586 17.4025V72.4676C53.4584 73.4179 52.6881 74.1884 51.7378 74.1884Z"
          fill="white"
        />
        <path
          d="M51.7378 75.0479H36.2627C34.8401 75.0479 33.6825 73.8905 33.6825 72.4678V17.4026C33.6825 15.98 34.8399 14.8224 36.2627 14.8224H40.888C41.1199 14.8224 41.3418 14.9161 41.5037 15.0821C41.6654 15.2482 41.7532 15.4725 41.7472 15.7043C41.7467 15.7246 41.7455 15.7449 41.7439 15.7652C41.7302 16.2277 41.3509 16.5986 40.8849 16.5986C40.7758 16.5986 40.6715 16.5783 40.5755 16.5412H36.2627C35.7876 16.5412 35.4012 16.9276 35.4012 17.4026V72.4678C35.4012 72.9428 35.7876 73.3292 36.2627 73.3292H51.7378C52.2129 73.3292 52.5992 72.9428 52.5992 72.4678V17.4026C52.5992 16.9276 52.2129 16.5412 51.7378 16.5412H45.9336C45.8375 16.5783 45.7332 16.5986 45.624 16.5986C45.1579 16.5986 44.7786 16.2277 44.765 15.7648C44.7634 15.7428 44.7624 15.7238 44.7619 15.7042C44.7559 15.4723 44.8437 15.248 45.0055 15.082C45.167 14.9159 45.3891 14.8223 45.6209 14.8223H51.7376C53.1602 14.8223 54.3178 15.9797 54.3178 17.4025V72.4678C54.3178 73.8904 53.1604 75.0479 51.7378 75.0479ZM41.7443 15.7423V15.7442C41.7443 15.7435 41.7443 15.743 41.7443 15.7423ZM44.7647 15.7425V15.743C44.7647 15.7428 44.7647 15.7427 44.7647 15.7425ZM41.7443 15.7392V15.742C41.7443 15.7409 41.7443 15.7401 41.7443 15.7392ZM44.7647 15.7392V15.7418C44.7647 15.7409 44.7647 15.7401 44.7647 15.7392Z"
          fill="#2626BC"
        />
        <path
          d="M48.8402 27.438H39.1602C38.8751 27.438 38.6439 27.2068 38.6439 26.9216V20.0485C38.6439 19.7634 38.8751 19.5322 39.1602 19.5322H48.8401C49.1252 19.5322 49.3564 19.7634 49.3564 20.0485V26.9216C49.3564 27.207 49.1254 27.438 48.8402 27.438Z"
          fill="#97FFD2"
        />
        <path
          d="M48.8402 37.894H39.1602C38.8751 37.894 38.6439 37.6628 38.6439 37.3777V30.5046C38.6439 30.2195 38.8751 29.9883 39.1602 29.9883H48.8401C49.1252 29.9883 49.3564 30.2195 49.3564 30.5046V37.3777C49.3564 37.663 49.1254 37.894 48.8402 37.894Z"
          fill="#02FFB3"
        />
        <path
          d="M48.8402 48.3509H39.1602C38.8751 48.3509 38.6439 48.1197 38.6439 47.8346V40.9616C38.6439 40.6765 38.8751 40.4453 39.1602 40.4453H48.8401C49.1252 40.4453 49.3564 40.6765 49.3564 40.9616V47.8347C49.3564 48.1197 49.1254 48.3509 48.8402 48.3509Z"
          fill="#01ECA5"
        />
        <path
          d="M48.8402 58.8071H39.1602C38.8751 58.8071 38.6439 58.5759 38.6439 58.2908V51.4177C38.6439 51.1325 38.8751 50.9014 39.1602 50.9014H48.8401C49.1252 50.9014 49.3564 51.1325 49.3564 51.4177V58.2908C49.3564 58.5759 49.1254 58.8071 48.8402 58.8071Z"
          fill="#00D890"
        />
        <path
          d="M42.3378 62.6448H45.6625C46.0453 62.6448 46.3555 62.3346 46.3555 61.9518C46.3555 61.569 46.0453 61.2588 45.6625 61.2588H42.3378C41.955 61.2588 41.6448 61.569 41.6448 61.9518C41.6448 62.3346 41.955 62.6448 42.3378 62.6448Z"
          fill="#B7C5FF"
        />
        <path
          d="M75.1836 74.1884H59.7085C58.7582 74.1884 57.9877 73.4181 57.9877 72.4676V17.4025C57.9877 16.4522 58.758 15.6816 59.7085 15.6816H75.1836C76.1339 15.6816 76.9044 16.452 76.9044 17.4025V72.4676C76.9042 73.4179 76.1339 74.1884 75.1836 74.1884Z"
          fill="white"
        />
        <path
          d="M75.1836 75.0478H59.7085C58.2859 75.0478 57.1283 73.8904 57.1283 72.4676V17.4025C57.1283 15.9798 58.2857 14.8223 59.7085 14.8223H75.1836C76.6062 14.8223 77.7638 15.9797 77.7638 17.4025V72.4676C77.7636 73.8902 76.6062 75.0478 75.1836 75.0478ZM59.7085 16.541C59.2334 16.541 58.847 16.9274 58.847 17.4025V72.4676C58.847 72.9427 59.2334 73.329 59.7085 73.329H75.1836C75.6587 73.329 76.045 72.9427 76.045 72.4676V17.4025C76.045 16.9274 75.6587 16.541 75.1836 16.541H59.7085Z"
          fill="#2626BC"
        />
        <path
          d="M72.286 27.438H62.6062C62.321 27.438 62.0898 27.2068 62.0898 26.9216V20.0485C62.0898 19.7634 62.321 19.5322 62.6062 19.5322H72.286C72.5711 19.5322 72.8023 19.7634 72.8023 20.0485V26.9216C72.8021 27.207 72.5711 27.438 72.286 27.438Z"
          fill="#9FB0FE"
        />
        <path
          d="M72.286 37.894H62.6062C62.321 37.894 62.0898 37.6628 62.0898 37.3777V30.5046C62.0898 30.2195 62.321 29.9883 62.6062 29.9883H72.286C72.5711 29.9883 72.8023 30.2195 72.8023 30.5046V37.3777C72.8021 37.663 72.5711 37.894 72.286 37.894Z"
          fill="#6583FE"
        />
        <path
          d="M72.286 48.3509H62.6062C62.321 48.3509 62.0898 48.1197 62.0898 47.8346V40.9616C62.0898 40.6765 62.321 40.4453 62.6062 40.4453H72.286C72.5711 40.4453 72.8023 40.6765 72.8023 40.9616V47.8347C72.8021 48.1197 72.5711 48.3509 72.286 48.3509Z"
          fill="#4369FD"
        />
        <path
          d="M72.286 58.8071H62.6062C62.321 58.8071 62.0898 58.5759 62.0898 58.2908V51.4177C62.0898 51.1325 62.321 50.9014 62.6062 50.9014H72.286C72.5711 50.9014 72.8023 51.1325 72.8023 51.4177V58.2908C72.8021 58.5759 72.5711 58.8071 72.286 58.8071Z"
          fill="#2D58E0"
        />
        <path
          d="M65.7836 62.6448H69.1083C69.4911 62.6448 69.8013 62.3346 69.8013 61.9518C69.8013 61.569 69.4911 61.2588 69.1083 61.2588H65.7836C65.4008 61.2588 65.0906 61.569 65.0906 61.9518C65.0906 62.3346 65.4008 62.6448 65.7836 62.6448Z"
          fill="#B7C5FF"
        />
        <path
          d="M23.8932 12.6426C23.4186 12.6426 23.0338 12.258 23.0338 11.7832C23.0338 11.2119 22.5689 10.7472 21.9976 10.7472C21.523 10.7472 21.1382 10.3625 21.1382 9.8878C21.1382 9.41308 21.523 9.02842 21.9976 9.02842C22.5689 9.02842 23.0338 8.5635 23.0338 7.99219C23.0338 7.51747 23.4186 7.13281 23.8932 7.13281C24.3677 7.13281 24.7525 7.51747 24.7525 7.99219C24.7525 8.5635 25.2173 9.02842 25.7886 9.02842C26.2632 9.02842 26.648 9.41308 26.648 9.8878C26.648 10.3625 26.2632 10.7472 25.7886 10.7472C25.2173 10.7472 24.7525 11.2119 24.7525 11.7832C24.7525 12.2578 24.3677 12.6426 23.8932 12.6426Z"
          fill="#97FFD2"
        />
        <path
          d="M55.0352 82.586C54.5605 82.586 54.1758 82.2013 54.1758 81.7266C54.1758 81.1553 53.7111 80.6905 53.1398 80.6905C52.6651 80.6905 52.2804 80.3059 52.2804 79.8312C52.2804 79.3564 52.6651 78.9718 53.1398 78.9718C53.7111 78.9718 54.1758 78.5069 54.1758 77.9355C54.1758 77.4608 54.5605 77.0762 55.0352 77.0762C55.5099 77.0762 55.8946 77.4608 55.8946 77.9355C55.8946 78.5069 56.3595 78.9718 56.9308 78.9718C57.4055 78.9718 57.7902 79.3564 57.7902 79.8312C57.7902 80.3059 57.4055 80.6905 56.9308 80.6905C56.3595 80.6905 55.8946 81.1553 55.8946 81.7266C55.8946 82.2013 55.5099 82.586 55.0352 82.586Z"
          fill="#6583FE"
        />
        <path
          d="M33.3703 10.9166C33.1424 10.9166 32.9238 10.8262 32.7626 10.6648L30.8708 8.7728C30.5351 8.4373 30.5351 7.89314 30.8708 7.55764L32.7626 5.66582C33.0983 5.33014 33.6423 5.33014 33.9779 5.66582L35.8698 7.55764C36.031 7.71886 36.1214 7.93732 36.1214 8.16522C36.1214 8.39313 36.0308 8.61175 35.8698 8.7728L33.9779 10.6648C33.8169 10.8258 33.5983 10.9166 33.3703 10.9166ZM32.6938 8.16522L33.3703 8.84189L34.0468 8.16522L33.3703 7.48872L32.6938 8.16522Z"
          fill="#FF7EB8"
        />
        <path
          d="M45.8919 82.5827C45.6721 82.5827 45.4519 82.4988 45.2843 82.3309L43.3925 80.439C43.0568 80.1035 43.0568 79.5594 43.3925 79.2239L45.2842 77.3319C45.4454 77.1707 45.664 77.0801 45.8919 77.0801C46.1198 77.0801 46.3384 77.1705 46.4995 77.3319L48.3915 79.2239C48.5527 79.3851 48.6433 79.6035 48.6433 79.8315C48.6433 80.0594 48.5527 80.278 48.3915 80.4392L46.4995 82.331C46.3317 82.4986 46.1117 82.5827 45.8919 82.5827ZM45.2154 79.8315L45.8919 80.508L46.5686 79.8315L45.8919 79.1548L45.2154 79.8315Z"
          fill="#FF7EB8"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6612"
          x1="3.1283"
          y1="26.7094"
          x2="92.4664"
          y2="61.3278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6612">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationColorPalette;
