import { SVGProps } from "types";

const IllustrationSpeedTest = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6601)">
        <path
          d="M83.0103 24.7026C77.0041 18.5241 67.6309 17.6718 60.6956 22.1455C58.7225 23.4182 56.3332 23.845 54.0411 23.3361C48.6159 22.1314 42.9407 22.9542 38.1071 25.5777C34.8574 27.3413 30.8993 27.0346 27.8669 24.9194C25.8539 23.5153 23.5164 22.5092 20.9478 22.0358C11.4194 20.2798 2.15547 26.5996 0.323798 36.1136C-1.54947 45.8433 4.90049 55.1938 14.6231 56.9258C16.9986 57.3489 19.3476 57.2776 21.5648 56.7855C25.1632 55.987 28.9228 57.3221 31.245 60.1845C32.7107 61.9913 34.4783 63.6038 36.5313 64.9436C47.1414 71.8667 61.3547 68.8778 68.2779 58.2679C68.2852 58.2568 68.2925 58.2454 68.2999 58.2342C69.5678 56.2836 71.5399 54.893 73.8205 54.4325C77.1468 53.761 80.3175 52.1287 82.8879 49.535C89.6552 42.706 89.7115 31.5962 83.0103 24.7026Z"
          fill="url(#paint0_linear_2042_6601)"
        />
        <path
          opacity="0.1"
          d="M78.375 16.9365V60.6357C78.375 62.0589 77.22 63.2139 75.7969 63.2139H12.2031C10.78 63.2139 9.625 62.0589 9.625 60.6357V16.9365C9.625 15.5134 10.78 14.3584 12.2031 14.3584H75.7969C77.22 14.3584 78.375 15.5134 78.375 16.9365Z"
          fill="#2626BC"
        />
        <path
          d="M53.7469 79.3194H34.2527L38.1512 63.8672H49.8485L53.7469 79.3194Z"
          fill="#9FB0FE"
        />
        <path
          d="M51.2064 69.2472H36.7935L38.1513 63.8672H49.8486L51.2064 69.2472Z"
          fill="#8399FE"
        />
        <path
          d="M78.375 20.5459V64.2451C78.375 65.6682 77.22 66.8232 75.7969 66.8232H12.2031C10.78 66.8232 9.625 65.6682 9.625 64.2451V20.5459C9.625 19.1228 10.78 17.9678 12.2031 17.9678H75.7969C77.22 17.9678 78.375 19.1228 78.375 20.5459Z"
          fill="white"
        />
        <path
          d="M67.9448 26.2471C67.9448 39.4715 57.2243 50.192 43.9999 50.192C30.7755 50.192 20.0549 39.4715 20.0549 26.2471H67.9448Z"
          fill="#DCFDEE"
        />
        <path
          d="M38.6484 58.1622C38.6484 55.2067 41.0444 52.8105 44.0001 52.8105C46.9558 52.8105 49.3518 55.2065 49.3518 58.1622H38.6484Z"
          fill="#B7C5FF"
        />
        <path
          d="M78.375 26.2472V20.5459C78.375 19.1221 77.2207 17.9678 75.7969 17.9678H12.2031C10.7793 17.9678 9.625 19.1221 9.625 20.5459V26.2472H78.375Z"
          fill="#FF7EB8"
        />
        <path
          d="M9.625 58.1621V64.2448C9.625 65.6686 10.7793 66.8229 12.2031 66.8229H75.7969C77.2207 66.8229 78.375 65.6686 78.375 64.2448V58.1621H9.625Z"
          fill="#B7C5FF"
        />
        <path
          d="M58.5039 58.1621C58.5039 50.1519 52.0103 43.6583 44 43.6583C35.9898 43.6583 29.4962 50.1519 29.4962 58.1621H24.0098C24.0098 47.1218 32.9596 38.1719 44 38.1719C55.0404 38.1719 63.9903 47.1218 63.9903 58.1621H58.5039Z"
          fill="#FF5BA8"
        />
        <path
          d="M44 43.6583C35.9898 43.6583 29.4962 50.1519 29.4962 58.1621H24.0098C24.0098 47.1218 32.9596 38.1719 44 38.1719V43.6583Z"
          fill="#97FFD2"
        />
        <path
          d="M58.9291 44.8706L55.0412 48.7584C52.3841 45.6371 48.4223 43.6571 44 43.6571V38.1709C49.9366 38.1709 55.2698 40.7593 58.9291 44.8706Z"
          fill="#FF7EB8"
        />
        <path
          d="M44.0001 38.1709V43.6571C39.5778 43.6571 35.616 45.6371 32.9589 48.7584L29.071 44.8706C32.7303 40.7593 38.0635 38.1709 44.0001 38.1709Z"
          fill="#02FFB3"
        />
        <path
          d="M49.0529 49.644L49.0292 49.6278L49.3042 49.2239C49.5713 48.8315 49.4699 48.297 49.0776 48.0299C48.6852 47.763 48.1509 47.8642 47.8836 48.2564L47.6086 48.6603L47.5849 48.6442C47.1925 48.3774 46.6582 48.4787 46.3909 48.8707C46.1238 49.2631 46.2252 49.7976 46.6174 50.0647L46.6411 50.0809L43.2897 55.0022C43.0227 55.3946 43.1241 55.9291 43.5163 56.1962C43.6644 56.2969 43.8327 56.3454 43.9992 56.3454C44.2741 56.3454 44.5439 56.2139 44.7103 55.9697L48.0617 51.0484L48.0854 51.0645C48.2336 51.1653 48.4018 51.2137 48.5684 51.2137C48.8432 51.2137 49.113 51.0822 49.2794 50.838C49.5467 50.4456 49.4451 49.9111 49.0529 49.644Z"
          fill="#9FB0FE"
        />
        <path
          d="M27.6651 31.4183H16.2206C15.7344 31.4183 15.3401 31.024 15.3401 30.5377C15.3401 30.0515 15.7342 29.6572 16.2206 29.6572H27.6651C28.1513 29.6572 28.5456 30.0515 28.5456 30.5377C28.5454 31.024 28.1513 31.4183 27.6651 31.4183Z"
          fill="#B7C5FF"
        />
        <path
          d="M24.5713 34.6839H19.3144C18.8281 34.6839 18.4338 34.2896 18.4338 33.8034C18.4338 33.3171 18.8279 32.9229 19.3144 32.9229H24.5713C25.0576 32.9229 25.4518 33.3171 25.4518 33.8034C25.4517 34.2896 25.0576 34.6839 24.5713 34.6839Z"
          fill="#6583FE"
        />
        <path
          d="M71.7793 31.4183H60.3349C59.8486 31.4183 59.4543 31.024 59.4543 30.5377C59.4543 30.0515 59.8485 29.6572 60.3349 29.6572H71.7793C72.2656 29.6572 72.6598 30.0515 72.6598 30.5377C72.6598 31.024 72.2657 31.4183 71.7793 31.4183Z"
          fill="#B7C5FF"
        />
        <path
          d="M68.6856 34.6839H63.4286C62.9424 34.6839 62.5481 34.2896 62.5481 33.8034C62.5481 33.3171 62.9422 32.9229 63.4286 32.9229H68.6856C69.1718 32.9229 69.5661 33.3171 69.5661 33.8034C69.5661 34.2896 69.172 34.6839 68.6856 34.6839Z"
          fill="#6583FE"
        />
        <path
          d="M23.1194 43.2773H20.7659C20.4811 43.2773 20.2502 43.0465 20.2502 42.7617V38.5508C20.2502 38.266 20.4811 38.0352 20.7659 38.0352H23.1194C23.4042 38.0352 23.635 38.266 23.635 38.5508V42.7617C23.635 43.0465 23.4042 43.2773 23.1194 43.2773Z"
          fill="#FFA8CF"
        />
        <path
          d="M67.2341 43.2773H64.8806C64.5958 43.2773 64.365 43.0465 64.365 42.7617V38.5508C64.365 38.266 64.5958 38.0352 64.8806 38.0352H67.2341C67.5189 38.0352 67.7497 38.266 67.7497 38.5508V42.7617C67.7497 43.0465 67.5189 43.2773 67.2341 43.2773Z"
          fill="#FFA8CF"
        />
        <path
          d="M46.9063 34.7643C47.437 34.7643 47.8671 34.3342 47.8671 33.8035C47.8671 33.2729 47.437 32.8428 46.9063 32.8428C46.3757 32.8428 45.9456 33.2729 45.9456 33.8035C45.9456 34.3342 46.3757 34.7643 46.9063 34.7643Z"
          fill="#6583FE"
        />
        <path
          d="M43.9998 34.7643C44.5305 34.7643 44.9606 34.3342 44.9606 33.8035C44.9606 33.2729 44.5305 32.8428 43.9998 32.8428C43.4692 32.8428 43.0391 33.2729 43.0391 33.8035C43.0391 34.3342 43.4692 34.7643 43.9998 34.7643Z"
          fill="#6583FE"
        />
        <path
          d="M41.0933 34.7643C41.624 34.7643 42.0541 34.3342 42.0541 33.8035C42.0541 33.2729 41.624 32.8428 41.0933 32.8428C40.5627 32.8428 40.1326 33.2729 40.1326 33.8035C40.1326 34.3342 40.5627 34.7643 41.0933 34.7643Z"
          fill="#6583FE"
        />
        <path
          d="M21.3854 78.0853C21.1654 78.0853 20.9456 78.0014 20.7777 77.8337L18.884 75.94C18.7227 75.7787 18.6323 75.5603 18.6323 75.3322C18.6323 75.1041 18.7229 74.8857 18.884 74.7244L20.7777 72.8307C21.1133 72.4952 21.6573 72.4952 21.993 72.8307L23.8867 74.7244C24.0479 74.8857 24.1383 75.1041 24.1383 75.3322C24.1383 75.5603 24.0478 75.7787 23.8867 75.94L21.993 77.8337C21.8252 78.0014 21.6054 78.0853 21.3854 78.0853ZM20.707 75.3322L21.3854 76.0106L22.0638 75.3322L21.3854 74.6538L20.707 75.3322Z"
          fill="#6583FE"
        />
        <path
          d="M59.3205 15.3861C58.8459 15.3861 58.4611 15.0012 58.4611 14.5267C58.4611 13.9564 57.997 13.4924 57.4268 13.4924C56.9522 13.4924 56.5674 13.1075 56.5674 12.633C56.5674 12.1584 56.9522 11.7736 57.4268 11.7736C57.997 11.7736 58.4611 11.3095 58.4611 10.7393C58.4611 10.2647 58.8459 9.87988 59.3205 9.87988C59.795 9.87988 60.1799 10.2647 60.1799 10.7393C60.1799 11.3095 60.6439 11.7736 61.2142 11.7736C61.6887 11.7736 62.0736 12.1584 62.0736 12.633C62.0736 13.1075 61.6887 13.4924 61.2142 13.4924C60.6439 13.4924 60.1799 13.9564 60.1799 14.5267C60.1799 15.0012 59.795 15.3861 59.3205 15.3861Z"
          fill="#FF7EB8"
        />
        <path
          d="M62.2187 75.3665C61.7441 75.3665 61.3593 74.9817 61.3593 74.5072C61.3593 73.9369 60.8952 73.4728 60.325 73.4728C59.8504 73.4728 59.4656 73.088 59.4656 72.6134C59.4656 72.1389 59.8504 71.7541 60.325 71.7541C60.8952 71.7541 61.3593 71.29 61.3593 70.7197C61.3593 70.2452 61.7441 69.8604 62.2187 69.8604C62.6932 69.8604 63.078 70.2452 63.078 70.7197C63.078 71.29 63.5421 71.7541 64.1124 71.7541C64.5869 71.7541 64.9718 72.1389 64.9718 72.6134C64.9718 73.088 64.5869 73.4728 64.1124 73.4728C63.5421 73.4728 63.078 73.9369 63.078 74.5072C63.078 74.9817 62.6932 75.3665 62.2187 75.3665Z"
          fill="#FF7EB8"
        />
        <path
          d="M69.1758 13.3283C68.9558 13.3283 68.736 13.2444 68.5681 13.0767L66.6744 11.1829C66.3387 10.8473 66.3387 10.3031 66.6744 9.96761L68.5681 8.07389C68.9037 7.73839 69.4477 7.73839 69.7834 8.07389L71.6771 9.96761C72.0128 10.3033 72.0128 10.8474 71.6771 11.1829L69.7834 13.0767C69.6157 13.2444 69.3958 13.3283 69.1758 13.3283ZM68.4974 10.5752L69.1758 11.2536L69.8542 10.5752L69.1758 9.8968L68.4974 10.5752Z"
          fill="#97FFD2"
        />
        <path
          d="M16.1704 21.2354C15.6959 21.2354 15.311 21.6202 15.311 22.0947V22.1198C15.311 22.5944 15.6959 22.9792 16.1704 22.9792C16.645 22.9792 17.0298 22.5944 17.0298 22.1198V22.0947C17.0298 21.62 16.645 21.2354 16.1704 21.2354Z"
          fill="#2626BC"
        />
        <path
          d="M19.4919 21.2354C19.0174 21.2354 18.6326 21.6202 18.6326 22.0947V22.1198C18.6326 22.5944 19.0174 22.9792 19.4919 22.9792C19.9665 22.9792 20.3513 22.5944 20.3513 22.1198V22.0947C20.3513 21.62 19.9665 21.2354 19.4919 21.2354Z"
          fill="#2626BC"
        />
        <path
          d="M22.8132 21.2354C22.3387 21.2354 21.9539 21.6202 21.9539 22.0947V22.1198C21.9539 22.5944 22.3387 22.9792 22.8132 22.9792C23.2878 22.9792 23.6726 22.5944 23.6726 22.1198V22.0947C23.6726 21.62 23.288 21.2354 22.8132 21.2354Z"
          fill="#2626BC"
        />
        <path
          d="M75.7969 17.1084H12.2031C10.3077 17.1084 8.76562 18.6505 8.76562 20.5459V64.2448C8.76562 66.1402 10.3077 67.6823 12.2031 67.6823H36.3024L33.5835 78.459H30.9488C30.4743 78.459 30.0895 78.8438 30.0895 79.3184C30.0895 79.7929 30.4743 80.1777 30.9488 80.1777H57.0512C57.5257 80.1777 57.9105 79.7929 57.9105 79.3184C57.9105 78.8438 57.5257 78.459 57.0512 78.459H54.4161L51.6974 67.6823H75.7969C77.6923 67.6823 79.2344 66.1402 79.2344 64.2448V58.1621C79.2344 57.6876 78.8495 57.3027 78.375 57.3027C78.3587 57.3027 78.3435 57.3067 78.3276 57.3075C78.3116 57.3067 78.2963 57.3027 78.2801 57.3027H10.4844V27.1067H77.5156V53.4287C77.5156 53.9032 77.9005 54.288 78.375 54.288C78.8495 54.288 79.2344 53.9032 79.2344 53.4287V20.5459C79.2344 18.6506 77.6923 17.1084 75.7969 17.1084ZM52.6436 78.459H35.3561L38.075 67.6823H49.9247L52.6436 78.459ZM77.5156 59.0215V64.2448C77.5156 65.1925 76.7446 65.9635 75.7969 65.9635H12.2031C11.2554 65.9635 10.4844 65.1925 10.4844 64.2448V59.0215H77.5156ZM10.4844 25.388V20.5459C10.4844 19.5982 11.2554 18.8271 12.2031 18.8271H75.7969C76.7446 18.8271 77.5156 19.5982 77.5156 20.5459V25.3878H10.4844V25.388Z"
          fill="#2626BC"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6601"
          x1="-3.43201"
          y1="44.5642"
          x2="91.0305"
          y2="43.7208"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6601">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationSpeedTest;
