import { SVGProps } from "types";

const BoxClick = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5425)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M17.5208 14.262C17.5193 14.2605 17.5186 14.2598 17.5171 14.2583L16.0103 12.7515C15.8318 12.573 15.5438 12.5723 15.3668 12.75C15.1898 12.927 15.1906 13.2158 15.3683 13.3935L15.5296 13.5548C15.5596 13.5848 15.5596 13.6328 15.5296 13.662C15.5003 13.6913 15.4523 13.6913 15.4223 13.662L15.0458 13.2855C14.8673 13.107 14.5793 13.1063 14.4023 13.284C14.2253 13.461 14.2261 13.7498 14.4038 13.9275L14.7803 14.304C14.8103 14.334 14.8103 14.382 14.7803 14.4113C14.7511 14.4405 14.7031 14.4405 14.6731 14.4113L14.0813 13.8195C13.9028 13.641 13.6148 13.6403 13.4378 13.818C13.2608 13.995 13.2616 14.2838 13.4393 14.4615L14.0311 15.0533C14.0611 15.0833 14.0611 15.1313 14.0311 15.1605C14.0018 15.1898 13.9538 15.1898 13.9238 15.1605L11.8253 13.062C11.6468 12.8835 11.3588 12.8828 11.1818 13.0605C11.0048 13.2383 11.0056 13.5263 11.1833 13.704L13.7086 16.2293C13.3778 16.1775 12.8206 16.1558 12.4786 16.4978C12.1576 16.8188 12.1658 17.0408 12.2656 17.1413C12.5371 17.4128 12.7463 16.9838 13.9831 17.5748C15.2198 18.1658 16.0156 18.1178 16.5586 17.5823L17.3078 16.833C17.3116 16.8293 17.3041 16.8368 17.3078 16.833C18.0713 16.071 18.2896 15.03 17.5208 14.262Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M12.5002 8.5H9.50025C9.2235 8.5 9 8.72425 9 9.00025C9 9.27625 9.2235 9.5005 9.50025 9.5005H12.5002C12.777 9.5005 13.0005 9.27625 13.0005 9.00025C13.0005 8.72425 12.7762 8.5 12.5002 8.5Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M12.9998 11.0002C12.9998 10.7242 12.7763 10.5 12.4995 10.5H9.4995C9.2235 10.5 9 10.7235 9 11.0002C9 11.277 9.2235 11.5005 9.50025 11.5005H12.5002C12.7762 11.4997 12.9998 11.2762 12.9998 11.0002Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M9.375 12.5C9.168 12.5 9 12.668 9 12.875C9 13.082 9.168 13.25 9.375 13.25H9.63C9.837 13.25 10.005 13.082 10.005 12.875C10.005 12.668 9.837 12.5 9.63 12.5H9.375Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M10.7565 14.991C8.64975 14.8695 6.99975 13.116 6.99975 10.9995C6.99975 8.79375 8.7945 6.99975 11.0002 6.99975C13.206 6.99975 15 8.79375 15 10.9995C15 11.061 15.0008 11.1233 14.9963 11.1848C14.9783 11.4608 15.186 11.6985 15.462 11.7172C15.7365 11.7405 15.9757 11.5275 15.9945 11.2523C15.9998 11.1683 16.0005 11.0843 16.0005 10.9995C16.0005 8.2425 13.7572 6 11.0002 6C8.24325 6 6 8.2425 6 10.9995C6 13.6455 8.064 15.837 10.6988 15.9893C10.7085 15.99 10.7182 15.99 10.728 15.99C10.9905 15.99 11.2118 15.7845 11.2267 15.519C11.2425 15.243 11.0325 15.0068 10.7565 14.991Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5425">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxClick;
