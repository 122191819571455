import { SVGProps } from "types";

const IllustrationProfile = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6616)">
        <path
          d="M70.1644 26.5147C66.0915 24.9273 62.6505 22.0307 60.5461 18.1993C57.0072 11.7562 50.1597 7.38867 42.2893 7.38867C34.1201 7.38867 27.0533 12.0941 23.6449 18.9419C21.5573 23.1359 18.0049 26.4288 13.6606 28.1821C7.24128 30.773 1.8523 36.694 0.142148 45.9509C-0.0455393 46.9667 -0.0482893 48.011 0.139398 49.0268C2.79452 63.3907 14.3017 69.7265 24.7749 68.0464C30.6403 67.1056 36.5779 69.0621 40.9255 73.1102C49.7657 81.3417 63.9368 83.9224 77.6935 75.0988C79.3052 74.0652 81.4674 71.8961 82.5002 70.2837C94.3537 51.778 85.5731 32.5204 70.1644 26.5147Z"
          fill="url(#paint0_linear_2042_6616)"
        />
        <path
          opacity="0.1"
          d="M77.4277 17.5643H63.4332V11.0281C63.4332 9.32034 62.0484 7.93555 60.3406 7.93555H25.4283C23.7206 7.93555 22.3358 9.32034 22.3358 11.0281V21.0367H7.90345C7.19309 21.0367 6.61731 21.6187 6.61731 22.3368V34.5291C6.61731 35.247 7.19309 35.8291 7.90345 35.8291H22.3358V42.678H7.90345C7.19309 42.678 6.61731 43.26 6.61731 43.9781V66.5456C6.61731 67.2635 7.19309 67.8457 7.90345 67.8457H22.3358V73.6657C22.3358 75.3734 23.7206 76.7582 25.4283 76.7582H60.3406C62.0484 76.7582 63.4332 75.3734 63.4332 73.6657V58.4707H69.7551C70.7005 58.4707 71.4669 57.6961 71.4669 56.7403V48.4704C71.4669 47.5147 70.7005 46.7399 69.7551 46.7399H63.4332V37.8525H77.4277C78.3799 37.8525 79.1518 37.0806 79.1518 36.1284V19.2884C79.1518 18.3362 78.3799 17.5643 77.4277 17.5643Z"
          fill="#2626BC"
        />
        <path
          d="M63.4332 14.6365V77.2741C63.4332 78.9818 62.0484 80.3666 60.3406 80.3666H25.4284C23.7206 80.3666 22.3358 78.9818 22.3358 77.2741V14.6365C22.3358 12.9287 23.7206 11.5439 25.4284 11.5439H60.3406C62.0484 11.5439 63.4332 12.9287 63.4332 14.6365Z"
          fill="white"
        />
        <path
          d="M63.4332 52.9248V64.6561H60.155C59.2101 64.6561 58.4421 63.8813 58.4421 62.926V54.6568C58.4421 53.6998 59.2101 52.925 60.155 52.925H63.4332V52.9248Z"
          fill="#D2DEFF"
        />
        <path
          d="M27.7186 28.5224V40.7142C27.7186 41.4323 27.143 42.0148 26.4335 42.0148H22.3358V27.2217H26.4335C27.1432 27.2219 27.7186 27.8042 27.7186 28.5224Z"
          fill="#D2DEFF"
        />
        <path
          d="M27.7186 50.1639V72.7314C27.7186 73.4495 27.143 74.032 26.4335 74.032H22.3358V48.8633H26.4335C27.1432 48.8633 27.7186 49.4456 27.7186 50.1639Z"
          fill="#D2DEFF"
        />
        <path
          d="M63.4332 23.749V44.0383H56.5212C55.5694 44.0383 54.7963 43.2653 54.7963 42.3134V25.4741C54.7963 24.5223 55.5694 23.7492 56.5212 23.7492H63.4332V23.749Z"
          fill="#D2DEFF"
        />
        <path
          d="M63.4334 29.3539V14.6522C63.4334 12.935 62.0561 11.543 60.3575 11.543H25.4117C23.7129 11.543 22.3358 12.935 22.3358 14.6522V29.3539H63.4334Z"
          fill="#FFC4DF"
        />
        <path
          d="M55.1358 15.8068L56.5096 11.543H29.2596L30.6334 15.8068C30.8323 16.4242 31.4017 16.8422 32.0442 16.8422H53.7252C54.3675 16.8422 54.9369 16.4244 55.1358 15.8068Z"
          fill="#B7C5FF"
        />
        <path
          d="M53.7252 17.7017H32.0442C31.0255 17.7017 30.1298 17.0462 29.8155 16.0706L28.4417 11.8067C28.2961 11.355 28.5444 10.8709 28.9961 10.7253C29.448 10.5795 29.9322 10.8281 30.0776 11.2798L31.4514 15.5436C31.5361 15.8064 31.7743 15.9831 32.0442 15.9831H53.7252C53.995 15.9831 54.2332 15.8064 54.3179 15.5435L55.6916 11.2798C55.8371 10.8281 56.3211 10.5797 56.773 10.7253C57.2247 10.8709 57.4731 11.355 57.3275 11.8067L55.9537 16.0706C55.6393 17.0462 54.7437 17.7017 53.7252 17.7017Z"
          fill="#2626BC"
        />
        <path
          d="M60.3408 81.2253H25.4284C23.2492 81.2253 21.4764 79.4524 21.4764 77.2734V71.9016C21.4764 71.4271 21.8613 71.0422 22.3358 71.0422C22.8104 71.0422 23.1952 71.4271 23.1952 71.9016V77.2734C23.1952 78.5049 24.1971 79.5066 25.4284 79.5066H60.3408C61.5723 79.5066 62.574 78.5047 62.574 77.2734V62.3428C62.574 61.8682 62.9588 61.4834 63.4333 61.4834C63.9079 61.4834 64.2927 61.8682 64.2927 62.3428V77.2734C64.2927 79.4524 62.52 81.2253 60.3408 81.2253Z"
          fill="#2626BC"
        />
        <path
          d="M63.4333 50.9326C62.9588 50.9326 62.574 50.5478 62.574 50.0732V41.8232C62.574 41.3487 62.9588 40.9639 63.4333 40.9639C63.9079 40.9639 64.2927 41.3487 64.2927 41.8232V50.0732C64.2927 50.5478 63.9081 50.9326 63.4333 50.9326Z"
          fill="#2626BC"
        />
        <path
          d="M22.3358 24.9882C21.8613 24.9882 21.4764 24.6034 21.4764 24.1289V14.6355C21.4764 12.4563 23.2492 10.6836 25.4284 10.6836H60.3408C62.52 10.6836 64.2927 12.4563 64.2927 14.6355V20.7972C64.2927 21.2718 63.9079 21.6566 63.4333 21.6566C62.9588 21.6566 62.574 21.2718 62.574 20.7972V14.6355C62.574 13.404 61.5721 12.4023 60.3408 12.4023H25.4284C24.1969 12.4023 23.1952 13.4042 23.1952 14.6355V24.129C23.1952 24.6036 22.8104 24.9882 22.3358 24.9882Z"
          fill="#2626BC"
        />
        <path
          d="M22.3358 47.1453C21.8613 47.1453 21.4764 46.7605 21.4764 46.286V39.9326C21.4764 39.4581 21.8613 39.0732 22.3358 39.0732C22.8104 39.0732 23.1952 39.4581 23.1952 39.9326V46.286C23.1952 46.7605 22.8104 47.1453 22.3358 47.1453Z"
          fill="#2626BC"
        />
        <path
          d="M42.8845 49.3969C47.1361 49.3969 50.5826 45.913 50.5826 41.6155C50.5826 37.3179 47.1361 33.834 42.8845 33.834C38.633 33.834 35.1864 37.3179 35.1864 41.6155C35.1864 45.913 38.633 49.3969 42.8845 49.3969Z"
          fill="#FF7EB8"
        />
        <path
          d="M45.0084 42.7407C45.5998 42.1737 45.9709 41.3752 45.9709 40.4895C45.9709 38.7744 44.5862 37.3789 42.8843 37.3789C41.1826 37.3789 39.798 38.7744 39.798 40.4895C39.798 41.3752 40.1691 42.1737 40.7605 42.7407C40.1691 43.3077 39.798 44.1063 39.798 44.9919C39.798 45.4665 40.1828 45.8513 40.6573 45.8513C41.1319 45.8513 41.5167 45.4665 41.5167 44.9919C41.5167 44.2245 42.1301 43.6001 42.8843 43.6001C43.6385 43.6001 44.2521 44.2245 44.2521 44.9919C44.2521 45.4665 44.6369 45.8513 45.1115 45.8513C45.586 45.8513 45.9709 45.4665 45.9709 44.9919C45.971 44.1063 45.6 43.3077 45.0084 42.7407ZM41.5169 40.4895C41.5169 39.7221 42.1303 39.0977 42.8845 39.0977C43.6387 39.0977 44.2523 39.7221 44.2523 40.4895C44.2523 41.2569 43.6387 41.8813 42.8845 41.8813C42.1303 41.8813 41.5169 41.2569 41.5169 40.4895Z"
          fill="white"
        />
        <path
          d="M48.5799 56.0998H37.1893C36.7053 56.0998 36.3129 55.7031 36.3129 55.214C36.3129 54.7248 36.7051 54.3281 37.1893 54.3281H48.5799C49.0639 54.3281 49.4563 54.7248 49.4563 55.214C49.4563 55.7031 49.0639 56.0998 48.5799 56.0998Z"
          fill="#B7C5FF"
        />
        <path
          d="M45.5008 59.3859H40.2686C39.7846 59.3859 39.3922 58.9893 39.3922 58.5001C39.3922 58.0109 39.7844 57.6143 40.2686 57.6143H45.5008C45.9848 57.6143 46.3772 58.0109 46.3772 58.5001C46.377 58.9893 45.9848 59.3859 45.5008 59.3859Z"
          fill="#6583FE"
        />
        <path
          d="M45.7774 70.2262C46.3055 70.2262 46.7337 69.7934 46.7337 69.2596C46.7337 68.7257 46.3055 68.293 45.7774 68.293C45.2492 68.293 44.821 68.7257 44.821 69.2596C44.821 69.7934 45.2492 70.2262 45.7774 70.2262Z"
          fill="#D2DEFF"
        />
        <path
          d="M42.8847 68.293C43.4128 68.293 43.841 68.7257 43.841 69.2596C43.841 69.7934 43.4128 70.2262 42.8847 70.2262C42.3565 70.2262 41.9283 69.7934 41.9283 69.2596C41.9283 68.7257 42.3565 68.293 42.8847 68.293Z"
          fill="#D2DEFF"
        />
        <path
          d="M39.9918 70.2262C40.52 70.2262 40.9482 69.7934 40.9482 69.2596C40.9482 68.7257 40.52 68.293 39.9918 68.293C39.4637 68.293 39.0355 68.7257 39.0355 69.2596C39.0355 69.7934 39.4637 70.2262 39.9918 70.2262Z"
          fill="#D2DEFF"
        />
        <path
          d="M69.7551 62.0785H60.1549C59.2094 62.0785 58.443 61.3038 58.443 60.348V52.0781C58.443 51.1225 59.2094 50.3477 60.1549 50.3477H69.7551C70.7006 50.3477 71.467 51.1223 71.467 52.0781V60.348C71.467 61.3037 70.7006 62.0785 69.7551 62.0785Z"
          fill="#97FFD2"
        />
        <path
          d="M69.7551 62.9386H60.1549C58.7371 62.9386 57.5836 61.7769 57.5836 60.3488V52.0789C57.5836 50.651 58.7371 49.4893 60.1549 49.4893H69.7551C71.1729 49.4893 72.3262 50.651 72.3262 52.0789V60.3488C72.3264 61.7769 71.1729 62.9386 69.7551 62.9386ZM60.1549 51.2078C59.6848 51.2078 59.3024 51.5985 59.3024 52.0787V60.3487C59.3024 60.8289 59.6848 61.2197 60.1549 61.2197H69.7551C70.2252 61.2197 70.6074 60.8291 70.6074 60.3487V52.0789C70.6074 51.5987 70.225 51.208 69.7551 51.208L60.1549 51.2078Z"
          fill="#2626BC"
        />
        <path
          d="M63.7907 59.1614C63.648 59.1614 63.5036 59.1253 63.3708 59.0495C62.959 58.8145 62.8137 58.2867 63.0463 57.8706L65.3729 53.7055C65.6053 53.2894 66.1275 53.1427 66.5389 53.3776C66.9508 53.6126 67.096 54.1404 66.8634 54.5565L64.5368 58.7215C64.3795 59.0031 64.0894 59.1614 63.7907 59.1614Z"
          fill="#01ECA5"
        />
        <path
          d="M26.4328 39.437H7.90345C7.19309 39.437 6.61731 38.8548 6.61731 38.1369V25.9446C6.61731 25.2265 7.19309 24.6445 7.90345 24.6445H26.4328C27.1431 24.6445 27.7189 25.2267 27.7189 25.9446V38.1369C27.7189 38.855 27.1431 39.437 26.4328 39.437Z"
          fill="#FF7EB8"
        />
        <path
          d="M26.4329 40.2963H7.90345C6.72043 40.2963 5.75793 39.3276 5.75793 38.1369V25.9446C5.75793 24.7538 6.72043 23.7852 7.90345 23.7852H26.4329C27.616 23.7852 28.5785 24.7538 28.5785 25.9446V38.1369C28.5783 39.3276 27.616 40.2963 26.4329 40.2963ZM7.90345 25.5039C7.66815 25.5039 7.47668 25.7016 7.47668 25.9446V38.1369C7.47668 38.3799 7.66815 38.5776 7.90345 38.5776H26.4329C26.6682 38.5776 26.8597 38.3799 26.8597 38.1369V25.9446C26.8597 25.7016 26.6682 25.5039 26.4329 25.5039H7.90345Z"
          fill="#2626BC"
        />
        <path
          d="M13.1701 34.9859C12.9513 34.9859 12.7323 34.9015 12.5656 34.7327L10.5075 32.6523C10.1736 32.3147 10.1736 31.7675 10.5075 31.4301L12.5656 29.3497C12.8992 29.0123 13.4409 29.0123 13.7745 29.3497C14.1085 29.6873 14.1085 30.2345 13.7745 30.5719L12.3212 32.0411L13.7745 33.5103C14.1085 33.8479 14.1085 34.3951 13.7745 34.7325C13.6078 34.9015 13.3889 34.9859 13.1701 34.9859Z"
          fill="white"
        />
        <path
          d="M21.1662 29.0967C21.385 29.0967 21.604 29.1811 21.7707 29.3499L23.8287 31.4302C24.1627 31.7678 24.1627 32.315 23.8287 32.6524L21.7707 34.7328C21.4371 35.0702 20.8954 35.0702 20.5617 34.7328C20.2278 34.3952 20.2278 33.848 20.5617 33.5106L22.0151 32.0414L20.5617 30.5722C20.2278 30.2347 20.2278 29.6874 20.5617 29.35C20.7286 29.1811 20.9476 29.0967 21.1662 29.0967Z"
          fill="white"
        />
        <path
          d="M16.0054 34.9861C15.8629 34.9861 15.7187 34.95 15.5858 34.8742C15.1745 34.6394 15.0295 34.1122 15.2617 33.6967L17.5858 29.5361C17.8178 29.1205 18.3396 28.9739 18.7506 29.2085C19.1619 29.4433 19.3069 29.9704 19.0747 30.386L16.7506 34.5466C16.5935 34.8278 16.3037 34.9861 16.0054 34.9861Z"
          fill="white"
        />
        <path
          d="M26.4328 71.4538H7.90345C7.19309 71.4538 6.61731 70.8717 6.61731 70.1537V47.5862C6.61731 46.8681 7.19309 46.2861 7.90345 46.2861H26.4328C27.1431 46.2861 27.7189 46.8683 27.7189 47.5862V70.1537C27.7189 70.8717 27.1431 71.4538 26.4328 71.4538Z"
          fill="#01ECA5"
        />
        <path
          d="M26.4329 72.3132H7.90345C6.72043 72.3132 5.75793 71.3445 5.75793 70.1537V47.5862C5.75793 46.3954 6.72043 45.4268 7.90345 45.4268H17.6245C18.0991 45.4268 18.4839 45.8116 18.4839 46.2861C18.4839 46.7607 18.0991 47.1455 17.6245 47.1455H7.90345C7.66815 47.1455 7.47668 47.3432 7.47668 47.5862V70.1537C7.47668 70.3968 7.66815 70.5944 7.90345 70.5944H26.4329C26.6682 70.5944 26.8597 70.3968 26.8597 70.1537V47.5862C26.8597 47.3432 26.6682 47.1455 26.4329 47.1455H22.3358C21.8612 47.1455 21.4764 46.7607 21.4764 46.2861C21.4764 45.8116 21.8612 45.4268 22.3358 45.4268H26.4329C27.616 45.4268 28.5785 46.3954 28.5785 47.5862V70.1537C28.5783 71.3445 27.616 72.3132 26.4329 72.3132Z"
          fill="#2626BC"
        />
        <path
          d="M77.4278 41.461H56.521C55.5688 41.461 54.7969 40.6891 54.7969 39.7369V22.8969C54.7969 21.9447 55.5688 21.1729 56.521 21.1729H77.4278C78.38 21.1729 79.1519 21.9447 79.1519 22.8969V39.7369C79.1519 40.6891 78.38 41.461 77.4278 41.461Z"
          fill="white"
        />
        <path
          d="M77.437 21.1729H71.5933V41.461H77.437C78.384 41.461 79.1518 40.685 79.1518 39.7276V22.9062C79.1518 21.9489 78.384 21.1729 77.437 21.1729Z"
          fill="#DCFDEE"
        />
        <path
          d="M79.1519 26.8353V22.9062C79.1519 21.9489 78.3841 21.1729 77.4371 21.1729H56.5117C55.5646 21.1729 54.7969 21.9489 54.7969 22.9062V26.8353H79.1519Z"
          fill="#01ECA5"
        />
        <path
          d="M77.4371 42.3204H56.5117C55.0923 42.3204 53.9375 41.1573 53.9375 39.7276V22.9062C53.9375 21.4766 55.0923 20.3135 56.5117 20.3135H77.4371C78.8564 20.3135 80.0113 21.4766 80.0113 22.9062V26.8351C80.0113 27.3096 79.6264 27.6945 79.1519 27.6945C78.6774 27.6945 78.2925 27.3096 78.2925 26.8351V22.9062C78.2925 22.4243 77.9087 22.0322 77.4371 22.0322H56.5117C56.0401 22.0322 55.6562 22.4243 55.6562 22.9062V39.7276C55.6562 40.2096 56.0401 40.6016 56.5117 40.6016H77.4371C77.9087 40.6016 78.2925 40.2096 78.2925 39.7276V31.5976C78.2925 31.123 78.6774 30.7382 79.1519 30.7382C79.6264 30.7382 80.0113 31.123 80.0113 31.5976V39.7276C80.0113 41.1573 78.8564 42.3204 77.4371 42.3204Z"
          fill="#2626BC"
        />
        <path
          d="M79.1519 27.6943H54.7969C54.3223 27.6943 53.9375 27.3095 53.9375 26.835C53.9375 26.3604 54.3223 25.9756 54.7969 25.9756H79.1519C79.6264 25.9756 80.0113 26.3604 80.0113 26.835C80.0113 27.3095 79.6264 27.6943 79.1519 27.6943Z"
          fill="#2626BC"
        />
        <path
          d="M68.1476 37.4135H59.6429C59.1694 37.4135 58.7858 37.0256 58.7858 36.5471C58.7858 36.0686 59.1696 35.6807 59.6429 35.6807H68.1476C68.6211 35.6807 69.0048 36.0686 69.0048 36.5471C69.0048 37.0256 68.621 37.4135 68.1476 37.4135Z"
          fill="#B7C5FF"
        />
        <path
          d="M63.5178 33.8158H59.6429C59.1694 33.8158 58.7858 33.4279 58.7858 32.9494C58.7858 32.4709 59.1696 32.083 59.6429 32.083H63.5178C63.9913 32.083 64.375 32.4709 64.375 32.9494C64.375 33.4279 63.9912 33.8158 63.5178 33.8158Z"
          fill="#B7C5FF"
        />
        <path
          d="M22.1792 59.2679H12.157C11.6839 59.2679 11.3004 58.8802 11.3004 58.402C11.3004 57.9239 11.6839 57.5361 12.157 57.5361H22.1792C22.6524 57.5361 23.0359 57.9239 23.0359 58.402C23.0359 58.8802 22.6524 59.2679 22.1792 59.2679Z"
          fill="white"
        />
        <path
          d="M20.1263 54.6361H14.2099C13.7367 54.6361 13.3533 54.2484 13.3533 53.7702C13.3533 53.2921 13.7367 52.9043 14.2099 52.9043H20.1263C20.5995 52.9043 20.983 53.2921 20.983 53.7702C20.983 54.2484 20.5995 54.6361 20.1263 54.6361Z"
          fill="#97FFD2"
        />
        <path
          d="M22.1792 62.3431H12.157C11.6839 62.3431 11.3004 61.9554 11.3004 61.4772C11.3004 60.9991 11.6839 60.6113 12.157 60.6113H22.1792C22.6524 60.6113 23.0359 60.9991 23.0359 61.4772C23.0359 61.9556 22.6524 62.3431 22.1792 62.3431Z"
          fill="white"
        />
        <path
          d="M22.1792 65.4183H12.157C11.6839 65.4183 11.3004 65.0306 11.3004 64.5524C11.3004 64.0743 11.6839 63.6865 12.157 63.6865H22.1792C22.6524 63.6865 23.0359 64.0743 23.0359 64.5524C23.0359 65.0306 22.6524 65.4183 22.1792 65.4183Z"
          fill="white"
        />
        <path
          d="M79.4972 66.452C79.2677 66.452 79.0477 66.3602 78.8862 66.1971L77.0004 64.2909C76.6692 63.956 76.6692 63.4169 77.0004 63.0821L78.8862 61.1758C79.0476 61.0127 79.2676 60.9209 79.4972 60.9209C79.7268 60.9209 79.9467 61.0127 80.1082 61.1758L81.994 63.0821C82.3252 63.4169 82.3252 63.956 81.994 64.2909L80.1082 66.1971C79.9467 66.3601 79.7266 66.452 79.4972 66.452ZM78.8202 63.6864L79.497 64.3706L80.174 63.6864L79.497 63.0021L78.8202 63.6864Z"
          fill="#6583FE"
        />
        <path
          d="M70.8412 71.2997C70.6117 71.2997 70.3917 71.2079 70.2302 71.0448L68.3444 69.1385C68.0131 68.8037 68.0131 68.2645 68.3444 67.9297L70.2302 66.0234C70.3916 65.8603 70.6116 65.7686 70.8412 65.7686C71.0706 65.7686 71.2906 65.8603 71.4522 66.0234L73.338 67.9297C73.6692 68.2645 73.6692 68.8037 73.338 69.1385L71.4522 71.0448C71.2906 71.2079 71.0706 71.2997 70.8412 71.2997ZM70.1642 68.534L70.8412 69.2183L71.518 68.534L70.8412 67.8498L70.1642 68.534Z"
          fill="#FF7EB8"
        />
        <path
          d="M15.3484 21.0777C14.8739 21.0777 14.489 20.6929 14.489 20.2183C14.489 19.6432 14.0305 19.1756 13.4666 19.1756C12.992 19.1756 12.6072 18.7908 12.6072 18.3162C12.6072 17.8417 12.992 17.4568 13.4666 17.4568C14.0303 17.4568 14.489 16.989 14.489 16.4141C14.489 15.9395 14.8739 15.5547 15.3484 15.5547C15.823 15.5547 16.2078 15.9395 16.2078 16.4141C16.2078 16.9892 16.6664 17.4568 17.2303 17.4568C17.7048 17.4568 18.0896 17.8417 18.0896 18.3162C18.0896 18.7908 17.7048 19.1756 17.2303 19.1756C16.6665 19.1756 16.2078 19.6434 16.2078 20.2183C16.2078 20.6929 15.823 21.0777 15.3484 21.0777Z"
          fill="#97FFD2"
        />
        <path
          d="M18.4915 12.2984C18.0169 12.2984 17.6321 11.9136 17.6321 11.439C17.6321 10.864 17.1735 10.3963 16.6096 10.3963C16.1351 10.3963 15.7502 10.0115 15.7502 9.53691C15.7502 9.06236 16.1351 8.67753 16.6096 8.67753C17.1734 8.67753 17.6321 8.20969 17.6321 7.63477C17.6321 7.16022 18.0169 6.77539 18.4915 6.77539C18.966 6.77539 19.3509 7.16022 19.3509 7.63477C19.3509 8.20986 19.8094 8.67753 20.3733 8.67753C20.8479 8.67753 21.2327 9.06236 21.2327 9.53691C21.2327 10.0115 20.8479 10.3963 20.3733 10.3963C19.8096 10.3963 19.3509 10.8641 19.3509 11.439C19.3509 11.9138 18.966 12.2984 18.4915 12.2984Z"
          fill="#6583FE"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6616"
          x1="6.26193"
          y1="25.331"
          x2="89.2714"
          y2="65.9051"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#D2DEFF" />
          <stop offset="0.9993" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6616">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationProfile;
