import { SVGProps } from "types";

const BoxPenTool = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5418)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M13.6231 13.6188L14.4263 12.3123C14.5096 12.1765 14.5238 12.01 14.4638 11.8622L12.4636 6.937C12.3871 6.74875 12.2033 6.625 12.0001 6.625C11.7968 6.625 11.6131 6.748 11.5366 6.937L9.53631 11.8622C9.47706 12.01 9.49056 12.1765 9.57381 12.3123L10.3771 13.6188C10.1483 13.7508 9.98181 13.9833 9.94581 14.26L9.50331 17.5765C9.48831 17.6837 9.52131 17.7917 9.59256 17.8735C9.66381 17.9552 9.76731 18.0002 9.87531 18.0002H14.1256C14.2336 18.0002 14.3363 17.9538 14.4076 17.872C14.4788 17.791 14.5118 17.6823 14.4968 17.575L14.0543 14.2592C14.0183 13.9832 13.8518 13.7508 13.6231 13.6188ZM11.4998 9.68575V11.0005C11.4998 11.2765 11.7233 11.5007 12.0001 11.5007C12.2768 11.5007 12.5003 11.2765 12.5003 11.0005V9.68575L13.4416 12.004L12.5221 13.5002H11.4773L10.5578 12.004L11.4998 9.68575Z"
          fill="var(--color-cyber-yellow)"
        />
        <path
          d="M17.625 6H16.3748C16.1678 6 15.9998 6.168 15.9998 6.375V6.50025H7.9995V6.375C7.9995 6.168 7.8315 6 7.6245 6H6.375C6.168 6 6 6.168 6 6.375V7.62525C6 7.83225 6.168 8.00025 6.375 8.00025H7.62525C7.83225 8.00025 8.00025 7.83225 8.00025 7.62525V7.5H8.93025C7.641 8.43375 6.79275 9.93525 6.756 11.6363C6.45525 11.8088 6.2505 12.1297 6.2505 12.5002C6.24975 13.0515 6.69825 13.5 7.2495 13.5C7.80075 13.5 8.25 13.0515 8.25 12.5002C8.25 12.1342 8.04975 11.8162 7.75575 11.6423C7.8135 9.34875 9.693 7.5 12 7.5C14.307 7.5 16.1865 9.34875 16.245 11.6423C15.9502 11.8162 15.75 12.1335 15.75 12.5002C15.75 13.0515 16.1992 13.5 16.7505 13.5C17.3018 13.5 17.7502 13.0515 17.7502 12.5002C17.7502 12.1297 17.5448 11.8088 17.2448 11.6363C17.208 9.93525 16.359 8.43375 15.0705 7.5H16.0005V7.62525C16.0005 7.83225 16.1685 8.00025 16.3755 8.00025H17.625C17.832 8.00025 18 7.83225 18 7.62525V6.375C18 6.168 17.832 6 17.625 6Z"
          fill="var(--color-shandy)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5418">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxPenTool;
