import { SVGProps } from "types";

const BoxProgramming = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5411)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M11.3535 12.3533L8.85374 14.853C8.75549 14.9513 8.62799 15 8.49974 15C8.37149 15 8.24399 14.9513 8.14649 14.8538C7.95149 14.6588 7.95149 14.3422 8.14649 14.1465L10.293 12L8.14649 9.8535C7.95149 9.6585 7.95149 9.342 8.14649 9.14625C8.34149 8.95125 8.65799 8.95125 8.85374 9.14625L11.3535 11.646C11.5485 11.8418 11.5485 12.1583 11.3535 12.3533Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M15.5002 14.9995H12.5002C12.2235 14.9995 12 14.776 12 14.4993C12 14.2225 12.2235 13.999 12.5002 13.999H15.5002C15.777 13.999 16.0005 14.2233 16.0005 14.4993C16.0005 14.7753 15.7762 14.9995 15.5002 14.9995Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M16.5 18H7.5C6.67275 18 6 17.3273 6 16.5V7.5C6 6.67275 6.67275 6 7.5 6H16.5C17.3273 6 18 6.67275 18 7.5V16.5C18 17.3273 17.3273 18 16.5 18ZM7.5 6.99975C7.224 6.99975 6.99975 7.224 6.99975 7.5V16.5C6.99975 16.776 7.224 17.0002 7.5 17.0002H16.5C16.776 17.0002 17.0002 16.776 17.0002 16.5V7.5C17.0002 7.224 16.776 6.99975 16.5 6.99975H7.5Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5411">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxProgramming;
