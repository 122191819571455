import { SVGProps } from "types";

const Check = (props: SVGProps) => {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5403 0.632737C12.9308 1.02326 12.9308 1.65643 12.5403 2.04695L5.20694 9.38028C4.81642 9.77081 4.18325 9.77081 3.79273 9.38028L0.459397 6.04695C0.0688728 5.65643 0.0688728 5.02326 0.459397 4.63274C0.849921 4.24221 1.48309 4.24221 1.87361 4.63274L4.49984 7.25896L11.1261 0.632737C11.5166 0.242213 12.1498 0.242213 12.5403 0.632737Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Check;
