import { SVGProps } from "types";

const BoxResponsiveWebpage = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5360)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M10.5 9.5H6.99975C6.44775 9.5 6 9.94775 6 10.4998V17C6 17.552 6.44775 17.9998 6.99975 17.9998H10.5C11.052 17.9998 11.4998 17.552 11.4998 17V10.4998C11.4998 9.94775 11.052 9.5 10.5 9.5ZM10.5 17H6.99975V10.7502C6.99975 10.6115 7.1115 10.4998 7.2495 10.4998H7.5C7.5 10.7758 7.7235 11 8.00025 11H9.50025C9.77625 11 10.0005 10.7765 10.0005 10.4998H10.2502C10.3882 10.4998 10.5 10.6115 10.5 10.7495V17Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M15.3413 11.134C15.1448 10.942 14.8283 10.945 14.634 11.143L13.9125 11.8825L13.6403 11.6102C13.533 11.503 13.3718 11.4707 13.2315 11.5292C13.0913 11.587 12.9998 11.7242 12.9998 11.8757V13.1252C12.9998 13.3322 13.1678 13.5002 13.3748 13.5002H14.625C14.7765 13.5002 14.9138 13.4087 14.9715 13.2685C15.0293 13.1282 14.9978 12.967 14.8898 12.8597L14.619 12.589L15.3495 11.8405C15.543 11.6432 15.5385 11.3267 15.3413 11.134Z"
          fill="var(--color-cyber-yellow)"
        />
        <path
          d="M16.5 6H7.5C6.675 6 6 6.675 6 7.5V8.76975C6.29475 8.5995 6.63525 8.49975 6.99975 8.49975H17.0002V15.4995C17.0002 15.7748 16.7753 15.9998 16.5 15.9998H12.4995V16.9995H16.5C17.325 16.9995 18 16.3245 18 15.4995V7.5C18 6.675 17.325 6 16.5 6Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5360">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxResponsiveWebpage;
