import { SVGProps } from "types";

const BoxKey = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5432)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M14.0002 9C13.3492 9 12.7995 9.41925 12.5925 9.99975H9.75C9.33675 9.99975 9 10.3365 9 10.7498V11.4998C9 11.7758 9.2235 12 9.50025 12C9.777 12 10.0005 11.7758 10.0005 11.4998V10.9995H12.5925C12.7995 11.5808 13.3492 12 14.0002 12C14.8275 12 15.5002 11.3273 15.5002 10.5C15.5002 9.67275 14.8275 9 14.0002 9ZM14.0002 11.0002C13.7242 11.0002 13.5 10.776 13.5 10.5C13.5 10.224 13.7242 9.99975 14.0002 9.99975C14.2762 9.99975 14.5005 10.224 14.5005 10.5C14.4997 10.776 14.2755 11.0002 14.0002 11.0002Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M17.1255 7H6.8745C6.39225 7 6 7.393 6 7.87525V14.6252C6 15.1075 6.39225 15.5005 6.87525 15.5005H10.7048C10.6462 15.751 10.4903 16.1575 10.074 16.5737C10.0027 16.645 9.981 16.753 10.02 16.846C10.0582 16.9398 10.1498 17.0005 10.251 17.0005H13.7512C13.8525 17.0005 13.944 16.9398 13.9822 16.846C14.0205 16.7523 13.9995 16.645 13.9282 16.5737C13.5127 16.1582 13.353 15.7517 13.2908 15.5005H17.1262C17.6085 15.5005 18.0015 15.1082 18.0015 14.6252V7.87525C18 7.393 17.6077 7 17.1255 7ZM17.0002 13.5002H6.99975V8.0005H17.0002V13.5002Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5432">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxKey;
