import { SVGProps } from "types";

const BoxTickMark = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5399)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M12.9998 12.9998H8.49976C8.22376 12.9998 7.99951 12.7755 7.99951 12.4995C7.99951 12.2235 8.22376 12 8.49976 12H12.9998C13.2765 12 13.5 12.2242 13.5 12.5002C13.5 12.7762 13.2765 12.9998 12.9998 12.9998Z"
          fill="var(--color-orange-soda)"
        />
        <path
          d="M11.0002 11.0005H8.50049C8.22449 11.0005 8.00024 10.7762 8.00024 10.5002C8.00024 10.2242 8.22449 10 8.50049 10H11.0002C11.2762 10 11.5005 10.2242 11.5005 10.5002C11.4997 10.7762 11.2762 11.0005 11.0002 11.0005Z"
          fill="var(--color-orange-soda)"
        />
        <path
          d="M15.2505 6C13.734 6 12.5002 7.23375 12.5002 8.75025C12.5002 10.2667 13.734 11.5005 15.2505 11.5005C16.767 11.5005 18 10.266 18 8.75025C18 7.2345 16.7662 6 15.2505 6ZM16.683 8.33625L15.3195 9.83625C15.228 9.93675 15.099 9.996 14.9632 9.99975C14.9587 9.99975 14.9542 9.99975 14.9497 9.99975C14.8185 9.99975 14.6925 9.948 14.5987 9.85575L13.8375 9.10575C13.641 8.91225 13.6387 8.595 13.8322 8.3985C14.025 8.20125 14.3415 8.199 14.5395 8.39325L14.9295 8.778L15.9435 7.66275C16.1302 7.45875 16.4467 7.44375 16.65 7.629C16.8532 7.81575 16.8682 8.13225 16.683 8.33625Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.0002 12.0598V14.4995H6.99975V8.99975H11.5147C11.505 8.915 11.4998 8.83475 11.4998 8.75C11.4998 8.495 11.5245 8.24 11.5747 8H6.8745C6.3945 8 6 8.3945 6 8.8745V15.6245C6 16.1045 6.3945 16.4998 6.8745 16.4998H10.704C10.6455 16.7502 10.4895 17.1567 10.0732 17.573C10.002 17.6442 9.98025 17.7523 10.0192 17.8453C10.0575 17.939 10.149 17.9998 10.2502 17.9998H13.7505C13.8517 17.9998 13.9433 17.939 13.9815 17.8453C14.0198 17.7515 13.9987 17.6442 13.9275 17.573C13.512 17.1575 13.3522 16.751 13.29 16.4998H17.1255C17.6055 16.4998 18 16.1045 18 15.6245V11.2895C17.7203 11.6045 17.3805 11.8648 17.0002 12.0598Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5399">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxTickMark;
