import { SVGProps } from "types";

const BoxMerging = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5422)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M17.1255 8H15C15 8.18975 14.9648 8.39 14.8853 8.57525C14.82 8.735 14.73 8.87525 14.6152 9.0005H17.0002V15.0005H6.99975V9.0005H9.38475C9.27 8.87525 9.18 8.73575 9.11475 8.57525C9.0345 8.39 9 8.18975 9 8H6.8745C6.3945 8 6 8.3945 6 8.8745V15.1243C6 15.6043 6.39525 15.9995 6.87525 15.9995H11.0002V16.9993H10.0005C9.7245 16.9993 9.50025 17.2235 9.50025 17.4995C9.50025 17.7755 9.72375 17.9998 9.99975 17.9998H14.0002C14.277 17.9998 14.5005 17.7755 14.5005 17.4995C14.5005 17.2235 14.277 16.9993 14.0002 16.9993H13.0005V15.9995H17.1255C17.6055 15.9995 18.0007 15.6043 18.0007 15.1243V8.8745C18 8.3945 17.6055 8 17.1255 8Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M13.4999 8.49975C13.7024 8.49975 13.8847 8.37825 13.9619 8.1915C14.0392 8.00475 13.9964 7.7895 13.8532 7.64625L12.3532 6.14625C12.1582 5.95125 11.8417 5.95125 11.646 6.14625L10.146 7.64625C10.0027 7.7895 9.95996 8.004 10.0372 8.1915C10.1145 8.37825 10.2967 8.49975 10.4992 8.49975H11.6242V10.5945L9.85871 12.36C9.71246 12.5062 9.71246 12.744 9.85871 12.8902C9.93221 12.9637 10.0275 12.9997 10.1242 12.9997C10.221 12.9997 10.3162 12.963 10.3897 12.8902L12.2647 11.0152C12.3352 10.9447 12.3742 10.8495 12.3742 10.7497V8.49975H13.4999Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M14.1404 12.3597L13.3904 11.6097C13.2442 11.4634 13.0064 11.4634 12.8602 11.6097C12.7139 11.7559 12.7139 11.9937 12.8602 12.1399L13.6102 12.8899C13.6837 12.9634 13.7789 12.9994 13.8757 12.9994C13.9724 12.9994 14.0677 12.9627 14.1412 12.8899C14.2867 12.7437 14.2867 12.5059 14.1404 12.3597Z"
          fill="var(--color-sky-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5422">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxMerging;
