import { SVGProps } from "types";

const Upload = (props: SVGProps) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0277 2.75618C10.6208 2.34926 9.95998 2.34926 9.55305 2.75618L5.38618 6.92305C4.97926 7.32997 4.97926 7.99081 5.38618 8.39773C5.79311 8.80465 6.45394 8.80465 6.86087 8.39773L9.25031 6.00829V12.8674C9.25031 13.4436 9.71582 13.9091 10.292 13.9091C10.8682 13.9091 11.3337 13.4436 11.3337 12.8674V6.00829L13.7232 8.39773C14.1301 8.80465 14.7909 8.80465 15.1979 8.39773C15.6048 7.99081 15.6048 7.32997 15.1979 6.92305L11.031 2.75618H11.0277ZM5.08344 13.9091C5.08344 13.3329 4.61792 12.8674 4.04172 12.8674C3.46552 12.8674 3 13.3329 3 13.9091V15.9925C3 17.7178 4.39981 19.1177 6.12515 19.1177H14.4589C16.1842 19.1177 17.584 17.7178 17.584 15.9925V13.9091C17.584 13.3329 17.1185 12.8674 16.5423 12.8674C15.9661 12.8674 15.5006 13.3329 15.5006 13.9091V15.9925C15.5006 16.5687 15.0351 17.0342 14.4589 17.0342H6.12515C5.54895 17.0342 5.08344 16.5687 5.08344 15.9925V13.9091Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Upload;
