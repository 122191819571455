import { SVGProps } from "types";

const File = (props: SVGProps) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.8335 3.74996C3.8335 2.60087 4.76774 1.66663 5.91683 1.66663H11.1252V5.83329C11.1252 6.40946 11.5907 6.87496 12.1668 6.87496H16.3335V16.25C16.3335 17.399 15.3993 18.3333 14.2502 18.3333H5.91683C4.76774 18.3333 3.8335 17.399 3.8335 16.25V3.74996ZM16.3335 5.83329H12.1668V1.66663L16.3335 5.83329Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default File;
