import classNames from "classnames";
import { ReactNode } from "react";
import Icon from "components/Icon";
import { ClassNameArgument } from "types";
import styles from "./Socials.module.css";

export type SocialsProps = {
  children?: ReactNode;
  className?: ClassNameArgument;
  tabIndex?: number;
};

const Socials = ({ className, children, tabIndex }: SocialsProps) => {
  return (
    <ul className={classNames(styles["social-link-list"], className)}>
      <li>
        <a
          href="https://www.facebook.com/uniit.online/"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="Facebook"
          tabIndex={tabIndex}
        >
          <Icon name="facebook" />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/uniit/"
          rel="noopener noreferrer"
          target="_blank"
          aria-label="LinkedIn"
          tabIndex={tabIndex}
        >
          <Icon name="linked-in" />
        </a>
      </li>
      {children}
    </ul>
  );
};

export default Socials;
