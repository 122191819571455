import { SVGProps } from "types";

const Home = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.9939 11.8719C21.9939 12.4969 21.473 12.9865 20.8827 12.9865H19.7716L19.7959 18.5458C19.7959 18.643 19.7889 18.7333 19.7785 18.827V19.3896C19.7785 20.157 19.157 20.7785 18.3896 20.7785H17.834C17.7958 20.7785 17.7576 20.7473 17.7194 20.775C17.6708 20.7473 17.6222 20.7785 17.5736 20.7785H15.6117C14.8443 20.7785 14.2227 20.157 14.2227 19.3896V16.3339C14.2227 15.7193 13.7262 15.2227 13.1116 15.2227H10.8893C10.2746 15.2227 9.7781 15.7193 9.7781 16.3339V19.3896C9.7781 20.157 9.15655 20.7785 8.38915 20.7785H6.4481C6.39602 20.7785 6.34393 20.775 6.29184 20.7716C6.25018 20.775 6.20851 20.7785 6.16684 20.7785H5.61126C4.84422 20.7785 4.22231 20.157 4.22231 19.3896V15.5005C4.22231 15.4693 4.22336 15.4345 4.22544 15.4033V12.9865H3.11289C2.48683 12.9865 2 12.4969 2 11.8719C2 11.5594 2.10431 11.2816 2.34758 11.0385L11.2504 3.27834C11.4935 3.03479 11.7712 3 12.0143 3C12.2574 3 12.5352 3.06959 12.747 3.24355L21.6119 11.0385C21.8897 11.2816 22.0321 11.5594 21.9939 11.8719Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Home;
