import { SVGProps } from "types";

const IllustrationCoding = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70.1642 26.0841C66.0913 24.4966 62.6503 21.6 60.5459 17.7686C57.007 11.3255 50.1595 6.95801 42.2892 6.95801C34.1199 6.95801 27.0531 11.6634 23.6447 18.5113C21.5571 22.7052 18.0048 25.9981 13.6605 27.7514C7.24127 30.3425 1.8523 36.2634 0.142148 45.5204C-0.0455393 46.5362 -0.0482893 47.5805 0.139398 48.5963C2.79452 62.9602 14.3017 69.296 24.7749 67.616C30.6403 66.6751 36.5779 68.6316 40.9255 72.6797C49.7657 80.9112 63.9368 83.4919 77.6935 74.6683C79.3052 73.6347 81.4674 71.4656 82.5002 69.8532C94.3537 51.3475 85.5729 32.0897 70.1642 26.0841Z"
        fill="url(#paint0_linear_2042_6633)"
      />
      <path
        opacity="0.1"
        d="M67.9084 5.41406H30.1263C28.7044 5.41406 27.5503 6.5682 27.5503 7.99012V13.8476H20.348C18.9261 13.8476 17.772 15.0018 17.772 16.4237V75.544C17.772 76.966 18.9261 78.1201 20.348 78.1201H58.1301C59.552 78.1201 60.7062 76.966 60.7062 75.544V69.6865H67.9084C69.3304 69.6865 70.4845 68.5324 70.4845 67.1105V7.99012C70.4845 6.5682 69.3305 5.41406 67.9084 5.41406Z"
        fill="#2626BC"
      />
      <path
        d="M60.7064 20.0305V79.1503C60.7064 80.5735 59.5513 81.7268 58.1299 81.7268H20.3484C18.927 81.7268 17.772 80.5735 17.772 79.1503V20.0305C17.772 18.6091 18.927 17.4541 20.3484 17.4541H58.1299C59.5513 17.4541 60.7064 18.6091 60.7064 20.0305Z"
        fill="#D2DEFF"
      />
      <path
        d="M32.8695 65.0191V77.4752C32.8695 78.1885 32.2903 78.766 31.577 78.766H17.772V63.7266H31.577C32.2903 63.7266 32.8695 64.3058 32.8695 65.0191Z"
        fill="#9FB0FE"
      />
      <path
        d="M56.5037 51.7491V30.4538C56.5037 30.1691 56.2731 29.9385 55.9884 29.9385H22.4896C22.205 29.9385 21.9744 30.1691 21.9744 30.4538V51.7491C21.9744 52.0337 22.205 52.2644 22.4896 52.2644H55.9886C56.2731 52.2644 56.5037 52.0337 56.5037 51.7491Z"
        fill="#9FB0FE"
      />
      <path
        d="M60.7062 43.9326V76.1453H30.1268C28.7049 76.1453 27.5508 74.9911 27.5508 73.5692V43.9326H60.7062Z"
        fill="#9FB0FE"
      />
      <path
        d="M60.7067 25.7268V20.0302C60.7067 18.6074 59.5534 17.4541 58.1306 17.4541H20.3485C18.9257 17.4541 17.7725 18.6074 17.7725 20.0302V25.727L60.7067 25.7268Z"
        fill="#4369FD"
      />
      <path
        d="M70.4845 11.5966V70.7169C70.4845 72.1389 69.3303 73.293 67.9084 73.293H30.1264C28.7044 73.293 27.5503 72.1389 27.5503 70.7169V11.5966C27.5503 10.1746 28.7044 9.02051 30.1264 9.02051H67.9084C69.3305 9.02068 70.4845 10.1746 70.4845 11.5966Z"
        fill="#9FB0FE"
      />
      <path
        d="M27.5503 66.6455V70.7174C27.5503 72.1393 28.7044 73.2935 30.1264 73.2935H67.9084C69.3303 73.2935 70.4845 72.1395 70.4845 70.7174V66.6455H27.5503Z"
        fill="#B7C5FF"
      />
      <path
        d="M36.9242 9.02051H30.1264C28.7044 9.02051 27.5503 10.1745 27.5503 11.5966V70.7169C27.5503 72.1389 28.7044 73.293 30.1264 73.293H36.9242V9.02051Z"
        fill="white"
      />
      <path
        d="M70.4845 17.2934V11.5966C70.4845 10.1738 69.3312 9.02051 67.9084 9.02051H30.1264C28.7036 9.02051 27.5503 10.1738 27.5503 11.5966V17.2934H70.4845Z"
        fill="#FF7EB8"
      />
      <path
        d="M11.6681 23.7404C11.1935 23.7404 10.8087 23.3556 10.8087 22.881C10.8087 22.3116 10.3453 21.8482 9.77588 21.8482C9.30133 21.8482 8.9165 21.4634 8.9165 20.9889C8.9165 20.5143 9.30133 20.1295 9.77588 20.1295C10.3453 20.1295 10.8087 19.6663 10.8087 19.0967C10.8087 18.6221 11.1935 18.2373 11.6681 18.2373C12.1426 18.2373 12.5274 18.6221 12.5274 19.0967C12.5274 19.6661 12.9906 20.1295 13.5602 20.1295C14.0348 20.1295 14.4196 20.5143 14.4196 20.9889C14.4196 21.4634 14.0348 21.8482 13.5602 21.8482C12.9908 21.8482 12.5274 22.3116 12.5274 22.881C12.5274 23.3557 12.1426 23.7404 11.6681 23.7404Z"
        fill="#6583FE"
      />
      <path
        d="M68.6045 82.4204C68.3845 82.4204 68.1646 82.3365 67.9967 82.1688L66.1033 80.2754C65.7676 79.9397 65.7676 79.3955 66.1033 79.06L67.9967 77.1667C68.3324 76.8312 68.8764 76.8312 69.212 77.1667L71.1054 79.06C71.4411 79.3957 71.4411 79.9399 71.1054 80.2754L69.212 82.1688C69.0443 82.3363 68.8245 82.4204 68.6045 82.4204ZM67.9266 79.6676L68.6045 80.3455L69.2823 79.6676L68.6045 78.9897L67.9266 79.6676Z"
        fill="#FF7EB8"
      />
      <path
        d="M19.6519 11.7526C19.1774 11.7526 18.7925 11.3677 18.7925 10.8932C18.7925 10.3231 18.3287 9.8592 17.7585 9.8592C17.284 9.8592 16.8992 9.47437 16.8992 8.99982C16.8992 8.52527 17.284 8.14045 17.7585 8.14045C18.3287 8.14045 18.7925 7.67655 18.7925 7.10645C18.7925 6.6319 19.1774 6.24707 19.6519 6.24707C20.1265 6.24707 20.5113 6.6319 20.5113 7.10645C20.5113 7.67655 20.9752 8.14045 21.5453 8.14045C22.0198 8.14045 22.4047 8.52527 22.4047 8.99982C22.4047 9.47437 22.0198 9.8592 21.5453 9.8592C20.9752 9.8592 20.5113 10.3231 20.5113 10.8932C20.5113 11.3677 20.1265 11.7526 19.6519 11.7526Z"
        fill="#97FFD2"
      />
      <path
        d="M76.3306 23.7282C75.8561 23.7282 75.4713 23.3433 75.4713 22.8688C75.4713 22.2987 75.0074 21.8348 74.4373 21.8348C73.9627 21.8348 73.5779 21.45 73.5779 20.9754C73.5779 20.5009 73.9627 20.116 74.4373 20.116C75.0074 20.116 75.4713 19.6521 75.4713 19.082C75.4713 18.6075 75.8561 18.2227 76.3306 18.2227C76.8052 18.2227 77.19 18.6075 77.19 19.082C77.19 19.6521 77.6539 20.116 78.224 20.116C78.6986 20.116 79.0834 20.5009 79.0834 20.9754C79.0834 21.45 78.6986 21.8348 78.224 21.8348C77.6539 21.8348 77.19 22.2987 77.19 22.8688C77.19 23.3433 76.8054 23.7282 76.3306 23.7282Z"
        fill="#6583FE"
      />
      <path
        d="M49.8687 36.6357H47.165C46.7155 36.6357 46.3142 36.9644 46.2714 37.4118C46.2224 37.925 46.6246 38.3569 47.128 38.3569H49.8687C50.344 38.3569 50.7293 37.9716 50.7293 37.4963C50.7293 37.0211 50.3441 36.6357 49.8687 36.6357Z"
        fill="#D2DEFF"
      />
      <path
        d="M60.2543 36.6357H53.8244C53.3492 36.6357 52.9639 37.0211 52.9639 37.4963C52.9639 37.9716 53.3492 38.3569 53.8244 38.3569H60.2912C60.7945 38.3569 61.1969 37.925 61.1479 37.4118C61.1053 36.9644 60.7037 36.6357 60.2543 36.6357Z"
        fill="#97FFD2"
      />
      <path
        d="M56.0562 40.2393H47.165C46.7155 40.2393 46.3142 40.5679 46.2714 41.0153C46.2224 41.5285 46.6246 41.9606 47.128 41.9606H56.0562C56.5315 41.9606 56.9168 41.5752 56.9168 41.1C56.9168 40.6244 56.5316 40.2393 56.0562 40.2393Z"
        fill="#D2DEFF"
      />
      <path
        d="M65.4105 40.2393H60.0119C59.5367 40.2393 59.1514 40.6246 59.1514 41.0998C59.1514 41.5751 59.5367 41.9604 60.0119 41.9604H65.4475C65.9507 41.9604 66.3531 41.5285 66.3041 41.0151C66.2615 40.5677 65.86 40.2393 65.4105 40.2393Z"
        fill="#97FFD2"
      />
      <path
        d="M53.4781 43.8428H47.165C46.7155 43.8428 46.3142 44.1714 46.2714 44.6188C46.2224 45.132 46.6246 45.5641 47.128 45.5641H53.4781C53.9533 45.5641 54.3387 45.1788 54.3387 44.7035C54.3387 44.2279 53.9535 43.8428 53.4781 43.8428Z"
        fill="#D2DEFF"
      />
      <path
        d="M60.2543 43.8428H57.4338C56.9586 43.8428 56.5732 44.2281 56.5732 44.7034C56.5732 45.1786 56.9586 45.5639 57.4338 45.5639H60.2912C60.7945 45.5639 61.1969 45.132 61.1479 44.6186C61.1053 44.1712 60.7037 43.8428 60.2543 43.8428Z"
        fill="#97FFD2"
      />
      <path
        d="M65.4105 47.4463H54.8557C54.3805 47.4463 53.9951 47.8316 53.9951 48.3069C53.9951 48.7821 54.3805 49.1674 54.8557 49.1674H65.4475C65.9507 49.1674 66.3531 48.7355 66.3041 48.2223C66.2613 47.7749 65.86 47.4463 65.4105 47.4463Z"
        fill="#97FFD2"
      />
      <path
        d="M50.9 47.4463H47.165C46.7155 47.4463 46.3142 47.7749 46.2714 48.2223C46.2224 48.7355 46.6248 49.1674 47.128 49.1674H50.9C51.3752 49.1674 51.7606 48.7821 51.7606 48.3069C51.7606 47.8316 51.3754 47.4463 50.9 47.4463Z"
        fill="#D2DEFF"
      />
      <path
        d="M44.8846 30.6465H42.009C41.5595 30.6465 41.1582 30.9751 41.1154 31.4225C41.0664 31.9357 41.4686 32.3676 41.972 32.3676H44.8846C45.3598 32.3676 45.7452 31.9823 45.7452 31.5071C45.7452 31.0318 45.36 30.6465 44.8846 30.6465Z"
        fill="white"
      />
      <path
        d="M60.2544 30.6465H49.0693C48.5941 30.6465 48.2087 31.0318 48.2087 31.5071C48.2087 31.9823 48.5941 32.3676 49.0693 32.3676H60.2914C60.7946 32.3676 61.197 31.9357 61.148 31.4225C61.1052 30.9751 60.7039 30.6465 60.2544 30.6465Z"
        fill="white"
      />
      <path
        d="M44.8846 53.9463H42.009C41.5595 53.9463 41.1582 54.2749 41.1154 54.7223C41.0664 55.2355 41.4686 55.6674 41.972 55.6674H44.8846C45.3598 55.6674 45.7452 55.2821 45.7452 54.8069C45.7452 54.3315 45.36 53.9463 44.8846 53.9463Z"
        fill="white"
      />
      <path
        d="M60.2544 53.9463H49.0693C48.5941 53.9463 48.2087 54.3316 48.2087 54.8069C48.2087 55.2821 48.5941 55.6674 49.0693 55.6674H60.2914C60.7946 55.6674 61.197 55.2355 61.148 54.7223C61.1052 54.2747 60.7039 53.9463 60.2544 53.9463Z"
        fill="white"
      />
      <path
        d="M31.5769 76.4976H12.9601C12.2465 76.4976 11.668 75.9191 11.668 75.2055V63.0871C11.668 62.3735 12.2465 61.7949 12.9601 61.7949H31.5769C32.2906 61.7949 32.8691 62.3735 32.8691 63.0871V75.2055C32.8693 75.9191 32.2907 76.4976 31.5769 76.4976Z"
        fill="#FF7EB8"
      />
      <path
        d="M23.4531 21.5771V21.6021C23.4531 22.0766 23.838 22.4614 24.3125 22.4614C24.787 22.4614 25.1719 22.0766 25.1719 21.6021V21.5771C25.1719 21.1026 24.787 20.7178 24.3125 20.7178C23.838 20.7178 23.4531 21.1026 23.4531 21.5771Z"
        fill="#2626BC"
      />
      <path
        d="M34.0903 12.2852C33.6158 12.2852 33.231 12.67 33.231 13.1445V13.1695C33.231 13.644 33.6158 14.0288 34.0903 14.0288C34.5649 14.0288 34.9497 13.644 34.9497 13.1695V13.1445C34.9497 12.67 34.5649 12.2852 34.0903 12.2852Z"
        fill="#2626BC"
      />
      <path
        d="M37.4089 12.2852C36.9344 12.2852 36.5496 12.67 36.5496 13.1445V13.1695C36.5496 13.644 36.9344 14.0288 37.4089 14.0288C37.8835 14.0288 38.2683 13.644 38.2683 13.1695V13.1445C38.2683 12.67 37.8835 12.2852 37.4089 12.2852Z"
        fill="#2626BC"
      />
      <path
        d="M40.7278 12.2852C40.2532 12.2852 39.8684 12.67 39.8684 13.1445V13.1695C39.8684 13.644 40.2532 14.0288 40.7278 14.0288C41.2023 14.0288 41.5872 13.644 41.5872 13.1695V13.1445C41.5872 12.67 41.2025 12.2852 40.7278 12.2852Z"
        fill="#2626BC"
      />
      <path
        d="M67.9082 8.16113H30.1263C28.2321 8.16113 26.6909 9.70216 26.6909 11.5966V16.5944H20.3485C18.4543 16.5944 16.9131 18.1354 16.9131 20.0298V60.9359H12.9601C11.7738 60.9359 10.8086 61.9011 10.8086 63.0874V75.2058C10.8086 76.3923 11.7738 77.3575 12.9601 77.3575H16.9129V79.1496C16.9129 81.0439 18.4539 82.5851 20.3483 82.5851H58.1304C60.0247 82.5851 61.5659 81.0441 61.5659 79.1496V73.2923C61.5659 72.8178 61.181 72.4329 60.7065 72.4329H60.7063H60.7062H33.7286V63.0872C33.7286 61.901 32.7634 60.9357 31.5771 60.9357H28.4096V25.7266C28.4096 25.252 28.0248 24.8672 27.5503 24.8672H18.6318V20.0298C18.6318 19.0833 19.4018 18.3131 20.3485 18.3131H26.6909V20.9957C26.6909 21.4703 27.0757 21.8551 27.5503 21.8551C28.0248 21.8551 28.4096 21.4703 28.4096 20.9957V18.1526H69.6251V70.7162C69.6251 71.6628 68.8551 72.4329 67.9084 72.4329H65.4368C64.9623 72.4329 64.5775 72.8178 64.5775 73.2923C64.5775 73.7669 64.9623 74.1517 65.4368 74.1517H67.9084C69.8026 74.1517 71.3438 72.6107 71.3438 70.7162V11.5966C71.3437 9.70216 69.8026 8.16113 67.9082 8.16113ZM59.8471 74.1517V79.1496C59.8471 80.0962 59.0769 80.8663 58.1304 80.8663H20.3483C19.4018 80.8663 18.6317 80.0963 18.6317 79.1496V77.3575H31.5769C32.7632 77.3575 33.7285 76.3923 33.7285 75.2058V74.1517H59.8471ZM32.0099 63.0874V75.2058C32.0099 75.4445 31.8158 75.6387 31.5771 75.6387H12.9601C12.7214 75.6387 12.5273 75.4445 12.5273 75.2058V63.0874C12.5273 62.8487 12.7214 62.6546 12.9601 62.6546H31.5769C31.8157 62.6546 32.0099 62.8487 32.0099 63.0874ZM26.6907 26.586V60.9359H18.6317V26.586H26.6907ZM28.4095 16.4338V11.5966C28.4095 10.6501 29.1795 9.87988 30.1261 9.87988H67.9081C68.8547 9.87988 69.6247 10.6499 69.6247 11.5966V16.434L28.4095 16.4338Z"
        fill="#2626BC"
      />
      <path
        d="M33.6072 31.4814C33.6072 31.0069 33.2223 30.6221 32.7478 30.6221C32.2733 30.6221 31.8884 31.0069 31.8884 31.4814V31.5065C31.8884 31.9811 32.2733 32.3659 32.7478 32.3659C33.2223 32.3659 33.6072 31.9811 33.6072 31.5065V31.4814Z"
        fill="#2626BC"
      />
      <path
        d="M33.6072 39.2734C33.6072 38.7989 33.2223 38.4141 32.7478 38.4141C32.2733 38.4141 31.8884 38.7989 31.8884 39.2734V39.2984C31.8884 39.7729 32.2733 40.1577 32.7478 40.1577C33.2223 40.1577 33.6072 39.7729 33.6072 39.2984V39.2734Z"
        fill="#2626BC"
      />
      <path
        d="M33.6072 47.0654C33.6072 46.5909 33.2223 46.2061 32.7478 46.2061C32.2733 46.2061 31.8884 46.5909 31.8884 47.0654V47.0903C31.8884 47.5649 32.2733 47.9497 32.7478 47.9497C33.2223 47.9497 33.6072 47.5649 33.6072 47.0903V47.0654Z"
        fill="#2626BC"
      />
      <path
        d="M32.7478 53.9971C32.2733 53.9971 31.8884 54.3819 31.8884 54.8564V54.8815C31.8884 55.3561 32.2733 55.7409 32.7478 55.7409C33.2223 55.7409 33.6072 55.3561 33.6072 54.8815V54.8564C33.6072 54.3819 33.2223 53.9971 32.7478 53.9971Z"
        fill="#2626BC"
      />
      <path
        d="M18.2516 72.074C18.0318 72.074 17.8118 71.9901 17.6442 71.8223L15.5766 69.7547C15.2411 69.4192 15.2411 68.8752 15.5766 68.5399L17.6442 66.4722C17.9794 66.1369 18.5237 66.1369 18.8589 66.4722C19.1944 66.8077 19.1944 67.3517 18.8589 67.687L17.3986 69.1473L18.8589 70.6075C19.1944 70.943 19.1944 71.487 18.8589 71.8223C18.6915 71.9901 18.4715 72.074 18.2516 72.074Z"
        fill="white"
      />
      <path
        d="M26.2856 66.2197C26.5054 66.2197 26.7254 66.3036 26.893 66.4714L28.9607 68.539C29.2962 68.8745 29.2962 69.4185 28.9607 69.7538L26.893 71.8215C26.5579 72.1568 26.0135 72.1568 25.6784 71.8215C25.3429 71.486 25.3429 70.942 25.6784 70.6067L27.1386 69.1464L25.6784 67.6862C25.3429 67.3507 25.3429 66.8067 25.6784 66.4714C25.8458 66.3036 26.0658 66.2197 26.2856 66.2197Z"
        fill="white"
      />
      <path
        d="M21.1003 72.0737C20.9571 72.0737 20.8122 72.0379 20.6789 71.9625C20.2657 71.7293 20.1198 71.2052 20.3532 70.792L22.6883 66.6565C22.9215 66.2435 23.4455 66.0976 23.8586 66.3308C24.2717 66.5641 24.4177 67.0881 24.1843 67.5013L21.8492 71.6368C21.6912 71.9164 21.4002 72.0737 21.1003 72.0737Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2042_6633"
          x1="8.05424"
          y1="26.737"
          x2="89.865"
          y2="63.4253"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IllustrationCoding;
