import { SVGProps } from "types";

const BoxOrganizationStructure = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5380)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M15.1252 6H8.87475C8.1165 6 7.5 6.6165 7.5 7.37475V16.6245C7.5 17.3835 8.1165 18 8.87475 18H15.1252C15.8835 18 16.5 17.3835 16.5 16.6252V7.37475C16.5 6.6165 15.8835 6 15.1252 6ZM15.5002 16.6252C15.5002 16.8322 15.3322 17.0002 15.1252 17.0002H8.87475C8.66775 17.0002 8.49975 16.8322 8.49975 16.6252V7.37475C8.49975 7.16775 8.66775 6.99975 8.87475 6.99975H15.1252C15.3322 6.99975 15.5002 7.16775 15.5002 7.37475V16.6252Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M14.1248 10.8135V9.75C14.1248 8.99175 13.5083 8.37525 12.75 8.37525H11.4998C11.4998 7.893 11.1075 7.5 10.6245 7.5H9.87452C9.39227 7.5 9.00002 7.89225 9.00002 8.37525V9.12525C9.00002 9.6075 9.39227 10.0005 9.87527 10.0005H10.6253C11.1075 10.0005 11.5005 9.60825 11.5005 9.12525H12.75C13.095 9.12525 13.3748 9.40575 13.3748 9.75V10.8135C12.8693 10.974 12.4995 11.442 12.4995 12C12.4995 12.558 12.8693 13.026 13.3748 13.1865V14.25C13.3748 14.595 13.0943 14.8748 12.75 14.8748H11.4998C11.4998 14.3925 11.1075 13.9995 10.6245 13.9995H9.87452C9.39227 13.9995 8.99927 14.3918 8.99927 14.8748V15.6248C9.00002 16.1077 9.39227 16.5 9.87527 16.5H10.6253C11.1075 16.5 11.5005 16.1077 11.5005 15.6248H12.75C13.5083 15.6248 14.1248 15.0083 14.1248 14.25V13.1865C14.6303 13.026 15 12.558 15 12C15 11.442 14.6303 10.974 14.1248 10.8135ZM10.7498 9.12525C10.7498 9.19425 10.6935 9.2505 10.6245 9.2505H9.87452C9.80552 9.2505 9.74927 9.19425 9.74927 9.12525V8.37525C9.75002 8.30625 9.80627 8.25 9.87527 8.25H10.6253C10.6943 8.25 10.7505 8.30625 10.7505 8.37525V9.12525H10.7498ZM10.7498 15.6248C10.7498 15.6938 10.6935 15.75 10.6245 15.75H9.87452C9.80627 15.75 9.75002 15.6938 9.75002 15.6248V14.8748C9.75002 14.8058 9.80627 14.7495 9.87527 14.7495H10.6253C10.6943 14.7495 10.7505 14.8058 10.7505 14.8748V15.6248H10.7498Z"
          fill="var(--color-mauve)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5380">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxOrganizationStructure;
