import { SVGProps } from "types";

const BoxSettings = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5365)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M16.5 5.99219H7.5C6.675 5.99219 6 6.66719 6 7.49219V15.4924C6 16.3174 6.675 16.9924 7.5 16.9924H12.09L12.015 16.8627C11.8598 16.5927 11.8148 16.2829 11.88 15.9927H7.5C7.22475 15.9927 6.99975 15.7677 6.99975 15.4924V8.49269H17.0002V12.7429C17.385 12.7482 17.76 12.9229 18 13.2432V7.49219C18 6.66719 17.325 5.99219 16.5 5.99219Z"
          fill="var(--color-cyber-yellow)"
        />
        <path
          d="M17.1015 15.6986C17.1113 15.6206 17.1255 15.5441 17.1255 15.4638C17.1255 15.3836 17.112 15.3071 17.1015 15.2291L17.5515 14.8886C17.652 14.8128 17.6805 14.6733 17.6168 14.5646L17.2125 13.8641C17.1495 13.7546 17.0153 13.7096 16.899 13.7591L16.3808 13.9781C16.2548 13.8821 16.1198 13.7966 15.9713 13.7343L15.9023 13.1838C15.8865 13.0586 15.7801 12.9648 15.6541 12.9648H14.8456C14.7196 12.9648 14.6131 13.0586 14.5973 13.1838L14.5283 13.7343C14.3798 13.7966 14.2448 13.8813 14.1188 13.9781L13.6006 13.7591C13.4843 13.7103 13.3501 13.7553 13.2871 13.8641L12.8828 14.5646C12.8198 14.6741 12.8476 14.8128 12.9481 14.8886L13.3981 15.2291C13.3883 15.3071 13.3741 15.3836 13.3741 15.4638C13.3741 15.5441 13.3876 15.6206 13.3981 15.6986L12.9481 16.0391C12.8476 16.1148 12.8191 16.2543 12.8828 16.3631L13.2871 17.0636C13.3501 17.1731 13.4843 17.2181 13.6006 17.1686L14.1188 16.9496C14.2448 17.0456 14.3798 17.1311 14.5283 17.1933L14.5973 17.7438C14.6131 17.8691 14.7196 17.9628 14.8456 17.9628H15.6541C15.7801 17.9628 15.8865 17.8691 15.9023 17.7438L15.9713 17.1933C16.1198 17.1311 16.2548 17.0463 16.3808 16.9496L16.899 17.1686C17.0153 17.2173 17.1495 17.1723 17.2125 17.0636L17.6168 16.3631C17.6798 16.2536 17.652 16.1148 17.5515 16.0391L17.1015 15.6986ZM15.2506 16.4643C14.6986 16.4643 14.2508 16.0166 14.2508 15.4646C14.2508 14.9126 14.6986 14.4648 15.2506 14.4648C15.8025 14.4648 16.2503 14.9126 16.2503 15.4646C16.2503 16.0166 15.8025 16.4643 15.2506 16.4643Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M13.9898 12.3082C13.8548 12.4334 13.755 12.5834 13.6845 12.7529C13.1895 12.6832 12.6795 12.9127 12.4193 13.3627L12.054 13.9927H9.24903C8.55903 13.9927 7.99878 13.4324 7.99878 12.7424C7.99878 12.1177 8.46378 11.6024 9.06378 11.5124C9.27903 10.6424 10.059 9.99219 10.9988 9.99219C11.7488 9.99219 12.3885 10.4069 12.7335 11.0174C13.3898 11.1179 13.905 11.6429 13.9898 12.3082Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5365">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSettings;
