import { SVGProps } from "types";

const BoxSliding = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5369)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M9.49952 15.5005C9.7758 15.5005 9.99977 15.2765 9.99977 15.0002C9.99977 14.724 9.7758 14.5 9.49952 14.5C9.22324 14.5 8.99927 14.724 8.99927 15.0002C8.99927 15.2765 9.22324 15.5005 9.49952 15.5005Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M9.85349 12.1463C10.0485 12.3413 10.0485 12.6578 9.85349 12.8535C9.75599 12.951 9.62774 12.9998 9.50024 12.9998C9.37274 12.9998 9.24449 12.951 9.14699 12.8535L7.64698 11.3535C7.45198 11.1585 7.45198 10.842 7.64698 10.6463L9.14699 9.14625C9.34199 8.95125 9.65849 8.95125 9.85424 9.14625C10.0492 9.34125 10.0492 9.65775 9.85424 9.8535L8.70749 11.0003L9.85349 12.1463Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M12 15.5005C12.2763 15.5005 12.5003 15.2765 12.5003 15.0002C12.5003 14.724 12.2763 14.5 12 14.5C11.7237 14.5 11.4998 14.724 11.4998 15.0002C11.4998 15.2765 11.7237 15.5005 12 15.5005Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M14.4998 15.5005C14.776 15.5005 15 15.2765 15 15.0002C15 14.724 14.776 14.5 14.4998 14.5C14.2235 14.5 13.9995 14.724 13.9995 15.0002C13.9995 15.2765 14.2235 15.5005 14.4998 15.5005Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M16.3537 11.3533L14.8537 12.8533C14.7562 12.9508 14.628 12.9995 14.5005 12.9995C14.373 12.9995 14.2447 12.9508 14.1472 12.8533C13.9522 12.6583 13.9522 12.3418 14.1472 12.146L15.294 10.9993L14.1472 9.85252C13.9522 9.65752 13.9522 9.34102 14.1472 9.14527C14.3422 8.95027 14.6587 8.95027 14.8545 9.14527L16.3545 10.6453C16.5487 10.8418 16.5487 11.1583 16.3537 11.3533Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M16.5 18H7.5C6.67275 18 6 17.3273 6 16.5V7.5C6 6.67275 6.67275 6 7.5 6H16.5C17.3273 6 18 6.67275 18 7.5V16.5C18 17.3273 17.3273 18 16.5 18ZM7.5 6.99975C7.224 6.99975 6.99975 7.224 6.99975 7.5V16.5C6.99975 16.776 7.224 17.0002 7.5 17.0002H16.5C16.776 17.0002 17.0002 16.776 17.0002 16.5V7.5C17.0002 7.224 16.776 6.99975 16.5 6.99975H7.5Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5369">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSliding;
