import { SVGProps } from "types";

const BoxSliderNavigation = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5368)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M16.7498 13.4999C16.6455 13.4999 16.5405 13.4676 16.4505 13.4001C16.2293 13.2344 16.1843 12.9209 16.3508 12.7004L16.875 11.9998L16.35 11.3001C16.1843 11.0788 16.2293 10.7653 16.4498 10.6003C16.6703 10.4338 16.9838 10.4796 17.1495 10.7001L17.8995 11.6998C18.033 11.8776 18.033 12.1221 17.8995 12.2998L17.1495 13.2996C17.052 13.4309 16.902 13.4999 16.7498 13.4999Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M7.25014 13.4995C7.09789 13.4995 6.94789 13.4305 6.84964 13.2993L6.09964 12.2995C5.96614 12.1218 5.96614 11.8773 6.09964 11.6995L6.84964 10.6998C7.01539 10.4793 7.32889 10.4343 7.54939 10.6C7.77064 10.7658 7.81564 11.0793 7.64914 11.2998L7.12489 11.9995L7.64989 12.6993C7.81564 12.9205 7.77064 13.234 7.55014 13.399C7.45939 13.4673 7.35439 13.4995 7.25014 13.4995Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M14.0003 17.0007H9.99976C9.17251 17.0007 8.49976 16.328 8.49976 15.5007V8.50098C8.49976 7.67373 9.17251 7.00098 9.99976 7.00098H14.0003C14.8275 7.00098 15.5003 7.67373 15.5003 8.50098V15.5007C15.5003 16.328 14.8275 17.0007 14.0003 17.0007ZM9.99976 8.00073C9.72376 8.00073 9.49951 8.22498 9.49951 8.50098V15.5007C9.49951 15.7767 9.72376 16.001 9.99976 16.001H14.0003C14.2763 16.001 14.5005 15.7767 14.5005 15.5007V8.50098C14.5005 8.22498 14.2763 8.00073 14.0003 8.00073H9.99976Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5368">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSliderNavigation;
