import Image, { ImageProps } from "next/image";
import { useColorScheme } from "contexts/ColorScheme";

export type LogoImageProps = Omit<ImageProps, "src" | "alt">;

const LogoImage = (props: LogoImageProps) => {
  const [colorScheme, systemColorScheme] = useColorScheme();

  return (
    <Image
      {...props}
      src={`/assets/logo${
        colorScheme === "dark" ||
        (colorScheme === "system" && systemColorScheme === "dark")
          ? "-white"
          : ""
      }.svg`}
      alt="UNIIT s.r.o."
    />
  );
};

export default LogoImage;
