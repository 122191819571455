import { SVGProps } from "types";

const BoxMouse = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5433)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M9.12524 14.5V15.625C9.12524 16.9345 10.191 18.0002 11.5005 18.0002H12.75C14.0595 18.0002 15.1252 16.9345 15.1252 15.625V14.5H9.12524Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M13.7505 10.5H12.6255V7.74975C12.6248 6.78525 11.8403 6 10.875 6C9.90976 6 9.12526 6.78525 9.12526 7.74975V8.25C9.12526 8.66325 8.78851 9 8.37526 9C7.96201 9 7.62526 8.66325 7.62526 8.25V6.50025C7.62451 6.2235 7.40101 6 7.12501 6C6.84901 6 6.62476 6.2235 6.62476 6.50025V8.25C6.62476 9.2145 7.41001 9.99975 8.37451 9.99975C9.33901 9.99975 10.125 9.2145 10.125 8.25V7.74975C10.125 7.3365 10.4618 6.99975 10.875 6.99975C11.2883 6.99975 11.625 7.3365 11.625 7.74975V10.5H10.5C9.74176 10.5 9.12526 11.1165 9.12526 11.8748V13.5H11.625V12C11.625 11.724 11.8493 11.4998 12.1253 11.4998C12.4013 11.4998 12.6255 11.724 12.6255 12V13.5H15.1253V11.8748C15.1253 11.1165 14.5088 10.5 13.7505 10.5Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5433">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxMouse;
