import { SVGProps } from "types";

const IllustrationFont = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6621)">
        <path
          d="M65.7604 29.9086C65.7274 29.9084 65.6942 29.9082 65.6612 29.9082C60.1554 29.8996 54.9388 27.5288 51.32 23.3794C45.929 17.1981 37.9825 13.3037 29.1273 13.3431C13.0549 13.4142 -0.205387 26.9178 0.00240946 42.9889C0.209175 58.9709 13.229 71.8631 29.2602 71.8631C33.7879 71.8631 38.075 70.8341 41.9011 68.9976C45.2 67.4141 49.131 67.8316 52.0362 70.0565C55.7939 72.9346 60.4907 74.6478 65.5878 74.6564C77.8566 74.6769 87.9283 64.6842 87.9996 52.4156C88.0715 40.0322 78.1268 29.9813 65.7604 29.9086Z"
          fill="url(#paint0_linear_2042_6621)"
        />
        <path
          opacity="0.1"
          d="M20.3186 13.3994V24.8836C20.3186 25.3581 20.7034 25.743 21.178 25.743H24.7707C25.1665 25.743 25.5109 25.4728 25.6053 25.0883L27.2197 18.507C27.3141 18.1227 27.6585 17.8524 28.0544 17.8524H37.4535C37.9281 17.8524 38.3129 18.2372 38.3129 18.7117V60.7008C38.3129 61.1064 38.0291 61.4569 37.6324 61.5413L28.494 63.4864C28.0971 63.5708 27.8136 63.9212 27.8136 64.3268V67.3821C27.8136 67.8566 28.1984 68.2415 28.6729 68.2415H54.8122C55.2867 68.2415 55.6716 67.8566 55.6716 67.3821V64.3268C55.6716 63.9212 55.3878 63.5708 54.9911 63.4864L45.8527 61.5413C45.4558 61.4569 45.1722 61.1064 45.1722 60.7008V18.7117C45.1722 18.2372 45.5571 17.8524 46.0316 17.8524H55.4308C55.8266 17.8524 56.171 18.1225 56.2654 18.507L57.8798 25.0883C57.9742 25.4726 58.3186 25.743 58.7144 25.743H62.3071C62.7817 25.743 63.1665 25.3581 63.1665 24.8836V13.3994C63.1665 12.9249 62.7817 12.54 62.3071 12.54H21.178C20.7034 12.54 20.3186 12.9247 20.3186 13.3994Z"
          fill="#2626BC"
        />
        <path
          opacity="0.1"
          d="M75.1116 19.896H76.8283C77.5356 19.896 77.94 19.0894 77.517 18.5225L73.7402 13.4625C73.3965 13.0021 72.7066 13.0021 72.3628 13.4625L68.586 18.5225C68.1631 19.0894 68.5675 19.896 69.2747 19.896H70.9914V60.8853H69.2747C68.5675 60.8853 68.1631 61.6919 68.586 62.2587L72.3628 67.3187C72.7066 67.7792 73.3965 67.7792 73.7402 67.3187L77.517 62.2587C77.94 61.6919 77.5356 60.8853 76.8283 60.8853H75.1116V19.896Z"
          fill="#2626BC"
        />
        <path
          d="M20.3311 17.0229V28.5009C20.3311 28.9753 20.7155 29.3598 21.1899 29.3598H24.7806C25.176 29.3598 25.5205 29.0896 25.6147 28.7054L27.2282 22.1278C27.3224 21.7436 27.6669 21.4735 28.0623 21.4735H37.4565C37.9309 21.4735 38.3154 21.8579 38.3154 22.3323V64.299C38.3154 64.7045 38.0318 65.0548 37.6353 65.1392L28.5016 67.0832C28.1051 67.1676 27.8215 67.5179 27.8215 67.9234V70.9771C27.8215 71.4514 28.206 71.8359 28.6804 71.8359H54.8056C55.2799 71.8359 55.6644 71.4514 55.6644 70.9771V67.9234C55.6644 67.5179 55.3808 67.1676 54.9843 67.0832L45.8507 65.1392C45.4542 65.0548 45.1706 64.7045 45.1706 64.299V22.3323C45.1706 21.8579 45.5551 21.4735 46.0295 21.4735H55.4236C55.8191 21.4735 56.1635 21.7436 56.2577 22.1278L57.8713 28.7054C57.9655 29.0896 58.3099 29.3598 58.7054 29.3598H62.2961C62.7704 29.3598 63.1549 28.9753 63.1549 28.5009V17.0229C63.1549 16.5485 62.7704 16.1641 62.2961 16.1641H21.1901C20.7157 16.1641 20.3311 16.5485 20.3311 17.0229Z"
          fill="#FF7EB8"
        />
        <path
          d="M75.095 23.5164H76.8108C77.5177 23.5164 77.922 22.7102 77.4992 22.1438L73.7245 17.0864C73.3809 16.6261 72.6913 16.6261 72.3477 17.0864L68.573 22.1438C68.1502 22.7103 68.5545 23.5164 69.2614 23.5164H70.9772V64.4839H69.2614C68.5545 64.4839 68.1502 65.2901 68.573 65.8565L72.3477 70.9139C72.6913 71.3742 73.3809 71.3742 73.7245 70.9139L77.4992 65.8565C77.922 65.29 77.5177 64.4839 76.8108 64.4839H75.095V23.5164Z"
          fill="#9FB0FE"
        />
        <path
          d="M62.2973 15.3049H54.2553C53.7806 15.3049 53.3959 15.6897 53.3959 16.1642C53.3959 16.6388 53.7806 17.0236 54.2553 17.0236L62.2968 17.0231L62.2973 28.5006L58.707 28.5009L57.0933 21.9233C56.9042 21.1526 56.2181 20.6142 55.4244 20.6142H46.0304C45.0828 20.6142 44.312 21.3851 44.312 22.3325V64.2992C44.312 65.1051 44.8841 65.812 45.6725 65.98L54.8058 67.9235L54.8063 70.9767L28.6815 70.9772L28.6812 67.924L37.8148 65.98C38.6032 65.812 39.1753 65.1053 39.1753 64.2992V22.3325C39.1753 21.3851 38.4046 20.6142 37.4571 20.6142H28.0629C27.2695 20.6142 26.5834 21.1524 26.394 21.9231L24.781 28.5006L21.1907 28.5009L21.1902 17.0234H49.5244C49.9991 17.0234 50.3838 16.6386 50.3838 16.1641C50.3838 15.6895 49.9991 15.3047 49.5244 15.3047H21.1902C20.2426 15.3047 19.4719 16.0755 19.4719 17.0229V28.5009C19.4719 29.4485 20.2426 30.2193 21.1902 30.2193H24.781C25.5743 30.2193 26.2605 29.6812 26.4499 28.9103L28.0629 22.333L37.4566 22.3325L37.4569 64.2987L28.3233 66.2428C27.5349 66.4107 26.9627 67.1174 26.9627 67.9234V70.9771C26.9627 71.9244 27.7334 72.6953 28.681 72.6953H54.8063C55.7539 72.6953 56.5246 71.9244 56.5246 70.9771V67.9235C56.5246 67.1176 55.9524 66.4107 55.164 66.2429L46.0307 64.2994L46.0304 22.3332L55.4239 22.3328L57.0376 28.9105C57.2267 29.6812 57.913 30.2195 58.7065 30.2195H62.2973C63.2449 30.2195 64.0156 29.4486 64.0156 28.5011V17.0231C64.0156 16.0757 63.2449 15.3049 62.2973 15.3049Z"
          fill="#2626BC"
        />
        <path
          d="M13.4888 17.0234C13.9635 17.0234 14.3481 16.6386 14.3481 16.1641C14.3481 15.6895 13.9635 15.3047 13.4888 15.3047H10.3108C9.83607 15.3047 9.45142 15.6895 9.45142 16.1641V71.8359C9.45142 72.3105 9.83607 72.6953 10.3108 72.6953H13.4888C13.9635 72.6953 14.3481 72.3105 14.3481 71.8359C14.3481 71.3614 13.9635 70.9766 13.4888 70.9766H11.1702V63.7556H13.4888C13.9635 63.7556 14.3481 63.3707 14.3481 62.8962C14.3481 62.4216 13.9635 62.0368 13.4888 62.0368H11.1702V54.3074H13.4888C13.9635 54.3074 14.3481 53.9226 14.3481 53.4481C14.3481 52.9735 13.9635 52.5887 13.4888 52.5887H11.1702V44.8595H13.4888C13.9635 44.8595 14.3481 44.4746 14.3481 44.0001C14.3481 43.5255 13.9635 43.1407 13.4888 43.1407H11.1702V35.4113H13.4888C13.9635 35.4113 14.3481 35.0265 14.3481 34.5519C14.3481 34.0774 13.9635 33.6926 13.4888 33.6926H11.1702V25.9632H13.4888C13.9635 25.9632 14.3481 25.5783 14.3481 25.1038C14.3481 24.6293 13.9635 24.2444 13.4888 24.2444H11.1702V17.0234H13.4888Z"
          fill="#2626BC"
        />
        <path
          d="M76.8106 63.6246H75.9544V24.3759H76.8108C77.465 24.3759 78.0533 24.0127 78.3467 23.4278C78.6401 22.8429 78.5792 22.1541 78.1877 21.6299L74.4132 16.5724C74.0857 16.1336 73.5839 15.8818 73.0363 15.8818C73.0361 15.8818 73.0361 15.8818 73.0359 15.8818C72.4883 15.8818 71.9866 16.1335 71.6589 16.5724L67.8841 21.6299C67.493 22.1541 67.4319 22.8431 67.7252 23.4278C68.0184 24.0126 68.6069 24.3759 69.2612 24.3759H70.9769C71.4516 24.3759 71.8362 23.9911 71.8362 23.5165C71.8362 23.042 71.4516 22.6571 70.9769 22.6571L69.2616 22.6578L73.0356 17.6004L76.8108 22.657H75.095C74.6203 22.657 74.2356 23.0418 74.2356 23.5164V64.4838C74.2356 64.9583 74.6203 65.3432 75.095 65.3432L76.8103 65.3425L73.0363 70.3999L69.2612 65.3433H70.9769C71.4516 65.3433 71.8362 64.9585 71.8362 64.484V28.2474C71.8362 27.7728 71.4516 27.388 70.9769 27.388C70.5021 27.388 70.1175 27.7728 70.1175 28.2474V63.6246H69.2612C68.6069 63.6246 68.0186 63.9878 67.7252 64.5726C67.4318 65.1575 67.4928 65.8464 67.8841 66.3706L71.6589 71.428C71.9865 71.8668 72.4885 72.1185 73.0363 72.1185C73.5839 72.1185 74.0854 71.8668 74.413 71.428L78.1877 66.3706C78.5789 65.8464 78.6399 65.1574 78.3467 64.5726C78.0535 63.9879 77.465 63.6246 76.8106 63.6246Z"
          fill="#2626BC"
        />
        <path
          d="M24.1086 60.6444C23.6339 60.6444 23.2492 60.2596 23.2492 59.785C23.2492 59.217 22.7872 58.7548 22.219 58.7548C21.7443 58.7548 21.3596 58.37 21.3596 57.8955C21.3596 57.4209 21.7443 57.0361 22.219 57.0361C22.7872 57.0361 23.2492 56.5739 23.2492 56.0059C23.2492 55.5313 23.6339 55.1465 24.1086 55.1465C24.5833 55.1465 24.968 55.5313 24.968 56.0059C24.968 56.5739 25.43 57.0361 25.9982 57.0361C26.4729 57.0361 26.8576 57.4209 26.8576 57.8955C26.8576 58.37 26.4729 58.7548 25.9982 58.7548C25.43 58.7548 24.968 59.217 24.968 59.785C24.968 60.2596 24.5833 60.6444 24.1086 60.6444Z"
          fill="#6583FE"
        />
        <path
          d="M61.2665 40.5643C60.7918 40.5643 60.4072 40.1795 60.4072 39.705C60.4072 39.1369 59.9452 38.6748 59.377 38.6748C58.9022 38.6748 58.5176 38.2899 58.5176 37.8154C58.5176 37.3408 58.9022 36.956 59.377 36.956C59.9452 36.956 60.4072 36.4938 60.4072 35.9258C60.4072 35.4512 60.7918 35.0664 61.2665 35.0664C61.7413 35.0664 62.1259 35.4512 62.1259 35.9258C62.1259 36.4938 62.5879 36.956 63.1561 36.956C63.6309 36.956 64.0155 37.3408 64.0155 37.8154C64.0155 38.2899 63.6309 38.6748 63.1561 38.6748C62.5879 38.6748 62.1259 39.1369 62.1259 39.705C62.1259 40.1795 61.7413 40.5643 61.2665 40.5643Z"
          fill="#6583FE"
        />
        <path
          d="M29.3574 48.6384C29.1376 48.6384 28.9174 48.5545 28.7499 48.3868L26.8603 46.4972C26.5246 46.1615 26.5246 45.6173 26.8603 45.2818L28.7499 43.3922C29.0852 43.0568 29.6297 43.0568 29.965 43.3922L31.8546 45.2818C32.1903 45.6175 32.1903 46.1617 31.8546 46.4972L29.965 48.3868C29.7974 48.5545 29.5774 48.6384 29.3574 48.6384ZM28.6834 45.8894L29.3576 46.5637L30.0319 45.8894L29.3576 45.2152L28.6834 45.8894Z"
          fill="#FF7EB8"
        />
        <path
          d="M49.6279 60.6444C49.1532 60.6444 48.7686 60.2596 48.7686 59.785V33.5215C48.7686 33.0469 49.1532 32.6621 49.6279 32.6621C50.1026 32.6621 50.4873 33.0469 50.4873 33.5215V59.7852C50.4873 60.2596 50.1026 60.6444 49.6279 60.6444Z"
          fill="#6583FE"
        />
        <path
          d="M37.9467 10.8281H10.3108C9.83607 10.8281 9.45142 10.4433 9.45142 9.96875C9.45142 9.4942 9.83607 9.10938 10.3108 9.10938H37.9467C38.4215 9.10938 38.8061 9.4942 38.8061 9.96875C38.8061 10.4433 38.4215 10.8281 37.9467 10.8281Z"
          fill="#97FFD2"
        />
        <path
          d="M63.1562 78.8906H10.3108C9.83607 78.8906 9.45142 78.5058 9.45142 78.0312C9.45142 77.5567 9.83607 77.1719 10.3108 77.1719H63.1562C63.6309 77.1719 64.0156 77.5567 64.0156 78.0312C64.0156 78.5058 63.6309 78.8906 63.1562 78.8906Z"
          fill="#97FFD2"
        />
        <path
          d="M67.5484 15.6378C67.0737 15.6378 66.689 15.2529 66.689 14.7784C66.689 14.2103 66.227 13.7482 65.6588 13.7482C65.1841 13.7482 64.7994 13.3633 64.7994 12.8888C64.7994 12.4142 65.1841 12.0294 65.6588 12.0294C66.227 12.0294 66.689 11.5672 66.689 10.999C66.689 10.5245 67.0737 10.1396 67.5484 10.1396C68.0231 10.1396 68.4078 10.5245 68.4078 10.999C68.4078 11.5672 68.8698 12.0294 69.438 12.0294C69.9127 12.0294 70.2974 12.4142 70.2974 12.8888C70.2974 13.3633 69.9127 13.7482 69.438 13.7482C68.8698 13.7482 68.4078 14.2103 68.4078 14.7784C68.4078 15.2529 68.023 15.6378 67.5484 15.6378Z"
          fill="#97FFD2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6621"
          x1="-4.11365"
          y1="31.9233"
          x2="90.7704"
          y2="62.2862"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#D2DEFF" />
          <stop offset="0.9993" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6621">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationFont;
