import { SVGProps } from "types";

const IllustrationLandingPage = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6618)">
        <path
          d="M53.2942 15.3575C49.6109 6.30503 40.7008 -0.0636297 30.3101 0.000479654C17.0544 0.0821203 6.15543 10.7743 5.83385 24.0262C5.67521 30.5643 8.06445 36.5423 12.0788 41.0389C15.2789 44.6235 16.8849 49.3448 16.723 54.1475C16.7015 54.7858 16.6982 55.4288 16.7141 56.0759C17.1376 73.504 32.0259 88.0105 49.4592 88C67.5289 87.9892 82.1738 73.3373 82.1738 55.2652C82.1738 42.0181 74.3047 30.61 62.9862 25.4581C58.5747 23.4501 55.1209 19.8469 53.2942 15.3575Z"
          fill="url(#paint0_linear_2042_6618)"
        />
        <path
          opacity="0.1"
          d="M78.2016 13.187H68.0795V10.6072C68.0795 9.18303 66.9237 8.02734 65.4997 8.02734H22.5008C21.0767 8.02734 19.921 9.1832 19.921 10.6072V13.187H9.79859C8.37444 13.187 7.21875 14.3429 7.21875 15.7669V69.8164C7.21875 71.2406 8.37461 72.3963 9.79859 72.3963H19.9207V74.9761C19.9207 76.4003 21.0765 77.5559 22.5005 77.5559H65.4993C66.9235 77.5559 68.0792 76.4001 68.0792 74.9761V72.3963H78.2012C79.6254 72.3963 80.7811 71.2404 80.7811 69.8164V15.7669C80.7814 14.3427 79.6256 13.187 78.2016 13.187Z"
          fill="#2626BC"
        />
        <path
          d="M78.2016 16.7969H61.8877V76.0063H78.2016C79.6257 76.0063 80.7814 74.8504 80.7814 73.4264V19.3769C80.7814 17.9526 79.6255 16.7969 78.2016 16.7969Z"
          fill="white"
        />
        <path
          d="M74.2713 56.0332H61.8877V76.0061H74.2713C74.5563 76.0061 74.7873 75.7751 74.7873 75.4901V56.5492C74.7873 56.2642 74.5563 56.0332 74.2713 56.0332Z"
          fill="#FFA8CF"
        />
        <path
          d="M74.2713 31.5479H61.8877V51.5208H74.2713C74.5563 51.5208 74.7873 51.2898 74.7873 51.0048V32.0638C74.7873 31.7789 74.5563 31.5479 74.2713 31.5479Z"
          fill="#97FFD2"
        />
        <path
          d="M78.2016 16.7969H61.8877V25.0821H80.7814V19.3767C80.7814 17.9519 79.6262 16.7969 78.2016 16.7969Z"
          fill="#02FFB3"
        />
        <path
          d="M25.5967 16.7969H9.79859C8.37444 16.7969 7.21875 17.9527 7.21875 19.3767V73.4262C7.21875 74.8504 8.37461 76.0061 9.79859 76.0061H25.5965V16.7969H25.5967Z"
          fill="white"
        />
        <path
          d="M25.5965 68.715V46.0117H12.3529C12.0679 46.0117 11.8369 46.2427 11.8369 46.5277V68.1991C11.8369 68.484 12.0679 68.715 12.3529 68.715H25.5965Z"
          fill="#FFA8CF"
        />
        <path
          d="M25.5965 41.5636V34.3398H12.3529C12.0679 34.3398 11.8369 34.5708 11.8369 34.8558V41.0476C11.8369 41.3326 12.0679 41.5636 12.3529 41.5636H25.5965Z"
          fill="#9FB0FE"
        />
        <path
          d="M25.5965 16.7969H9.79859C8.37375 16.7969 7.21875 17.9519 7.21875 19.3767V25.0821H25.5965V16.7969Z"
          fill="#FF7EB8"
        />
        <path
          d="M68.0794 14.2166V78.5858C68.0794 80.01 66.9235 81.1656 65.4996 81.1656H22.5007C21.0766 81.1656 19.9209 80.0098 19.9209 78.5858V14.2166C19.9209 12.7924 21.0768 11.6367 22.5007 11.6367H65.4996C66.9235 11.6367 68.0794 12.7924 68.0794 14.2166Z"
          fill="white"
        />
        <path
          d="M19.9207 25.0996H68.0793V48.3189H19.9207V25.0996Z"
          fill="#FFA8CF"
        />
        <path
          d="M68.0794 25.0996V43.2108C66.0877 44.0397 63.9016 44.4955 61.6107 44.4955C52.2817 44.4955 44.719 36.9311 44.719 27.6021C44.719 26.7525 44.7826 25.9165 44.9031 25.0996H68.0794Z"
          fill="#FF7EB8"
        />
        <path
          d="M68.0794 19.922V14.2166C68.0794 12.7917 66.9244 11.6367 65.4996 11.6367H22.5007C21.0759 11.6367 19.9209 12.7917 19.9209 14.2166V19.922H68.0794Z"
          fill="#6583FE"
        />
        <path
          d="M78.2016 15.9372H68.9389V14.2166C68.9389 12.3203 67.396 10.7773 65.4997 10.7773H22.5008C20.6044 10.7773 19.0616 12.3203 19.0616 14.2166V15.9372H9.79877C7.9023 15.9372 6.35938 17.48 6.35938 19.3764V73.4254C6.35938 75.3219 7.9023 76.8648 9.79877 76.8648H15.1872C15.6618 76.8648 16.0466 76.4802 16.0466 76.0054C16.0466 75.5307 15.6618 75.1461 15.1872 75.1461H9.79877C8.85002 75.1461 8.07813 74.3742 8.07813 73.4254V25.9412H19.0613V78.5853C19.0613 80.4818 20.6042 82.0247 22.5005 82.0247H65.4993C67.3958 82.0247 68.9386 80.4818 68.9386 78.5853V76.8648H78.2012C80.0977 76.8648 81.6405 75.3219 81.6405 73.4254V19.3764C81.6408 17.48 80.0979 15.9372 78.2016 15.9372ZM78.2016 17.656C79.1503 17.656 79.9221 18.4278 79.9221 19.3766V24.2224H68.9389V17.656H78.2016ZM22.5008 12.4961H65.4997C66.4484 12.4961 67.2201 13.2678 67.2201 14.2166V19.0626H20.7802V14.2166C20.7802 13.2678 21.5521 12.4961 22.5008 12.4961ZM8.07813 24.2224V19.3766C8.07813 18.4278 8.85002 17.656 9.79877 17.656H19.0615V24.2224H8.07813ZM78.2016 75.1461H68.9389V29.8152C68.9389 29.3405 68.5541 28.9559 68.0795 28.9559C67.605 28.9559 67.2201 29.3405 67.2201 29.8152V78.5853C67.2201 79.534 66.4483 80.3059 65.4997 80.3059H22.5008C21.5521 80.3059 20.7804 79.534 20.7804 78.5853V20.7813H67.2201V25.0818C67.2201 25.5565 67.605 25.9412 68.0795 25.9412H79.9221V73.4254C79.9221 74.3742 79.1502 75.1461 78.2016 75.1461Z"
          fill="#2626BC"
        />
        <path
          d="M13.7688 20.0674C13.2943 20.0674 12.9094 20.452 12.9094 20.9268V20.9517C12.9094 21.4264 13.2943 21.8111 13.7688 21.8111C14.2433 21.8111 14.6282 21.4264 14.6282 20.9517V20.9268C14.6282 20.452 14.2435 20.0674 13.7688 20.0674Z"
          fill="#2626BC"
        />
        <path
          d="M17.0925 20.0674C16.618 20.0674 16.2332 20.452 16.2332 20.9268V20.9517C16.2332 21.4264 16.618 21.8111 17.0925 21.8111C17.5671 21.8111 17.9519 21.4264 17.9519 20.9517V20.9268C17.9519 20.452 17.5671 20.0674 17.0925 20.0674Z"
          fill="#2626BC"
        />
        <path
          d="M26.4707 16.6511C26.9453 16.6511 27.3301 16.2664 27.3301 15.7917V15.7666C27.3301 15.2919 26.9453 14.9072 26.4707 14.9072C25.9962 14.9072 25.6113 15.2919 25.6113 15.7666V15.7917C25.6113 16.2664 25.9962 16.6511 26.4707 16.6511Z"
          fill="#2626BC"
        />
        <path
          d="M29.7944 16.6511C30.269 16.6511 30.6538 16.2664 30.6538 15.7917V15.7666C30.6538 15.2919 30.269 14.9072 29.7944 14.9072C29.3199 14.9072 28.9351 15.2919 28.9351 15.7666V15.7917C28.9351 16.2664 29.3199 16.6511 29.7944 16.6511Z"
          fill="#2626BC"
        />
        <path
          d="M33.1184 16.6511C33.593 16.6511 33.9778 16.2664 33.9778 15.7917V15.7666C33.9778 15.2919 33.593 14.9072 33.1184 14.9072C32.6439 14.9072 32.259 15.2919 32.259 15.7666V15.7917C32.259 16.2664 32.6437 16.6511 33.1184 16.6511Z"
          fill="#2626BC"
        />
        <path
          d="M62.2183 34.1051C61.8826 33.7696 61.3386 33.7698 61.003 34.1049C60.6673 34.4406 60.6673 34.9848 61.003 35.3203L61.4004 35.7178L61.003 36.1153C60.6673 36.4508 60.6673 36.995 61.003 37.3307C61.1707 37.4984 61.3906 37.5823 61.6106 37.5823C61.8306 37.5823 62.0506 37.4984 62.2183 37.3305L63.2233 36.3254C63.5589 35.9899 63.5589 35.4457 63.2233 35.1102L62.2183 34.1051Z"
          fill="#2626BC"
        />
        <path
          d="M26.9972 34.1051C26.6617 33.7697 26.1174 33.7696 25.7819 34.1052L24.7769 35.1104C24.4412 35.4459 24.4412 35.99 24.7769 36.3255L25.7819 37.3306C25.9498 37.4986 26.1696 37.5824 26.3896 37.5824C26.6094 37.5824 26.8294 37.4986 26.9972 37.3308C27.3328 36.9951 27.3328 36.451 26.9972 36.1155L26.5998 35.7179L26.9972 35.3204C27.3328 34.9849 27.3328 34.4407 26.9972 34.1051Z"
          fill="#2626BC"
        />
        <path
          d="M41.0916 46.1368C41.6226 46.1368 42.0531 45.7063 42.0531 45.1753C42.0531 44.6443 41.6226 44.2139 41.0916 44.2139C40.5606 44.2139 40.1301 44.6443 40.1301 45.1753C40.1301 45.7063 40.5606 46.1368 41.0916 46.1368Z"
          fill="#4369FD"
        />
        <path
          d="M44 46.1368C44.531 46.1368 44.9615 45.7063 44.9615 45.1753C44.9615 44.6443 44.531 44.2139 44 44.2139C43.469 44.2139 43.0386 44.6443 43.0386 45.1753C43.0386 45.7063 43.469 46.1368 44 46.1368Z"
          fill="#FF5BA8"
        />
        <path
          d="M46.9085 46.1368C47.4395 46.1368 47.87 45.7063 47.87 45.1753C47.87 44.6443 47.4395 44.2139 46.9085 44.2139C46.3775 44.2139 45.947 44.6443 45.947 45.1753C45.947 45.7063 46.3775 46.1368 46.9085 46.1368Z"
          fill="#FF5BA8"
        />
        <path
          d="M34.273 61.7212H23.4947C23.2098 61.7212 22.9788 61.4902 22.9788 61.2053V53.5228C22.9788 53.2378 23.2098 53.0068 23.4947 53.0068H34.273C34.558 53.0068 34.789 53.2378 34.789 53.5228V61.2053C34.789 61.4902 34.558 61.7212 34.273 61.7212Z"
          fill="#6583FE"
        />
        <path
          d="M32.4814 61.7205H28.3535V56.6634C28.3535 56.3785 28.5845 56.1475 28.8695 56.1475H31.9655C32.2504 56.1475 32.4814 56.3785 32.4814 56.6634V61.7205Z"
          fill="#9FB0FE"
        />
        <path
          d="M31.8714 66.1145H25.8959C25.4201 66.1145 25.0344 65.7288 25.0344 65.253C25.0344 64.7773 25.4199 64.3916 25.8959 64.3916H31.8714C32.3472 64.3916 32.7329 64.7773 32.7329 65.253C32.7329 65.7288 32.3472 66.1145 31.8714 66.1145Z"
          fill="#FF7EB8"
        />
        <path
          d="M34.3582 72.299H23.84C23.3642 72.299 22.9785 71.9134 22.9785 71.4376C22.9785 70.9619 23.364 70.5762 23.84 70.5762H34.3582C34.8339 70.5762 35.2196 70.9619 35.2196 71.4376C35.2196 71.9134 34.8339 72.299 34.3582 72.299Z"
          fill="#B7C5FF"
        />
        <path
          d="M34.3582 75.6526H23.84C23.3642 75.6526 22.9785 75.2669 22.9785 74.7911C22.9785 74.3154 23.364 73.9297 23.84 73.9297H34.3582C34.8339 73.9297 35.2196 74.3154 35.2196 74.7911C35.2196 75.2669 34.8339 75.6526 34.3582 75.6526Z"
          fill="#B7C5FF"
        />
        <path
          d="M49.1739 61.7212H38.3956C38.1106 61.7212 37.8796 61.4902 37.8796 61.2053V53.5228C37.8796 53.2378 38.1106 53.0068 38.3956 53.0068H49.1739C49.4589 53.0068 49.6899 53.2378 49.6899 53.5228V61.2053C49.6899 61.4902 49.4589 61.7212 49.1739 61.7212Z"
          fill="#6583FE"
        />
        <path
          d="M47.3826 61.7205H43.2546V56.6634C43.2546 56.3785 43.4856 56.1475 43.7706 56.1475H46.8666C47.1516 56.1475 47.3826 56.3785 47.3826 56.6634V61.7205Z"
          fill="#9FB0FE"
        />
        <path
          d="M46.7723 66.1145H40.7967C40.321 66.1145 39.9353 65.7288 39.9353 65.253C39.9353 64.7773 40.3208 64.3916 40.7967 64.3916H46.7723C47.2481 64.3916 47.6338 64.7773 47.6338 65.253C47.6338 65.7288 47.2483 66.1145 46.7723 66.1145Z"
          fill="#FF7EB8"
        />
        <path
          d="M49.2593 72.299H38.7411C38.2653 72.299 37.8796 71.9134 37.8796 71.4376C37.8796 70.9619 38.2652 70.5762 38.7411 70.5762H49.2593C49.7351 70.5762 50.1208 70.9619 50.1208 71.4376C50.1208 71.9134 49.7351 72.299 49.2593 72.299Z"
          fill="#B7C5FF"
        />
        <path
          d="M49.2593 75.6526H38.7411C38.2653 75.6526 37.8796 75.2669 37.8796 74.7911C37.8796 74.3154 38.2652 73.9297 38.7411 73.9297H49.2593C49.7351 73.9297 50.1208 74.3154 50.1208 74.7911C50.1208 75.2669 49.7351 75.6526 49.2593 75.6526Z"
          fill="#B7C5FF"
        />
        <path
          d="M64.075 61.7212H53.2967C53.0118 61.7212 52.7808 61.4902 52.7808 61.2053V53.5228C52.7808 53.2378 53.0118 53.0068 53.2967 53.0068H64.075C64.36 53.0068 64.591 53.2378 64.591 53.5228V61.2053C64.591 61.4902 64.36 61.7212 64.075 61.7212Z"
          fill="#6583FE"
        />
        <path
          d="M62.2835 61.7205H58.1558V56.6634C58.1558 56.3785 58.3868 56.1475 58.6717 56.1475H61.7677C62.0527 56.1475 62.2837 56.3785 62.2837 56.6634V61.7205H62.2835Z"
          fill="#9FB0FE"
        />
        <path
          d="M61.6734 66.1145H55.6979C55.2221 66.1145 54.8364 65.7288 54.8364 65.253C54.8364 64.7773 55.2219 64.3916 55.6979 64.3916H61.6734C62.1492 64.3916 62.5349 64.7773 62.5349 65.253C62.5349 65.7288 62.1492 66.1145 61.6734 66.1145Z"
          fill="#FF7EB8"
        />
        <path
          d="M64.1602 72.299H53.642C53.1662 72.299 52.7805 71.9134 52.7805 71.4376C52.7805 70.9619 53.166 70.5762 53.642 70.5762H64.1602C64.6359 70.5762 65.0216 70.9619 65.0216 71.4376C65.0216 71.9134 64.6359 72.299 64.1602 72.299Z"
          fill="#B7C5FF"
        />
        <path
          d="M64.1602 75.6526H53.642C53.1662 75.6526 52.7805 75.2669 52.7805 74.7911C52.7805 74.3154 53.166 73.9297 53.642 73.9297H64.1602C64.6359 73.9297 65.0216 74.3154 65.0216 74.7911C65.0216 75.2669 64.6359 75.6526 64.1602 75.6526Z"
          fill="#B7C5FF"
        />
        <path
          d="M72.6833 13.5268C72.4554 13.5268 72.2367 13.4364 72.0755 13.275L70.1818 11.3811C70.0206 11.2199 69.9302 11.0015 69.9302 10.7736C69.9302 10.5457 70.0208 10.327 70.1818 10.166L72.0755 8.27226C72.4112 7.93659 72.9552 7.93659 73.2909 8.27226L75.1846 10.166C75.3458 10.3272 75.4362 10.5457 75.4362 10.7736C75.4362 11.0015 75.3456 11.2201 75.1846 11.3811L73.291 13.275C73.1298 13.4364 72.9112 13.5268 72.6833 13.5268ZM72.0051 10.7737L72.6834 11.4521L73.3618 10.7737L72.6834 10.0955L72.0051 10.7737Z"
          fill="#FF7EB8"
        />
        <path
          d="M14.7282 84.6508C14.5003 84.6508 14.2817 84.5604 14.1204 84.399L12.2267 82.5052C12.0655 82.3439 11.9751 82.1255 11.9751 81.8976C11.9751 81.6697 12.0657 81.4511 12.2267 81.29L14.1204 79.3963C14.4561 79.0606 15.0001 79.0606 15.3358 79.3963L17.2295 81.29C17.3907 81.4512 17.4811 81.6697 17.4811 81.8976C17.4811 82.1255 17.3905 82.3441 17.2295 82.5052L15.3359 84.399C15.1749 84.5603 14.9563 84.6508 14.7282 84.6508ZM14.05 81.8976L14.7284 82.576L15.4068 81.8976L14.7284 81.2194L14.05 81.8976Z"
          fill="#6583FE"
        />
        <path
          d="M64.5907 8.68675C64.3707 8.68675 64.1509 8.60287 63.983 8.43495L62.0893 6.54123C61.9281 6.38001 61.8376 6.16156 61.8376 5.93365C61.8376 5.70574 61.9282 5.48712 62.0893 5.32607L63.983 3.43235C64.3188 3.09668 64.8628 3.09685 65.1983 3.43235L67.092 5.32607C67.4277 5.66157 67.4277 6.20573 67.092 6.54123L65.1983 8.43495C65.0306 8.60287 64.8107 8.68675 64.5907 8.68675ZM63.9124 5.93365L64.5907 6.61187L65.269 5.93365L64.5907 5.25543L63.9124 5.93365Z"
          fill="#6583FE"
        />
        <path
          d="M75.9772 84.8197C75.5027 84.8197 75.1178 84.435 75.1178 83.9603C75.1178 83.39 74.6538 82.9259 74.0835 82.9259C73.6089 82.9259 73.2241 82.5413 73.2241 82.0666C73.2241 81.5919 73.6089 81.2072 74.0835 81.2072C74.6538 81.2072 75.1178 80.7433 75.1178 80.1729C75.1178 79.6981 75.5027 79.3135 75.9772 79.3135C76.4518 79.3135 76.8366 79.6981 76.8366 80.1729C76.8366 80.7431 77.3007 81.2072 77.8709 81.2072C78.3455 81.2072 78.7303 81.5919 78.7303 82.0666C78.7303 82.5413 78.3455 82.9259 77.8709 82.9259C77.3007 82.9259 76.8366 83.3898 76.8366 83.9603C76.8366 84.435 76.4518 84.8197 75.9772 84.8197Z"
          fill="#97FFD2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6618"
          x1="12.6166"
          y1="3.41959"
          x2="69.9685"
          y2="83.9652"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#FFDBED" />
          <stop offset="0.9993" stopColor="#D2DEFF" />
        </linearGradient>
        <clipPath id="clip0_2042_6618">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationLandingPage;
