import { SVGProps } from "types";

const IllustrationDevelopment = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M86.9044 49.7443C84.8718 43.7957 80.3495 39.4043 74.8986 37.3346C72.0747 36.2622 70.1985 33.5781 70.1571 30.5577C70.1562 30.4917 70.155 30.4255 70.1535 30.3594C69.8945 19.2593 60.6165 10.3651 49.5153 10.5557C42.9678 10.6681 37.1767 13.8824 33.5472 18.792C30.4217 23.0199 25.5567 25.661 20.2991 25.6582C20.1447 25.6582 19.9902 25.6598 19.835 25.6632C9.19303 25.8958 0.143815 35.0264 0.00184618 45.6701C-0.149232 57.0022 8.99143 66.2357 20.2886 66.2357C29.0451 66.2357 36.5048 60.6881 39.3471 52.9159C40.0908 50.8823 42.2951 49.7922 44.3801 50.3769C44.4061 50.3842 44.4322 50.3916 44.4584 50.3988C46.366 50.9254 47.6308 52.7431 47.4748 54.7161C47.2656 57.3601 47.5746 60.0881 48.4713 62.7592C51.9983 73.2667 63.4567 79.0331 73.9935 75.5948C84.772 72.0774 90.5587 60.438 86.9044 49.7443Z"
        fill="url(#paint0_linear_2042_6599)"
      />
      <path
        opacity="0.1"
        d="M75.7969 17.8784H57.5781C57.5778 10.3797 51.4989 4.30078 44 4.30078C36.5011 4.30078 30.4222 10.3797 30.4219 17.8784H12.2031C10.78 17.8784 9.625 19.0334 9.625 20.4565V64.1557C9.625 65.5789 10.78 66.7339 12.2031 66.7339H75.7969C77.22 66.7339 78.375 65.5789 78.375 64.1557V20.4565C78.375 19.0334 77.22 17.8784 75.7969 17.8784Z"
        fill="#2626BC"
      />
      <path
        d="M53.747 82.84H34.2528L38.1513 67.3877H49.8486L53.747 82.84Z"
        fill="#9FB0FE"
      />
      <path
        d="M51.2064 72.7677H36.7935L38.1513 67.3877H49.8486L51.2064 72.7677Z"
        fill="#8399FE"
      />
      <path
        d="M78.375 24.0654V67.7647C78.375 69.1878 77.22 70.3428 75.7969 70.3428H12.2031C10.78 70.3428 9.625 69.1878 9.625 67.7647V24.0654C9.625 22.6423 10.78 21.4873 12.2031 21.4873H75.7969C77.22 21.4873 78.375 22.6423 78.375 24.0654Z"
        fill="white"
      />
      <path
        d="M57.5781 24.4161C57.5781 31.915 51.4989 37.9942 44 37.9942C36.5011 37.9942 30.4219 31.915 30.4219 24.4161C30.4219 23.4106 30.5319 22.4292 30.7398 21.4873H57.2619C57.4681 22.4292 57.5781 23.4106 57.5781 24.4161Z"
        fill="#D2DEFF"
      />
      <path
        d="M78.375 24.0654V29.7682H9.625V24.0654C9.625 22.6423 10.78 21.4873 12.2031 21.4873H75.7969C77.22 21.4873 78.375 22.6423 78.375 24.0654Z"
        fill="#6583FE"
      />
      <path
        d="M57.5781 24.4161C57.5781 26.317 57.188 28.1268 56.4816 29.7682H31.5184C30.812 28.1268 30.4219 26.317 30.4219 24.4161C30.4219 23.4106 30.5319 22.4292 30.7398 21.4873H57.2619C57.4681 22.4292 57.5781 23.4106 57.5781 24.4161Z"
        fill="#4369FD"
      />
      <path
        d="M78.375 30.627H55C54.5255 30.627 54.1406 30.2421 54.1406 29.7676C54.1406 29.293 54.5255 28.9082 55 28.9082H78.375C78.8495 28.9082 79.2344 29.293 79.2344 29.7676C79.2344 30.2421 78.8495 30.627 78.375 30.627Z"
        fill="#2626BC"
      />
      <path
        d="M32.8854 30.627H9.625C9.15045 30.627 8.76562 30.2421 8.76562 29.7676C8.76562 29.293 9.15045 28.9082 9.625 28.9082H32.8854C33.3599 28.9082 33.7447 29.293 33.7447 29.7676C33.7447 30.2421 33.3601 30.627 32.8854 30.627Z"
        fill="#2626BC"
      />
      <path
        d="M52.6245 79.2529C52.2402 79.2529 51.8902 78.9932 51.7919 78.6036L49.8381 70.8589C49.722 70.3986 50.0008 69.9315 50.4611 69.8155C50.9209 69.6991 51.3885 69.9784 51.5046 70.4385L53.4584 78.1832C53.5745 78.6435 53.2957 79.1106 52.8354 79.2266C52.7649 79.2445 52.6939 79.2529 52.6245 79.2529Z"
        fill="#2626BC"
      />
      <path
        d="M34.2535 83.6996C34.1839 83.6996 34.1133 83.6912 34.0426 83.6733C33.5824 83.5571 33.3036 83.0899 33.4196 82.6298L36.4955 70.4384C36.6115 69.9781 37.0788 69.6993 37.5389 69.8154C37.9992 69.9315 38.278 70.3987 38.162 70.8588L35.0861 83.0504C34.9878 83.4401 34.6378 83.6996 34.2535 83.6996Z"
        fill="#2626BC"
      />
      <path
        d="M9.625 61.6826V67.7653C9.625 69.1891 10.7793 70.3434 12.2031 70.3434H75.7969C77.2207 70.3434 78.375 69.1891 78.375 67.7653V61.6826H9.625Z"
        fill="#B7C5FF"
      />
      <path
        d="M78.28 62.542H9.625C9.15045 62.542 8.76562 62.1572 8.76562 61.6826C8.76562 61.2081 9.15045 60.8232 9.625 60.8232H78.28C78.7545 60.8232 79.1393 61.2081 79.1393 61.6826C79.1393 62.1572 78.7545 62.542 78.28 62.542Z"
        fill="#2626BC"
      />
      <path
        d="M57.0512 83.6982H30.9489C30.4743 83.6982 30.0895 83.3134 30.0895 82.8389C30.0895 82.3643 30.4743 81.9795 30.9489 81.9795H57.0512C57.5257 81.9795 57.9105 82.3643 57.9105 82.8389C57.9105 83.3134 57.5257 83.6982 57.0512 83.6982Z"
        fill="#2626BC"
      />
      <path
        d="M16.1703 26.4995C15.6957 26.4995 15.3109 26.1147 15.3109 25.6402V25.6152C15.3109 25.1407 15.6957 24.7559 16.1703 24.7559C16.6448 24.7559 17.0297 25.1407 17.0297 25.6152V25.6402C17.0297 26.1147 16.6448 26.4995 16.1703 26.4995Z"
        fill="#2626BC"
      />
      <path
        d="M19.4918 26.4995C19.0173 26.4995 18.6324 26.1147 18.6324 25.6402V25.6152C18.6324 25.1407 19.0173 24.7559 19.4918 24.7559C19.9664 24.7559 20.3512 25.1407 20.3512 25.6152V25.6402C20.3512 26.1147 19.9664 26.4995 19.4918 26.4995Z"
        fill="#2626BC"
      />
      <path
        d="M22.8131 26.4995C22.3386 26.4995 21.9537 26.1147 21.9537 25.6402V25.6152C21.9537 25.1407 22.3386 24.7559 22.8131 24.7559C23.2877 24.7559 23.6725 25.1407 23.6725 25.6152V25.6402C23.6725 26.1147 23.2878 26.4995 22.8131 26.4995Z"
        fill="#2626BC"
      />
      <path
        d="M75.7969 71.2028H12.2031C10.3077 71.2028 8.76562 69.6607 8.76562 67.7653V24.0664C8.76562 22.171 10.3077 20.6289 12.2031 20.6289H29.9922C30.4667 20.6289 30.8516 21.0137 30.8516 21.4883C30.8516 21.9628 30.4667 22.3477 29.9922 22.3477H12.2031C11.2554 22.3477 10.4844 23.1187 10.4844 24.0664V67.7653C10.4844 68.713 11.2554 69.484 12.2031 69.484H75.7969C76.7446 69.484 77.5156 68.713 77.5156 67.7653V24.0664C77.5156 23.1187 76.7446 22.3477 75.7969 22.3477H62.3117C61.8372 22.3477 61.4524 21.9628 61.4524 21.4883C61.4524 21.0137 61.8372 20.6289 62.3117 20.6289H75.7969C77.6923 20.6289 79.2344 22.171 79.2344 24.0664V67.7653C79.2344 69.6607 77.6923 71.2028 75.7969 71.2028Z"
        fill="#2626BC"
      />
      <path
        d="M35.0053 51.1495H20.9687C20.4942 51.1495 20.1094 50.7647 20.1094 50.2902C20.1094 49.8156 20.4942 49.4308 20.9687 49.4308H34.1459V44.5117C34.1459 44.0372 34.5307 43.6523 35.0053 43.6523H42.9031C43.3776 43.6523 43.7625 44.0372 43.7625 44.5117C43.7625 44.9863 43.3776 45.3711 42.9031 45.3711H35.8646V50.2902C35.8646 50.7647 35.4798 51.1495 35.0053 51.1495Z"
        fill="#2626BC"
      />
      <path
        d="M72.3594 51.149H41.089C40.6144 51.149 40.2296 50.7642 40.2296 50.2896C40.2296 49.8151 40.6144 49.4302 41.089 49.4302H71.5V35.7158C71.5 35.2413 71.8849 34.8564 72.3594 34.8564C72.834 34.8564 73.2188 35.2413 73.2188 35.7158V50.2896C73.2188 50.7642 72.834 51.149 72.3594 51.149Z"
        fill="#2626BC"
      />
      <path
        d="M52.5937 62.5424C52.1192 62.5424 51.7344 62.1576 51.7344 61.683V56.9791H35.0052C34.5307 56.9791 34.1459 56.5943 34.1459 56.1198V49.0703C34.1459 48.5958 34.5307 48.2109 35.0052 48.2109C35.4798 48.2109 35.8646 48.5958 35.8646 49.0703V55.2604H52.5937C53.0683 55.2604 53.4531 55.6452 53.4531 56.1198V61.683C53.4531 62.1577 53.0683 62.5424 52.5937 62.5424Z"
        fill="#2626BC"
      />
      <path
        d="M78.28 56.9795H58.4375C57.963 56.9795 57.5781 56.5947 57.5781 56.1201C57.5781 55.6456 57.963 55.2607 58.4375 55.2607H78.28C78.7545 55.2607 79.1393 55.6456 79.1393 56.1201C79.1393 56.5947 78.7545 56.9795 78.28 56.9795Z"
        fill="#2626BC"
      />
      <path
        d="M29.3906 57.2673H15.2396C14.7651 57.2673 14.3802 56.8825 14.3802 56.4079V48.0801C14.3802 47.6055 14.7651 47.2207 15.2396 47.2207C15.7142 47.2207 16.099 47.6055 16.099 48.0801V55.5486H29.3906C29.8652 55.5486 30.25 55.9334 30.25 56.4079C30.25 56.8825 29.8652 57.2673 29.3906 57.2673Z"
        fill="#2626BC"
      />
      <path
        d="M24.1198 62.5426C23.6452 62.5426 23.2604 62.1578 23.2604 61.6832V56.4082C23.2604 55.9337 23.6452 55.5488 24.1198 55.5488C24.5943 55.5488 24.9791 55.9337 24.9791 56.4082V61.6832C24.9791 62.1579 24.5945 62.5426 24.1198 62.5426Z"
        fill="#2626BC"
      />
      <path
        d="M28.5885 45.3173H20.9688C20.4942 45.3173 20.1094 44.9325 20.1094 44.4579V29.9912C20.1094 29.5167 20.4942 29.1318 20.9688 29.1318C21.4433 29.1318 21.8281 29.5167 21.8281 29.9912V43.5986H28.5885C29.063 43.5986 29.4479 43.9834 29.4479 44.4579C29.4479 44.9325 29.0632 45.3173 28.5885 45.3173Z"
        fill="#2626BC"
      />
      <path
        d="M15.2396 43.3916H9.625C9.15045 43.3916 8.76562 43.0068 8.76562 42.5322C8.76562 42.0577 9.15045 41.6729 9.625 41.6729H14.3803V36.667C14.3803 36.1924 14.7651 35.8076 15.2396 35.8076C15.7142 35.8076 16.099 36.1924 16.099 36.667V42.5322C16.099 43.0068 15.7142 43.3916 15.2396 43.3916Z"
        fill="#2626BC"
      />
      <path
        d="M49.5 45.3704C49.2726 45.3704 49.0543 45.2803 48.8933 45.1196C48.7315 44.9584 48.6406 44.7394 48.6406 44.511V38.6142C48.6406 38.1396 49.0255 37.7548 49.5 37.7548H60.3854C60.8599 37.7548 61.2447 38.1396 61.2447 38.6142C61.2447 39.0887 60.8599 39.4735 60.3854 39.4735H50.3594V43.6491L66 43.6011V29.7676C66 29.293 66.3848 28.9082 66.8594 28.9082C67.3339 28.9082 67.7188 29.293 67.7188 29.7676V44.4579C67.7188 44.9314 67.3356 45.3159 66.862 45.3173L49.5026 45.3704C49.5017 45.3704 49.5009 45.3704 49.5 45.3704Z"
        fill="#2626BC"
      />
      <path
        d="M49.5 39.4736H27.3281C26.8536 39.4736 26.4688 39.0888 26.4688 38.6143C26.4688 38.1397 26.8536 37.7549 27.3281 37.7549H49.5C49.9745 37.7549 50.3594 38.1397 50.3594 38.6143C50.3594 39.0888 49.9745 39.4736 49.5 39.4736Z"
        fill="#2626BC"
      />
      <path
        d="M44 35.0664C51.499 35.0664 57.5781 28.9873 57.5781 21.4883C57.5781 13.9893 51.499 7.91016 44 7.91016C36.501 7.91016 30.4219 13.9893 30.4219 21.4883C30.4219 28.9873 36.501 35.0664 44 35.0664Z"
        fill="#02FFB3"
      />
      <path
        d="M44 35.9258C36.0391 35.9258 29.5625 29.4492 29.5625 21.4883C29.5625 13.5274 36.0391 7.05078 44 7.05078C51.9609 7.05078 58.4375 13.5274 58.4375 21.4883C58.4375 29.449 51.9609 35.9258 44 35.9258ZM44 8.76936C36.9868 8.76936 31.2812 14.4749 31.2812 21.4881C31.2812 28.5013 36.9868 34.2069 44 34.2069C51.0132 34.2069 56.7188 28.5013 56.7188 21.4881C56.7188 14.4749 51.0132 8.76936 44 8.76936Z"
        fill="#2626BC"
      />
      <path
        d="M51.1109 28.6091C55.042 24.678 55.042 18.3044 51.1109 14.3733C47.1798 10.4422 40.8062 10.4422 36.8751 14.3733C32.944 18.3044 32.944 24.678 36.8751 28.6091C40.8062 32.5402 47.1798 32.5402 51.1109 28.6091Z"
        fill="#DCFDEE"
      />
      <path
        d="M39.9813 24.4162C39.7615 24.4162 39.5413 24.3323 39.3737 24.1645L37.3052 22.096C36.9695 21.7603 36.9695 21.2162 37.3052 20.8807L39.3737 18.8122C39.7091 18.4767 40.2536 18.4767 40.5889 18.8122C40.9246 19.1478 40.9246 19.692 40.5889 20.0275L39.1281 21.4884L40.5889 22.9494C40.9246 23.285 40.9246 23.8292 40.5889 24.1647C40.4213 24.3321 40.2011 24.4162 39.9813 24.4162Z"
        fill="#01ECA5"
      />
      <path
        d="M48.0186 24.4163C47.7988 24.4163 47.5786 24.3325 47.4111 24.1647C47.0754 23.829 47.0754 23.2849 47.4111 22.9494L48.8718 21.4884L47.4111 20.0275C47.0754 19.6918 47.0754 19.1477 47.4111 18.8122C47.7464 18.4767 48.2909 18.4767 48.6262 18.8122L50.6947 20.8807C51.0304 21.2164 51.0304 21.7605 50.6947 22.096L48.6262 24.1645C48.4585 24.3323 48.2385 24.4163 48.0186 24.4163Z"
        fill="#01ECA5"
      />
      <path
        d="M42.831 24.4168C42.6878 24.4168 42.5428 24.381 42.4094 24.3055C41.996 24.0721 41.8501 23.5479 42.0835 23.1346L44.4196 18.9975C44.6529 18.5843 45.1773 18.4384 45.5905 18.6717C46.0038 18.9051 46.1497 19.4293 45.9163 19.8426L43.5802 23.9797C43.4222 24.2595 43.1309 24.4168 42.831 24.4168Z"
        fill="#01ECA5"
      />
      <path
        d="M64.3728 81.1477C64.1449 81.1477 63.9262 81.0571 63.765 80.896L61.8703 79.0013C61.7091 78.8401 61.6187 78.6216 61.6187 78.3935C61.6187 78.1655 61.7092 77.947 61.8703 77.7858L63.765 75.8912C64.1009 75.5557 64.6448 75.5559 64.9803 75.8912L66.8749 77.7858C67.2106 78.1215 67.2106 78.6656 66.8749 79.0011L64.9803 80.8959C64.8193 81.0571 64.6007 81.1477 64.3728 81.1477ZM63.6933 78.3935L64.3728 79.073L65.0522 78.3935L64.3728 77.7141L63.6933 78.3935Z"
        fill="#6583FE"
      />
      <path
        d="M26.2043 79.4395C25.7298 79.4395 25.345 79.0546 25.345 78.5801C25.345 78.0113 24.8823 77.5488 24.3137 77.5488C23.8392 77.5488 23.4543 77.164 23.4543 76.6895C23.4543 76.2149 23.8392 75.8301 24.3137 75.8301C24.8825 75.8301 25.345 75.3674 25.345 74.7988C25.345 74.3243 25.7298 73.9395 26.2043 73.9395C26.6789 73.9395 27.0637 74.3243 27.0637 74.7988C27.0637 75.3676 27.5262 75.8301 28.095 75.8301C28.5695 75.8301 28.9543 76.2149 28.9543 76.6895C28.9543 77.164 28.5695 77.5488 28.095 77.5488C27.5262 77.5488 27.0637 78.0115 27.0637 78.5801C27.0637 79.0546 26.6791 79.4395 26.2043 79.4395Z"
        fill="#97FFD2"
      />
      <path
        d="M61.4181 13.2168C60.9435 13.2168 60.5587 12.832 60.5587 12.3574C60.5587 11.7887 60.0962 11.3262 59.5275 11.3262C59.0529 11.3262 58.6681 10.9413 58.6681 10.4668C58.6681 9.99225 59.0529 9.60742 59.5275 9.60742C60.0962 9.60742 60.5587 9.14473 60.5587 8.57617C60.5587 8.10163 60.9435 7.7168 61.4181 7.7168C61.8926 7.7168 62.2775 8.10163 62.2775 8.57617C62.2775 9.14491 62.7402 9.60742 63.3087 9.60742C63.7833 9.60742 64.1681 9.99225 64.1681 10.4668C64.1681 10.9413 63.7833 11.3262 63.3087 11.3262C62.74 11.3262 62.2775 11.7889 62.2775 12.3574C62.2775 12.8321 61.8928 13.2168 61.4181 13.2168Z"
        fill="#FF7EB8"
      />
      <path
        d="M70.8125 16.3506C70.338 16.3506 69.9531 15.9658 69.9531 15.4912C69.9531 14.9225 69.4904 14.46 68.9219 14.46C68.4473 14.46 68.0625 14.0751 68.0625 13.6006C68.0625 13.126 68.4473 12.7412 68.9219 12.7412C69.4906 12.7412 69.9531 12.2785 69.9531 11.71C69.9531 11.2354 70.338 10.8506 70.8125 10.8506C71.287 10.8506 71.6719 11.2354 71.6719 11.71C71.6719 12.2787 72.1344 12.7412 72.7031 12.7412C73.1777 12.7412 73.5625 13.126 73.5625 13.6006C73.5625 14.0751 73.1777 14.46 72.7031 14.46C72.1344 14.46 71.6719 14.9226 71.6719 15.4912C71.6719 15.9658 71.2872 16.3506 70.8125 16.3506Z"
        fill="#FF7EB8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2042_6599"
          x1="4.242"
          y1="23.3206"
          x2="90.2294"
          y2="65.3835"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#FFDBED" />
          <stop offset="0.9993" stopColor="#D2DEFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IllustrationDevelopment;
