import { SVGProps } from "types";

const BoxHashtag = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5445)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M11.0002 16C10.7235 16 10.5 15.7758 10.5 15.4998V10.5002C10.5 10.2242 10.7235 10 11.0002 10C11.277 10 11.5005 10.2242 11.5005 10.5002V15.5005C11.4997 15.7765 11.2762 16 11.0002 16Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M12.9998 16C12.723 16 12.4995 15.7758 12.4995 15.4998V10.5002C12.4995 10.2242 12.723 10 12.9998 10C13.2765 10 13.5 10.2242 13.5 10.5002V15.5005C13.5 15.7765 13.2765 16 12.9998 16Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M14.4998 12.5005H9.4995C9.2235 12.5005 9 12.2762 9 12.0002C9 11.7242 9.2235 11.5 9.50025 11.5H14.5005C14.7765 11.5 15 11.7242 15 12.0002C15 12.2762 14.7765 12.5005 14.4998 12.5005Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M14.4998 14.4998H9.49952C9.22277 14.4998 8.99927 14.2755 8.99927 13.9995C8.99927 13.7235 9.22352 13.5 9.50027 13.5H14.5005C14.7765 13.5 15 13.7242 15 14.0002C15 14.2762 14.7765 14.4998 14.4998 14.4998Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5445">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxHashtag;
