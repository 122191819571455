import { SVGProps } from "types";

const BoxMicrochip = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5441)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M17.0002 12.5002C16.5817 12.5002 16.2233 12.759 16.0748 13.125H15V10.875H16.0748C16.2233 11.241 16.5817 11.4998 17.0002 11.4998C17.5515 11.4998 18 11.0512 18 10.5C18 9.94875 17.5515 9.50025 17.0002 9.50025C16.5817 9.50025 16.2233 9.759 16.0748 10.125H14.9468C14.8103 9.60075 14.3993 9.18975 13.875 9.05325V7.92525C14.241 7.77675 14.4998 7.41825 14.4998 6.99975C14.4998 6.4485 14.0513 6 13.5 6C12.9488 6 12.5002 6.4485 12.5002 6.99975C12.5002 7.41825 12.7597 7.77675 13.125 7.92525V9H10.875V7.92525C11.241 7.77675 11.4998 7.41825 11.4998 6.99975C11.4998 6.4485 11.0512 6 10.5 6C9.94875 6 9.50025 6.4485 9.50025 6.99975C9.50025 7.41825 9.75975 7.77675 10.125 7.92525V9.05325C9.60075 9.18975 9.18975 9.60075 9.05325 10.125H7.92525C7.77675 9.759 7.41825 9.50025 6.99975 9.50025C6.4485 9.50025 6 9.94875 6 10.5C6 11.0512 6.4485 11.4998 6.99975 11.4998C7.41825 11.4998 7.77675 11.241 7.92525 10.875H9V13.125H7.92525C7.77675 12.759 7.41825 12.5002 6.99975 12.5002C6.4485 12.5002 6 12.9488 6 13.5C6 14.0513 6.4485 14.4998 6.99975 14.4998C7.41825 14.4998 7.77675 14.241 7.92525 13.875H9.05325C9.18975 14.3993 9.60075 14.8103 10.125 14.9468V16.0748C9.759 16.2233 9.50025 16.5817 9.50025 17.0002C9.50025 17.5515 9.94875 18 10.5 18C11.0512 18 11.4998 17.5515 11.4998 17.0002C11.4998 16.5817 11.2403 16.2233 10.875 16.0748V15H13.125V16.0748C12.759 16.2233 12.5002 16.5817 12.5002 17.0002C12.5002 17.5515 12.9488 18 13.5 18C14.0513 18 14.4998 17.5515 14.4998 17.0002C14.4998 16.5817 14.2402 16.2233 13.875 16.0748V14.9468C14.3993 14.8103 14.8103 14.3993 14.9468 13.875H16.0748C16.2233 14.241 16.5817 14.4998 17.0002 14.4998C17.5515 14.4998 18 14.0513 18 13.5C18 12.9488 17.5515 12.5002 17.0002 12.5002ZM14.0002 13.5C14.0002 13.776 13.776 14.0002 13.5 14.0002H10.5C10.224 14.0002 9.99975 13.776 9.99975 13.5V10.5C9.99975 10.224 10.224 9.99975 10.5 9.99975H13.5C13.776 9.99975 14.0002 10.224 14.0002 10.5V13.5Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5441">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxMicrochip;
