import { SVGProps } from "types";

const IllustrationOnline = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6606)">
        <path
          d="M70.1644 26.086C66.0915 24.4986 62.6505 21.602 60.5461 17.7706C57.0072 11.3275 50.1597 6.95996 42.2893 6.95996C34.1201 6.95996 27.0533 11.6654 23.6449 18.5132C21.5573 22.7072 18.0049 26.0001 13.6606 27.7534C7.24128 30.3442 1.8523 36.2653 0.142148 45.5222C-0.0455393 46.538 -0.0482893 47.5823 0.139398 48.5981C2.79452 62.962 14.3017 69.2978 24.7749 67.6178C30.6403 66.6769 36.5779 68.6334 40.9255 72.6815C49.7657 80.913 63.9368 83.4937 77.6935 74.6701C79.3052 73.6365 81.4674 71.4674 82.5002 69.855C94.3537 51.3494 85.5731 32.0917 70.1644 26.086Z"
          fill="url(#paint0_linear_2042_6606)"
        />
        <path
          opacity="0.1"
          d="M68.1072 9.54566V73.9895C68.1072 75.4142 66.9491 76.5723 65.5244 76.5723H22.476C21.0513 76.5723 19.8932 75.4142 19.8932 73.9895V9.54566C19.8932 8.12098 21.0513 6.96289 22.476 6.96289H65.5246C66.9493 6.96289 68.1072 8.12081 68.1072 9.54566Z"
          fill="#2626BC"
        />
        <path
          d="M68.1073 13.1555V77.5984C68.1073 79.0232 66.9489 80.1816 65.524 80.1816H22.4762C21.0514 80.1816 19.8929 79.0232 19.8929 77.5984V13.1555C19.8929 11.7307 21.0514 10.5723 22.4762 10.5723H65.524C66.9489 10.5723 68.1073 11.7307 68.1073 13.1555Z"
          fill="white"
        />
        <path
          d="M68.1073 13.1555V31.3949C66.8458 32.5791 65.1476 33.3045 63.2862 33.3045C61.4231 33.3045 59.7267 32.5774 58.4634 31.3932C57.2019 32.5774 55.5054 33.3045 53.6423 33.3045C51.7792 33.3045 50.0845 32.5774 48.8212 31.3932C47.5597 32.5774 45.8633 33.3045 44.0001 33.3045C42.137 33.3045 40.4406 32.5774 39.179 31.3932C37.9158 32.5774 36.2211 33.3045 34.3579 33.3045C32.4948 33.3045 30.7984 32.5774 29.5369 31.3932C28.2736 32.5774 26.5772 33.3045 24.714 33.3045C22.8526 33.3045 21.1545 32.5791 19.8929 31.3949V13.1555C19.8929 11.7307 21.0514 10.5723 22.4762 10.5723H65.524C66.9489 10.5723 68.1073 11.7307 68.1073 13.1555Z"
          fill="#D2DEFF"
        />
        <path
          d="M31.969 18.8672H56.0315V59.5672H31.969V18.8672Z"
          fill="#D2DEFF"
        />
        <path
          d="M56.0315 18.8672V32.887C55.2856 33.1569 54.4812 33.3047 53.6424 33.3047C51.7793 33.3047 50.0846 32.5777 48.8213 31.3934C47.5598 32.5777 45.8634 33.3047 44.0002 33.3047C42.1371 33.3047 40.4407 32.5777 39.1791 31.3934C37.9159 32.5777 36.2212 33.3047 34.3581 33.3047C33.5193 33.3047 32.7149 33.1569 31.969 32.887V18.8672H56.0315Z"
          fill="#B7C5FF"
        />
        <path
          d="M24.7148 31.0703C22.0521 31.0703 19.8936 28.9117 19.8936 26.249V18.8672H29.5363V26.249C29.5363 28.9117 27.3777 31.0703 24.7148 31.0703Z"
          fill="#FF7EB8"
        />
        <path
          d="M34.3575 31.0703C31.6948 31.0703 29.5363 28.9117 29.5363 26.249V18.8672H39.179V26.249C39.179 28.9117 37.0204 31.0703 34.3575 31.0703Z"
          fill="#97FFD2"
        />
        <path
          d="M44.0002 31.0703C41.3375 31.0703 39.179 28.9117 39.179 26.249V18.8672H48.8217V26.249C48.8215 28.9117 46.6629 31.0703 44.0002 31.0703Z"
          fill="#FF7EB8"
        />
        <path
          d="M53.6429 31.0703C50.9802 31.0703 48.8217 28.9117 48.8217 26.249V18.8672H58.4644V26.249C58.4642 28.9117 56.3056 31.0703 53.6429 31.0703Z"
          fill="#97FFD2"
        />
        <path
          d="M63.2856 31.0703C60.6229 31.0703 58.4644 28.9117 58.4644 26.249V18.8672H68.1071V26.249C68.1069 28.9117 65.9483 31.0703 63.2856 31.0703Z"
          fill="#FF7EB8"
        />
        <path
          d="M68.1068 18.8679V13.1562C68.1068 11.7298 66.9504 10.5732 65.5239 10.5732H22.4762C21.0499 10.5732 19.8933 11.7296 19.8933 13.1562V18.8679H68.1068Z"
          fill="#6583FE"
        />
        <path
          d="M25.5916 14.708V14.7331C25.5916 15.2078 25.9762 15.5925 26.4509 15.5925C26.9256 15.5925 27.3103 15.2078 27.3103 14.7331V14.708C27.3103 14.2333 26.9256 13.8486 26.4509 13.8486C25.9762 13.8486 25.5916 14.2335 25.5916 14.708Z"
          fill="#2626BC"
        />
        <path
          d="M28.9189 14.708V14.7331C28.9189 15.2078 29.3036 15.5925 29.7783 15.5925C30.253 15.5925 30.6377 15.2078 30.6377 14.7331V14.708C30.6377 14.2333 30.253 13.8486 29.7783 13.8486C29.3036 13.8486 28.9189 14.2335 28.9189 14.708Z"
          fill="#2626BC"
        />
        <path
          d="M32.2465 14.708V14.7331C32.2465 15.2078 32.6311 15.5925 33.1058 15.5925C33.5806 15.5925 33.9652 15.2078 33.9652 14.7331V14.708C33.9652 14.2333 33.5806 13.8486 33.1058 13.8486C32.6311 13.8486 32.2465 14.2335 32.2465 14.708Z"
          fill="#2626BC"
        />
        <path
          d="M65.5239 9.71387H22.4764C20.5784 9.71387 19.0343 11.258 19.0343 13.156V18.8678C19.0343 19.3425 19.419 19.7271 19.8937 19.7271C19.9238 19.7271 19.9521 19.7213 19.9813 19.7182C20.0106 19.7213 20.0389 19.7271 20.069 19.7271H63.4031C63.8779 19.7271 64.2625 19.3425 64.2625 18.8678C64.2625 18.393 63.8779 18.0084 63.4031 18.0084H20.7529V13.156C20.7529 12.2057 21.526 11.4326 22.4763 11.4326H65.5239C66.4742 11.4326 67.2473 12.2057 67.2473 13.156V77.5983C67.2473 78.5486 66.4742 79.3217 65.5239 79.3217H22.4764C21.5261 79.3217 20.7531 78.5486 20.7531 77.5983V23.6012C20.7531 23.1265 20.3684 22.7418 19.8937 22.7418C19.419 22.7418 19.0343 23.1265 19.0343 23.6012V77.5983C19.0343 79.4963 20.5784 81.0405 22.4764 81.0405H65.5241C67.4221 81.0405 68.9662 79.4963 68.9662 77.5983V13.156C68.9662 11.258 67.4219 9.71387 65.5239 9.71387Z"
          fill="#2626BC"
        />
        <path
          d="M51.6106 39.0876C51.4474 38.8925 51.2061 38.78 50.9517 38.78H40.882L40.3283 37.0667C40.2136 36.7121 39.8833 36.4717 39.5105 36.4717H37.0487C36.574 36.4717 36.1893 36.8563 36.1893 37.3311C36.1893 37.8058 36.574 38.1904 37.0487 38.1904H38.8852L40.9718 44.645H40.8239C40.3492 44.645 39.9646 45.0297 39.9646 45.5044C39.9646 45.9791 40.3492 46.3638 40.8239 46.3638H49.9048C50.3212 46.3638 50.6775 46.0654 50.7508 45.6555L51.7978 39.7904C51.8425 39.54 51.7739 39.2827 51.6106 39.0876ZM49.1851 44.645H42.7782L41.4377 40.4987H49.9254L49.1851 44.645Z"
          fill="#FF5BA8"
        />
        <path
          d="M42.6642 51.6418C41.431 51.6418 40.4277 50.6386 40.4277 49.4054C40.4277 48.1722 41.431 47.1689 42.6642 47.1689C43.8974 47.1689 44.9008 48.1722 44.9008 49.4054C44.9008 50.6386 43.8972 51.6418 42.6642 51.6418ZM42.6642 48.888C42.3787 48.888 42.1465 49.1202 42.1465 49.4057C42.1465 49.6912 42.3787 49.9234 42.6642 49.9234C42.9497 49.9234 43.182 49.6912 43.182 49.4057C43.182 49.1202 42.9497 48.888 42.6642 48.888Z"
          fill="#FF5BA8"
        />
        <path
          d="M49.0813 51.6418C47.8481 51.6418 46.8448 50.6386 46.8448 49.4054C46.8448 48.1722 47.8481 47.1689 49.0813 47.1689C50.3145 47.1689 51.3177 48.1722 51.3177 49.4054C51.3177 50.6386 50.3145 51.6418 49.0813 51.6418ZM49.0813 48.888C48.7958 48.888 48.5636 49.1202 48.5636 49.4057C48.5636 49.6912 48.7958 49.9234 49.0813 49.9234C49.3668 49.9234 49.599 49.6912 49.599 49.4057C49.599 49.1202 49.3669 48.888 49.0813 48.888Z"
          fill="#FF5BA8"
        />
        <path
          d="M55.5159 63.135H32.4846C32.1998 63.135 31.969 62.9042 31.969 62.6194V56.5166C31.969 56.2318 32.1998 56.001 32.4846 56.001H55.5159C55.8007 56.001 56.0315 56.2318 56.0315 56.5166V62.6194C56.0315 62.9042 55.8007 63.135 55.5159 63.135Z"
          fill="#9FB0FE"
        />
        <path
          d="M40.5505 69.8666H56.0342C56.5211 69.8666 56.9157 69.472 56.9157 68.9851C56.9157 68.4981 56.5211 68.1035 56.0342 68.1035H40.5505C40.0636 68.1035 39.6689 68.4981 39.6689 68.9851C39.6689 69.472 40.0636 69.8666 40.5505 69.8666Z"
          fill="#6583FE"
        />
        <path
          d="M31.9661 69.8666H36.1953C36.6822 69.8666 37.0768 69.472 37.0768 68.9851C37.0768 68.4981 36.6822 68.1035 36.1953 68.1035H31.9661C31.4792 68.1035 31.0846 68.4981 31.0846 68.9851C31.0846 69.472 31.4794 69.8666 31.9661 69.8666Z"
          fill="#FFA8CF"
        />
        <path
          d="M31.9661 73.3637H56.034C56.5209 73.3637 56.9155 72.9691 56.9155 72.4821C56.9155 71.9952 56.5209 71.6006 56.034 71.6006H31.9661C31.4792 71.6006 31.0846 71.9952 31.0846 72.4821C31.0846 72.9691 31.4794 73.3637 31.9661 73.3637Z"
          fill="#B7C5FF"
        />
        <path
          d="M14.0053 24.6717C13.7854 24.6717 13.5653 24.5878 13.3977 24.4199L11.5081 22.5303C11.1724 22.1948 11.1724 21.6507 11.5081 21.3152L13.3977 19.4256C13.733 19.0899 14.2775 19.0899 14.6128 19.4256L16.5024 21.3152C16.8381 21.6507 16.8381 22.1948 16.5024 22.5303L14.613 24.4199C14.4453 24.5878 14.2253 24.6717 14.0053 24.6717ZM13.3312 21.9228L14.0054 22.597L14.6797 21.9228L14.0054 21.2485L13.3312 21.9228Z"
          fill="#97FFD2"
        />
        <path
          d="M73.6459 49.3182C73.4261 49.3182 73.2059 49.2343 73.0383 49.0664L71.1487 47.1768C70.8131 46.8413 70.8131 46.2972 71.1487 45.9617L73.0383 44.0721C73.3737 43.7364 73.9182 43.7364 74.2535 44.0721L76.1431 45.9617C76.4788 46.2972 76.4788 46.8413 76.1431 47.1768L74.2535 49.0664C74.0859 49.2343 73.8659 49.3182 73.6459 49.3182ZM72.9718 46.5692L73.6461 47.2435L74.3204 46.5692L73.6461 45.895L72.9718 46.5692Z"
          fill="#6583FE"
        />
        <path
          d="M77.2616 59.7205C77.0418 59.7205 76.8216 59.6367 76.654 59.4688L74.7645 57.5792C74.4288 57.2437 74.4288 56.6995 74.7645 56.364L76.654 54.4744C76.9894 54.1387 77.5339 54.1387 77.8692 54.4744L79.7588 56.364C80.0945 56.6995 80.0945 57.2437 79.7588 57.5792L77.8692 59.4688C77.7016 59.6367 77.4816 59.7205 77.2616 59.7205ZM76.5875 56.9716L77.2618 57.6458L77.9361 56.9716L77.2618 56.2973L76.5875 56.9716Z"
          fill="#FF7EB8"
        />
        <path
          d="M10.7388 53.8284C10.264 53.8284 9.87939 53.4437 9.87939 52.969C9.87939 52.4008 9.41722 51.9386 8.849 51.9386C8.37428 51.9386 7.98962 51.5539 7.98962 51.0792C7.98962 50.6045 8.37428 50.2198 8.849 50.2198C9.41722 50.2198 9.87939 49.7577 9.87939 49.1895C9.87939 48.7147 10.264 48.3301 10.7388 48.3301C11.2135 48.3301 11.5981 48.7147 11.5981 49.1895C11.5981 49.7577 12.0603 50.2198 12.6285 50.2198C13.1032 50.2198 13.4879 50.6045 13.4879 51.0792C13.4879 51.5539 13.1032 51.9386 12.6285 51.9386C12.0603 51.9386 11.5981 52.4008 11.5981 52.969C11.5981 53.4435 11.2135 53.8284 10.7388 53.8284Z"
          fill="#6583FE"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6606"
          x1="5.64679"
          y1="23.3553"
          x2="89.4025"
          y2="68.2747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6606">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationOnline;
