import { IncomingMessage } from "http";
import { ParsedUrlQuery } from "querystring";
import { get, kebabCase, set } from "lodash";
import MarkdownIt from "markdown-it";
import { IconName } from "components/Icon";

export const windowIsDefined = () => typeof window !== "undefined";

export const parseNumberQueryParam = <Fallback extends number | undefined>(
  query: ParsedUrlQuery,
  key: string,
  fallback?: Fallback
): Fallback => {
  let limit = query[key] ? Number.parseInt(query[key] as string) : fallback;

  if (Number.isNaN(limit)) {
    limit = fallback;
  }

  return limit as Fallback;
};

export const parseJsonQueryParam = <T>(
  query: ParsedUrlQuery,
  key: string,
  fallback?: T
): T | undefined => {
  if (!query[key]) {
    return fallback;
  }

  try {
    return JSON.parse(query[key] as string) as T;
  } catch (error) {
    return fallback;
  }
};

export const userAgentIsMobile = (req: IncomingMessage) => {
  return req.headers["user-agent"]?.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  );
};

export const getIllustrationIconName = (
  iconSuffix?: string | null
): IconName => {
  return `illustration-${kebabCase(iconSuffix ?? "approved")}` as IconName;
};

const md = MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
});

export const renderMarkdownProperty = <T extends Record<string, unknown>>(
  data: T,
  ...paths: string[]
): T => {
  /* Deep cloning hack. */
  const result = JSON.parse(JSON.stringify(data));

  for (const path of paths) {
    const fullPath = path.split(".");

    let currentPath = fullPath[0];

    for (let index = 0; index < fullPath.length; index++) {
      const pathSegment = fullPath[index];

      if (index > 0) {
        currentPath = `${currentPath}.${pathSegment}`;
      }

      const value = get(data, currentPath);

      if (Array.isArray(value)) {
        const subPath =
          index > 0 ? path.replace(`${currentPath}.`, "") : currentPath;

        set(
          result,
          currentPath,
          value.map((item) => renderMarkdownProperty(item, subPath))
        );

        break;
      }

      if (typeof value === "string" && index === fullPath.length - 1) {
        set(result, currentPath, md.render(value));
        continue;
      }
    }
  }

  return result;
};

export const getMediaUrl = (filePath: string) => {
  return `${process.env.NEXT_PUBLIC_API_URL}${filePath}`;
};

export const limitString = (string: string, limit = 50, suffix = "...") => {
  return `${string.slice(0, limit - suffix.length)}${suffix}`;
};

export const getRichTextFirstParagraphTextContent = (htmlString: string) => {
  return (
    htmlString
      ?.split("\n")
      .at(0)
      ?.replace(/<[^>]*>/g, "") ?? ""
  );
};

export const extractPageDescription = (htmlString: string) => {
  return limitString(getRichTextFirstParagraphTextContent(htmlString));
};
