import { SVGProps } from "types";

const IllustrationLogin = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6623)">
        <path
          d="M70.1644 26.5138C66.0915 24.9263 62.6505 22.0297 60.5461 18.1983C57.0072 11.7552 50.1597 7.3877 42.2893 7.3877C34.1201 7.3877 27.0533 12.0931 23.6449 18.941C21.5573 23.1349 18.0049 26.4278 13.6606 28.1811C7.24128 30.772 1.8523 36.6931 0.142148 45.9499C-0.0455393 46.9657 -0.0482893 48.01 0.139398 49.0258C2.79452 63.3897 14.3017 69.7256 24.7749 68.0455C30.6403 67.1046 36.5779 69.0611 40.9255 73.1093C49.7657 81.3407 63.9368 83.9214 77.6935 75.0979C79.3052 74.0642 81.4674 71.8951 82.5002 70.2828C94.3537 51.777 85.5731 32.5194 70.1644 26.5138Z"
          fill="url(#paint0_linear_2042_6623)"
        />
        <path
          opacity="0.1"
          d="M78.3751 17.835V61.5342C78.3751 62.9573 77.2201 64.1123 75.797 64.1123H12.2032C10.7801 64.1123 9.62512 62.9573 9.62512 61.5342V17.835C9.62512 16.4118 10.7801 15.2568 12.2032 15.2568H75.797C77.2201 15.2568 78.3751 16.4118 78.3751 17.835Z"
          fill="#2626BC"
        />
        <path
          d="M53.7472 80.2181H34.2529L38.1514 64.7656H49.8487L53.7472 80.2181Z"
          fill="#9FB0FE"
        />
        <path
          d="M51.2065 70.1457H36.7936L38.1514 64.7656H49.8487L51.2065 70.1457Z"
          fill="#8399FE"
        />
        <path
          d="M78.3751 21.4443V65.1436C78.3751 66.5667 77.2201 67.7217 75.797 67.7217H12.2032C10.7801 67.7217 9.62512 66.5667 9.62512 65.1436V21.4443C9.62512 20.0212 10.7801 18.8662 12.2032 18.8662H75.797C77.2201 18.8662 78.3751 20.0212 78.3751 21.4443Z"
          fill="white"
        />
        <path
          d="M73.5626 59.0613H36.7814V32.6035C36.7814 32.3187 37.0122 32.0879 37.297 32.0879H73.047C73.3318 32.0879 73.5626 32.3187 73.5626 32.6035V59.0613Z"
          fill="#D2DEFF"
        />
        <path
          d="M73.5626 35.7157H36.7814V32.6035C36.7814 32.3187 37.0122 32.0879 37.297 32.0879H73.047C73.3318 32.0879 73.5626 32.3187 73.5626 32.6035V35.7157Z"
          fill="#02FFB3"
        />
        <path
          d="M78.3751 27.1456V21.4443C78.3751 20.0205 77.2208 18.8662 75.797 18.8662H12.2032C10.7794 18.8662 9.62512 20.0205 9.62512 21.4443V27.1456H78.3751Z"
          fill="#FF7EB8"
        />
        <path
          d="M9.62512 59.0605V65.1432C9.62512 66.567 10.7794 67.7213 12.2032 67.7213H75.797C77.2208 67.7213 78.3751 66.567 78.3751 65.1432V59.0605H9.62512Z"
          fill="#B7C5FF"
        />
        <path
          d="M16.1704 22.1338C15.6959 22.1338 15.311 22.5184 15.311 22.9932V23.0181C15.311 23.4928 15.6959 23.8775 16.1704 23.8775C16.645 23.8775 17.0298 23.4928 17.0298 23.0181V22.9932C17.0298 22.5184 16.645 22.1338 16.1704 22.1338Z"
          fill="#2626BC"
        />
        <path
          d="M19.4919 22.1338C19.0174 22.1338 18.6326 22.5184 18.6326 22.9932V23.0181C18.6326 23.4928 19.0174 23.8775 19.4919 23.8775C19.9665 23.8775 20.3513 23.4928 20.3513 23.0181V22.9932C20.3513 22.5184 19.9665 22.1338 19.4919 22.1338Z"
          fill="#2626BC"
        />
        <path
          d="M22.8132 22.1338C22.3387 22.1338 21.9539 22.5184 21.9539 22.9932V23.0181C21.9539 23.4928 22.3387 23.8775 22.8132 23.8775C23.2878 23.8775 23.6726 23.4928 23.6726 23.0181V22.9932C23.6726 22.5184 23.288 22.1338 22.8132 22.1338Z"
          fill="#2626BC"
        />
        <path
          d="M75.797 18.0068H12.2032C10.3078 18.0068 8.76575 19.5489 8.76575 21.4443V27.1458C8.76575 27.6205 9.15058 28.0051 9.62512 28.0051H77.5157V58.2012H10.4845V31.8792C10.4845 31.4045 10.0997 31.0198 9.62512 31.0198C9.15058 31.0198 8.76575 31.4045 8.76575 31.8792V65.143C8.76575 67.0385 10.3078 68.5805 12.2032 68.5805H36.3025L33.5835 79.3573H30.949C30.4744 79.3573 30.0896 79.7419 30.0896 80.2167C30.0896 80.6914 30.4744 81.076 30.949 81.076H34.2529C34.6467 81.076 34.9899 80.8086 35.0862 80.427L38.0751 68.5805H49.9248L52.6435 79.3573L38.9864 79.3583C38.5118 79.3583 38.127 79.743 38.127 80.2177C38.127 80.6924 38.5118 81.0771 38.9864 81.0771L57.0511 81.076C57.5256 81.076 57.9105 80.6914 57.9105 80.2167C57.9105 79.7419 57.5256 79.3573 57.0511 79.3573H54.4161L51.6974 68.5805H75.797C77.6924 68.5805 79.2345 67.0385 79.2345 65.143V21.4443C79.2345 19.5489 77.6924 18.0068 75.797 18.0068ZM10.4845 26.2864V21.4443C10.4845 20.4966 11.2555 19.7256 12.2032 19.7256H75.797C76.7447 19.7256 77.5157 20.4966 77.5157 21.4443V26.2864H10.4845ZM75.797 66.862H12.2032C11.2555 66.862 10.4845 66.0909 10.4845 65.1432V59.9199H77.5157V65.1432C77.5157 66.0908 76.7447 66.862 75.797 66.862Z"
          fill="#2626BC"
        />
        <path
          d="M31.3917 39.9995C32.0014 36.2386 29.4467 32.6954 25.6857 32.0857C21.9247 31.476 18.3816 34.0307 17.7719 37.7917C17.1622 41.5527 19.7169 45.0958 23.4779 45.7055C27.2388 46.3152 30.782 43.7605 31.3917 39.9995Z"
          fill="#02FFB3"
        />
        <path
          d="M27.445 37.9021C27.445 36.3277 26.164 35.0469 24.5896 35.0469C23.0152 35.0469 21.7343 36.3277 21.7343 37.9021C21.7343 38.6786 22.0467 39.3828 22.5515 39.898C22.0469 40.4133 21.7343 41.1177 21.7343 41.894C21.7343 42.3688 22.1191 42.7534 22.5936 42.7534C23.0682 42.7534 23.453 42.3688 23.453 41.894C23.453 41.2674 23.9628 40.7574 24.5896 40.7574C25.2164 40.7574 25.7262 41.2674 25.7262 41.894C25.7262 42.3688 26.111 42.7534 26.5856 42.7534C27.0601 42.7534 27.445 42.3688 27.445 41.894C27.445 41.1175 27.1325 40.4133 26.6277 39.898C27.1325 39.3829 27.445 38.6786 27.445 37.9021ZM24.5896 36.7656C25.2163 36.7656 25.7262 37.2754 25.7262 37.9021C25.7262 38.5287 25.2163 39.0387 24.5896 39.0387C23.963 39.0387 23.453 38.5287 23.453 37.9021C23.453 37.2754 23.9628 36.7656 24.5896 36.7656Z"
          fill="white"
        />
        <path
          d="M66.2282 44.0526H44.1158C42.7704 44.0526 41.6798 42.9619 41.6798 41.6166C41.6798 40.2712 42.7705 39.1807 44.1158 39.1807H66.2282C67.5736 39.1807 68.6642 40.2714 68.6642 41.6166C68.6642 42.9619 67.5735 44.0526 66.2282 44.0526Z"
          fill="white"
        />
        <path
          d="M52.2655 42.6149C52.7961 42.6149 53.2262 42.1848 53.2262 41.6541C53.2262 41.1235 52.7961 40.6934 52.2655 40.6934C51.7348 40.6934 51.3047 41.1235 51.3047 41.6541C51.3047 42.1848 51.7348 42.6149 52.2655 42.6149Z"
          fill="#6583FE"
        />
        <path
          d="M55.1721 42.6149C55.7027 42.6149 56.1329 42.1848 56.1329 41.6541C56.1329 41.1235 55.7027 40.6934 55.1721 40.6934C54.6415 40.6934 54.2113 41.1235 54.2113 41.6541C54.2113 42.1848 54.6415 42.6149 55.1721 42.6149Z"
          fill="#6583FE"
        />
        <path
          d="M58.0786 40.6934C58.6092 40.6934 59.0394 41.1236 59.0394 41.6541C59.0394 42.1847 58.6092 42.6149 58.0786 42.6149C57.548 42.6149 57.1178 42.1847 57.1178 41.6541C57.1178 41.1236 57.5478 40.6934 58.0786 40.6934Z"
          fill="#6583FE"
        />
        <path
          d="M66.2282 50.8417H44.1158C42.7704 50.8417 41.6798 49.751 41.6798 48.4057C41.6798 47.0603 42.7705 45.9697 44.1158 45.9697H66.2282C67.5736 45.9697 68.6642 47.0604 68.6642 48.4057C68.6642 49.751 67.5735 50.8417 66.2282 50.8417Z"
          fill="white"
        />
        <path
          d="M52.2655 49.404C52.7961 49.404 53.2262 48.9738 53.2262 48.4432C53.2262 47.9126 52.7961 47.4824 52.2655 47.4824C51.7348 47.4824 51.3047 47.9126 51.3047 48.4432C51.3047 48.9738 51.7348 49.404 52.2655 49.404Z"
          fill="#6583FE"
        />
        <path
          d="M55.1721 49.404C55.7027 49.404 56.1329 48.9738 56.1329 48.4432C56.1329 47.9126 55.7027 47.4824 55.1721 47.4824C54.6415 47.4824 54.2113 47.9126 54.2113 48.4432C54.2113 48.9738 54.6415 49.404 55.1721 49.404Z"
          fill="#6583FE"
        />
        <path
          d="M58.0786 47.4824C58.6092 47.4824 59.0394 47.9126 59.0394 48.4432C59.0394 48.9738 58.6092 49.404 58.0786 49.404C57.548 49.404 57.1178 48.9738 57.1178 48.4432C57.1178 47.9126 57.5478 47.4824 58.0786 47.4824Z"
          fill="#6583FE"
        />
        <path
          d="M58.5913 55.9688H51.7896C51.5048 55.9688 51.2739 55.7379 51.2739 55.4531V53.4766C51.2739 53.1918 51.5048 52.9609 51.7896 52.9609H58.5913C58.8761 52.9609 59.107 53.1918 59.107 53.4766V55.4531C59.107 55.7379 58.8761 55.9688 58.5913 55.9688Z"
          fill="#FF7EB8"
        />
        <path
          d="M30.3119 50.2044H18.8675C18.3812 50.2044 17.9869 49.8101 17.9869 49.3239C17.9869 48.8376 18.381 48.4434 18.8675 48.4434H30.3119C30.7982 48.4434 31.1924 48.8376 31.1924 49.3239C31.1924 49.8101 30.7982 50.2044 30.3119 50.2044Z"
          fill="#B7C5FF"
        />
        <path
          d="M27.2182 53.4691H21.9612C21.475 53.4691 21.0807 53.0748 21.0807 52.5885C21.0807 52.1023 21.4748 51.708 21.9612 51.708H27.2182C27.7044 51.708 28.0987 52.1023 28.0987 52.5885C28.0987 53.0748 27.7044 53.4691 27.2182 53.4691Z"
          fill="#6583FE"
        />
        <path
          d="M64.3177 77.9237C64.0977 77.9237 63.8779 77.8398 63.71 77.6719L61.8204 75.7823C61.4847 75.4468 61.4847 74.9026 61.8204 74.5671L63.71 72.6775C64.0456 72.3419 64.5896 72.3419 64.9253 72.6775L66.8149 74.5671C67.1506 74.9026 67.1506 75.4468 66.8149 75.7823L64.9253 77.6719C64.7575 77.8398 64.5377 77.9237 64.3177 77.9237ZM63.6434 75.1747L64.3177 75.849L64.992 75.1747L64.3177 74.5005L63.6434 75.1747Z"
          fill="#FF7EB8"
        />
        <path
          d="M22.5937 16.4032C22.3737 16.4032 22.1539 16.3193 21.986 16.1514L20.0964 14.2618C19.7607 13.9263 19.7607 13.3821 20.0964 13.0466L21.986 11.157C22.3216 10.8214 22.8656 10.8214 23.2013 11.157L25.0909 13.0466C25.4265 13.3821 25.4265 13.9263 25.0909 14.2618L23.2013 16.1514C23.0335 16.3193 22.8137 16.4032 22.5937 16.4032ZM21.9194 13.6542L22.5937 14.3285L23.268 13.6542L22.5937 12.9799L21.9194 13.6542Z"
          fill="#FF7EB8"
        />
        <path
          d="M31.1922 12.4198C30.9722 12.4198 30.7524 12.3359 30.5845 12.168L28.6949 10.2784C28.3592 9.94288 28.3592 9.39872 28.6949 9.06322L30.5845 7.17363C30.9201 6.83796 31.4641 6.83796 31.7998 7.17363L33.6894 9.06322C34.0251 9.39872 34.0251 9.94288 33.6894 10.2784L31.7998 12.168C31.6322 12.3357 31.4122 12.4198 31.1922 12.4198ZM30.5181 9.6708L31.1924 10.3451L31.8667 9.6708L31.1924 8.99654L30.5181 9.6708Z"
          fill="#6583FE"
        />
        <path
          d="M70.5579 13.3656C70.0834 13.3656 69.6985 12.9809 69.6985 12.5062C69.6985 11.9359 69.2345 11.4718 68.6642 11.4718C68.1896 11.4718 67.8048 11.0872 67.8048 10.6125C67.8048 10.1378 68.1896 9.75309 68.6642 9.75309C69.2345 9.75309 69.6985 9.2892 69.6985 8.71875C69.6985 8.24403 70.0834 7.85938 70.5579 7.85938C71.0324 7.85938 71.4173 8.24403 71.4173 8.71875C71.4173 9.28903 71.8813 9.75309 72.4516 9.75309C72.9262 9.75309 73.311 10.1378 73.311 10.6125C73.311 11.0872 72.9262 11.4718 72.4516 11.4718C71.8813 11.4718 71.4173 11.9357 71.4173 12.5062C71.4173 12.9807 71.0324 13.3656 70.5579 13.3656Z"
          fill="#97FFD2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6623"
          x1="4.98163"
          y1="21.4074"
          x2="86.371"
          y2="71.1688"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#FFDBED" />
          <stop offset="0.9993" stopColor="#D2DEFF" />
        </linearGradient>
        <clipPath id="clip0_2042_6623">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationLogin;
