import { SVGProps } from "types";

const HourglassStart = (props: SVGProps) => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.37516 0.666687C0.798991 0.666687 0.333496 1.13218 0.333496 1.70835C0.333496 2.28453 0.798991 2.75002 1.37516 2.75002V3.10809C1.37516 4.4883 1.92529 5.81317 2.90186 6.78973L5.10889 9.00002L2.90186 11.2103C1.92529 12.1869 1.37516 13.5117 1.37516 14.8919V15.25C0.798991 15.25 0.333496 15.7155 0.333496 16.2917C0.333496 16.8679 0.798991 17.3334 1.37516 17.3334H2.41683H10.7502H11.7918C12.368 17.3334 12.8335 16.8679 12.8335 16.2917C12.8335 15.7155 12.368 15.25 11.7918 15.25V14.8919C11.7918 13.5117 11.2417 12.1869 10.2651 11.2103L8.05811 9.00002L10.2684 6.78973C11.245 5.81317 11.7951 4.4883 11.7951 3.10809V2.75002C12.3713 2.75002 12.8368 2.28453 12.8368 1.70835C12.8368 1.13218 12.3713 0.666687 11.7951 0.666687H10.7502H2.41683H1.37516ZM9.7085 14.8919V15.25H3.4585V14.8919C3.4585 14.0619 3.78727 13.2676 4.37321 12.6817L6.5835 10.4746L8.79378 12.6849C9.37972 13.2709 9.7085 14.0651 9.7085 14.8952V14.8919Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HourglassStart;
