import { SVGProps } from "types";

const IllustrationImage = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70.1642 26.5138C66.0913 24.9263 62.6503 22.0297 60.5459 18.1983C57.007 11.7552 50.1595 7.3877 42.2892 7.3877C34.1199 7.3877 27.0531 12.0931 23.6447 18.941C21.5571 23.1349 18.0048 26.4278 13.6605 28.1811C7.24127 30.7721 1.8523 36.6932 0.142148 45.9501C-0.0455393 46.9659 -0.0482893 48.0102 0.139398 49.026C2.79452 63.3899 14.3017 69.7257 24.7749 68.0457C30.6403 67.1048 36.5779 69.0613 40.9255 73.1094C49.7657 81.3409 63.9368 83.9216 77.6935 75.098C79.3052 74.0644 81.4674 71.8953 82.5002 70.2829C94.3537 51.777 85.5729 32.5194 70.1642 26.5138Z"
        fill="url(#paint0_linear_2042_6622)"
      />
      <path
        opacity="0.1"
        d="M78.375 17.5469V61.2461C78.375 62.6692 77.22 63.8242 75.7969 63.8242H12.2031C10.78 63.8242 9.625 62.6692 9.625 61.2461V17.5469C9.625 16.1238 10.78 14.9688 12.2031 14.9688H75.7969C77.22 14.9688 78.375 16.1238 78.375 17.5469Z"
        fill="#2626BC"
      />
      <path
        d="M60.325 76.2052C60.105 76.2052 59.8852 76.1214 59.7173 75.9536L57.8235 74.0599C57.4879 73.7242 57.4879 73.1801 57.8235 72.8446L59.7173 70.9508C60.0529 70.6153 60.5969 70.6153 60.9326 70.9508L62.8263 72.8446C63.162 73.1802 63.162 73.7244 62.8263 74.0599L60.9326 75.9536C60.7648 76.1215 60.545 76.2052 60.325 76.2052ZM59.6466 73.4523L60.325 74.1307L61.0034 73.4523L60.325 72.7739L59.6466 73.4523Z"
        fill="#6583FE"
      />
      <path
        d="M68.8218 78.4464C68.6018 78.4464 68.382 78.3626 68.2141 78.1948L66.3204 76.3011C65.9847 75.9654 65.9847 75.4213 66.3204 75.0858L68.2141 73.1921C68.3753 73.0308 68.5938 72.9404 68.8218 72.9404C69.0499 72.9404 69.2684 73.031 69.4296 73.1921L71.3233 75.0858C71.4845 75.247 71.5749 75.4654 71.5749 75.6935C71.5749 75.9216 71.4844 76.14 71.3233 76.3013L69.4296 78.195C69.2617 78.3627 69.0417 78.4466 68.8218 78.4464ZM68.1434 75.6933L68.8218 76.3717L69.5002 75.6933L68.8218 75.015L68.1434 75.6933Z"
        fill="#FF7EB8"
      />
      <path
        d="M35.7072 15.3958C35.2326 15.3958 34.8478 15.011 34.8478 14.5365C34.8478 13.9662 34.3838 13.5021 33.8135 13.5021C33.3389 13.5021 32.9541 13.1173 32.9541 12.6427C32.9541 12.1682 33.3389 11.7834 33.8135 11.7834C34.3838 11.7834 34.8478 11.3193 34.8478 10.749C34.8478 10.2745 35.2326 9.88965 35.7072 9.88965C36.1817 9.88965 36.5666 10.2745 36.5666 10.749C36.5666 11.3193 37.0306 11.7834 37.6009 11.7834C38.0755 11.7834 38.4603 12.1682 38.4603 12.6427C38.4603 13.1173 38.0755 13.5021 37.6009 13.5021C37.0306 13.5021 36.5666 13.9662 36.5666 14.5365C36.5666 15.0112 36.1819 15.3958 35.7072 15.3958Z"
        fill="#FF7EB8"
      />
      <path
        d="M53.747 79.9298H34.2528L36.7935 69.8574L38.1513 64.4775H49.8486L51.2064 69.8574L53.747 79.9298Z"
        fill="#9FB0FE"
      />
      <path
        d="M51.2064 69.8574H36.7935L38.1513 64.4775H49.8486L51.2064 69.8574Z"
        fill="#8399FE"
      />
      <path
        d="M78.375 21.1562V64.8555C78.375 66.2786 77.22 67.4336 75.7969 67.4336H12.2031C10.78 67.4336 9.625 66.2786 9.625 64.8555V21.1562C9.625 19.7331 10.78 18.5781 12.2031 18.5781H75.7969C77.22 18.5781 78.375 19.7331 78.375 21.1562Z"
        fill="white"
      />
      <path
        d="M21.2833 18.5781H66.7168V58.7725H21.2833V18.5781Z"
        fill="#97FFD2"
      />
      <path
        d="M26.0963 62.0385H15.3256C15.0408 62.0385 14.8099 61.8076 14.8099 61.5228V53.8457C14.8099 53.5609 15.0408 53.3301 15.3256 53.3301H26.0965C26.3812 53.3301 26.6121 53.5609 26.6121 53.8457V61.5228C26.6119 61.8078 26.3811 62.0385 26.0963 62.0385Z"
        fill="#6583FE"
      />
      <path
        d="M24.3063 62.038H20.1813V56.9844C20.1813 56.6996 20.4121 56.4688 20.6969 56.4688H23.7906C24.0754 56.4688 24.3063 56.6996 24.3063 56.9844V62.038Z"
        fill="#9FB0FE"
      />
      <path
        d="M41.6225 62.0385H30.8516C30.5668 62.0385 30.3359 61.8076 30.3359 61.5228V53.8457C30.3359 53.5609 30.5668 53.3301 30.8516 53.3301H41.6225C41.9073 53.3301 42.1381 53.5609 42.1381 53.8457V61.5228C42.1381 61.8078 41.9071 62.0385 41.6225 62.0385Z"
        fill="#6583FE"
      />
      <path
        d="M39.8322 62.038H35.7072V56.9844C35.7072 56.6996 35.938 56.4688 36.2228 56.4688H39.3165C39.6013 56.4688 39.8322 56.6996 39.8322 56.9844V62.038Z"
        fill="#9FB0FE"
      />
      <path
        d="M57.1485 62.0385H46.3776C46.0928 62.0385 45.8619 61.8076 45.8619 61.5228V53.8457C45.8619 53.5609 46.0928 53.3301 46.3776 53.3301H57.1485C57.4332 53.3301 57.6641 53.5609 57.6641 53.8457V61.5228C57.6641 61.8078 57.4332 62.0385 57.1485 62.0385Z"
        fill="#6583FE"
      />
      <path
        d="M55.3584 62.038H51.2334V56.9844C51.2334 56.6996 51.4642 56.4688 51.749 56.4688H54.8428C55.1276 56.4688 55.3584 56.6996 55.3584 56.9844V62.038Z"
        fill="#9FB0FE"
      />
      <path
        d="M72.6745 62.0385H61.9036C61.6188 62.0385 61.3879 61.8076 61.3879 61.5228V53.8457C61.3879 53.5609 61.6188 53.3301 61.9036 53.3301H72.6745C72.9593 53.3301 73.1901 53.5609 73.1901 53.8457V61.5228C73.1901 61.8078 72.9593 62.0385 72.6745 62.0385Z"
        fill="#6583FE"
      />
      <path
        d="M70.8843 62.038H66.7593V56.9844C66.7593 56.6996 66.9901 56.4688 67.2749 56.4688H70.3687C70.6534 56.4688 70.8843 56.6996 70.8843 56.9844V62.038Z"
        fill="#9FB0FE"
      />
      <path
        d="M78.375 26.8575V21.1562C78.375 19.7324 77.2207 18.5781 75.7969 18.5781H12.2031C10.7793 18.5781 9.625 19.7324 9.625 21.1562V26.8575H78.375Z"
        fill="#FF7EB8"
      />
      <path
        d="M9.625 58.7725V64.8551C9.625 66.2789 10.7793 67.4332 12.2031 67.4332H75.7969C77.2207 67.4332 78.375 66.2789 78.375 64.8551V58.7725H9.625Z"
        fill="#B7C5FF"
      />
      <path
        d="M16.1703 23.5894C16.6448 23.5894 17.0297 23.2045 17.0297 22.73V22.7051C17.0297 22.2305 16.6448 21.8457 16.1703 21.8457C15.6957 21.8457 15.3109 22.2305 15.3109 22.7051V22.73C15.3109 23.2045 15.6957 23.5894 16.1703 23.5894Z"
        fill="#2626BC"
      />
      <path
        d="M19.4918 23.5894C19.9664 23.5894 20.3512 23.2045 20.3512 22.73V22.7051C20.3512 22.2305 19.9664 21.8457 19.4918 21.8457C19.0173 21.8457 18.6324 22.2305 18.6324 22.7051V22.73C18.6324 23.2045 19.0173 23.5894 19.4918 23.5894Z"
        fill="#2626BC"
      />
      <path
        d="M22.8131 23.5894C23.2877 23.5894 23.6725 23.2045 23.6725 22.73V22.7051C23.6725 22.2305 23.2877 21.8457 22.8131 21.8457C22.3386 21.8457 21.9537 22.2305 21.9537 22.7051V22.73C21.9537 23.2045 22.3386 23.5894 22.8131 23.5894Z"
        fill="#2626BC"
      />
      <path
        d="M75.7969 17.7188H12.2031C10.3077 17.7188 8.76562 19.2608 8.76562 21.1562V64.8549C8.76562 66.7504 10.3077 68.2924 12.2031 68.2924H36.3022L33.5828 79.0692H30.9488C30.4743 79.0692 30.0895 79.454 30.0895 79.9286C30.0895 80.4031 30.4743 80.7879 30.9488 80.7879H57.0512C57.5257 80.7879 57.9105 80.4031 57.9105 79.9286C57.9105 79.454 57.5257 79.0692 57.0512 79.0692H54.4172L52.5226 71.5606C52.4064 71.1004 51.9389 70.8219 51.4791 70.9376C51.0189 71.0538 50.7401 71.5209 50.8561 71.981L52.6446 79.069H35.3554L38.0748 68.2923H75.7969C77.6923 68.2923 79.2344 66.7502 79.2344 64.8548V21.1562C79.2344 19.2608 77.6923 17.7188 75.7969 17.7188ZM12.2031 19.4375H75.7969C76.7446 19.4375 77.5156 20.2085 77.5156 21.1562V25.9981H14.3586C13.8841 25.9981 13.4992 26.383 13.4992 26.8575C13.4992 27.3321 13.8841 27.7169 14.3586 27.7169H77.5156V57.9129H10.4844V21.1562C10.4844 20.2084 11.2554 19.4375 12.2031 19.4375ZM75.7969 66.5737H12.2031C11.2554 66.5737 10.4844 65.8027 10.4844 64.8549V59.6317H77.5156V64.8549C77.5156 65.8027 76.7446 66.5737 75.7969 66.5737Z"
        fill="#2626BC"
      />
      <path
        d="M26.0963 39.556H15.3256C15.0408 39.556 14.8099 39.3252 14.8099 39.0404V31.3633C14.8099 31.0785 15.0408 30.8477 15.3256 30.8477H26.0965C26.3812 30.8477 26.6121 31.0785 26.6121 31.3633V39.0404C26.6119 39.3252 26.3811 39.556 26.0963 39.556Z"
        fill="#6583FE"
      />
      <path
        d="M24.3063 39.5556H20.1813V34.502C20.1813 34.2172 20.4121 33.9863 20.6969 33.9863H23.7906C24.0754 33.9863 24.3063 34.2172 24.3063 34.502V39.5556Z"
        fill="#9FB0FE"
      />
      <path
        d="M41.6225 39.556H30.8516C30.5668 39.556 30.3359 39.3252 30.3359 39.0404V31.3633C30.3359 31.0785 30.5668 30.8477 30.8516 30.8477H41.6225C41.9073 30.8477 42.1381 31.0785 42.1381 31.3633V39.0404C42.1381 39.3252 41.9071 39.556 41.6225 39.556Z"
        fill="#6583FE"
      />
      <path
        d="M39.8322 39.5556H35.7072V34.502C35.7072 34.2172 35.938 33.9863 36.2228 33.9863H39.3165C39.6013 33.9863 39.8322 34.2172 39.8322 34.502V39.5556Z"
        fill="#9FB0FE"
      />
      <path
        d="M57.1485 39.556H46.3776C46.0928 39.556 45.8619 39.3252 45.8619 39.0404V31.3633C45.8619 31.0785 46.0928 30.8477 46.3776 30.8477H57.1485C57.4332 30.8477 57.6641 31.0785 57.6641 31.3633V39.0404C57.6641 39.3252 57.4332 39.556 57.1485 39.556Z"
        fill="#6583FE"
      />
      <path
        d="M55.3584 39.5556H51.2334V34.502C51.2334 34.2172 51.4642 33.9863 51.749 33.9863H54.8428C55.1276 33.9863 55.3584 34.2172 55.3584 34.502V39.5556Z"
        fill="#9FB0FE"
      />
      <path
        d="M72.6745 39.556H61.9036C61.6188 39.556 61.3879 39.3252 61.3879 39.0404V31.3633C61.3879 31.0785 61.6188 30.8477 61.9036 30.8477H72.6745C72.9593 30.8477 73.1901 31.0785 73.1901 31.3633V39.0404C73.1901 39.3252 72.9593 39.556 72.6745 39.556Z"
        fill="#6583FE"
      />
      <path
        d="M70.8843 39.5556H66.7593V34.502C66.7593 34.2172 66.9901 33.9863 67.2749 33.9863H70.3687C70.6534 33.9863 70.8843 34.2172 70.8843 34.502V39.5556Z"
        fill="#9FB0FE"
      />
      <path
        d="M26.0963 50.7973H15.3256C15.0408 50.7973 14.8099 50.5664 14.8099 50.2816V42.6045C14.8099 42.3197 15.0408 42.0889 15.3256 42.0889H26.0965C26.3812 42.0889 26.6121 42.3197 26.6121 42.6045V50.2816C26.6119 50.5666 26.3811 50.7973 26.0963 50.7973Z"
        fill="#6583FE"
      />
      <path
        d="M24.3063 50.7968H20.1813V45.7432C20.1813 45.4584 20.4121 45.2275 20.6969 45.2275H23.7906C24.0754 45.2275 24.3063 45.4584 24.3063 45.7432V50.7968Z"
        fill="#9FB0FE"
      />
      <path
        d="M41.6225 50.7973H30.8516C30.5668 50.7973 30.3359 50.5664 30.3359 50.2816V42.6045C30.3359 42.3197 30.5668 42.0889 30.8516 42.0889H41.6225C41.9073 42.0889 42.1381 42.3197 42.1381 42.6045V50.2816C42.1381 50.5666 41.9071 50.7973 41.6225 50.7973Z"
        fill="#6583FE"
      />
      <path
        d="M39.8322 50.7968H35.7072V45.7432C35.7072 45.4584 35.938 45.2275 36.2228 45.2275H39.3165C39.6013 45.2275 39.8322 45.4584 39.8322 45.7432V50.7968Z"
        fill="#9FB0FE"
      />
      <path
        d="M57.1485 50.7973H46.3776C46.0928 50.7973 45.8619 50.5664 45.8619 50.2816V42.6045C45.8619 42.3197 46.0928 42.0889 46.3776 42.0889H57.1485C57.4332 42.0889 57.6641 42.3197 57.6641 42.6045V50.2816C57.6641 50.5666 57.4332 50.7973 57.1485 50.7973Z"
        fill="#6583FE"
      />
      <path
        d="M55.3584 50.7968H51.2334V45.7432C51.2334 45.4584 51.4642 45.2275 51.749 45.2275H54.8428C55.1276 45.2275 55.3584 45.4584 55.3584 45.7432V50.7968Z"
        fill="#9FB0FE"
      />
      <path
        d="M72.6745 50.7973H61.9036C61.6188 50.7973 61.3879 50.5664 61.3879 50.2816V42.6045C61.3879 42.3197 61.6188 42.0889 61.9036 42.0889H72.6745C72.9593 42.0889 73.1901 42.3197 73.1901 42.6045V50.2816C73.1901 50.5666 72.9593 50.7973 72.6745 50.7973Z"
        fill="#6583FE"
      />
      <path
        d="M70.8843 50.7968H66.7593V45.7432C66.7593 45.4584 66.9901 45.2275 67.2749 45.2275H70.3687C70.6534 45.2275 70.8843 45.4584 70.8843 45.7432V50.7968Z"
        fill="#9FB0FE"
      />
      <path
        d="M26.2 12.7181C25.7254 12.7181 25.3406 12.3333 25.3406 11.8587C25.3406 11.2884 24.8766 10.8244 24.3063 10.8244C23.8317 10.8244 23.4469 10.4396 23.4469 9.96501C23.4469 9.49046 23.8317 9.10563 24.3063 9.10563C24.8766 9.10563 25.3406 8.64157 25.3406 8.07129C25.3406 7.59674 25.7254 7.21191 26.2 7.21191C26.6745 7.21191 27.0594 7.59674 27.0594 8.07129C27.0594 8.64157 27.5234 9.10563 28.0937 9.10563C28.5683 9.10563 28.9531 9.49046 28.9531 9.96501C28.9531 10.4396 28.5683 10.8244 28.0937 10.8244C27.5234 10.8244 27.0594 11.2884 27.0594 11.8587C27.0594 12.3334 26.6745 12.7181 26.2 12.7181Z"
        fill="#97FFD2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2042_6622"
          x1="6.00257"
          y1="17.948"
          x2="82.753"
          y2="76.9867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IllustrationImage;
