import { SVGProps } from "types";

const BoxMinimize = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5430)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M12 6.5H6.99975C6.44775 6.5 6 6.94775 6 7.49975V11.4995C6 12.0515 6.44775 12.4993 6.99975 12.4993H12C12.552 12.4993 12.9998 12.0515 12.9998 11.4995V7.49975C12.9998 6.94775 12.552 6.5 12 6.5ZM11.7502 11.4995H7.25025C7.11225 11.4995 7.0005 11.3878 7.0005 11.2498V8.4995H12V11.2498C12 11.3878 11.8882 11.4995 11.7502 11.4995Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M15.2685 14.0278C15.129 13.97 14.967 14.0015 14.8598 14.1088L14.5883 14.3803L13.8533 13.6453C13.6583 13.4503 13.3418 13.4503 13.146 13.6453C12.951 13.8403 12.951 14.1568 13.146 14.3525L13.881 15.0875L13.6095 15.359C13.5023 15.4663 13.47 15.6275 13.5285 15.7678C13.5863 15.908 13.7235 15.9995 13.875 15.9995H15.1253C15.3323 15.9995 15.5003 15.8315 15.5003 15.6245V14.3743C15.5003 14.2228 15.4088 14.0863 15.2685 14.0278Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M17.5005 16.5H17.0002V11.4998C17.0002 10.95 16.5503 10.5 16.0005 10.5H14.0002V11.4998H16.0005V16.5H7.9995V13.5H6.99975V16.5H6.4995C6.2235 16.5 6 16.7235 6 17.0002C6 17.2762 6.22425 17.5005 6.50025 17.5005H17.5005C17.7765 17.5005 18.0007 17.277 18.0007 17.0002C18 16.7235 17.7765 16.5 17.5005 16.5Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5430">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxMinimize;
