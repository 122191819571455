import { SVGProps } from "types";

const BoxLive = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5379)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M10.6252 12C10.4182 12 10.2502 11.832 10.2502 11.625V9.375C10.2502 9.168 10.4182 9 10.6252 9C10.8322 9 11.0002 9.168 11.0002 9.375V11.625C11.0002 11.832 10.8322 12 10.6252 12Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M9.1245 12H7.875C7.668 12 7.5 11.832 7.5 11.625V9.375C7.5 9.168 7.668 9 7.875 9C8.082 9 8.25 9.168 8.25 9.375V11.25H9.12525C9.33225 11.25 9.50025 11.418 9.50025 11.625C9.50025 11.832 9.33225 12 9.1245 12Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M12.75 11.9996C12.5813 11.9996 12.4335 11.8871 12.3885 11.7251L11.7638 9.47508C11.7083 9.27558 11.8253 9.06858 12.0248 9.01308C12.2258 8.95683 12.4313 9.07458 12.4868 9.27408L12.75 10.2236L13.014 9.27408C13.0695 9.07458 13.2758 8.95683 13.476 9.01308C13.6755 9.06858 13.7925 9.27558 13.737 9.47508L13.1123 11.7251C13.0665 11.8871 12.9188 11.9996 12.75 11.9996Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M16.125 10.875C16.332 10.875 16.5 10.707 16.5 10.5C16.5 10.293 16.332 10.125 16.125 10.125H15.2497V9.75H16.125C16.332 9.75 16.5 9.582 16.5 9.375C16.5 9.168 16.332 9 16.125 9H15.1252C14.7802 9 14.5005 9.2805 14.5005 9.62475V11.3745C14.5005 11.7195 14.781 11.9992 15.1252 11.9992H16.125C16.332 11.9992 16.5 11.8312 16.5 11.6242C16.5 11.4172 16.332 11.25 16.125 11.25H15.2497V10.875H16.125Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M17.1255 7H6.8745C6.39225 7 6 7.393 6 7.87525V14.6252C6 15.1075 6.39225 15.5005 6.87525 15.5005H10.7048C10.6462 15.751 10.4903 16.1575 10.074 16.5737C10.0027 16.645 9.981 16.753 10.02 16.846C10.0582 16.9398 10.1498 17.0005 10.251 17.0005H13.7512C13.8525 17.0005 13.944 16.9398 13.9822 16.846C14.0205 16.7523 13.9995 16.645 13.9282 16.5737C13.5127 16.1582 13.353 15.7517 13.2908 15.5005H17.1262C17.6085 15.5005 18.0015 15.1082 18.0015 14.6252V7.87525C18 7.393 17.6077 7 17.1255 7ZM17.0002 13.5002H6.99975V8.0005H17.0002V13.5002Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5379">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxLive;
