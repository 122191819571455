import { SVGProps } from "types";

const Sun = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9996 8.24946C9.9286 8.24946 8.28491 9.9283 8.28491 11.9642C8.28491 14 9.89422 15.7492 11.9996 15.7492C14.105 15.7492 15.7143 14.0703 15.7143 12.0345C15.7143 9.99861 14.0698 8.24946 11.9996 8.24946ZM21.8937 15.589L19.4251 11.9993L21.8918 8.44437C22.1391 8.08794 21.9349 7.59519 21.508 7.51785L17.2503 6.74835L16.4808 2.4907C16.4037 2.06376 15.9109 1.85947 15.5543 2.10696L11.9996 4.60859L8.4099 2.14173C8.05347 1.89439 7.56072 2.09849 7.48338 2.52546L6.74981 6.78467L2.49061 7.55417C2.06379 7.62839 1.85954 8.12446 2.10687 8.44477L4.57373 12.0345L2.10703 15.5894C1.8597 15.9458 2.06387 16.4386 2.49077 16.5159L6.74841 17.2854L7.51791 21.5431C7.59505 21.97 8.08781 22.1743 8.44443 21.9268L11.9996 19.46L15.5545 21.9267C15.9111 22.1741 16.4041 21.9699 16.4811 21.543L17.2506 17.2854L21.5082 16.5159C21.9367 16.4405 22.1398 15.9445 21.8937 15.589ZM11.9996 16.9991C9.23839 16.9991 7.03496 14.7605 7.03496 12.0345C7.03496 9.27325 9.27354 7.06982 11.9996 7.06982C14.7257 7.06982 16.9643 9.30801 16.9643 12.0345C16.9643 14.7961 14.7612 16.9991 11.9996 16.9991Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Sun;
