import { SVGProps } from "types";

const BoxBrowser = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5448)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M12 6.5H6.99975C6.44775 6.5 6 6.94775 6 7.49975V11.4995C6 12.0515 6.44775 12.4993 6.99975 12.4993H12C12.552 12.4993 12.9998 12.0515 12.9998 11.4995V7.49975C12.9998 6.94775 12.552 6.5 12 6.5ZM11.7502 11.4995H7.25025C7.11225 11.4995 7.0005 11.3878 7.0005 11.2498V8.4995H12V11.2498C12 11.3878 11.8882 11.4995 11.7502 11.4995Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M15.354 15.1463L14.619 14.4113L14.8905 14.1398C14.9978 14.0325 15.03 13.8713 14.9715 13.731C14.9138 13.5908 14.7765 13.5 14.625 13.5H13.3748C13.1678 13.5 12.9998 13.668 12.9998 13.875V15.1253C12.9998 15.2768 13.0913 15.4133 13.2315 15.4718C13.278 15.4913 13.3268 15.5003 13.3748 15.5003C13.4723 15.5003 13.5683 15.462 13.6403 15.3908L13.9118 15.1193L14.6468 15.8543C14.8418 16.0493 15.1583 16.0493 15.354 15.8543C15.5498 15.6593 15.549 15.3413 15.354 15.1463Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M17.5005 16.5H17.0002V11.4998C17.0002 10.95 16.5503 10.5 16.0005 10.5H14.0002V11.4998H16.0005V16.5H7.9995V13.5H6.99975V16.5H6.4995C6.2235 16.5 6 16.7235 6 17.0002C6 17.2762 6.22425 17.5005 6.50025 17.5005H17.5005C17.7765 17.5005 18.0007 17.277 18.0007 17.0002C18 16.7235 17.7765 16.5 17.5005 16.5Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5448">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxBrowser;
