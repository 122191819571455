import { SVGProps } from "types";

const BoxKeyboard = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5404)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M15.5003 13H15C14.724 13 14.4998 13.2235 14.4998 13.5002V15.5005C14.4998 15.7765 14.7233 16.0007 15 16.0007H15.5003C15.7763 16.0007 16.0005 15.7765 16.0005 15.5005V13.5002C16.0005 13.2242 15.7763 13 15.5003 13Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M12 13H8.49976C8.22376 13 7.99951 13.2235 7.99951 13.5002V15.5005C7.99951 15.7765 8.22301 16.0007 8.49976 16.0007H12C12.276 16.0007 12.5003 15.7765 12.5003 15.5005V13.5002C12.5003 13.2242 12.276 13 12 13Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M16.5 11.0002H12.9998V7.7505C12.9998 6.78525 12.2145 6 11.25 6C10.2855 6 9.50025 6.78525 9.50025 7.74975V8.25C9.50025 8.66325 9.1635 9 8.75025 9C8.337 9 8.00025 8.66325 8.00025 8.25V6.50025C7.9995 6.2235 7.776 6 7.5 6C7.22325 6 6.99975 6.2235 6.99975 6.50025V8.25C6.99975 9.2145 7.785 9.99975 8.7495 9.99975C9.714 9.99975 10.5 9.2145 10.5 8.25V7.74975C10.5 7.3365 10.8368 6.99975 11.25 6.99975C11.6632 6.99975 12 7.3365 12 7.74975V10.9995H7.5C6.67275 10.9995 6 11.6723 6 12.4995V16.5C6 17.3273 6.67275 18 7.5 18H16.5C17.3273 18 18 17.3273 18 16.5V12.5002C18 11.673 17.3273 11.0002 16.5 11.0002ZM17.0002 16.5C17.0002 16.776 16.776 17.0002 16.5 17.0002H7.5C7.224 17.0002 6.99975 16.776 6.99975 16.5V12.5002C6.99975 12.2242 7.224 12 7.5 12H16.5C16.776 12 17.0002 12.2242 17.0002 12.5002V16.5Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5404">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxKeyboard;
