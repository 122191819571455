import { SVGProps } from "types";

const BoxBlocked = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5391)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M16.98 8.78952L15.7703 9.99927H16.0005V15.2493C16.0005 15.3895 15.8903 15.499 15.7508 15.499H10.2705L9.27001 16.4995H16.0005C16.5503 16.4995 17.0003 16.0495 17.0003 15.4998V8.99952C17.0003 8.92977 16.9905 8.85477 16.98 8.78952ZM7.99951 15.2493V9.99927H13.9995L15.9998 7.99902H7.99951C7.44976 7.99902 6.99976 8.44902 6.99976 8.99877V15.499C6.99976 15.904 7.24501 16.2543 7.59001 16.4088L8.49976 15.499H8.25001C8.10976 15.4998 7.99951 15.3895 7.99951 15.2493Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M6.4995 18C6.37125 18 6.24375 17.9512 6.14625 17.8537C5.95125 17.6587 5.95125 17.3422 6.14625 17.1465L17.1465 6.14625C17.3415 5.95125 17.658 5.95125 17.8537 6.14625C18.0487 6.34125 18.0487 6.65775 17.8537 6.8535L6.8535 17.8537C6.75525 17.9512 6.62775 18 6.4995 18Z"
          fill="var(--color-mauve)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5391">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxBlocked;
