import { SVGProps } from "types";

const Contact = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0176 3C6.48438 3 2.00098 6.64314 2.00098 11.1393C2.00098 13.0814 2.83643 14.856 4.2303 16.2538C3.74115 18.2249 2.10663 19.9819 2.08707 20.0014C1.99902 20.0895 1.97456 20.2258 2.02837 20.3432C2.07736 20.4605 2.18579 20.5309 2.31297 20.5309C4.90542 20.5309 6.817 19.2881 7.81485 18.5199C9.09053 19.0009 10.5149 19.2787 12.0176 19.2787C17.5508 19.2787 22 15.6344 22 11.1393C22 6.64431 17.5508 3 12.0176 3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Contact;
