import { SVGProps } from "types";

const IllustrationWebsite = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6637)">
        <path
          d="M70.1644 26.5138C66.0915 24.9263 62.6505 22.0297 60.5461 18.1983C57.0072 11.7552 50.1597 7.3877 42.2893 7.3877C34.1201 7.3877 27.0533 12.0931 23.6449 18.941C21.5573 23.1349 18.0049 26.4278 13.6606 28.1811C7.24128 30.772 1.8523 36.6931 0.142148 45.9499C-0.0455393 46.9657 -0.0482893 48.01 0.139398 49.0258C2.79452 63.3897 14.3017 69.7256 24.7749 68.0455C30.6403 67.1046 36.5779 69.0611 40.9255 73.1093C49.7657 81.3407 63.9368 83.9214 77.6935 75.0979C79.3052 74.0642 81.4674 71.8951 82.5002 70.2828C94.3537 51.777 85.5731 32.5194 70.1644 26.5138V26.5138Z"
          fill="url(#paint0_linear_2042_6637)"
        />
        <path
          opacity="0.1"
          d="M78.3752 17.2871V60.9863C78.3752 62.4095 77.2202 63.5645 75.7971 63.5645H12.2034C10.7802 63.5645 9.62524 62.4095 9.62524 60.9863V17.2871C9.62524 15.864 10.7802 14.709 12.2034 14.709H75.7969C77.2202 14.709 78.3752 15.864 78.3752 17.2871V17.2871Z"
          fill="#2626BC"
        />
        <path
          d="M53.7472 79.6653H34.2529L36.7936 69.5929L38.1514 64.2129H49.8487L51.2065 69.5929L53.7472 79.6653Z"
          fill="#9FB0FE"
        />
        <path
          d="M51.2066 69.5929H36.7937L38.1515 64.2129H49.8488L51.2066 69.5929Z"
          fill="#8399FE"
        />
        <path
          d="M78.3752 20.8916V64.5908C78.3752 66.0139 77.2202 67.1689 75.7971 67.1689H12.2034C10.7802 67.1689 9.62524 66.0139 9.62524 64.5908V20.8916C9.62524 19.4685 10.7802 18.3135 12.2034 18.3135H75.7969C77.2202 18.3135 78.3752 19.4685 78.3752 20.8916V20.8916Z"
          fill="white"
        />
        <path
          d="M78.3753 44.793V64.5913C78.3753 66.0151 77.221 67.1694 75.7972 67.1694H47.4756V45.3086C47.4756 45.0238 47.7064 44.793 47.9912 44.793H78.3753Z"
          fill="#DCFDEE"
        />
        <path
          d="M78.3752 26.5938V20.8926C78.3752 19.4688 77.2209 18.3145 75.7971 18.3145H12.2034C10.7796 18.3145 9.62524 19.4688 9.62524 20.8926V26.5938H78.3752Z"
          fill="#6583FE"
        />
        <path
          d="M9.62524 58.5078V64.5905C9.62524 66.0143 10.7796 67.1686 12.2034 67.1686H75.7969C77.2207 67.1686 78.3751 66.0143 78.3751 64.5905V58.5078H9.62524Z"
          fill="#B7C5FF"
        />
        <path
          d="M16.1707 23.3257C16.6452 23.3257 17.03 22.9409 17.03 22.4663V22.4414C17.03 21.9669 16.6452 21.582 16.1707 21.582C15.6961 21.582 15.3113 21.9669 15.3113 22.4414V22.4663C15.3113 22.9409 15.6961 23.3257 16.1707 23.3257Z"
          fill="#2626BC"
        />
        <path
          d="M19.4922 23.3257C19.9667 23.3257 20.3516 22.9409 20.3516 22.4663V22.4414C20.3516 21.9669 19.9667 21.582 19.4922 21.582C19.0176 21.582 18.6328 21.9669 18.6328 22.4414V22.4663C18.6328 22.9409 19.0176 23.3257 19.4922 23.3257Z"
          fill="#2626BC"
        />
        <path
          d="M22.8135 23.3257C23.288 23.3257 23.6729 22.9409 23.6729 22.4663V22.4414C23.6729 21.9669 23.288 21.582 22.8135 21.582C22.3389 21.582 21.9541 21.9669 21.9541 22.4414V22.4663C21.9541 22.9409 22.3389 23.3257 22.8135 23.3257Z"
          fill="#2626BC"
        />
        <path
          d="M10.4846 27.4532H78.3751C78.8496 27.4532 79.2344 27.0684 79.2344 26.5938V20.8926C79.2344 18.9971 77.6924 17.4551 75.7969 17.4551H12.2034C10.3079 17.4551 8.76587 18.9971 8.76587 20.8926V64.5913C8.76587 66.4867 10.3079 68.0288 12.2034 68.0288H49.9255L52.6449 78.8055H35.3556L37.1441 71.7176C37.2602 71.2575 36.9814 70.7901 36.5211 70.6741C36.0612 70.5578 35.5937 70.8369 35.4776 71.2972L33.5831 78.8055H30.9491C30.4745 78.8055 30.0897 79.1903 30.0897 79.6649C30.0897 80.1394 30.4745 80.5243 30.9491 80.5243H34.2525H53.7481H57.0516C57.5261 80.5243 57.9109 80.1394 57.9109 79.6649C57.9109 79.1903 57.5261 78.8055 57.0516 78.8055H54.4176L51.6982 68.0288H75.7971C77.6926 68.0288 79.2346 66.4867 79.2346 64.5913V31.3275C79.2346 30.8529 78.8498 30.4681 78.3752 30.4681C77.9007 30.4681 77.5159 30.8529 77.5159 31.3275V57.6494H10.4846V27.4532ZM12.2034 19.1738H75.7969C76.7447 19.1738 77.5157 19.9449 77.5157 20.8926V25.7345H10.4846V20.8926C10.4846 19.9449 11.2557 19.1738 12.2034 19.1738V19.1738ZM77.5159 59.368V64.5913C77.5159 65.539 76.7448 66.31 75.7971 66.31H12.2034C11.2557 66.31 10.4846 65.539 10.4846 64.5913V59.368H77.5159Z"
          fill="#2626BC"
        />
        <path
          d="M40.5337 53.7371H17.0312C16.5558 53.7371 16.1704 53.3518 16.1704 52.8764C16.1704 52.401 16.5558 52.0156 17.0312 52.0156H40.5337C41.0091 52.0156 41.3944 52.401 41.3944 52.8764C41.3946 53.3518 41.0093 53.7371 40.5337 53.7371Z"
          fill="#B7C5FF"
        />
        <path
          d="M40.5337 50.4549H17.0312C16.5558 50.4549 16.1704 50.0696 16.1704 49.5941C16.1704 49.1187 16.5558 48.7334 17.0312 48.7334H40.5337C41.0091 48.7334 41.3944 49.1187 41.3944 49.5941C41.3946 50.0696 41.0093 50.4549 40.5337 50.4549Z"
          fill="#B7C5FF"
        />
        <path
          d="M20.9399 47.1717H17.0312C16.5558 47.1717 16.1704 46.7864 16.1704 46.311C16.1704 45.8355 16.5558 45.4502 17.0312 45.4502H20.9399C21.4153 45.4502 21.8007 45.8355 21.8007 46.311C21.8007 46.7864 21.4155 47.1717 20.9399 47.1717Z"
          fill="#6583FE"
        />
        <path
          d="M40.5337 47.1717H24.7655C24.2901 47.1717 23.9048 46.7864 23.9048 46.311C23.9048 45.8355 24.2901 45.4502 24.7655 45.4502H40.5337C41.0091 45.4502 41.3944 45.8355 41.3944 46.311C41.3944 46.7864 41.0093 47.1717 40.5337 47.1717V47.1717Z"
          fill="#9FB0FE"
        />
        <path
          d="M25.0649 41.5057H17.0312C16.5558 41.5057 16.1704 41.1203 16.1704 40.6449C16.1704 40.1695 16.5558 39.7842 17.0312 39.7842H25.0649C25.5403 39.7842 25.9257 40.1695 25.9257 40.6449C25.9259 41.1202 25.5405 41.5057 25.0649 41.5057Z"
          fill="#B7C5FF"
        />
        <path
          d="M54.4722 53.6332H51.7222C51.4374 53.6332 51.2065 53.4024 51.2065 53.1176V42.6562C51.2065 42.3715 51.4374 42.1406 51.7222 42.1406H54.4722C54.757 42.1406 54.9878 42.3715 54.9878 42.6562V53.1176C54.9878 53.4024 54.757 53.6332 54.4722 53.6332Z"
          fill="#02FFB3"
        />
        <path
          d="M48.8496 53.6325H46.0996C45.8148 53.6325 45.584 53.4016 45.584 53.1168V46.6445C45.584 46.3597 45.8148 46.1289 46.0996 46.1289H48.8496C49.1344 46.1289 49.3652 46.3597 49.3652 46.6445V53.1168C49.3652 53.4016 49.1344 53.6325 48.8496 53.6325Z"
          fill="#97FFD2"
        />
        <path
          d="M60.0945 53.6327H57.3445C57.0597 53.6327 56.8289 53.4018 56.8289 53.117V39.6523C56.8289 39.3675 57.0597 39.1367 57.3445 39.1367H60.0945C60.3793 39.1367 60.6101 39.3675 60.6101 39.6523V53.117C60.6101 53.4018 60.3793 53.6327 60.0945 53.6327Z"
          fill="#FFA8CF"
        />
        <path
          d="M65.717 53.6332H62.967C62.6822 53.6332 62.4514 53.4024 62.4514 53.1176V36.4687C62.4514 36.184 62.6822 35.9531 62.967 35.9531H65.717C66.0018 35.9531 66.2327 36.184 66.2327 36.4687V53.1176C66.2327 53.4024 66.0018 53.6332 65.717 53.6332Z"
          fill="#FF7EB8"
        />
        <path
          d="M71.3396 53.6327H68.5896C68.3048 53.6327 68.074 53.4018 68.074 53.117V33.4648C68.074 33.18 68.3048 32.9492 68.5896 32.9492H71.3396C71.6244 32.9492 71.8552 33.18 71.8552 33.4648V53.117C71.8552 53.4018 71.6244 53.6327 71.3396 53.6327Z"
          fill="#FF5BA8"
        />
        <path
          d="M20.2271 36.6917C19.9146 36.6917 19.6238 36.5212 19.4723 36.2431L18.9603 35.3019L18.4483 36.2431C18.2842 36.5444 17.9557 36.7197 17.6151 36.6881C17.2734 36.6568 16.9831 36.4253 16.8765 36.0991L15.6975 32.488C15.5502 32.0368 15.7965 31.5516 16.2476 31.4043C16.6986 31.257 17.1838 31.5033 17.3313 31.9545L17.8916 33.6703L18.2058 33.0931C18.3562 32.8168 18.6458 32.6446 18.9607 32.6446C19.2756 32.6446 19.5652 32.8166 19.7156 33.0931L20.0296 33.6701L20.5899 31.9543C20.7372 31.5031 21.2224 31.2565 21.6736 31.4041C22.1247 31.5514 22.3712 32.0366 22.2237 32.4878L21.0447 36.0989C20.9381 36.4251 20.6478 36.6566 20.3061 36.6879C20.2793 36.6905 20.253 36.6917 20.2271 36.6917V36.6917Z"
          fill="#6583FE"
        />
        <path
          d="M27.5834 36.6917C27.271 36.6917 26.9801 36.5212 26.8289 36.2431L26.3167 35.3019L25.8045 36.2431C25.6405 36.5444 25.3128 36.7197 24.9713 36.6881C24.6296 36.6568 24.3393 36.4253 24.2327 36.0991L23.0537 32.488C22.9064 32.0368 23.1527 31.5516 23.6038 31.4043C24.055 31.257 24.5402 31.5033 24.6875 31.9545L25.2478 33.6703L25.562 33.0931C25.7124 32.8168 26.002 32.6446 26.3169 32.6446C26.6317 32.6446 26.9214 32.8166 27.0717 33.0931L27.3858 33.6701L27.9461 31.9543C28.0934 31.5031 28.5786 31.2565 29.0297 31.4041C29.4809 31.5514 29.7274 32.0366 29.5799 32.4878L28.4009 36.0989C28.2943 36.4251 28.004 36.6566 27.6623 36.6879C27.6358 36.6905 27.6095 36.6917 27.5834 36.6917V36.6917Z"
          fill="#6583FE"
        />
        <path
          d="M34.9399 36.6917C34.6275 36.6917 34.3367 36.5212 34.1852 36.2431L33.6732 35.3019L33.1612 36.2431C32.9971 36.5444 32.6691 36.7197 32.328 36.6881C31.9863 36.6568 31.696 36.4253 31.5894 36.0991L30.4103 32.488C30.263 32.0368 30.5093 31.5516 30.9605 31.4043C31.4117 31.257 31.8969 31.5033 32.0442 31.9545L32.6045 33.6703L32.9187 33.0931C33.0691 32.8168 33.3587 32.6446 33.6736 32.6446C33.9884 32.6446 34.278 32.8166 34.4284 33.0931L34.7425 33.6701L35.3028 31.9543C35.4501 31.5031 35.9353 31.2565 36.3864 31.4041C36.8376 31.5514 37.0841 32.0366 36.9366 32.4878L35.7575 36.0989C35.651 36.4251 35.3607 36.6566 35.019 36.6879C34.9924 36.6905 34.9661 36.6917 34.9399 36.6917V36.6917Z"
          fill="#6583FE"
        />
        <path
          d="M54.8009 31.4023C55.3314 31.4023 55.7617 31.8325 55.7617 32.3631C55.7617 32.8937 55.3314 33.3239 54.8009 33.3239C54.2703 33.3239 53.8401 32.8937 53.8401 32.3631C53.8401 31.8325 54.2701 31.4023 54.8009 31.4023Z"
          fill="#FF5BA8"
        />
        <path
          d="M57.7071 31.4023C58.2377 31.4023 58.6679 31.8325 58.6679 32.3631C58.6679 32.8937 58.2377 33.3239 57.7071 33.3239C57.1765 33.3239 56.7463 32.8937 56.7463 32.3631C56.7463 31.8325 57.1764 31.4023 57.7071 31.4023Z"
          fill="#FF5BA8"
        />
        <path
          d="M60.6136 31.4023C61.1442 31.4023 61.5744 31.8325 61.5744 32.3631C61.5744 32.8937 61.1442 33.3239 60.6136 33.3239C60.083 33.3239 59.6528 32.8937 59.6528 32.3631C59.6528 31.8325 60.0829 31.4023 60.6136 31.4023Z"
          fill="#FF5BA8"
        />
        <path
          d="M50.385 34.2177C50.385 32.6431 49.104 31.3623 47.5297 31.3623C45.9553 31.3623 44.6743 32.6433 44.6743 34.2177C44.6743 34.9942 44.9868 35.6984 45.4916 36.2137C44.987 36.7289 44.6743 37.4331 44.6743 38.2096C44.6743 38.6842 45.0591 39.069 45.5337 39.069C46.0082 39.069 46.3931 38.6842 46.3931 38.2096C46.3931 37.583 46.903 37.073 47.5297 37.073C48.1563 37.073 48.6663 37.583 48.6663 38.2096C48.6663 38.6842 49.0511 39.069 49.5257 39.069C50.0002 39.069 50.385 38.6842 50.385 38.2096C50.385 37.4331 50.0726 36.7289 49.5678 36.2137C50.0726 35.6984 50.385 34.994 50.385 34.2177ZM47.5297 33.0809C48.1563 33.0809 48.6663 33.5908 48.6663 34.2175C48.6663 34.8441 48.1563 35.3541 47.5297 35.3541C46.903 35.3541 46.3931 34.8441 46.3931 34.2175C46.3931 33.5908 46.9028 33.0809 47.5297 33.0809Z"
          fill="#4369FD"
        />
        <path
          d="M23.0424 77.2288C22.8224 77.2288 22.6026 77.145 22.4347 76.9772L20.5409 75.0835C20.3797 74.9223 20.2893 74.7038 20.2893 74.4757C20.2893 74.2477 20.3799 74.0292 20.5409 73.868L22.4347 71.9743C22.7703 71.6388 23.3143 71.6388 23.65 71.9743L25.5437 73.868C25.7049 74.0292 25.7953 74.2477 25.7953 74.4757C25.7953 74.7038 25.7047 74.9223 25.5437 75.0835L23.6502 76.977C23.4822 77.1448 23.2624 77.2288 23.0424 77.2288ZM22.364 74.4756L23.0424 75.154L23.7208 74.4756L23.0424 73.7972L22.364 74.4756Z"
          fill="#97FFD2"
        />
        <path
          d="M62.9252 75.1292C62.7052 75.1292 62.4854 75.0454 62.3175 74.8776L60.4237 72.9839C60.2625 72.8227 60.1721 72.6042 60.1721 72.3761C60.1721 72.1481 60.2627 71.9296 60.4237 71.7684L62.3175 69.8747C62.6531 69.5392 63.1971 69.5392 63.5328 69.8747L65.4265 71.7684C65.5877 71.9296 65.6781 72.1481 65.6781 72.3761C65.6781 72.6042 65.5876 72.8227 65.4265 72.9839L63.5328 74.8776C63.365 75.0452 63.1452 75.1292 62.9252 75.1292V75.1292ZM62.2468 72.3761L62.9252 73.0545L63.6036 72.3761L62.9252 71.6978L62.2468 72.3761Z"
          fill="#FF7EB8"
        />
        <path
          d="M73.9032 79.807C73.6832 79.807 73.4634 79.7231 73.2955 79.5553L71.4018 77.6616C71.2406 77.5004 71.1501 77.282 71.1501 77.0539C71.1501 76.8258 71.2407 76.6073 71.4018 76.4461L73.2955 74.5524C73.6312 74.2169 74.1751 74.2169 74.5108 74.5524L76.4045 76.4461C76.5658 76.6073 76.6562 76.8258 76.6562 77.0539C76.6562 77.282 76.5656 77.5004 76.4045 77.6616L74.5108 79.5553C74.3431 79.7229 74.1232 79.807 73.9032 79.807V79.807ZM73.2249 77.0537L73.9032 77.7321L74.5816 77.0537L73.9032 76.3753L73.2249 77.0537Z"
          fill="#6583FE"
        />
        <path
          d="M34.7919 12.9808C34.3174 12.9808 33.9325 12.596 33.9325 12.1214C33.9325 11.5511 33.4685 11.0871 32.8982 11.0871C32.4236 11.0871 32.0388 10.7022 32.0388 10.2277C32.0388 9.75316 32.4236 9.36833 32.8982 9.36833C33.4685 9.36833 33.9325 8.90427 33.9325 8.33398C33.9325 7.85944 34.3174 7.47461 34.7919 7.47461C35.2665 7.47461 35.6513 7.85944 35.6513 8.33398C35.6513 8.90427 36.1153 9.36833 36.6856 9.36833C37.1602 9.36833 37.545 9.75316 37.545 10.2277C37.545 10.7022 37.1602 11.0871 36.6856 11.0871C36.1153 11.0871 35.6513 11.5511 35.6513 12.1214C35.6513 12.5961 35.2665 12.9808 34.7919 12.9808Z"
          fill="#6583FE"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6637"
          x1="5.13374"
          y1="18.8091"
          x2="85.6797"
          y2="76.1613"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#D2DEFF" />
          <stop offset="0.9993" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6637">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationWebsite;
