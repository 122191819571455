import classNames from "classnames";
import { ClassNameArgument } from "types";
import styles from "./Contacts.module.css";

export type ContactsProps = {
  className?: ClassNameArgument;
  tabIndex?: number;
};

const Contacts = ({ className, tabIndex }: ContactsProps) => {
  return (
    <section className={classNames(styles["contacts"], className)}>
      <p className={styles["contacts-title"]}>Contact</p>
      <address className={styles["contacts-address"]}>
        <a
          href="tel:+421944115309"
          className="external-link"
          tabIndex={tabIndex}
        >
          +421 944 115 309
        </a>
        <a
          href="mailto:hello@uniit.sk"
          className="external-link"
          tabIndex={tabIndex}
        >
          hello@uniit.sk
        </a>
      </address>
    </section>
  );
};

export default Contacts;
