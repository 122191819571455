import { SVGProps } from "types";

const HourglassEnd = (props: SVGProps) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.87516 0.666687C1.29899 0.666687 0.833496 1.13218 0.833496 1.70835C0.833496 2.28453 1.29899 2.75002 1.87516 2.75002V3.10809C1.87516 4.4883 2.42529 5.81317 3.40186 6.78973L5.60889 9.00002L3.40186 11.2103C2.42529 12.1869 1.87516 13.5117 1.87516 14.8919V15.25C1.29899 15.25 0.833496 15.7155 0.833496 16.2917C0.833496 16.8679 1.29899 17.3334 1.87516 17.3334H2.91683H11.2502H12.2918C12.868 17.3334 13.3335 16.8679 13.3335 16.2917C13.3335 15.7155 12.868 15.25 12.2918 15.25V14.8919C12.2918 13.5117 11.7417 12.1869 10.7651 11.2103L8.55811 9.00002L10.7684 6.78973C11.745 5.81317 12.2951 4.4883 12.2951 3.10809V2.75002C12.8713 2.75002 13.3368 2.28453 13.3368 1.70835C13.3368 1.13218 12.8713 0.666687 12.2951 0.666687H11.2502H2.91683H1.87516ZM3.9585 3.10809V2.75002H10.2085V3.10809C10.2085 3.93817 9.87972 4.73244 9.29378 5.31838L7.0835 7.52541L4.87321 5.31512C4.28727 4.73244 3.9585 3.93492 3.9585 3.10809Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HourglassEnd;
