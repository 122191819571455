import { SVGProps } from "types";

const BoxSignIn = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5358)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M9.99975 12.5002C9.4485 12.5002 9 12.0517 9 11.5005C9 10.9492 9.4485 10.5 9.99975 10.5C10.551 10.5 10.9995 10.9485 10.9995 11.4998C10.9995 12.051 10.551 12.5002 9.99975 12.5002Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M11.625 15.0005H8.37451C8.16751 15.0005 7.99951 14.8325 7.99951 14.6255V14.3757C7.99951 13.6175 8.61601 13.001 9.37426 13.001H10.6245C11.3828 13.001 11.9993 13.6175 11.9993 14.3757V14.6255C12 14.8325 11.832 15.0005 11.625 15.0005Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M16.41 12.5059L15.66 11.6306C15.558 11.5121 15.3938 11.4694 15.2453 11.5226C15.0975 11.5781 15 11.7184 15 11.8751V12.2501H13.5C13.2233 12.2501 12.9998 12.4744 12.9998 12.7504C12.9998 13.0264 13.2233 13.2506 13.5 13.2506H15V13.6256C15 13.7824 15.0975 13.9226 15.2453 13.9774C15.288 13.9931 15.3315 14.0006 15.375 14.0006C15.4823 14.0006 15.5873 13.9541 15.66 13.8701L16.41 12.9949C16.5307 12.8539 16.5307 12.6469 16.41 12.5059Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5358">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSignIn;
