import { SVGProps } from "types";

const BoxHierarchy = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5370)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M15.1252 6H8.87475C8.1165 6 7.5 6.6165 7.5 7.37475V16.6245C7.5 17.3835 8.1165 18 8.87475 18H15.1252C15.8835 18 16.5 17.3835 16.5 16.6252V7.37475C16.5 6.6165 15.8835 6 15.1252 6ZM15.5002 16.6252C15.5002 16.8322 15.3322 17.0002 15.1252 17.0002H8.87475C8.66775 17.0002 8.49975 16.8322 8.49975 16.6252V7.37475C8.49975 7.16775 8.66775 6.99975 8.87475 6.99975H15.1252C15.3322 6.99975 15.5002 7.16775 15.5002 7.37475V16.6252Z"
          fill="var(--color-orange-soda)"
        />
        <path
          d="M14.1248 10.8133V9.87427C14.1248 9.11602 13.5083 8.49952 12.75 8.49952H11.4998V8.37427C11.4998 7.89202 11.1075 7.49902 10.6245 7.49902H9.8745C9.39225 7.49977 9 7.89202 9 8.37502V9.12502C9 9.60727 9.39225 10.0003 9.87525 10.0003H10.6252C11.0647 10.0003 11.4263 9.67327 11.4878 9.25027H12.75C13.095 9.25027 13.3748 9.53077 13.3748 9.87502V10.814C12.9885 10.9363 12.6862 11.2393 12.5632 11.6255H11.499C11.499 11.1433 11.1068 10.7503 10.6237 10.7503H9.87375C9.39225 10.7495 9 11.1425 9 11.6248V12.3748C9 12.857 9.39225 13.25 9.87525 13.25H10.6252C11.1075 13.25 11.5005 12.8578 11.5005 12.3748H12.5648C12.6878 12.761 12.99 13.0633 13.3762 13.1863V14.1253C13.3762 14.4703 13.0958 14.75 12.7515 14.75H11.4885C11.427 14.327 11.0655 14 10.626 14H9.876C9.39375 14 9.00075 14.3923 9.00075 14.8753V15.6253C9 16.1075 9.39225 16.4998 9.87525 16.4998H10.6252C11.1075 16.4998 11.5005 16.1075 11.5005 15.6245V15.4993H12.75C13.5083 15.4993 14.1248 14.8828 14.1248 14.1245V13.1855C14.6302 13.0258 15 12.5578 15 11.9998C15 11.4418 14.6302 10.9738 14.1248 10.8133ZM10.7498 9.12502C10.7498 9.19402 10.6935 9.25027 10.6245 9.25027H9.8745C9.8055 9.25027 9.74925 9.19402 9.74925 9.12502V8.37502C9.75 8.30602 9.80625 8.24977 9.87525 8.24977H10.6252C10.6942 8.24977 10.7505 8.30602 10.7505 8.37502V9.12502H10.7498ZM10.7498 12.3748C10.7498 12.4438 10.6935 12.5 10.6245 12.5H9.8745C9.80625 12.5 9.75 12.4438 9.75 12.3748V11.6248C9.75 11.5558 9.80625 11.4995 9.87525 11.4995H10.6252C10.6942 11.4995 10.7505 11.5558 10.7505 11.6248V12.3748H10.7498ZM10.7498 15.6245C10.7498 15.6935 10.6935 15.7498 10.6245 15.7498H9.8745C9.80625 15.7498 9.75 15.6935 9.75 15.6245V14.8745C9.75 14.8055 9.80625 14.7493 9.87525 14.7493H10.6252C10.6942 14.7493 10.7505 14.8055 10.7505 14.8745V15.6245H10.7498Z"
          fill="var(--color-congo-pink)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5370">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxHierarchy;
