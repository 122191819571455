import { SVGProps } from "types";

const BoxProgram = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5434)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M9.50024 9.5C8.94899 9.5 8.50049 9.9485 8.50049 10.4998V12.125C8.50049 12.332 8.66849 12.5 8.87549 12.5C9.08249 12.5 9.25049 12.332 9.25049 12.125V11.5002H9.74999V12.125C9.74999 12.332 9.91799 12.5 10.125 12.5C10.332 12.5 10.5 12.332 10.5 12.125V10.4998C10.5 9.9485 10.0515 9.5 9.50024 9.5ZM9.24974 10.7495V10.4998C9.24974 10.3618 9.36149 10.25 9.49949 10.25C9.63749 10.25 9.74999 10.3618 9.74999 10.4998V10.7495H9.24974Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M14.8755 12.5C14.6685 12.5 14.5005 12.332 14.5005 12.125V9.875C14.5005 9.668 14.6685 9.5 14.8755 9.5C15.0825 9.5 15.2505 9.668 15.2505 9.875V12.125C15.2505 12.332 15.0825 12.5 14.8755 12.5Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M11.8748 12.5C11.6678 12.5 11.4998 12.332 11.4998 12.125V9.875C11.4998 9.668 11.6678 9.5 11.8748 9.5H12.4995C13.0515 9.5 13.5 9.9485 13.5 10.4998C13.5 11.051 13.0515 11.4995 12.5003 11.4995H12.2505V12.1243C12.2498 12.332 12.0818 12.5 11.8748 12.5ZM12.2498 10.7495H12.4995C12.6383 10.7495 12.75 10.6378 12.75 10.4998C12.75 10.3618 12.6383 10.25 12.5003 10.25H12.2505V10.7495H12.2498Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M17.1255 7H6.8745C6.39225 7 6 7.393 6 7.87525V14.125C6 14.608 6.39225 15.0002 6.8745 15.0002H10.9995V16H9.99975C9.72375 16 9.4995 16.2242 9.4995 16.5002C9.4995 16.7762 9.72375 17.0005 9.99975 17.0005H14.0002C14.277 17.0005 14.5005 16.7762 14.5005 16.5002C14.5005 16.2242 14.277 16 14.0002 16H13.0005V15.0002H17.1255C17.6077 15.0002 18 14.608 18 14.125V7.87525C18 7.393 17.6077 7 17.1255 7ZM17.0002 14.0005H6.99975V8.0005H17.0002V14.0005Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5434">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxProgram;
