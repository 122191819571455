import { SVGProps } from "types";

const BoxWebLink = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5455)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-cyber-yellow)"
        />
        <path
          d="M11.8649 14.2421C11.4479 14.2421 11.0324 14.0808 10.7152 13.7636L9.08466 12.1278C8.77716 11.8241 8.73291 11.5413 8.73291 11.1063C8.73291 10.6706 8.90316 10.2626 9.21216 9.95656C9.80466 9.35131 10.8914 9.34382 11.5064 9.95807L11.6609 10.1133C11.8072 10.2596 11.8072 10.4973 11.6609 10.6436C11.5139 10.7898 11.2769 10.7891 11.1307 10.6436L10.9762 10.4883C10.6469 10.1591 10.0627 10.1598 9.74391 10.4853C9.57366 10.6533 9.48291 10.8716 9.48291 11.1056C9.48291 11.3396 9.57441 11.5578 9.73941 11.7213L11.2462 13.2333C11.5657 13.5528 12.0697 13.5783 12.4184 13.2926C12.5774 13.1621 12.8152 13.1846 12.9457 13.3451C13.0769 13.5056 13.0537 13.7418 12.8932 13.8731C12.5909 14.1206 12.2272 14.2421 11.8649 14.2421Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M13.6424 16.0174C13.2067 16.0174 12.7987 15.8471 12.4927 15.5389L12.3494 15.3949C12.2032 15.2486 12.2032 15.0109 12.3502 14.8646C12.4972 14.7184 12.7349 14.7184 12.8812 14.8654L13.0259 15.0101C13.1902 15.1766 13.4092 15.2674 13.6432 15.2674C13.8772 15.2674 14.0954 15.1766 14.2589 15.0116C14.4269 14.8451 14.5184 14.6269 14.5184 14.3929C14.5184 14.1589 14.4277 13.9406 14.2619 13.7771L12.7552 12.2651C12.4477 11.9576 11.9144 11.9329 11.5844 12.2044C11.4254 12.3356 11.1892 12.3139 11.0564 12.1541C10.9244 11.9944 10.9469 11.7581 11.1067 11.6261C11.7217 11.1176 12.7139 11.1634 13.2854 11.7349L14.7907 13.2454C15.0982 13.5491 15.2677 13.9571 15.2677 14.3929C15.2677 14.8279 15.0974 15.2359 14.7884 15.5419C14.4862 15.8479 14.0782 16.0174 13.6424 16.0174Z"
          fill="var(--color-shandy)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5455">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxWebLink;
