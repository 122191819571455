import { SVGProps } from "types";

const BoxDesktop = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5388)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M17.1255 8H16.8652C16.7452 8.35025 16.5352 8.65475 16.2705 8.9C16.3103 8.93 16.3507 8.96525 16.3853 8.99975H17.0002V14.4995H6.99975V8.99975H7.61475C7.65 8.9645 7.68975 8.93 7.7295 8.9C7.46475 8.65475 7.25475 8.35025 7.13475 8H6.8745C6.3945 8 6 8.3945 6 8.8745V15.6245C6 16.1045 6.3945 16.4998 6.8745 16.4998H10.704C10.6455 16.7502 10.4895 17.1567 10.0732 17.573C10.002 17.6442 9.98025 17.7523 10.0192 17.8453C10.0575 17.939 10.149 17.9998 10.2502 17.9998H13.7505C13.8517 17.9998 13.9433 17.939 13.9815 17.8453C14.0198 17.7515 13.9987 17.6442 13.9275 17.573C13.512 17.1575 13.3522 16.751 13.29 16.4998H17.1255C17.6055 16.4998 18 16.1045 18 15.6245V8.8745C18 8.3945 17.6055 8 17.1255 8Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M15.1253 9.50025H14.3753C13.893 9.50025 13.5 9.8925 13.5 10.3755H12.375V7.62525H13.5638C13.7243 8.13075 14.1923 8.5005 14.7503 8.5005C15.4395 8.5005 16.0005 7.9395 16.0005 7.25025C16.0005 6.561 15.4395 6 14.7503 6C14.1923 6 13.7243 6.36975 13.5638 6.87525H10.4363C10.2758 6.36975 9.80776 6 9.24976 6C8.56051 6 7.99951 6.561 7.99951 7.25025C7.99951 7.9395 8.56051 8.5005 9.24976 8.5005C9.80776 8.5005 10.2758 8.13075 10.4363 7.62525H11.625V10.3755H10.5C10.5 9.89325 10.1078 9.50025 9.62476 9.50025H8.87476C8.39251 9.50025 7.99951 9.8925 7.99951 10.3755V11.1255C7.99951 11.6077 8.39176 12.0007 8.87476 12.0007H9.62476C10.107 12 10.5 11.6077 10.5 11.1248H13.5C13.5 11.6077 13.893 12 14.3753 12H15.1253C15.6075 12 16.0005 11.6077 16.0005 11.1248V10.3748C16.0005 9.8925 15.6075 9.50025 15.1253 9.50025Z"
          fill="var(--color-mauve)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5388">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxDesktop;
