import { SVGProps } from "types";

const BoxSpreadsheet = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5381)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M15.1253 10.5H11.4998V12.5002H15.1253C15.33 12.5002 15.5003 12.33 15.5003 12.1252V10.875C15.5003 10.6702 15.33 10.5 15.1253 10.5ZM8.49976 10.875V12.1252C8.49976 12.33 8.67001 12.5002 8.87476 12.5002H10.5V10.5H8.87476C8.67001 10.5 8.49976 10.6702 8.49976 10.875Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M15.1253 13.5H11.4998V15.5002H15.1253C15.33 15.5002 15.5003 15.33 15.5003 15.1252V13.875C15.5003 13.6702 15.33 13.5 15.1253 13.5ZM8.49976 13.875V15.1252C8.49976 15.33 8.67001 15.5002 8.87476 15.5002H10.5V13.5H8.87476C8.67001 13.5 8.49976 13.6702 8.49976 13.875Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5381">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSpreadsheet;
