import { SVGProps } from "types";

const BoxCloudNetwork = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5361)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M15.0547 7.69425C14.4472 6.6585 13.2712 6 12 6C10.3837 6 8.97899 7.06125 8.60699 8.51475C7.42274 8.64375 6.50024 9.597 6.50024 10.7498C6.50024 11.9903 7.56824 12.9998 8.88074 12.9998H14.6745C16.233 12.9998 17.5005 11.8035 17.5005 10.3328C17.5005 8.98875 16.4422 7.866 15.0547 7.69425ZM14.6737 12H8.87999C8.11874 12 7.49999 11.439 7.49999 10.7498C7.49999 10.1175 8.02199 9.5835 8.71499 9.5085L9.40349 9.4335L9.57524 8.76225C9.83699 7.74075 10.857 6.999 12 6.999C12.9037 6.999 13.764 7.47 14.1922 8.199L14.442 8.625L14.9317 8.68575C15.8257 8.7975 16.5 9.5055 16.5 10.3335C16.5 11.2522 15.681 12 14.6737 12Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.5005 15.7502H14.0002V15.3752C14.0002 14.893 13.608 14.5 13.125 14.5H12.5002V12.7502C12.5002 12.4742 12.276 12.25 12 12.25C11.724 12.25 11.4998 12.4742 11.4998 12.7502V14.5H10.875C10.3927 14.5 9.99975 14.8922 9.99975 15.3752V15.7502H6.4995C6.2235 15.7502 6 15.9745 6 16.2505C6 16.5265 6.22425 16.7507 6.50025 16.7507H10.0005V17.1257C10.0005 17.608 10.3928 18.001 10.8758 18.001H13.1257C13.608 18.001 14.001 17.6087 14.001 17.1257V16.7507H17.5012C17.7772 16.7507 18.0015 16.5265 18.0015 16.2505C18.0015 15.9745 17.7765 15.7502 17.5005 15.7502Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5361">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxCloudNetwork;
