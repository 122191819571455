import { useCallback } from "react";

const useToggleBodyNoScrollAttribute = () => {
  const toggleBodyNoScrollAttribute = useCallback((force?: boolean) => {
    document.body.toggleAttribute("data-no-scroll", force);
  }, []);

  return toggleBodyNoScrollAttribute;
};

export default useToggleBodyNoScrollAttribute;
