import { SVGProps } from "types";

const IllustrationHome = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6605)">
        <path
          d="M83.0103 24.7026C77.0041 18.5241 67.6309 17.6718 60.6956 22.1455C58.7225 23.4182 56.3332 23.845 54.0411 23.3361C48.6159 22.1314 42.9407 22.9542 38.1071 25.5777C34.8574 27.3413 30.8993 27.0346 27.8669 24.9194C25.8539 23.5153 23.5164 22.5092 20.9478 22.0358C11.4194 20.28 2.15547 26.5996 0.323798 36.1138C-1.54947 45.8434 4.90049 55.1941 14.6231 56.9258C16.9986 57.3489 19.3476 57.2776 21.5648 56.7855C25.1632 55.987 28.9228 57.3221 31.245 60.1845C32.7107 61.9913 34.4783 63.6038 36.5313 64.9436C47.1414 71.8667 61.3547 68.8778 68.2779 58.2679C68.2852 58.2568 68.2925 58.2454 68.2999 58.2343C69.5678 56.2836 71.5399 54.893 73.8205 54.4325C77.1468 53.761 80.3175 52.1287 82.8879 49.535C89.6552 42.7062 89.7115 31.5966 83.0103 24.7026Z"
          fill="url(#paint0_linear_2042_6605)"
        />
        <path
          opacity="0.1"
          d="M78.3754 18.0566V61.7559C78.3754 63.179 77.2204 64.334 75.7973 64.334H12.2035C10.7804 64.334 9.62537 63.179 9.62537 61.7559V18.0566C9.62537 16.6335 10.7804 15.4785 12.2035 15.4785H75.7973C77.2204 15.4785 78.3754 16.6335 78.3754 18.0566Z"
          fill="#2626BC"
        />
        <path
          d="M53.7474 80.4405H34.2532L38.1516 64.9883H49.8489L53.7474 80.4405Z"
          fill="#9FB0FE"
        />
        <path
          d="M51.2067 70.3683H36.7938L38.1516 64.9883H49.8489L51.2067 70.3683Z"
          fill="#8399FE"
        />
        <path
          d="M78.3746 21.666V65.3652C78.3746 66.7884 77.2197 67.9434 75.7965 67.9434H12.2028C10.7796 67.9434 9.62463 66.7884 9.62463 65.3652V21.666C9.62463 20.2429 10.7796 19.0879 12.2028 19.0879H75.7965C77.2197 19.0879 78.3746 20.2427 78.3746 21.666Z"
          fill="white"
        />
        <path
          d="M75.7965 19.0879H63.9852V67.9434H75.7965C77.2196 67.9434 78.3746 66.7884 78.3746 65.3652V21.666C78.3746 20.2427 77.2196 19.0879 75.7965 19.0879Z"
          fill="#FFC4DF"
        />
        <path
          d="M44.0004 34.126L29.2191 46.6098H32.6566C32.9414 46.6098 33.1722 46.8406 33.1722 47.1254V60.85H54.8285V47.1254C54.8285 46.8406 55.0593 46.6098 55.3441 46.6098H58.7816L44.0004 34.126Z"
          fill="#FFA8CF"
        />
        <path
          d="M58.7812 47.4691C58.5853 47.4691 58.3885 47.4025 58.2271 47.2663L44.0003 35.2509L29.7735 47.2663C29.411 47.5724 28.8686 47.5271 28.5625 47.1642C28.2562 46.8016 28.3019 46.2593 28.6646 45.953L43.4458 33.4692C43.766 33.1991 44.2346 33.1991 44.5548 33.4692L59.336 45.953C59.6987 46.2593 59.7442 46.8016 59.4381 47.1642C59.2681 47.3657 59.0254 47.4691 58.7812 47.4691Z"
          fill="#FF7EB8"
        />
        <path
          d="M44.7451 60.8506H37.5264V49.2207C37.5264 48.7462 37.9112 48.3613 38.3857 48.3613H43.8857C44.3603 48.3613 44.7451 48.7462 44.7451 49.2207V60.8506Z"
          fill="#6583FE"
        />
        <path
          d="M49.0296 52.7146C50.232 52.7146 51.2067 51.7399 51.2067 50.5375C51.2067 49.3351 50.232 48.3604 49.0296 48.3604C47.8272 48.3604 46.8524 49.3351 46.8524 50.5375C46.8524 51.7399 47.8272 52.7146 49.0296 52.7146Z"
          fill="#6583FE"
        />
        <path
          d="M78.3754 27.3683V21.667C78.3754 20.2432 77.2211 19.0889 75.7973 19.0889H12.2035C10.7797 19.0889 9.62537 20.2432 9.62537 21.667V27.3683H78.3754Z"
          fill="#6583FE"
        />
        <path
          d="M9.62537 59.2832V65.3659C9.62537 66.7897 10.7797 67.944 12.2035 67.944H75.7973C77.2211 67.944 78.3754 66.7897 78.3754 65.3659V59.2832H9.62537Z"
          fill="#B7C5FF"
        />
        <path
          d="M16.1707 24.1003C16.6452 24.1003 17.03 23.7156 17.03 23.2409V23.2158C17.03 22.7411 16.6452 22.3564 16.1707 22.3564C15.6961 22.3564 15.3113 22.7411 15.3113 23.2158V23.2409C15.3113 23.7155 15.6961 24.1003 16.1707 24.1003Z"
          fill="#2626BC"
        />
        <path
          d="M19.4922 24.1003C19.9667 24.1003 20.3516 23.7156 20.3516 23.2409V23.2158C20.3516 22.7411 19.9667 22.3564 19.4922 22.3564C19.0176 22.3564 18.6328 22.7411 18.6328 23.2158V23.2409C18.6328 23.7155 19.0175 24.1003 19.4922 24.1003Z"
          fill="#2626BC"
        />
        <path
          d="M22.8135 24.1003C23.288 24.1003 23.6729 23.7156 23.6729 23.2409V23.2158C23.6729 22.7411 23.288 22.3564 22.8135 22.3564C22.3389 22.3564 21.9541 22.7411 21.9541 23.2158V23.2409C21.9541 23.7155 22.3389 24.1003 22.8135 24.1003Z"
          fill="#2626BC"
        />
        <path
          d="M75.7973 18.2295H12.2035C10.3081 18.2295 8.76599 19.7716 8.76599 21.667V65.3659C8.76599 67.2613 10.3081 68.8034 12.2035 68.8034H36.3028L33.5839 79.5801H30.9492C30.4747 79.5801 30.0898 79.9647 30.0898 80.4395C30.0898 80.9142 30.4747 81.2988 30.9492 81.2988H57.0515C57.5261 81.2988 57.9109 80.9142 57.9109 80.4395C57.9109 79.9647 57.5261 79.5801 57.0515 79.5801H54.4167L51.6978 68.8034H57.6655C58.14 68.8034 58.5248 68.4187 58.5248 67.944C58.5248 67.4693 58.14 67.0846 57.6655 67.0846H12.2035C11.2558 67.0846 10.4847 66.3136 10.4847 65.3659V60.1426H77.516V65.3659C77.516 66.3136 76.745 67.0846 75.7973 67.0846H62.3989C61.9244 67.0846 61.5395 67.4693 61.5395 67.944C61.5395 68.4187 61.9244 68.8034 62.3989 68.8034H75.7973C77.6927 68.8034 79.2348 67.2613 79.2348 65.3659V21.667C79.2348 19.7717 77.6927 18.2295 75.7973 18.2295ZM52.644 79.5799H35.3564L38.0753 68.8032H49.9252L52.644 79.5799ZM10.4847 58.4237V21.667C10.4847 20.7193 11.2558 19.9482 12.2035 19.9482H75.7973C76.745 19.9482 77.516 20.7193 77.516 21.667V26.5089H14.3588C13.8843 26.5089 13.4994 26.8935 13.4994 27.3683C13.4994 27.843 13.8843 28.2276 14.3588 28.2276H77.5158V58.4238H10.4847V58.4237Z"
          fill="#2626BC"
        />
        <path
          d="M21.3632 37.686H15.4187C14.9433 37.686 14.5581 37.3007 14.5581 36.8254C14.5581 36.3502 14.9433 35.9648 15.4187 35.9648H21.3632C21.8386 35.9648 22.2237 36.3502 22.2237 36.8254C22.2237 37.3007 21.8384 37.686 21.3632 37.686Z"
          fill="#B7C5FF"
        />
        <path
          d="M73.785 43.7993H67.8406C67.3652 43.7993 66.98 43.4139 66.98 42.9387C66.98 42.4635 67.3652 42.0781 67.8406 42.0781H73.785C74.2604 42.0781 74.6456 42.4635 74.6456 42.9387C74.6456 43.4141 74.2603 43.7993 73.785 43.7993Z"
          fill="#FFA8CF"
        />
        <path
          d="M73.785 47.4702H67.8406C67.3652 47.4702 66.98 47.0848 66.98 46.6096C66.98 46.1344 67.3652 45.749 67.8406 45.749H73.785C74.2604 45.749 74.6456 46.1344 74.6456 46.6096C74.6456 47.0848 74.2603 47.4702 73.785 47.4702Z"
          fill="#FFA8CF"
        />
        <path
          d="M73.785 51.1411H67.8406C67.3652 51.1411 66.98 50.7557 66.98 50.2805C66.98 49.8053 67.3652 49.4199 67.8406 49.4199H73.785C74.2604 49.4199 74.6456 49.8053 74.6456 50.2805C74.6456 50.7557 74.2603 51.1411 73.785 51.1411Z"
          fill="#FFA8CF"
        />
        <path
          d="M28.5819 33.0825H15.4187C14.9433 33.0825 14.5581 32.6971 14.5581 32.2219C14.5581 31.7467 14.9433 31.3613 15.4187 31.3613H28.5819C29.0573 31.3613 29.4425 31.7467 29.4425 32.2219C29.4425 32.6971 29.0571 33.0825 28.5819 33.0825Z"
          fill="#02FFB3"
        />
        <path
          d="M21.3632 40.7426H15.4187C14.9433 40.7426 14.5581 40.3573 14.5581 39.8821C14.5581 39.4068 14.9433 39.0215 15.4187 39.0215H21.3632C21.8386 39.0215 22.2237 39.4068 22.2237 39.8821C22.2237 40.3573 21.8384 40.7426 21.3632 40.7426Z"
          fill="#FFA8CF"
        />
        <path
          d="M21.3632 43.7993H15.4187C14.9433 43.7993 14.5581 43.4139 14.5581 42.9387C14.5581 42.4635 14.9433 42.0781 15.4187 42.0781H21.3632C21.8386 42.0781 22.2237 42.4635 22.2237 42.9387C22.2237 43.4141 21.8384 43.7993 21.3632 43.7993Z"
          fill="#FFA8CF"
        />
        <path
          d="M67.9207 33.052C68.4525 33.052 68.8837 32.6209 68.8837 32.089C68.8837 31.5571 68.4525 31.126 67.9207 31.126C67.3888 31.126 66.9576 31.5571 66.9576 32.089C66.9576 32.6209 67.3888 33.052 67.9207 33.052Z"
          fill="#FF7EB8"
        />
        <path
          d="M67.9207 36.4807C68.4525 36.4807 68.8837 36.0496 68.8837 35.5177C68.8837 34.9858 68.4525 34.5547 67.9207 34.5547C67.3888 34.5547 66.9576 34.9858 66.9576 35.5177C66.9576 36.0496 67.3888 36.4807 67.9207 36.4807Z"
          fill="#FF7EB8"
        />
        <path
          d="M67.9207 39.9094C68.4525 39.9094 68.8837 39.4783 68.8837 38.9464C68.8837 38.4146 68.4525 37.9834 67.9207 37.9834C67.3888 37.9834 66.9576 38.4146 66.9576 38.9464C66.9576 39.4783 67.3888 39.9094 67.9207 39.9094Z"
          fill="#FF7EB8"
        />
        <path
          d="M20.3516 50.2763C20.3516 48.7019 19.0706 47.4209 17.4962 47.4209C15.9219 47.4209 14.6409 48.7019 14.6409 50.2763C14.6409 51.0528 14.9533 51.757 15.4581 52.2722C14.9535 52.7875 14.6409 53.4917 14.6409 54.2682C14.6409 54.7429 15.0257 55.1276 15.5002 55.1276C15.9748 55.1276 16.3596 54.7429 16.3596 54.2682C16.3596 53.6416 16.8696 53.1316 17.4962 53.1316C18.1229 53.1316 18.6328 53.6416 18.6328 54.2682C18.6328 54.7429 19.0177 55.1276 19.4922 55.1276C19.9668 55.1276 20.3516 54.7429 20.3516 54.2682C20.3516 53.4917 20.0391 52.7875 19.5343 52.2722C20.0391 51.757 20.3516 51.053 20.3516 50.2763ZM17.4961 49.1396C18.1227 49.1396 18.6327 49.6496 18.6327 50.2763C18.6327 50.9029 18.1227 51.4129 17.4961 51.4129C16.8694 51.4129 16.3594 50.9029 16.3594 50.2763C16.3594 49.6496 16.8694 49.1396 17.4961 49.1396Z"
          fill="#6583FE"
        />
        <path
          d="M68.8837 12.2067C68.4092 12.2067 68.0244 11.822 68.0244 11.3473C68.0244 10.7772 67.5605 10.3133 66.9904 10.3133C66.5158 10.3133 66.131 9.92864 66.131 9.45392C66.131 8.9792 66.5158 8.59455 66.9904 8.59455C67.5605 8.59455 68.0244 8.13083 68.0244 7.56055C68.0244 7.08583 68.4092 6.70117 68.8837 6.70117C69.3583 6.70117 69.7431 7.08583 69.7431 7.56055C69.7431 8.13066 70.207 8.59455 70.7771 8.59455C71.2517 8.59455 71.6365 8.9792 71.6365 9.45392C71.6365 9.92864 71.2517 10.3133 70.7771 10.3133C70.207 10.3133 69.7431 10.777 69.7431 11.3473C69.7431 11.8222 69.3585 12.2067 68.8837 12.2067Z"
          fill="#97FFD2"
        />
        <path
          d="M28.4359 75.8014C27.9613 75.8014 27.5765 75.4168 27.5765 74.9421C27.5765 74.3719 27.1126 73.908 26.5425 73.908C26.0679 73.908 25.6831 73.5234 25.6831 73.0487C25.6831 72.5739 26.0679 72.1893 26.5425 72.1893C27.1126 72.1893 27.5765 71.7256 27.5765 71.1553C27.5765 70.6806 27.9613 70.2959 28.4359 70.2959C28.9104 70.2959 29.2952 70.6806 29.2952 71.1553C29.2952 71.7254 29.7591 72.1893 30.3292 72.1893C30.8038 72.1893 31.1886 72.5739 31.1886 73.0487C31.1886 73.5234 30.8038 73.908 30.3292 73.908C29.7591 73.908 29.2952 74.3718 29.2952 74.9421C29.2952 75.4168 28.9104 75.8014 28.4359 75.8014Z"
          fill="#6583FE"
        />
        <path
          d="M18.3909 78.3248C17.9164 78.3248 17.5316 77.9402 17.5316 77.4655C17.5316 76.8954 17.0677 76.4315 16.4976 76.4315C16.023 76.4315 15.6382 76.0468 15.6382 75.5721C15.6382 75.0974 16.023 74.7127 16.4976 74.7127C17.0677 74.7127 17.5316 74.249 17.5316 73.6787C17.5316 73.204 17.9164 72.8193 18.3909 72.8193C18.8655 72.8193 19.2503 73.204 19.2503 73.6787C19.2503 74.2488 19.7142 74.7127 20.2843 74.7127C20.7589 74.7127 21.1437 75.0974 21.1437 75.5721C21.1437 76.0468 20.7589 76.4315 20.2843 76.4315C19.7142 76.4315 19.2503 76.8952 19.2503 77.4655C19.2503 77.9398 18.8655 78.3248 18.3909 78.3248Z"
          fill="#6583FE"
        />
        <path
          d="M59.5175 14.4364C59.2975 14.4364 59.0777 14.3525 58.9098 14.1846L57.0202 12.295C56.6845 11.9595 56.6845 11.4153 57.0202 11.0798L58.9098 9.19023C59.2454 8.85456 59.7894 8.85456 60.1251 9.19023L62.0147 11.0798C62.3504 11.4153 62.3504 11.9595 62.0147 12.295L60.1253 14.1847C59.9574 14.3525 59.7375 14.4364 59.5175 14.4364ZM58.8433 11.6872L59.5175 12.3615L60.1918 11.6872L59.5175 11.013L58.8433 11.6872Z"
          fill="#FF7EB8"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6605"
          x1="-2.48705"
          y1="47.4835"
          x2="91.6902"
          y2="41.5277"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#FFDBED" />
          <stop offset="0.9993" stopColor="#D2DEFF" />
        </linearGradient>
        <clipPath id="clip0_2042_6605">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationHome;
