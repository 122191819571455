import { SVGProps } from "types";
const Link = (props: SVGProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66659 0.333496C8.20565 0.333496 7.83325 0.705892 7.83325 1.16683C7.83325 1.62777 8.20565 2.00016 8.66659 2.00016H10.8202L5.57804 7.24495C5.25252 7.57048 5.25252 8.09912 5.57804 8.42464C5.90356 8.75016 6.43221 8.75016 6.75773 8.42464L11.9999 3.17985V5.3335C11.9999 5.79443 12.3723 6.16683 12.8333 6.16683C13.2942 6.16683 13.6666 5.79443 13.6666 5.3335V1.16683C13.6666 0.705892 13.2942 0.333496 12.8333 0.333496H8.66659ZM2.41659 1.16683C1.26554 1.16683 0.333252 2.09912 0.333252 3.25016V11.5835C0.333252 12.7345 1.26554 13.6668 2.41659 13.6668H10.7499C11.901 13.6668 12.8333 12.7345 12.8333 11.5835V8.66683C12.8333 8.20589 12.4609 7.8335 11.9999 7.8335C11.539 7.8335 11.1666 8.20589 11.1666 8.66683V11.5835C11.1666 11.8127 10.9791 12.0002 10.7499 12.0002H2.41659C2.18742 12.0002 1.99992 11.8127 1.99992 11.5835V3.25016C1.99992 3.021 2.18742 2.8335 2.41659 2.8335H5.33325C5.79419 2.8335 6.16659 2.4611 6.16659 2.00016C6.16659 1.53923 5.79419 1.16683 5.33325 1.16683H2.41659Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Link;
