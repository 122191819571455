import { SVGProps } from "types";

const IllustrationError = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M86.9044 50.174C84.8718 44.2254 80.3495 39.834 74.8986 37.7642C72.0747 36.6919 70.1985 34.0077 70.1571 30.9874C70.1562 30.9214 70.155 30.8552 70.1535 30.789C69.8945 19.689 60.6165 10.7948 49.5153 10.9854C42.9678 11.0978 37.1767 14.3121 33.5472 19.2217C30.4217 23.4496 25.5567 26.0907 20.2991 26.0879C20.1447 26.0879 19.9902 26.0895 19.835 26.0929C9.19304 26.3256 0.143819 35.4563 0.00185036 46.0998C-0.1494 57.4317 8.99126 66.6654 20.2886 66.6654C29.0451 66.6654 36.5048 61.1178 39.3471 53.3456C40.0908 51.3119 42.2951 50.2219 44.3801 50.8066C44.4061 50.8139 44.4322 50.8212 44.4583 50.8285C46.366 51.3551 47.6308 53.1728 47.4748 55.1458C47.2656 57.7897 47.5746 60.5177 48.4713 63.1889C51.9983 73.6964 63.4567 79.4628 73.9935 76.0245C84.772 72.5071 90.5587 60.8677 86.9044 50.174Z"
        fill="url(#paint0_linear_2042_6635)"
      />
      <path
        opacity="0.1"
        d="M78.375 35.0172V19.0364C78.375 17.6133 77.22 16.4583 75.7969 16.4583H50.8525L45.4773 7.42211C44.8109 6.302 43.1893 6.302 42.5229 7.42211L37.1477 16.4583H12.2031C10.78 16.4583 9.625 17.6133 9.625 19.0364V35.0172H78.375Z"
        fill="#2626BC"
      />
      <path
        d="M53.7469 81.42H34.2527L38.1512 65.9678H49.8485L53.7469 81.42Z"
        fill="#9FB0FE"
      />
      <path
        d="M51.2064 71.3476H36.7935L38.1513 65.9678H49.8486L51.2064 71.3476Z"
        fill="#8399FE"
      />
      <path
        d="M78.375 22.6465V66.3457C78.375 67.7688 77.22 68.9238 75.7969 68.9238H12.2031C10.78 68.9238 9.625 67.7688 9.625 66.3457V22.6465C9.625 21.2234 10.78 20.0684 12.2031 20.0684H75.7969C77.22 20.0684 78.375 21.2234 78.375 22.6465Z"
        fill="white"
      />
      <path
        d="M23.819 49.8744H64.1805C65.5136 49.8744 66.3393 48.4226 65.6576 47.277L45.4771 13.3518C44.8107 12.2317 43.1891 12.2317 42.5227 13.3518L22.3419 47.277C21.6604 48.4227 22.4859 49.8744 23.819 49.8744Z"
        fill="#D2DEFF"
      />
      <path
        d="M78.375 35.5328V22.6465C78.375 21.2234 77.22 20.0684 75.7969 20.0684H12.2031C10.78 20.0684 9.625 21.2234 9.625 22.6465V35.5328H78.375Z"
        fill="#FFC4DF"
      />
      <path
        d="M78.375 28.3477V22.6465C78.375 21.2227 77.2207 20.0684 75.7969 20.0684H12.2031C10.7793 20.0684 9.625 21.2227 9.625 22.6465V28.3477H78.375Z"
        fill="#FF7EB8"
      />
      <path
        d="M9.625 60.2627V66.3453C9.625 67.7692 10.7793 68.9235 12.2031 68.9235H75.7969C77.2207 68.9235 78.375 67.7692 78.375 66.3453V60.2627H9.625Z"
        fill="#B7C5FF"
      />
      <path
        d="M16.1704 25.0796C15.6957 25.0796 15.311 24.6948 15.311 24.2202V24.1953C15.311 23.7208 15.6957 23.3359 16.1704 23.3359C16.6451 23.3359 17.0298 23.7208 17.0298 24.1953V24.2202C17.0298 24.6948 16.6451 25.0796 16.1704 25.0796Z"
        fill="#2626BC"
      />
      <path
        d="M19.4917 25.0796C19.017 25.0796 18.6323 24.6948 18.6323 24.2202V24.1953C18.6323 23.7208 19.017 23.3359 19.4917 23.3359C19.9664 23.3359 20.3511 23.7208 20.3511 24.1953V24.2202C20.3511 24.6948 19.9664 25.0796 19.4917 25.0796Z"
        fill="#2626BC"
      />
      <path
        d="M22.8132 25.0796C22.3385 25.0796 21.9539 24.6948 21.9539 24.2202V24.1953C21.9539 23.7208 22.3385 23.3359 22.8132 23.3359C23.288 23.3359 23.6726 23.7208 23.6726 24.1953V24.2202C23.6726 24.6948 23.288 25.0796 22.8132 25.0796Z"
        fill="#2626BC"
      />
      <path
        d="M23.819 47.5541H64.1805C65.5136 47.5541 66.3393 46.1022 65.6576 44.9567L45.4771 11.0315C44.8107 9.91138 43.1891 9.91138 42.5227 11.0315L22.3419 44.9567C21.6604 46.1024 22.4859 47.5541 23.819 47.5541Z"
        fill="#02FFB3"
      />
      <path
        d="M75.7969 19.2088H55.5765C55.1017 19.2088 54.7171 19.5937 54.7171 20.0682C54.7171 20.5428 55.1017 20.9276 55.5765 20.9276H75.7969C76.7446 20.9276 77.5156 21.6986 77.5156 22.6463V27.4882H56.2665L46.2156 10.592C45.7463 9.80314 44.9178 9.33203 43.9997 9.33203C43.0817 9.33203 42.2534 9.80314 41.7842 10.5922L36.6584 19.2088H12.2031C10.3077 19.2088 8.76562 20.7509 8.76562 22.6463V66.345C8.76562 68.2405 10.3077 69.7825 12.2031 69.7825H36.3026L34.6337 76.3975C34.5175 76.8578 34.7964 77.3249 35.2567 77.4409C35.3272 77.4588 35.3978 77.4672 35.4676 77.4672C35.8519 77.4672 36.2019 77.2075 36.3002 76.8179L38.0751 69.7824H49.925L52.6439 80.5591H30.9488C30.4741 80.5591 30.0895 80.9439 30.0895 81.4185C30.0895 81.893 30.4741 82.2778 30.9488 82.2778H57.051C57.5257 82.2778 57.9104 81.893 57.9104 81.4185C57.9104 80.9439 57.5257 80.5591 57.051 80.5591H54.4161L51.6973 69.7824H75.7969C77.6923 69.7824 79.2344 68.2403 79.2344 66.3449V22.6463C79.2344 20.7509 77.6923 19.2088 75.7969 19.2088ZM77.5156 29.207V59.403H10.4844V29.207H30.7111L21.6037 44.5173C21.1234 45.3244 21.1138 46.2934 21.5782 47.1098C22.0426 47.9262 22.8802 48.4135 23.8193 48.4135H64.181C65.1202 48.4135 65.9579 47.9262 66.4221 47.1098C66.8864 46.2934 66.8769 45.3244 66.3967 44.5174L57.2892 29.2071L77.5156 29.207ZM43.2615 11.4708C43.4871 11.0917 43.8529 11.051 44 11.0508C44.1468 11.0508 44.513 11.0917 44.7387 11.4708L64.9194 45.396C65.1501 45.7838 65.0021 46.1296 64.928 46.2601C64.8536 46.3907 64.632 46.6946 64.1809 46.6946H23.8191C23.368 46.6946 23.1464 46.3907 23.072 46.2601C22.9977 46.1294 22.8499 45.7838 23.0806 45.3959L43.2615 11.4708ZM12.2031 20.9276H35.6362L31.7335 27.4882H10.4844V22.6463C10.4844 21.6986 11.2554 20.9276 12.2031 20.9276ZM75.7969 68.0638H12.2031C11.2554 68.0638 10.4844 67.2928 10.4844 66.345V61.1217H77.5156V66.345C77.5156 67.2928 76.7446 68.0638 75.7969 68.0638Z"
        fill="#2626BC"
      />
      <path
        d="M44 36.0723C43.0509 36.0723 42.2812 35.3028 42.2812 34.3535V23.0527C42.2812 22.1035 43.0509 21.334 44 21.334C44.9491 21.334 45.7188 22.1035 45.7188 23.0527V34.3535C45.7188 35.3028 44.9491 36.0723 44 36.0723Z"
        fill="white"
      />
      <path
        d="M44 41.4104C43.0509 41.4104 42.2812 40.6409 42.2812 39.6916V39.6719C42.2812 38.7226 43.0509 37.9531 44 37.9531C44.9491 37.9531 45.7188 38.7226 45.7188 39.6719V39.6916C45.7188 40.6409 44.9491 41.4104 44 41.4104Z"
        fill="white"
      />
      <path
        d="M21.1584 55.502C20.9385 55.502 20.7184 55.4181 20.5508 55.2504C20.1486 54.8484 19.4945 54.848 19.0924 55.2504C18.7571 55.5859 18.2126 55.5859 17.8773 55.2504C17.7094 55.0825 17.6255 54.8626 17.6255 54.6426C17.6255 54.4226 17.7094 54.2028 17.8773 54.0349C18.072 53.8402 18.1793 53.5811 18.1793 53.3056C18.1793 53.0301 18.072 52.7713 17.8773 52.5765C17.7094 52.4088 17.6255 52.1888 17.6255 51.9689C17.6255 51.7489 17.7094 51.5289 17.8771 51.3612C18.2128 51.0255 18.7568 51.0253 19.0924 51.3612C19.2873 51.5559 19.5464 51.6632 19.8217 51.6632C20.0971 51.6632 20.3559 51.5559 20.5506 51.3612C20.8863 51.0255 21.4303 51.0253 21.766 51.3612C21.9339 51.5289 22.0178 51.7489 22.0178 51.9688C22.0178 52.1888 21.9339 52.4088 21.7661 52.5765C21.3639 52.9787 21.3639 53.6329 21.766 54.0349C21.9339 54.2028 22.0178 54.4226 22.0178 54.6426C22.0178 54.8626 21.9339 55.0825 21.766 55.2504C21.5984 55.4181 21.3784 55.502 21.1584 55.502Z"
        fill="#FF7EB8"
      />
      <path
        d="M28.1481 55.502C27.9282 55.502 27.7081 55.4181 27.5405 55.2504C27.1383 54.8482 26.4841 54.8482 26.0819 55.2504C25.7466 55.5859 25.2021 55.5859 24.8668 55.2504C24.6989 55.0825 24.615 54.8626 24.615 54.6426C24.615 54.4226 24.6989 54.2028 24.8668 54.0349C25.0615 53.8402 25.1688 53.5811 25.1688 53.3056C25.1688 53.0301 25.0615 52.7713 24.8668 52.5765C24.6989 52.4088 24.615 52.1888 24.615 51.9689C24.615 51.7489 24.6989 51.5289 24.8666 51.3612C25.2023 51.0255 25.7463 51.0253 26.0819 51.3612C26.4841 51.7632 27.1383 51.7632 27.5405 51.3612C27.8758 51.0257 28.4201 51.0255 28.7558 51.3612C28.9236 51.5289 29.0074 51.7489 29.0074 51.9689C29.0074 52.1888 28.9236 52.4088 28.7556 52.5765C28.5609 52.7713 28.4536 53.0303 28.4536 53.3056C28.4536 53.581 28.5609 53.84 28.7556 54.0349C28.9236 54.2028 29.0074 54.4226 29.0074 54.6426C29.0074 54.8626 28.9236 55.0825 28.7556 55.2504C28.5881 55.4181 28.3681 55.502 28.1481 55.502Z"
        fill="#FF7EB8"
      />
      <path
        d="M35.1376 55.502C34.9177 55.502 34.6976 55.4181 34.53 55.2504C34.1278 54.8482 33.4736 54.8482 33.0714 55.2504C32.7361 55.5859 32.1916 55.5859 31.8563 55.2504C31.6884 55.0825 31.6045 54.8626 31.6045 54.6426C31.6045 54.4226 31.6884 54.2028 31.8563 54.0349C32.051 53.8402 32.1583 53.5811 32.1583 53.3056C32.1583 53.0301 32.051 52.7713 31.8563 52.5765C31.6884 52.4088 31.6045 52.1888 31.6045 51.9689C31.6045 51.7489 31.6884 51.5289 31.8561 51.3612C32.1918 51.0255 32.7358 51.0253 33.0714 51.3612C33.4736 51.7632 34.1278 51.7632 34.53 51.3612C34.8653 51.0257 35.4096 51.0255 35.7453 51.3612C35.9131 51.5289 35.9969 51.7489 35.9969 51.9689C35.9969 52.1888 35.9131 52.4088 35.7451 52.5765C35.5504 52.7713 35.4431 53.0303 35.4431 53.3056C35.4431 53.581 35.5504 53.84 35.7451 54.0349C35.9131 54.2028 35.9969 54.4226 35.9969 54.6426C35.9969 54.8626 35.9131 55.0825 35.7451 55.2504C35.5776 55.4181 35.3576 55.502 35.1376 55.502Z"
        fill="#FF7EB8"
      />
      <path
        d="M68.6543 52.5418H41.6435C41.1681 52.5418 40.7827 52.1565 40.7827 51.6811C40.7827 51.2057 41.1681 50.8203 41.6435 50.8203H68.6543C69.1297 50.8203 69.5151 51.2057 69.5151 51.6811C69.5151 52.1565 69.1299 52.5418 68.6543 52.5418Z"
        fill="#B7C5FF"
      />
      <path
        d="M68.6543 55.7928H41.6435C41.1681 55.7928 40.7827 55.4075 40.7827 54.932C40.7827 54.4566 41.1681 54.0713 41.6435 54.0713H68.6543C69.1297 54.0713 69.5151 54.4566 69.5151 54.932C69.5151 55.4073 69.1299 55.7928 68.6543 55.7928Z"
        fill="#B7C5FF"
      />
      <path
        d="M24.7069 78.34C24.479 78.34 24.2604 78.2494 24.0993 78.0884L22.2056 76.1947C21.8699 75.859 21.8699 75.3148 22.2056 74.9793L24.0993 73.0856C24.2605 72.9244 24.479 72.834 24.7069 72.834C24.9348 72.834 25.1534 72.9246 25.3145 73.0856L27.2084 74.9793C27.3696 75.1405 27.4602 75.359 27.4602 75.5871C27.4602 75.8152 27.3698 76.0336 27.2084 76.1948L25.3145 78.0886C25.1534 78.2494 24.9348 78.34 24.7069 78.34ZM24.0287 75.5869L24.7069 76.2653L25.3853 75.5869L24.7069 74.9085L24.0287 75.5869Z"
        fill="#FF7EB8"
      />
      <path
        d="M55.1488 14.4417C54.9209 14.4417 54.7022 14.3512 54.5412 14.1901L52.6473 12.2964C52.4861 12.1352 52.3955 11.9167 52.3955 11.6886C52.3955 11.4606 52.4859 11.2421 52.6473 11.0809L54.5412 9.18717C54.7024 9.02595 54.9209 8.93555 55.1488 8.93555C55.3767 8.93555 55.5953 9.02613 55.7564 9.18717L57.6501 11.0809C57.9857 11.4166 57.9857 11.9607 57.6501 12.2962L55.7564 14.1899C55.5953 14.3512 55.3767 14.4417 55.1488 14.4417ZM54.4704 11.6886L55.1488 12.367L55.827 11.6886L55.1488 11.0103L54.4704 11.6886Z"
        fill="#6583FE"
      />
      <path
        d="M62.7313 11.2288C62.2566 11.2288 61.8719 10.844 61.8719 10.3695C61.8719 9.79919 61.4079 9.33513 60.8374 9.33513C60.3627 9.33513 59.978 8.9503 59.978 8.47575C59.978 8.0012 60.3627 7.61638 60.8374 7.61638C61.4077 7.61638 61.8719 7.15231 61.8719 6.58203C61.8719 6.10748 62.2566 5.72266 62.7313 5.72266C63.206 5.72266 63.5907 6.10748 63.5907 6.58203C63.5907 7.15231 64.0546 7.61638 64.625 7.61638C65.0997 7.61638 65.4844 8.0012 65.4844 8.47575C65.4844 8.9503 65.0997 9.33513 64.625 9.33513C64.0547 9.33513 63.5907 9.79919 63.5907 10.3695C63.5907 10.8442 63.206 11.2288 62.7313 11.2288Z"
        fill="#97FFD2"
      />
      <path
        d="M28.1481 14.4417C27.6734 14.4417 27.2887 14.0569 27.2887 13.5824C27.2887 13.0121 26.8248 12.548 26.2544 12.548C25.7797 12.548 25.395 12.1632 25.395 11.6886C25.395 11.2141 25.7797 10.8293 26.2544 10.8293C26.8247 10.8293 27.2887 10.3652 27.2887 9.79492C27.2887 9.32038 27.6734 8.93555 28.1481 8.93555C28.6228 8.93555 29.0075 9.32038 29.0075 9.79492C29.0075 10.3652 29.4715 10.8293 30.042 10.8293C30.5167 10.8293 30.9014 11.2141 30.9014 11.6886C30.9014 12.1632 30.5167 12.548 30.042 12.548C29.4717 12.548 29.0075 13.0121 29.0075 13.5824C29.0075 14.0569 28.6228 14.4417 28.1481 14.4417Z"
        fill="#97FFD2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2042_6635"
          x1="0.18971"
          y1="28.9225"
          x2="90.6437"
          y2="54.607"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#D2DEFF" />
          <stop offset="0.9993" stopColor="#DCFDEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IllustrationError;
