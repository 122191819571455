import { SVGProps } from "types";

const BoxSlide = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5386)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M13.125 16H10.875C10.3928 16 9.99976 15.6077 9.99976 15.1248V10.8752C9.99976 10.393 10.392 10 10.875 10H13.125C13.6073 10 14.0003 10.3922 14.0003 10.8752V15.1255C14.0003 15.6077 13.6073 16 13.125 16Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M8.75014 14.4995C8.59789 14.4995 8.44789 14.4305 8.34964 14.2993L7.59964 13.2995C7.46614 13.1218 7.46614 12.8773 7.59964 12.6995L8.34964 11.6998C8.51539 11.4793 8.82889 11.4343 9.04939 11.6C9.27064 11.7658 9.31564 12.0793 9.14914 12.2998L8.62414 12.9995L9.14914 13.6993C9.31489 13.9205 9.26989 14.234 9.04939 14.399C8.95939 14.4673 8.85439 14.4995 8.75014 14.4995Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M15.2495 14.4999C15.1453 14.4999 15.0403 14.4676 14.9503 14.4001C14.729 14.2344 14.684 13.9209 14.8505 13.7004L15.3755 13.0006L14.8498 12.3001C14.684 12.0788 14.729 11.7653 14.9495 11.6003C15.17 11.4338 15.4835 11.4796 15.6493 11.7001L16.3993 12.6998C16.5328 12.8776 16.5328 13.1221 16.3993 13.2998L15.6493 14.2996C15.5518 14.4309 15.4018 14.4999 15.2495 14.4999Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5386">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSlide;
