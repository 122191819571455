import { SVGProps } from "types";

const BoxSharing = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5373)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M6.24525 12.0011C6.10875 12.0041 6 12.1151 6 12.2516V16.8753C6 16.9443 6.05625 17.0006 6.12525 17.0006H7.311C7.42275 17.0006 7.521 16.9263 7.55175 16.8191L8.658 12.9198C8.72925 12.6678 8.59425 12.4031 8.34825 12.3138C7.8945 12.1481 7.2 11.9853 6.24525 12.0011Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M17.0002 14.7501C16.0005 14.7501 14.0002 16.0161 12.9997 16.0161C12 16.0161 10.875 15.2504 10.875 15.2504C10.875 15.2504 12.222 15.5001 12.9997 15.5001C13.7782 15.5001 13.9995 15.2346 13.9995 14.8754C13.9995 14.0001 12.513 13.8644 11.7615 13.7504C11.0235 13.3394 10.6402 13.0146 9.41774 12.9111C9.41024 12.9824 9.39899 13.0529 9.37949 13.1241L8.37524 16.6641C9.51299 17.1816 11.472 18.0014 12.5002 18.0014C14.0002 18.0014 18.0007 16.0011 18.0007 15.5016C18 14.9999 17.5005 14.7501 17.0002 14.7501Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M16.5 6H7.5C6.675 6 6 6.675 6 7.5V11.025C6.075 11.01 6.15 11.0002 6.23025 11.0002C6.495 10.995 6.75 11.0002 6.99975 11.025V8.49975H17.0002V13.7498C17.37 13.7498 17.7105 13.83 18 13.9695V7.5C18 6.675 17.325 6 16.5 6Z"
          fill="var(--color-mauve)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5373">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSharing;
