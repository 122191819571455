import { SVGProps } from "types";

const BoxView = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5438)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-orange-soda)"
        />
        <path
          d="M15.9217 12.7313C15.0322 11.334 13.5667 10.5 11.9999 10.5C10.4332 10.5 8.96769 11.334 8.07819 12.7313C7.97394 12.8955 7.97394 13.104 8.07819 13.2682C8.96769 14.6655 10.4339 15.4995 11.9999 15.4995C13.5659 15.4995 15.0322 14.6655 15.9217 13.2682C16.0259 13.1047 16.0259 12.8955 15.9217 12.7313ZM11.9999 14.0002C11.4479 14.0002 11.0002 13.5525 11.0002 13.0005C11.0002 12.4477 11.4479 12 11.9999 12C12.5519 12 12.9997 12.4478 12.9997 12.9998C12.9997 13.5525 12.5519 14.0002 11.9999 14.0002Z"
          fill="var(--color-congo-pink)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5438">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxView;
