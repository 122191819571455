import { SVGProps } from "types";

const Union = (props: SVGProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22895 9.52201C5.95125 9.76375 5.92209 10.1849 6.16384 10.4626C6.40559 10.7403 6.82669 10.7694 7.1044 10.5277L9.40558 8.52449C9.43492 8.50051 9.46262 8.47371 9.48828 8.44409C9.60163 8.31323 9.65502 8.15081 9.65083 7.99038C9.64712 7.84068 9.5932 7.69133 9.4872 7.56956C9.46167 7.54024 9.43415 7.51369 9.405 7.48993L7.10324 5.49603C6.82495 5.25496 6.40392 5.28513 6.16285 5.56343C5.92177 5.84172 5.95195 6.26275 6.23024 6.50383L7.96742 8.00866L6.22895 9.52201Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Union;
