import { SVGProps } from "types";

const BoxSocialMedia = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5420)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M14.4998 9.50025H13.5V8.5005H14.4998C14.7765 8.5005 15 8.27625 15 8.00025C15 7.72425 14.7765 7.5 14.4998 7.5H13.5V6.50025C13.5 6.2235 13.2765 6 12.9998 6C12.7238 6 12.4995 6.22425 12.4995 6.50025V7.5H11.4998V6.50025C11.4998 6.2235 11.2762 6 11.0002 6C10.7235 6 10.5 6.2235 10.5 6.50025V7.5H9.50025C9.2235 7.5 9 7.7235 9 8.00025C9 8.277 9.2235 8.5005 9.50025 8.5005H10.5V9.50025H9.50025C9.2235 9.50025 9 9.7245 9 10.0005C9 10.2765 9.2235 10.5 9.50025 10.5H10.5V11.4998C10.5 11.7758 10.7235 12 11.0002 12C11.277 12 11.5005 11.7758 11.5005 11.4998V10.5H12.5002V11.4998C12.5002 11.7758 12.7237 12 13.0005 12C13.2765 12 13.5 11.7758 13.5 11.4998V10.5H14.4998C14.7765 10.5 15 10.2758 15 9.99975C15 9.72375 14.7765 9.50025 14.4998 9.50025ZM11.4998 9.50025V8.5005H12.4995V9.50025H11.4998Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.1255 8H16.0005C16.0005 8.38475 15.8505 8.735 15.6105 8.99975H17.0002V14.4995H6.99975V8.99975H8.3895C8.1495 8.735 7.9995 8.38475 7.9995 8H6.8745C6.3945 8 6 8.3945 6 8.8745V15.6245C6 16.1045 6.3945 16.4998 6.8745 16.4998H10.704C10.6455 16.7502 10.4895 17.1567 10.0732 17.573C10.002 17.6442 9.98025 17.7523 10.0192 17.8453C10.0575 17.939 10.149 17.9998 10.2502 17.9998H13.7505C13.8517 17.9998 13.9433 17.939 13.9815 17.8453C14.0198 17.7515 13.9987 17.6442 13.9275 17.573C13.512 17.1575 13.3522 16.751 13.29 16.4998H17.1255C17.6055 16.4998 18 16.1045 18 15.6245V8.8745C18 8.3945 17.6055 8 17.1255 8Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5420">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSocialMedia;
