import { SVGProps } from "types";

const BoxShare = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5417)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M13.5 14.0005C13.2675 14.0005 13.056 14.0837 12.8857 14.2172L10.9853 13.1447C10.992 13.0975 10.9995 13.0495 10.9995 13C10.9995 12.9543 10.992 12.9108 10.986 12.8665L12.8857 11.7835C13.056 11.917 13.2675 12.0002 13.5 12.0002C14.0512 12.0002 14.4998 11.5517 14.4998 11.0005C14.4998 10.4492 14.0512 10 13.5 10C12.9488 10 12.5002 10.4485 12.5002 10.9998C12.5002 11.0455 12.5077 11.089 12.5137 11.1325L10.614 12.2155C10.4438 12.0828 10.2323 12.0002 9.99975 12.0002C9.4485 12.0002 9 12.4488 9 13C9 13.5512 9.4485 13.9998 9.99975 13.9998C10.2278 13.9998 10.4362 13.9202 10.6042 13.7905L12.513 14.8675C12.507 14.911 12.4995 14.9545 12.4995 14.9995C12.4995 15.5508 12.948 15.9993 13.4993 15.9993C14.0505 15.9993 14.499 15.5508 14.499 14.9995C14.4998 14.449 14.0512 14.0005 13.5 14.0005Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5417">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxShare;
