import { SVGProps } from "types";

const IllustrationPercentage = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6624)">
        <path
          d="M53.2942 15.3575C49.6109 6.30503 40.7008 -0.0636297 30.3101 0.000479654C17.0544 0.0821203 6.15543 10.7743 5.83385 24.0262C5.67521 30.5643 8.06445 36.5423 12.0788 41.0389C15.2789 44.6235 16.8849 49.3448 16.723 54.1475C16.7015 54.7858 16.6982 55.4288 16.7141 56.0759C17.1376 73.504 32.0259 88.0105 49.4592 88C67.5289 87.9892 82.1738 73.3373 82.1738 55.2652C82.1738 42.0181 74.3047 30.61 62.9862 25.4581C58.5747 23.4501 55.1209 19.8469 53.2942 15.3575Z"
          fill="url(#paint0_linear_2042_6624)"
        />
        <path
          opacity="0.1"
          d="M80.5234 22.8653C80.5234 15.3119 74.4002 9.18848 66.8467 9.18848C61.6799 9.18848 57.1837 12.0543 54.8572 16.2824H10.0535C8.63105 16.2824 7.47656 17.4369 7.47656 18.8594V62.5378C7.47656 63.9602 8.63105 65.1147 10.0535 65.1147H73.617C75.0394 65.1147 76.1939 63.9602 76.1939 62.5378V32.8448C78.8571 30.3494 80.5234 26.8027 80.5234 22.8653Z"
          fill="#2626BC"
        />
        <path
          d="M51.5776 81.2136H32.0928L35.9894 65.7686H47.6812L51.5776 81.2136Z"
          fill="#9FB0FE"
        />
        <path
          d="M49.0381 71.146H34.6321L35.9892 65.7686H47.681L49.0381 71.146Z"
          fill="#8399FE"
        />
        <path
          d="M76.1941 22.4675V66.146C76.1941 67.5684 75.0396 68.7229 73.6172 68.7229H10.0535C8.63105 68.7229 7.47656 67.5684 7.47656 66.146V22.4675C7.47656 21.0451 8.63105 19.8906 10.0535 19.8906H73.617C75.0396 19.8906 76.1941 21.0451 76.1941 22.4675Z"
          fill="white"
        />
        <path
          d="M76.1941 22.4675V38.9132C73.7512 41.205 70.4631 42.6085 66.8468 42.6085C59.2931 42.6085 53.1704 36.4841 53.1704 28.9321C53.1704 25.4669 54.4588 22.3008 56.5875 19.8906H73.6173C75.0396 19.8906 76.1941 21.0451 76.1941 22.4675Z"
          fill="#D2DEFF"
        />
        <path
          d="M23.8708 44.3065H13.3177C13.0331 44.3065 12.8022 44.0758 12.8022 43.791V28.166H24.3861V43.791C24.3861 44.0756 24.1554 44.3065 23.8708 44.3065Z"
          fill="#97FFD2"
        />
        <path
          d="M71.3513 28.166V43.7901C71.3513 44.0753 71.1194 44.3056 70.8358 44.3056H29.3565C29.0714 44.3056 28.8411 44.0755 28.8411 43.7901V28.166H71.3513Z"
          fill="#9FB0FE"
        />
        <path
          d="M71.3511 28.2324V41.8487C69.9407 42.3418 68.4254 42.608 66.8466 42.608C59.2929 42.608 53.1702 36.4836 53.1702 28.9316C53.1702 28.698 53.1753 28.4643 53.1891 28.2324H71.3511Z"
          fill="#8399FE"
        />
        <path
          d="M21.2114 56.1453H13.3177C13.0331 56.1453 12.8022 55.9145 12.8022 55.6299V47.7362C12.8022 47.4515 13.0331 47.2207 13.3177 47.2207H21.2114C21.496 47.2207 21.7269 47.4515 21.7269 47.7362V55.6299C21.7267 55.9145 21.496 56.1453 21.2114 56.1453Z"
          fill="#FFC4DF"
        />
        <path
          d="M34.1167 56.1453H26.223C25.9383 56.1453 25.7075 55.9145 25.7075 55.6299V47.7362C25.7075 47.4515 25.9382 47.2207 26.223 47.2207H34.1167C34.4013 47.2207 34.6321 47.4515 34.6321 47.7362V55.6299C34.6321 55.9145 34.4013 56.1453 34.1167 56.1453Z"
          fill="#97FFD2"
        />
        <path
          d="M52.6546 56.1453H39.1287C38.8441 56.1453 38.6133 55.9145 38.6133 55.6299V47.7362C38.6133 47.4515 38.8441 47.2207 39.1287 47.2207H52.6546C52.9392 47.2207 53.1701 47.4515 53.1701 47.7362V55.6299C53.1701 55.9145 52.9392 56.1453 52.6546 56.1453Z"
          fill="#FF7EB8"
        />
        <path
          d="M70.8355 56.1453H57.3096C57.025 56.1453 56.7942 55.9145 56.7942 55.6299V47.7362C56.7942 47.4515 57.025 47.2207 57.3096 47.2207H70.8355C71.1201 47.2207 71.351 47.4515 71.351 47.7362V55.6299C71.351 55.9145 71.1201 56.1453 70.8355 56.1453Z"
          fill="#FFA8CF"
        />
        <path
          d="M76.1941 28.1662V22.4675C76.1941 21.0444 75.0403 19.8906 73.6172 19.8906H10.0535C8.63036 19.8906 7.47656 21.0444 7.47656 22.4675V28.1662H76.1941Z"
          fill="#6583FE"
        />
        <path
          d="M7.47656 60.0664V66.1461C7.47656 67.5693 8.63036 68.7231 10.0535 68.7231H73.617C75.0401 68.7231 76.1939 67.5693 76.1939 66.1461V60.0664H7.47656Z"
          fill="#B7C5FF"
        />
        <path
          d="M76.5063 36.147C81.8466 30.8067 81.8466 22.1484 76.5063 16.8081C71.166 11.4677 62.5076 11.4677 57.1673 16.8081C51.827 22.1484 51.827 30.8067 57.1673 36.147C62.5076 41.4873 71.166 41.4873 76.5063 36.147Z"
          fill="#FF7EB8"
        />
        <path
          d="M13.1597 24.0166V24.0417C13.1597 24.5164 13.5445 24.9011 14.019 24.9011C14.4936 24.9011 14.8784 24.5164 14.8784 24.0417V24.0166C14.8784 23.5419 14.4936 23.1572 14.019 23.1572C13.5445 23.1572 13.1597 23.5421 13.1597 24.0166Z"
          fill="#2626BC"
        />
        <path
          d="M16.4795 24.0166V24.0417C16.4795 24.5164 16.8643 24.9011 17.3389 24.9011C17.8134 24.9011 18.1982 24.5164 18.1982 24.0417V24.0166C18.1982 23.5419 17.8134 23.1572 17.3389 23.1572C16.8643 23.1572 16.4795 23.5421 16.4795 24.0166Z"
          fill="#2626BC"
        />
        <path
          d="M19.7993 24.0166V24.0417C19.7993 24.5164 20.1841 24.9011 20.6587 24.9011C21.1332 24.9011 21.5181 24.5164 21.5181 24.0417V24.0166C21.5181 23.5419 21.1332 23.1572 20.6587 23.1572C20.1841 23.1572 19.7993 23.5421 19.7993 24.0166Z"
          fill="#2626BC"
        />
        <path
          d="M66.8468 11.9375C58.8316 11.9375 52.3107 18.4583 52.3107 26.4735C52.3107 26.7535 52.3205 27.0312 52.3363 27.3074H8.33594V22.4683C8.33594 21.5211 9.10645 20.7507 10.0535 20.7507H50.0961C50.5706 20.7507 50.9554 20.3661 50.9554 19.8914C50.9554 19.4166 50.5706 19.032 50.0961 19.032H10.0535C8.15873 19.032 6.61719 20.5735 6.61719 22.4683V66.1462C6.61719 68.0409 8.15873 69.5825 10.0535 69.5825H34.1407L31.4234 80.3532H28.7903C28.3157 80.3532 27.9309 80.7379 27.9309 81.2126C27.9309 81.6873 28.3157 82.072 28.7903 82.072H54.8802C55.3548 82.072 55.7396 81.6873 55.7396 81.2126C55.7396 80.7379 55.3548 80.3532 54.8802 80.3532H52.2467L49.5294 69.5825H73.6168C75.5116 69.5825 77.0531 68.0409 77.0531 66.1462V36.4646C77.0531 35.7396 76.147 35.3244 75.6032 35.8335C73.221 38.063 70.1111 39.2909 66.8467 39.2909C59.7792 39.2909 54.0293 33.541 54.0293 26.4735C54.0293 19.406 59.7792 13.6562 66.8467 13.6562C73.9142 13.6562 79.6641 19.406 79.6641 26.4735C79.6641 28.6193 79.1237 30.7422 78.1014 32.6123C77.8737 33.0288 78.0268 33.551 78.4432 33.7787C78.8599 34.0062 79.382 33.8531 79.6096 33.4368C80.7698 31.3145 81.383 28.9069 81.383 26.4735C81.383 18.4584 74.8622 11.9375 66.8468 11.9375ZM50.4744 80.3532H33.1961L35.9135 69.5825H47.757L50.4744 80.3532ZM73.6172 67.8637H10.0537C9.10663 67.8637 8.33611 67.0932 8.33611 66.1462V60.9258H75.3347V66.1462C75.3347 67.093 74.5642 67.8637 73.6172 67.8637ZM66.8468 41.0098C69.9219 41.0098 72.874 40.0465 75.3345 38.272V59.2071H8.33611V29.0262H52.5398C53.7503 35.8278 59.7029 41.0098 66.8468 41.0098Z"
          fill="#2626BC"
        />
        <path
          d="M76.7236 28.829C78.0118 23.3796 74.6384 17.9178 69.189 16.6296C63.7396 15.3415 58.2778 18.7149 56.9896 24.1643C55.7015 29.6136 59.0748 35.0755 64.5242 36.3636C69.9736 37.6518 75.4355 34.2784 76.7236 28.829Z"
          fill="#DCFDEE"
        />
        <path
          d="M63.3466 30.8328C63.1268 30.8328 62.9069 30.7489 62.7392 30.5812C62.4037 30.2458 62.4037 29.7019 62.7392 29.3665L69.7398 22.3659C70.0753 22.0304 70.6191 22.0304 70.9546 22.3659C71.2901 22.7012 71.2901 23.2452 70.9546 23.5805L63.954 30.5812C63.7862 30.7489 63.5664 30.8328 63.3466 30.8328Z"
          fill="#FF7EB8"
        />
        <path
          d="M63.6471 25.7324C62.291 25.7324 61.1877 24.6291 61.1877 23.2728C61.1877 21.9167 62.291 20.8135 63.6471 20.8135C65.0032 20.8135 66.1065 21.9167 66.1065 23.2728C66.1065 24.6291 65.0032 25.7324 63.6471 25.7324ZM63.6471 22.5315C63.2382 22.5315 62.9056 22.8641 62.9056 23.2728C62.9056 23.6816 63.2382 24.0143 63.6471 24.0143C64.056 24.0143 64.3886 23.6816 64.3886 23.2728C64.3886 22.8641 64.0558 22.5315 63.6471 22.5315Z"
          fill="#FF7EB8"
        />
        <path
          d="M70.0465 32.1318C68.6904 32.1318 67.5872 31.0285 67.5872 29.6723C67.5872 28.3162 68.6904 27.2129 70.0465 27.2129C71.4026 27.2129 72.5059 28.3162 72.5059 29.6723C72.5059 31.0287 71.4026 32.1318 70.0465 32.1318ZM70.0465 28.9311C69.6376 28.9311 69.305 29.2637 69.305 29.6724C69.305 30.0811 69.6376 30.4139 70.0465 30.4139C70.4554 30.4139 70.788 30.0811 70.788 29.6724C70.788 29.2637 70.4554 28.9311 70.0465 28.9311Z"
          fill="#FF7EB8"
        />
        <path
          d="M38.3549 41.8998C38.8835 41.8998 39.312 41.4667 39.312 40.9323C39.312 40.398 38.8835 39.9648 38.3549 39.9648C37.8262 39.9648 37.3977 40.398 37.3977 40.9323C37.3977 41.4667 37.8262 41.8998 38.3549 41.8998Z"
          fill="white"
        />
        <path
          d="M35.4591 41.8998C35.9878 41.8998 36.4163 41.4667 36.4163 40.9323C36.4163 40.398 35.9878 39.9648 35.4591 39.9648C34.9305 39.9648 34.502 40.398 34.502 40.9323C34.502 41.4667 34.9305 41.8998 35.4591 41.8998Z"
          fill="white"
        />
        <path
          d="M32.5636 41.8998C33.0922 41.8998 33.5208 41.4667 33.5208 40.9323C33.5208 40.398 33.0922 39.9648 32.5636 39.9648C32.035 39.9648 31.6064 40.398 31.6064 40.9323C31.6064 41.4667 32.035 41.8998 32.5636 41.8998Z"
          fill="white"
        />
        <path
          d="M44.955 50.8176H42.3815C41.8954 50.8176 41.5015 50.4235 41.5015 49.9376C41.5015 49.4517 41.8954 49.0576 42.3815 49.0576H44.955C45.441 49.0576 45.835 49.4517 45.835 49.9376C45.835 50.4235 45.441 50.8176 44.955 50.8176Z"
          fill="#FFC4DF"
        />
        <path
          d="M49.402 50.8176H48.2721C47.786 50.8176 47.3921 50.4235 47.3921 49.9376C47.3921 49.4517 47.786 49.0576 48.2721 49.0576H49.402C49.8881 49.0576 50.282 49.4517 50.282 49.9376C50.282 50.4235 49.8881 50.8176 49.402 50.8176Z"
          fill="#FFC4DF"
        />
        <path
          d="M49.402 54.3079H42.3815C41.8954 54.3079 41.5015 53.9137 41.5015 53.4279C41.5015 52.942 41.8954 52.5479 42.3815 52.5479H49.402C49.8881 52.5479 50.282 52.942 50.282 53.4279C50.282 53.9137 49.8881 54.3079 49.402 54.3079Z"
          fill="#FF5BA8"
        />
        <path
          d="M63.1359 50.8176H60.5624C60.0763 50.8176 59.6824 50.4235 59.6824 49.9376C59.6824 49.4517 60.0763 49.0576 60.5624 49.0576H63.1359C63.6219 49.0576 64.0159 49.4517 64.0159 49.9376C64.0159 50.4235 63.6219 50.8176 63.1359 50.8176Z"
          fill="#FF5BA8"
        />
        <path
          d="M67.5827 50.8176H66.4528C65.9667 50.8176 65.5728 50.4235 65.5728 49.9376C65.5728 49.4517 65.9667 49.0576 66.4528 49.0576H67.5827C68.0687 49.0576 68.4627 49.4517 68.4627 49.9376C68.4627 50.4235 68.0687 50.8176 67.5827 50.8176Z"
          fill="#FF5BA8"
        />
        <path
          d="M67.5827 54.3079H60.5621C60.0761 54.3079 59.6821 53.9137 59.6821 53.4279C59.6821 52.942 60.0761 52.5479 60.5621 52.5479H67.5827C68.0688 52.5479 68.4627 52.942 68.4627 53.4279C68.4627 53.9137 68.0688 54.3079 67.5827 54.3079Z"
          fill="#FF7EB8"
        />
        <path
          d="M54.2629 11.4261C54.035 11.4261 53.8164 11.3356 53.6552 11.1743L51.7654 9.28441C51.4298 8.94891 51.4298 8.40476 51.7654 8.06925L53.6552 6.17949C53.9909 5.84382 54.5348 5.84382 54.8705 6.17949L56.7605 8.06925C56.9217 8.23047 57.0121 8.44893 57.0121 8.67683C57.0121 8.90474 56.9215 9.12336 56.7605 9.28441L54.8705 11.1743C54.7096 11.3354 54.491 11.4261 54.2629 11.4261ZM53.5885 8.67666L54.2629 9.35127L54.9374 8.67666L54.2629 8.00222L53.5885 8.67666Z"
          fill="#97FFD2"
        />
        <path
          d="M58.5957 76.7023C58.3757 76.7023 58.1559 76.6184 57.988 76.4505L56.0981 74.5607C55.9368 74.3995 55.8464 74.1811 55.8464 73.9531C55.8464 73.7252 55.937 73.5066 56.0981 73.3456L57.988 71.4558C58.3238 71.1201 58.8678 71.1203 59.2033 71.4558L61.0931 73.3456C61.4288 73.6811 61.4288 74.2252 61.0931 74.5607L59.2033 76.4505C59.0356 76.6182 58.8157 76.7023 58.5957 76.7023ZM57.9213 73.9531L58.5957 74.6276L59.2702 73.9531L58.5957 73.2787L57.9213 73.9531Z"
          fill="#6583FE"
        />
        <path
          d="M66.1065 80.9713C65.632 80.9713 65.2471 80.5867 65.2471 80.1119C65.2471 79.5418 64.7832 79.0779 64.2131 79.0779C63.7386 79.0779 63.3538 78.6933 63.3538 78.2186C63.3538 77.7439 63.7386 77.3592 64.2131 77.3592C64.7832 77.3592 65.2471 76.8955 65.2471 76.3252C65.2471 75.8505 65.632 75.4658 66.1065 75.4658C66.5811 75.4658 66.9659 75.8505 66.9659 76.3252C66.9659 76.8953 67.4298 77.3592 67.9999 77.3592C68.4744 77.3592 68.8593 77.7439 68.8593 78.2186C68.8593 78.6933 68.4744 79.0779 67.9999 79.0779C67.4298 79.0779 66.9659 79.5417 66.9659 80.1119C66.9659 80.5867 66.5811 80.9713 66.1065 80.9713Z"
          fill="#FF7EB8"
        />
        <path
          d="M30.9493 13.8346C30.4747 13.8346 30.0899 13.4499 30.0899 12.9752C30.0899 12.4051 29.626 11.9412 29.0559 11.9412C28.5814 11.9412 28.1965 11.5566 28.1965 11.0819C28.1965 10.6071 28.5814 10.2225 29.0559 10.2225C29.626 10.2225 30.0899 9.75876 30.0899 9.18848C30.0899 8.71376 30.4747 8.3291 30.9493 8.3291C31.4238 8.3291 31.8087 8.71376 31.8087 9.18848C31.8087 9.75859 32.2726 10.2225 32.8427 10.2225C33.3172 10.2225 33.702 10.6071 33.702 11.0819C33.702 11.5566 33.3172 11.9412 32.8427 11.9412C32.2726 11.9412 31.8087 12.4049 31.8087 12.9752C31.8087 13.4499 31.4238 13.8346 30.9493 13.8346Z"
          fill="#FF7EB8"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6624"
          x1="8.00051"
          y1="6.11975"
          x2="73.1423"
          y2="83.1734"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#D2DEFF" />
          <stop offset="0.9993" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6624">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationPercentage;
