import { SVGProps } from "types";

const BoxLayout = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5394)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M8.49976 7.75H9.49951V13.75H8.49976V7.75Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M8.87476 9H17.625V9.99975H8.87476V9Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M8.87476 11.001H17.625V12.0007H8.87476V11.001Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M17.1255 7H6.8745C6.39225 7 6 7.393 6 7.87525V14.6252C6 15.1075 6.39225 15.5005 6.87525 15.5005H10.7048C10.6462 15.751 10.4903 16.1575 10.074 16.5737C10.0027 16.645 9.981 16.753 10.02 16.846C10.0582 16.9398 10.1498 17.0005 10.251 17.0005H13.7512C13.8525 17.0005 13.944 16.9398 13.9822 16.846C14.0205 16.7523 13.9995 16.645 13.9282 16.5737C13.5127 16.1582 13.353 15.7517 13.2908 15.5005H17.1262C17.6085 15.5005 18.0015 15.1082 18.0015 14.6252V7.87525C18 7.393 17.6077 7 17.1255 7ZM17.0002 13.5002H6.99975V8.0005H17.0002V13.5002Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5394">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxLayout;
