import { SVGProps } from "types";

const BoxWebBrowser = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5452)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-orange-soda)"
        />
        <path
          d="M13.4999 11.9997C13.7024 11.9997 13.8847 11.8782 13.9619 11.6915C14.0392 11.5047 13.9964 11.2895 13.8532 11.1463L12.3532 9.64625C12.1582 9.45125 11.8417 9.45125 11.6459 9.64625L10.1459 11.1463C10.0027 11.2895 9.95994 11.504 10.0372 11.6915C10.1152 11.8782 10.2974 11.9997 10.4999 11.9997H11.6249V13.5942L9.85944 15.3597C9.71319 15.506 9.71319 15.7437 9.85944 15.89C9.93294 15.9635 10.0289 15.9995 10.1249 15.9995C10.2209 15.9995 10.3169 15.9627 10.3904 15.89L12.2654 14.015C12.3359 13.9445 12.3749 13.8492 12.3749 13.7495V11.9997H13.4999Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M14.1404 15.3597L13.3904 14.6097C13.2442 14.4634 13.0064 14.4634 12.8602 14.6097C12.7139 14.7559 12.7139 14.9937 12.8602 15.1399L13.6102 15.8899C13.6837 15.9634 13.7797 15.9994 13.8757 15.9994C13.9717 15.9994 14.0677 15.9627 14.1412 15.8899C14.2867 15.7437 14.2867 15.5059 14.1404 15.3597Z"
          fill="var(--color-congo-pink)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5452">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxWebBrowser;
