import { SVGProps } from "types";

const BoxBlocked = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5400)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M12 14.5C11.1727 14.5 10.5 13.8273 10.5 13C10.5 12.1727 11.1727 11.5 12 11.5C12.8273 11.5 13.5 12.1727 13.5 13C13.5 13.8273 12.8273 14.5 12 14.5Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M14.9955 14.8552C14.8575 14.8552 14.7195 14.7982 14.6213 14.6872C14.4383 14.481 14.457 14.1645 14.6633 13.9815C15.513 13.2277 15.9998 12.1417 15.9998 11.0002C15.9998 8.7945 14.205 7.0005 11.9993 7.0005C9.79354 7.0005 7.99879 8.79525 7.99879 11.0002C7.99879 12.141 8.48554 13.2277 9.33529 13.9815C9.54154 14.1645 9.56104 14.481 9.37729 14.6872C9.19429 14.8935 8.87854 14.913 8.67154 14.7292C7.60804 13.7865 6.99829 12.4275 6.99829 11.0002C6.99979 8.24325 9.24304 6 12 6C14.757 6 17.0003 8.24325 17.0003 11.0002C17.0003 12.4275 16.3905 13.7865 15.327 14.7292C15.2318 14.8132 15.1133 14.8552 14.9955 14.8552Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M13.9844 11.2557C13.7354 11.2557 13.5194 11.0697 13.4887 10.8162C13.3979 10.0662 12.7574 9.50073 11.9999 9.50073C11.2424 9.50073 10.6019 10.0662 10.5112 10.8162C10.4782 11.0907 10.2277 11.2827 9.95468 11.252C9.68018 11.219 9.48518 10.9692 9.51893 10.6955C9.67043 9.44448 10.7377 8.50098 12.0007 8.50098C13.2637 8.50098 14.3302 9.44448 14.4824 10.6955C14.5154 10.97 14.3204 11.219 14.0467 11.252C14.0249 11.2542 14.0047 11.2557 13.9844 11.2557Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M14.625 17.9998H9.375C9.168 17.9998 9 17.8318 9 17.6248V16.8748C9 16.1165 9.6165 15.5 10.3748 15.5H13.6252C14.3835 15.5 15 16.1165 15 16.8748V17.6248C15 17.8318 14.832 17.9998 14.625 17.9998Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5400">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxBlocked;
