import { SVGProps } from "types";

const IllustrationNotFoundError = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6639)">
        <path
          d="M70.1644 26.5138C66.0915 24.9263 62.6505 22.0297 60.5461 18.1983C57.0072 11.7552 50.1597 7.3877 42.2893 7.3877C34.1201 7.3877 27.0533 12.0931 23.6449 18.941C21.5573 23.1349 18.0049 26.4278 13.6606 28.1811C7.24128 30.772 1.8523 36.6931 0.142148 45.9499C-0.0455393 46.9657 -0.0482893 48.01 0.139398 49.0258C2.79452 63.3897 14.3017 69.7256 24.7749 68.0455C30.6403 67.1046 36.5779 69.0611 40.9255 73.1093C49.7657 81.3407 63.9368 83.9214 77.6935 75.0979C79.3052 74.0642 81.4674 71.8951 82.5002 70.2828C94.3537 51.777 85.5731 32.5194 70.1644 26.5138Z"
          fill="url(#paint0_linear_2042_6639)"
        />
        <path
          opacity="0.1"
          d="M78.3752 17.1084V60.8076C78.3752 62.2307 77.2202 63.3857 75.7971 63.3857H12.2034C10.7802 63.3857 9.62524 62.2307 9.62524 60.8076V17.1084C9.62524 15.6853 10.7802 14.5303 12.2034 14.5303H75.7971C77.2202 14.5303 78.3752 15.6851 78.3752 17.1084Z"
          fill="#2626BC"
        />
        <path
          d="M53.7472 79.4913H34.2529L38.1514 64.0391H49.8487L53.7472 79.4913Z"
          fill="#9FB0FE"
        />
        <path
          d="M51.2066 69.4191H36.7937L38.1515 64.0391H49.8488L51.2066 69.4191Z"
          fill="#8399FE"
        />
        <path
          d="M78.3752 20.7178V64.417C78.3752 65.8401 77.2202 66.9951 75.7971 66.9951H12.2034C10.7802 66.9951 9.62524 65.8401 9.62524 64.417V20.7178C9.62524 19.2946 10.7802 18.1396 12.2034 18.1396H75.7971C77.2202 18.1396 78.3752 19.2946 78.3752 20.7178Z"
          fill="white"
        />
        <path
          d="M22.4934 26.4189H65.5068V58.3337H22.4934V26.4189Z"
          fill="#97FFD2"
        />
        <path
          d="M78.3752 26.419V20.7178C78.3752 19.294 77.2209 18.1396 75.7971 18.1396H12.2034C10.7796 18.1396 9.62524 19.294 9.62524 20.7178V26.419H78.3752Z"
          fill="#FF7EB8"
        />
        <path
          d="M9.62524 58.334V64.4166C9.62524 65.8405 10.7796 66.9948 12.2034 66.9948H75.7971C77.2209 66.9948 78.3752 65.8405 78.3752 64.4166V58.334H9.62524Z"
          fill="#B7C5FF"
        />
        <path
          d="M16.1707 21.4072C15.6959 21.4072 15.3113 21.7919 15.3113 22.2666V22.2917C15.3113 22.7664 15.6959 23.1511 16.1707 23.1511C16.6454 23.1511 17.03 22.7664 17.03 22.2917V22.2666C17.03 21.7919 16.6454 21.4072 16.1707 21.4072Z"
          fill="#2626BC"
        />
        <path
          d="M19.4922 21.4072C19.0175 21.4072 18.6328 21.7919 18.6328 22.2666V22.2917C18.6328 22.7664 19.0175 23.1511 19.4922 23.1511C19.9669 23.1511 20.3516 22.7664 20.3516 22.2917V22.2666C20.3516 21.7919 19.9669 21.4072 19.4922 21.4072Z"
          fill="#2626BC"
        />
        <path
          d="M22.8135 21.4072C22.3388 21.4072 21.9541 21.7919 21.9541 22.2666V22.2917C21.9541 22.7664 22.3388 23.1511 22.8135 23.1511C23.2882 23.1511 23.6729 22.7664 23.6729 22.2917V22.2666C23.6729 21.7919 23.2882 21.4072 22.8135 21.4072Z"
          fill="#2626BC"
        />
        <path
          d="M75.7971 17.2803H12.2034C10.3079 17.2803 8.76587 18.8223 8.76587 20.7178V26.4192C8.76587 26.8939 9.15053 27.2786 9.62524 27.2786H77.5159V53.6006C77.5159 54.0753 77.9005 54.4599 78.3752 54.4599C78.85 54.4599 79.2346 54.0753 79.2346 53.6006V20.7178C79.2346 18.8223 77.6926 17.2803 75.7971 17.2803ZM77.5159 25.5598H10.4846V20.7178C10.4846 19.7701 11.2557 18.999 12.2034 18.999H75.7971C76.7448 18.999 77.5159 19.7701 77.5159 20.7178V25.5598Z"
          fill="#2626BC"
        />
        <path
          d="M78.3752 57.4743H10.4846V31.1523C10.4846 30.6776 10.1 30.293 9.62524 30.293C9.15053 30.293 8.76587 30.6776 8.76587 31.1523V64.4163C8.76587 66.3118 10.3079 67.8539 12.2034 67.8539H36.3028L33.5839 78.6306H30.9493C30.4745 78.6306 30.0899 79.0152 30.0899 79.49C30.0899 79.9647 30.4745 80.3493 30.9493 80.3493H57.0514C57.5261 80.3493 57.9108 79.9647 57.9108 79.49C57.9108 79.0152 57.5261 78.6306 57.0514 78.6306H54.4166L51.6977 67.8539H75.7971C77.6926 67.8539 79.2346 66.3118 79.2346 64.4163V58.3337C79.2346 57.8591 78.85 57.4743 78.3752 57.4743ZM52.644 78.6306H35.3563L38.0752 67.8539H49.9253L52.644 78.6306ZM77.5159 64.4163C77.5159 65.3641 76.7448 66.1351 75.7971 66.1351H12.2034C11.2557 66.1351 10.4846 65.3641 10.4846 64.4163V59.1931H77.5159V64.4163Z"
          fill="#2626BC"
        />
        <path
          d="M36.1685 31.6992C35.3473 31.6992 34.6815 32.3649 34.6815 33.1863V35.5463H33.0093V33.1863C33.0093 32.3649 32.3434 31.6992 31.5222 31.6992C30.701 31.6992 30.0352 32.3649 30.0352 33.1863V37.0334C30.0352 37.8547 30.701 38.5204 31.5222 38.5204H34.6815V41.9435C34.6815 42.7649 35.3473 43.4305 36.1685 43.4305C36.9897 43.4305 37.6556 42.7649 37.6556 41.9435V33.1865C37.6556 32.3649 36.9897 31.6992 36.1685 31.6992Z"
          fill="#FF7EB8"
        />
        <path
          d="M56.4778 31.6992C55.6566 31.6992 54.9908 32.3649 54.9908 33.1863V35.5463H53.3186V33.1863C53.3186 32.3649 52.6528 31.6992 51.8315 31.6992C51.0103 31.6992 50.3445 32.3649 50.3445 33.1863V37.0334C50.3445 37.8547 51.0103 38.5204 51.8315 38.5204H54.9908V41.9435C54.9908 42.7649 55.6566 43.4305 56.4778 43.4305C57.2991 43.4305 57.9649 42.7649 57.9649 41.9435V33.1865C57.9649 32.3649 57.2991 31.6992 56.4778 31.6992Z"
          fill="#FF7EB8"
        />
        <path
          d="M44.8358 31.6074H43.2504C41.315 31.6074 39.7402 33.1821 39.7402 35.1176V40.0119C39.7402 41.9474 41.315 43.5221 43.2504 43.5221H44.8358C46.7713 43.5221 48.346 41.9474 48.346 40.0119V35.1176C48.346 33.1821 46.7713 31.6074 44.8358 31.6074ZM45.3721 40.0119C45.3721 40.3076 45.1316 40.548 44.836 40.548H43.2506C42.955 40.548 42.7145 40.3076 42.7145 40.0119V35.1176C42.7145 34.822 42.955 34.5815 43.2506 34.5815H44.836C45.1316 34.5815 45.3721 34.822 45.3721 35.1176V40.0119Z"
          fill="#FF7EB8"
        />
        <path
          d="M66.6844 50.5455C66.4646 50.5455 66.2444 50.4616 66.0769 50.2937C65.8818 50.0986 65.6224 49.9914 65.3467 49.9914C65.0711 49.9914 64.8117 50.0986 64.6166 50.2937C64.2813 50.6294 63.7368 50.6294 63.4015 50.2937C63.2335 50.1259 63.1497 49.9059 63.1497 49.6861C63.1497 49.4663 63.2335 49.2461 63.4015 49.0785C63.5965 48.8835 63.7038 48.6241 63.7038 48.3484C63.7038 48.0727 63.5965 47.8134 63.4015 47.6183C63.2335 47.4505 63.1497 47.2305 63.1497 47.0107C63.1497 46.7909 63.2335 46.5707 63.4015 46.4031C63.7368 46.0674 64.2813 46.0674 64.6166 46.4031C64.8117 46.5982 65.0711 46.7054 65.3467 46.7054C65.6224 46.7054 65.8818 46.5982 66.0769 46.4031C66.4122 46.0674 66.9567 46.0674 67.292 46.4031C67.4599 46.5709 67.5438 46.7909 67.5438 47.0107C67.5438 47.2305 67.4599 47.4507 67.292 47.6183C67.0969 47.8134 66.9897 48.0727 66.9897 48.3484C66.9897 48.6241 67.0969 48.8835 67.292 49.0785C67.4599 49.2463 67.5438 49.4663 67.5438 49.6861C67.5438 49.9059 67.4599 50.1261 67.292 50.2937C67.1244 50.4616 66.9044 50.5455 66.6844 50.5455Z"
          fill="#6583FE"
        />
        <path
          d="M66.6844 44.3813C66.4646 44.3813 66.2444 44.2975 66.0769 44.1295C65.8818 43.9345 65.6224 43.827 65.3467 43.827C65.0711 43.827 64.8117 43.9345 64.6166 44.1295C64.2813 44.4652 63.7368 44.4652 63.4015 44.1295C63.2335 43.9618 63.1497 43.7418 63.1497 43.522C63.1497 43.3021 63.2335 43.082 63.4015 42.9144C63.5965 42.7193 63.7038 42.4599 63.7038 42.1843C63.7038 41.9086 63.5965 41.6494 63.4015 41.4545C63.2335 41.2866 63.1497 41.0667 63.1497 40.8469C63.1497 40.6269 63.2335 40.4069 63.4013 40.2391C63.737 39.9035 64.2809 39.9036 64.6166 40.239C65.0191 40.6415 65.6743 40.6415 66.0769 40.239C66.4125 39.9033 66.9565 39.9035 67.2922 40.2391C67.4599 40.4071 67.5438 40.6269 67.5438 40.8469C67.5438 41.0667 67.4599 41.2867 67.292 41.4545C67.0969 41.6494 66.9897 41.9086 66.9897 42.1843C66.9897 42.4599 67.0969 42.7193 67.292 42.9144C67.4599 43.0821 67.5438 43.3021 67.5438 43.522C67.5438 43.7418 67.4599 43.962 67.292 44.1295C67.1244 44.2973 66.9044 44.3813 66.6844 44.3813Z"
          fill="#6583FE"
        />
        <path
          d="M23.9911 40.8706C23.7713 40.8706 23.5513 40.7867 23.3835 40.619C22.981 40.2164 22.3258 40.2164 21.9233 40.619C21.5874 40.9543 21.0434 40.9543 20.7079 40.6188C20.5402 40.4509 20.4563 40.231 20.4563 40.011C20.4563 39.7912 20.5402 39.5712 20.7081 39.4035C20.9032 39.2086 21.0104 38.9492 21.0104 38.6737C21.0104 38.398 20.9032 38.1386 20.7081 37.9437C20.5402 37.7758 20.4563 37.556 20.4563 37.3362C20.4563 37.1162 20.5402 36.8962 20.7079 36.7284C21.0436 36.3927 21.5876 36.3929 21.9233 36.7282C22.3258 37.1308 22.981 37.1308 23.3835 36.7282C23.7193 36.3926 24.2633 36.3927 24.5988 36.7284C24.7666 36.8963 24.8505 37.1162 24.8505 37.3362C24.8505 37.556 24.7666 37.776 24.5987 37.9437C24.4036 38.1386 24.2963 38.398 24.2963 38.6737C24.2963 38.9494 24.4036 39.2086 24.5987 39.4035C24.7666 39.5714 24.8505 39.7912 24.8505 40.011C24.8505 40.231 24.7666 40.451 24.5988 40.6188C24.4311 40.7867 24.2109 40.8706 23.9911 40.8706Z"
          fill="#6583FE"
        />
        <path
          d="M23.9911 34.7064C23.7713 34.7064 23.5513 34.6226 23.3835 34.4548C22.981 34.0523 22.3258 34.0523 21.9233 34.4548C21.5874 34.7901 21.0434 34.7901 20.7079 34.4546C20.5402 34.2867 20.4563 34.0669 20.4563 33.8469C20.4563 33.6271 20.5402 33.4071 20.7081 33.2393C20.9032 33.0444 21.0104 32.785 21.0104 32.5095C21.0104 32.2338 20.9032 31.9745 20.7081 31.7794C20.5402 31.6117 20.4563 31.3917 20.4563 31.1718C20.4563 30.952 20.5402 30.7318 20.7081 30.5643C21.0434 30.2286 21.5879 30.2286 21.9233 30.5643C22.1183 30.7593 22.3777 30.8666 22.6534 30.8666C22.9291 30.8666 23.1884 30.7593 23.3835 30.5643C23.7188 30.2286 24.2633 30.2286 24.5987 30.5643C24.7666 30.732 24.8505 30.952 24.8505 31.1718C24.8505 31.3917 24.7666 31.6118 24.5987 31.7794C24.4036 31.9745 24.2963 32.2338 24.2963 32.5095C24.2963 32.7852 24.4036 33.0444 24.5987 33.2393C24.7666 33.4072 24.8505 33.6271 24.8505 33.8469C24.8505 34.0669 24.7666 34.2869 24.5988 34.4546C24.4311 34.6224 24.2109 34.7064 23.9911 34.7064Z"
          fill="#6583FE"
        />
        <path
          d="M40.5505 49.8656H56.0342C56.5211 49.8656 56.9157 49.471 56.9157 48.9841C56.9157 48.4973 56.5211 48.1025 56.0342 48.1025H40.5505C40.0636 48.1025 39.6689 48.4972 39.6689 48.9841C39.6689 49.471 40.0636 49.8656 40.5505 49.8656Z"
          fill="#FF7EB8"
        />
        <path
          d="M31.9663 49.8656H36.1954C36.6823 49.8656 37.077 49.471 37.077 48.9841C37.077 48.4973 36.6823 48.1025 36.1954 48.1025H31.9663C31.4793 48.1025 31.0847 48.4972 31.0847 48.9841C31.0849 49.471 31.4795 49.8656 31.9663 49.8656Z"
          fill="#9FB0FE"
        />
        <path
          d="M31.9663 53.3627H56.0341C56.521 53.3627 56.9156 52.9681 56.9156 52.4812C56.9156 51.9944 56.521 51.5996 56.0341 51.5996H31.9663C31.4793 51.5996 31.0847 51.9942 31.0847 52.4812C31.0849 52.9681 31.4795 53.3627 31.9663 53.3627Z"
          fill="#FFA8CF"
        />
        <path
          d="M58.9769 13.1475C58.7571 13.1475 58.5371 13.0636 58.3693 12.8959L56.4796 11.0063C56.3184 10.845 56.2278 10.6264 56.2278 10.3985C56.2278 10.1706 56.3182 9.95198 56.4796 9.79093L58.3693 7.90117C58.7047 7.5655 59.2492 7.5655 59.5845 7.90117L61.4743 9.79093C61.6355 9.95215 61.7261 10.1706 61.7261 10.3985C61.7261 10.6264 61.6355 10.845 61.4743 11.0063L59.5845 12.8959C59.4166 13.0636 59.1968 13.1475 58.9769 13.1475ZM58.3025 10.3985L58.9769 11.073L59.6514 10.3985L58.9769 9.72407L58.3025 10.3985Z"
          fill="#FF7EB8"
        />
        <path
          d="M22.8133 79.1503C22.5935 79.1503 22.3734 79.0664 22.2058 78.8985L20.316 77.0088C20.1548 76.8475 20.0642 76.6291 20.0642 76.4012C20.0642 76.1733 20.1548 75.9546 20.316 75.7934L22.2058 73.9038C22.5414 73.5685 23.0853 73.5685 23.4209 73.9038L25.3107 75.7934C25.4719 75.9546 25.5625 76.1733 25.5625 76.4012C25.5625 76.6291 25.4721 76.8477 25.3107 77.0088L23.4209 78.8985C23.2534 79.0663 23.0333 79.1503 22.8133 79.1503ZM22.1391 76.4012L22.8135 77.0756L23.488 76.4012L22.8135 75.7267L22.1391 76.4012Z"
          fill="#6583FE"
        />
        <path
          d="M62.4259 75.5458C62.2061 75.5458 61.9859 75.4619 61.8183 75.294L59.9286 73.4043C59.7673 73.243 59.6768 73.0246 59.6768 72.7967C59.6768 72.5688 59.7673 72.3502 59.9286 72.1889L61.8183 70.2993C62.154 69.964 62.6978 69.964 63.0335 70.2993L64.9232 72.1889C65.0845 72.3502 65.175 72.5688 65.175 72.7967C65.175 73.0246 65.0846 73.2432 64.9232 73.4043L63.0335 75.294C62.8659 75.4619 62.6457 75.5458 62.4259 75.5458ZM61.7515 72.7967L62.4259 73.4711L63.1003 72.7967L62.4259 72.1222L61.7515 72.7967Z"
          fill="#FF7EB8"
        />
        <path
          d="M70.989 80.1816C70.7692 80.1816 70.549 80.0977 70.3814 79.9298L68.4917 78.04C68.3304 77.8788 68.2399 77.6603 68.2399 77.4324C68.2399 77.2045 68.3304 76.9859 68.4917 76.8247L70.3814 74.9351C70.7171 74.5998 71.2609 74.5998 71.5966 74.9351L73.4864 76.8247C73.6476 76.9859 73.7382 77.2045 73.7382 77.4324C73.7382 77.6603 73.6477 77.879 73.4864 78.04L71.5966 79.9298C71.429 80.0975 71.209 80.1816 70.989 80.1816ZM70.3147 77.4324L70.9892 78.1069L71.6636 77.4324L70.9892 76.758L70.3147 77.4324Z"
          fill="#6583FE"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6639"
          x1="6.43501"
          y1="25.3545"
          x2="90.5615"
          y2="66.6734"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0485" stopColor="#FFDBED" />
          <stop offset="0.9993" stopColor="#D2DEFF" />
        </linearGradient>
        <clipPath id="clip0_2042_6639">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationNotFoundError;
