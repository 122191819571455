import { SVGProps } from "types";

const BoxComputerMouse = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5376)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M12.1253 10.5128V7.875C12.1253 6.84075 11.2838 6 10.2503 6C9.21676 6 8.37526 6.84075 8.37526 7.875V8.25C8.37526 8.66325 8.03851 9 7.62526 9C7.21201 9 6.87526 8.66325 6.87526 8.25V6.50025C6.87451 6.2235 6.65101 6 6.37501 6C6.09901 6 5.87476 6.2235 5.87476 6.50025V8.25C5.87476 9.2145 6.66001 9.99975 7.62451 9.99975C8.58901 9.99975 9.37501 9.2145 9.37501 8.25V7.875C9.37501 7.39275 9.76726 6.99975 10.2503 6.99975C10.7325 6.99975 11.1255 7.392 11.1255 7.875V10.5128C9.87526 10.5795 8.87551 11.6085 8.87551 12.8752V15.6255C8.87476 16.9342 9.94051 18 11.25 18H12C13.3095 18 14.3753 16.9342 14.3753 15.6248V12.8745C14.3753 11.6085 13.3755 10.5795 12.1253 10.5128ZM12.1253 13.5C12.1253 13.776 11.9018 14.0002 11.625 14.0002C11.3483 14.0002 11.1248 13.776 11.1248 13.5V12.5002C11.1248 12.2242 11.3483 12 11.625 12C11.9018 12 12.1253 12.2242 12.1253 12.5002V13.5Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5376">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxComputerMouse;
