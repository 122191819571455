import { SVGProps } from "types";

const BoxShift = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5447)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M12.0001 18.0003C11.8943 18.0003 11.7893 17.9666 11.7001 17.9006L10.7003 17.1506C10.4791 16.9848 10.4341 16.6713 10.6006 16.4508C10.7663 16.2303 11.0798 16.1853 11.3003 16.3511L12.0001 16.8753L12.7006 16.3503C12.9211 16.1838 13.2346 16.2296 13.4003 16.4501C13.5661 16.6713 13.5211 16.9848 13.3006 17.1498L12.3008 17.8998C12.2108 17.9666 12.1058 18.0003 12.0001 18.0003Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M12.9998 7.74938C12.8955 7.74938 12.7905 7.71713 12.7005 7.64963L12 7.12463L11.2995 7.64963C11.0783 7.81538 10.7655 7.77038 10.5998 7.54988C10.434 7.32863 10.479 7.01513 10.6995 6.85013L11.6993 6.10013C11.877 5.96662 12.1215 5.96662 12.2993 6.10013L13.299 6.85013C13.5203 7.01588 13.5653 7.32938 13.3988 7.54988C13.302 7.68038 13.152 7.74938 12.9998 7.74938Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M15.5003 15.5007H8.49976C7.67251 15.5007 6.99976 14.828 6.99976 14.0007V10.001C6.99976 9.17373 7.67251 8.50098 8.49976 8.50098H15.5003C16.3275 8.50098 17.0003 9.17373 17.0003 10.001V14.0007C17.0003 14.828 16.3275 15.5007 15.5003 15.5007ZM8.49976 9.50073C8.22376 9.50073 7.99951 9.72498 7.99951 10.001V14.0007C7.99951 14.2767 8.22376 14.501 8.49976 14.501H15.5003C15.7763 14.501 16.0005 14.2767 16.0005 14.0007V10.001C16.0005 9.72498 15.7763 9.50073 15.5003 9.50073H8.49976Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5447">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxShift;
