import { SVGProps } from "types";

const MoneyBag = (props: SVGProps) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.4165 3.79169H10.5832L12.1261 1.47723C12.3573 1.12893 12.1099 0.666687 11.6932 0.666687H5.30648C4.88981 0.666687 4.64242 1.12893 4.87354 1.47723L6.4165 3.79169ZM10.5832 4.83335H6.4165C6.33404 4.8876 6.24291 4.94619 6.14502 5.00912C6.09606 5.04059 6.04542 5.07315 5.99333 5.10679L5.99005 5.10889C4.14291 6.29145 0.166504 8.83719 0.166504 14.2084C0.166504 15.9336 1.56624 17.3334 3.2915 17.3334H13.7082C15.4334 17.3334 16.8332 15.9336 16.8332 14.2084C16.8332 8.83718 12.8568 6.29145 11.0096 5.10889L11.0063 5.10679C10.8468 5.00588 10.7069 4.91473 10.5832 4.83335ZM9.76507 11.1832L10.1056 10.4892H7.67324C7.69294 10.4238 7.71493 10.3614 7.73922 10.3023C7.86992 9.98967 8.05336 9.75942 8.28954 9.61155C8.52571 9.46369 8.80316 9.38975 9.12189 9.38975C9.32367 9.38975 9.50711 9.4151 9.6722 9.4658C9.83959 9.51438 9.98749 9.57459 10.1159 9.64641C10.2466 9.71612 10.3578 9.78054 10.4495 9.83969L10.9998 8.75604C10.7453 8.56593 10.461 8.42017 10.1468 8.31878C9.83271 8.21738 9.49106 8.16669 9.12189 8.16669C8.52113 8.16669 7.98916 8.29977 7.52598 8.56593C7.06509 8.82997 6.70395 9.21126 6.44255 9.70978C6.31821 9.9469 6.22345 10.2067 6.15825 10.4892H5.4451L5.1665 11.1832H6.06019C6.05369 11.2884 6.05045 11.3961 6.05045 11.5064C6.05045 11.6122 6.05342 11.7157 6.05938 11.8169H5.4451L5.1665 12.5266H6.15862C6.2238 12.8085 6.31844 13.0673 6.44255 13.3029C6.70395 13.7972 7.06509 14.1764 7.52598 14.4405C7.98916 14.7024 8.52113 14.8334 9.12189 14.8334C9.4773 14.8334 9.81322 14.7858 10.1297 14.6908C10.4461 14.5936 10.7293 14.45 10.9792 14.2598L10.422 13.1762C10.3372 13.2332 10.2317 13.2955 10.1056 13.3631C9.98176 13.4307 9.8373 13.4888 9.6722 13.5374C9.50711 13.586 9.32367 13.6103 9.12189 13.6103C8.80316 13.6103 8.52571 13.5385 8.28954 13.3948C8.05336 13.2491 7.86992 13.022 7.73922 12.7136C7.71483 12.6546 7.69275 12.5923 7.67299 12.5266H9.07374L9.44864 11.8169H7.55579C7.54967 11.7177 7.54661 11.6142 7.54661 11.5064C7.54661 11.394 7.54999 11.2862 7.55675 11.1832H9.76507Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default MoneyBag;
