import { SVGProps } from "types";

const BoxAdd = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5375)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M15.2505 12.5C13.734 12.5 12.5002 13.7337 12.5002 15.2502C12.5002 16.7667 13.734 17.9998 15.2505 17.9998C16.767 17.9998 18 16.766 18 15.2495C18 13.733 16.7662 12.5 15.2505 12.5ZM16.5 15.7498H15.75V16.4998C15.75 16.7758 15.5257 17 15.2497 17C14.9737 17 14.7495 16.7758 14.7495 16.4998V15.7498H13.9995C13.7242 15.7498 13.5 15.5263 13.5 15.2495C13.5 14.9728 13.7242 14.7493 14.0002 14.7493H14.7502V13.9993C14.7502 13.7233 14.9745 13.499 15.2505 13.499C15.5265 13.4998 15.75 13.724 15.75 14V14.75H16.5C16.776 14.75 17.0002 14.9742 17.0002 15.2502C17.0002 15.5262 16.776 15.7498 16.5 15.7498Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M18 7.5C18 6.67275 17.3273 6 16.5 6H7.5C6.67275 6 6 6.67275 6 7.5V9C6 9.3855 6.15 9.73425 6.39 9.99975C6.15 10.266 6 10.6147 6 11.0002V12C6 12.3855 6.15 12.7343 6.39 12.9998C6.15 13.266 6 13.6147 6 14.0002V15.5002C6 16.3275 6.67275 17.0002 7.5 17.0002H11.5447C11.8207 17.0002 12.045 16.776 12.045 16.5C12.045 16.224 11.8207 15.9998 11.5447 15.9998H9.99975V13.5H11.79C12.066 13.5 12.2902 13.2758 12.2902 12.9998C12.2902 12.7238 12.066 12.4995 11.79 12.4995H9.99975V10.5H16.5C16.776 10.5 17.0002 10.7242 17.0002 11.0002V12.135C17.0002 12.411 17.2245 12.6353 17.5005 12.6353C17.7765 12.6353 18 12.411 18 12.135V11.0002C18 10.6147 17.85 10.266 17.61 10.0005C17.85 9.73425 18 9.3855 18 9V7.5ZM6.99975 7.5C6.99975 7.224 7.224 6.99975 7.5 6.99975H9V9.4995H7.5C7.224 9.4995 6.99975 9.27525 6.99975 8.99925V7.5ZM9 15.9998H7.5C7.224 15.9998 6.99975 15.7755 6.99975 15.4995V13.9995C6.99975 13.7243 7.224 13.5 7.5 13.5H9V15.9998ZM9 12.5002H7.5C7.224 12.5002 6.99975 12.276 6.99975 12V11.0002C6.99975 10.7242 7.224 10.5 7.5 10.5H9V12.5002ZM9.99975 9.50025V6.99975H16.5C16.776 6.99975 17.0002 7.224 17.0002 7.5V9C17.0002 9.276 16.776 9.50025 16.5 9.50025H9.99975Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5375">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxAdd;
