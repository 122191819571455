import { SVGProps } from "types";

const BoxApplication = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5359)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M9.50024 11.5C8.94899 11.5 8.50049 11.9485 8.50049 12.4998V14.125C8.50049 14.332 8.66849 14.5 8.87549 14.5C9.08249 14.5 9.25049 14.332 9.25049 14.125V13.5002H9.74999V14.125C9.74999 14.332 9.91799 14.5 10.125 14.5C10.332 14.5 10.5 14.332 10.5 14.125V12.4998C10.5 11.9485 10.0515 11.5 9.50024 11.5ZM9.24974 12.7502V12.5005C9.24974 12.3625 9.36149 12.2507 9.49949 12.2507C9.63749 12.2507 9.74999 12.3625 9.74999 12.5005V12.7502H9.24974Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M14.8755 14.5C14.6685 14.5 14.5005 14.332 14.5005 14.125V11.875C14.5005 11.668 14.6685 11.5 14.8755 11.5C15.0825 11.5 15.2505 11.668 15.2505 11.875V14.125C15.2505 14.332 15.0825 14.5 14.8755 14.5Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-cyber-yellow)"
        />
        <path
          d="M11.8748 14.5C11.6678 14.5 11.4998 14.332 11.4998 14.125V11.875C11.4998 11.668 11.6678 11.5 11.8748 11.5H12.4995C13.0508 11.5 13.4993 11.9485 13.4993 12.4998C13.4993 13.051 13.0515 13.5002 12.5003 13.5002H12.2505V14.125C12.2498 14.332 12.0818 14.5 11.8748 14.5ZM12.2498 12.7502H12.4995C12.6383 12.7502 12.75 12.6385 12.75 12.5005C12.75 12.3625 12.6383 12.2507 12.5003 12.2507H12.2505V12.7502H12.2498Z"
          fill="var(--color-shandy)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5359">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxApplication;
