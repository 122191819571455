import { SVGProps } from "types";

const LinkedIn = (props: SVGProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4701 -0.00010431H1.53006C1.33964 -0.00274893 1.15056 0.0321432 0.973624 0.102579C0.796689 0.173016 0.635362 0.277616 0.498856 0.410408C0.36235 0.543199 0.25334 0.70158 0.178051 0.876505C0.102761 1.05143 0.062667 1.23947 0.0600586 1.4299V18.5699C0.062667 18.7603 0.102761 18.9484 0.178051 19.1233C0.25334 19.2982 0.36235 19.4566 0.498856 19.5894C0.635362 19.7222 0.796689 19.8268 0.973624 19.8972C1.15056 19.9676 1.33964 20.0025 1.53006 19.9999H18.4701C18.6605 20.0025 18.8496 19.9676 19.0265 19.8972C19.2034 19.8268 19.3648 19.7222 19.5013 19.5894C19.6378 19.4566 19.7468 19.2982 19.8221 19.1233C19.8974 18.9484 19.9375 18.7603 19.9401 18.5699V1.4299C19.9375 1.23947 19.8974 1.05143 19.8221 0.876505C19.7468 0.70158 19.6378 0.543199 19.5013 0.410408C19.3648 0.277616 19.2034 0.173016 19.0265 0.102579C18.8496 0.0321432 18.6605 -0.00274893 18.4701 -0.00010431ZM6.09006 16.7399H3.09006V7.7399H6.09006V16.7399ZM4.59006 6.4799C4.17632 6.4799 3.77953 6.31554 3.48697 6.02298C3.19442 5.73043 3.03006 5.33363 3.03006 4.9199C3.03006 4.50616 3.19442 4.10937 3.48697 3.81681C3.77953 3.52425 4.17632 3.3599 4.59006 3.3599C4.80975 3.33498 5.03224 3.35675 5.24293 3.42378C5.45363 3.49081 5.6478 3.60159 5.81272 3.74886C5.97763 3.89613 6.10958 4.07657 6.19993 4.27838C6.29028 4.48018 6.33698 4.69879 6.33698 4.9199C6.33698 5.141 6.29028 5.35961 6.19993 5.56141C6.10958 5.76322 5.97763 5.94366 5.81272 6.09093C5.6478 6.23821 5.45363 6.34898 5.24293 6.41601C5.03224 6.48304 4.80975 6.50481 4.59006 6.4799ZM16.9101 16.7399H13.9101V11.9099C13.9101 10.6999 13.4801 9.9099 12.3901 9.9099C12.0527 9.91237 11.7242 10.0182 11.4489 10.2131C11.1735 10.408 10.9645 10.6826 10.8501 10.9999C10.7718 11.2349 10.7379 11.4825 10.7501 11.7299V16.7299H7.75006C7.75006 16.7299 7.75006 8.5499 7.75006 7.7299H10.7501V8.9999C11.0226 8.527 11.419 8.13741 11.8965 7.8731C12.374 7.60878 12.9146 7.47975 13.4601 7.4999C15.4601 7.4999 16.9101 8.7899 16.9101 11.5599V16.7399Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default LinkedIn;
