import { SVGProps } from "types";

const BoxWebDesign = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5366)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M17.8951 9.5606C17.7549 9.21035 17.4699 8.94035 17.1204 8.80535L16.0854 8.4206C15.8506 8.3306 15.6706 8.1506 15.5806 7.91585L15.1959 6.88085C15.0609 6.5306 14.7909 6.2456 14.4406 6.1061C14.0956 5.96135 13.7004 5.96585 13.3606 6.1211L12.3556 6.58085C12.1306 6.6806 11.8704 6.6806 11.6454 6.58085L10.6404 6.1211C10.3006 5.96585 9.90535 5.96135 9.56035 6.1061C9.2101 6.2456 8.9401 6.5306 8.8051 6.8801L8.42035 7.9151C8.33035 8.14985 8.15035 8.32985 7.9156 8.41985L6.8806 8.8046C6.53035 8.9396 6.24535 9.2096 6.10585 9.55985C5.9611 9.90485 5.9656 10.3001 6.12085 10.6398L6.5806 11.6448C6.68035 11.8698 6.68035 12.1301 6.5806 12.3551L6.12085 13.3601C5.9656 13.6998 5.9611 14.0951 6.10585 14.4401C6.2461 14.7903 6.5311 15.0603 6.8806 15.1953L7.9156 15.5801C8.15035 15.6701 8.33035 15.8501 8.42035 16.0848L8.8051 17.1198C8.9401 17.4701 9.2101 17.7551 9.56035 17.8946C9.62035 17.9193 9.6856 17.9396 9.7456 17.9546C9.59035 17.6696 9.50035 17.3448 9.50035 16.9998V12.4998C9.50035 11.3951 10.3951 10.4996 11.5006 10.4996H17.0011C17.3064 10.4996 17.5959 10.5693 17.8561 10.6946L17.8809 10.6398C18.0354 10.3001 18.0406 9.9056 17.8951 9.5606Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M17.0002 11.5H11.4998C10.9478 11.5 10.5 11.9478 10.5 12.4998V16.9998C10.5 17.5525 10.9478 18.0002 11.4998 18.0002H17.0002C17.5522 18.0002 18 17.5525 18 17.0005V12.5005C18 11.9477 17.5522 11.5 17.0002 11.5ZM16.7505 17.0005H11.7502C11.6122 17.0005 11.5005 16.8887 11.5005 16.7507V13.5002H17.001V16.75C17.0002 16.888 16.8885 17.0005 16.7505 17.0005Z"
          fill="var(--color-sky-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5366">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxWebDesign;
