import { SVGProps } from "types";

const IllustrationVideo = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6607)">
        <path
          d="M53.2941 15.3575C49.6108 6.30503 40.7006 -0.0636297 30.3099 0.000479654C17.0542 0.0821203 6.15531 10.7743 5.83373 24.0262C5.67509 30.5643 8.06432 36.5423 12.0786 41.0389C15.2788 44.6235 16.8848 49.3448 16.7229 54.1475C16.7014 54.7858 16.6981 55.4288 16.7139 56.0759C17.1374 73.504 32.0258 88.0105 49.459 88C67.5288 87.9892 82.1737 73.3373 82.1737 55.2652C82.1737 42.0181 74.3046 30.61 62.9861 25.4581C58.5746 23.4501 55.1208 19.8469 53.2941 15.3575Z"
          fill="url(#paint0_linear_2042_6607)"
        />
        <path
          opacity="0.1"
          d="M78.375 17.7676V66.623C78.375 68.0462 77.22 69.2012 75.7969 69.2012H12.2031C10.78 69.2012 9.625 68.0462 9.625 66.623V17.7676C9.625 16.3445 10.78 15.1895 12.2031 15.1895H75.7969C77.22 15.1895 78.375 16.3445 78.375 17.7676Z"
          fill="#2626BC"
        />
        <path
          d="M78.375 21.377V70.2324C78.375 71.6555 77.22 72.8105 75.7969 72.8105H12.2031C10.78 72.8105 9.625 71.6555 9.625 70.2324V21.377C9.625 19.9538 10.78 18.7988 12.2031 18.7988H75.7969C77.22 18.7988 78.375 19.9538 78.375 21.377Z"
          fill="white"
        />
        <path
          d="M71.7944 72.8104H16.2056V68.1484C16.2056 67.8636 16.4364 67.6328 16.7212 67.6328H71.2786C71.5634 67.6328 71.7943 67.8636 71.7943 68.1484V72.8104H71.7944Z"
          fill="#D2DEFF"
        />
        <path
          d="M16.2055 27.0781H71.7942V30.7088C71.7942 30.9936 71.5633 31.2244 71.2785 31.2244H16.7209C16.4362 31.2244 16.2053 30.9936 16.2053 30.7088L16.2055 27.0781Z"
          fill="#D2DEFF"
        />
        <path
          d="M78.375 27.0782V21.377C78.375 19.9531 77.2207 18.7988 75.7969 18.7988H12.2031C10.7793 18.7988 9.625 19.9531 9.625 21.377V27.0782H78.375Z"
          fill="#6583FE"
        />
        <path
          d="M16.1703 23.8101C15.6956 23.8101 15.3109 23.4252 15.3109 22.9507V22.9258C15.3109 22.4512 15.6956 22.0664 16.1703 22.0664C16.645 22.0664 17.0297 22.4512 17.0297 22.9258V22.9507C17.0297 23.4252 16.645 23.8101 16.1703 23.8101Z"
          fill="#2626BC"
        />
        <path
          d="M19.4918 23.8101C19.0171 23.8101 18.6324 23.4252 18.6324 22.9507V22.9258C18.6324 22.4512 19.0171 22.0664 19.4918 22.0664C19.9665 22.0664 20.3512 22.4512 20.3512 22.9258V22.9507C20.3512 23.4252 19.9665 23.8101 19.4918 23.8101Z"
          fill="#2626BC"
        />
        <path
          d="M22.8131 23.8101C22.3384 23.8101 21.9537 23.4252 21.9537 22.9507V22.9258C21.9537 22.4512 22.3384 22.0664 22.8131 22.0664C23.2878 22.0664 23.6725 22.4512 23.6725 22.9258V22.9507C23.6725 23.4252 23.2878 23.8101 22.8131 23.8101Z"
          fill="#2626BC"
        />
        <path
          d="M75.7969 17.9395H12.2031C10.3077 17.9395 8.76562 19.4815 8.76562 21.377V27.0873C8.76562 27.5619 9.15028 27.9467 9.625 27.9467C9.65577 27.9467 9.68498 27.9409 9.71489 27.9376H78.375C78.8497 27.9376 79.2344 27.5528 79.2344 27.0782V21.377C79.2344 19.4815 77.6923 17.9395 75.7969 17.9395ZM12.2031 19.6582H75.7969C76.7446 19.6582 77.5156 20.4292 77.5156 21.377V26.2188H10.4844V21.377C10.4842 20.4291 11.2552 19.6582 12.2031 19.6582Z"
          fill="#2626BC"
        />
        <path
          d="M78.375 30.9523C77.9003 30.9523 77.5156 31.3371 77.5156 31.8117V70.2317C77.5156 71.1795 76.7446 71.9505 75.7969 71.9505H12.2031C11.2554 71.9505 10.4844 71.1795 10.4844 70.2317V31.8027C10.4844 31.3282 10.0997 30.9434 9.625 30.9434C9.15028 30.9434 8.76562 31.3282 8.76562 31.8027V70.2319C8.76562 72.1274 10.3077 73.6694 12.2031 73.6694H75.7969C77.6923 73.6694 79.2344 72.1274 79.2344 70.2319V31.8117C79.2344 31.3371 78.8497 30.9523 78.375 30.9523Z"
          fill="#2626BC"
        />
        <path
          d="M36.7733 49.2151H16.7212C16.4364 49.2151 16.2056 48.9842 16.2056 48.6994V34.752C16.2056 34.4672 16.4364 34.2363 16.7212 34.2363H36.7733C37.0581 34.2363 37.289 34.4672 37.289 34.752V48.6994C37.289 48.9842 37.0581 49.2151 36.7733 49.2151Z"
          fill="#FF7EB8"
        />
        <path
          d="M24.2552 39.4295V44.0227C24.2552 44.4202 24.6861 44.6682 25.0299 44.4685L28.9825 42.1719C29.3245 41.9731 29.3245 41.4791 28.9825 41.2802L25.0299 38.9836C24.6861 38.7839 24.2552 39.0319 24.2552 39.4295Z"
          fill="white"
        />
        <path
          d="M40.9835 61.1483H17.0664C16.591 61.1483 16.2057 60.7629 16.2057 60.2875C16.2057 59.8121 16.591 59.4268 17.0664 59.4268H40.9835C41.4589 59.4268 41.8443 59.8121 41.8443 60.2875C41.8443 60.7629 41.4589 61.1483 40.9835 61.1483Z"
          fill="#B7C5FF"
        />
        <path
          d="M40.9835 54.7918H29.241C28.7656 54.7918 28.3802 54.4065 28.3802 53.9311C28.3802 53.4557 28.7656 53.0703 29.241 53.0703H40.9835C41.4589 53.0703 41.8442 53.4557 41.8442 53.9311C41.8442 54.4065 41.4589 54.7918 40.9835 54.7918Z"
          fill="#6583FE"
        />
        <path
          d="M40.9835 64.5623H17.0664C16.591 64.5623 16.2057 64.177 16.2057 63.7016C16.2057 63.2262 16.591 62.8408 17.0664 62.8408H40.9835C41.4589 62.8408 41.8443 63.2262 41.8443 63.7016C41.8443 64.1768 41.4589 64.5623 40.9835 64.5623Z"
          fill="#B7C5FF"
        />
        <path
          d="M70.9335 42.2791H42.2747C41.7993 42.2791 41.4139 41.8938 41.4139 41.4184C41.4139 40.943 41.7993 40.5576 42.2747 40.5576H70.9335C71.4089 40.5576 71.7942 40.943 71.7942 41.4184C71.7944 41.8938 71.4091 42.2791 70.9335 42.2791Z"
          fill="#B7C5FF"
        />
        <path
          d="M60.621 35.9578H42.2747C41.7993 35.9578 41.4139 35.5725 41.4139 35.0971C41.4139 34.6217 41.7993 34.2363 42.2747 34.2363H60.621C61.0964 34.2363 61.4817 34.6217 61.4817 35.0971C61.4819 35.5723 61.0966 35.9578 60.621 35.9578Z"
          fill="#FFA8CF"
        />
        <path
          d="M70.9335 45.6932H42.2747C41.7993 45.6932 41.4139 45.3078 41.4139 44.8324C41.4139 44.357 41.7993 43.9717 42.2747 43.9717H70.9335C71.4089 43.9717 71.7942 44.357 71.7942 44.8324C71.7944 45.3078 71.4091 45.6932 70.9335 45.6932Z"
          fill="#B7C5FF"
        />
        <path
          d="M70.9335 49.1795H42.2747C41.7993 49.1795 41.4139 48.7942 41.4139 48.3188C41.4139 47.8434 41.7993 47.458 42.2747 47.458H70.9335C71.4089 47.458 71.7942 47.8434 71.7942 48.3188C71.7944 48.794 71.4091 49.1795 70.9335 49.1795Z"
          fill="#B7C5FF"
        />
        <path
          d="M71.2788 64.5629H45.718C45.4332 64.5629 45.2024 64.3321 45.2024 64.0473V53.5859C45.2024 53.3011 45.4332 53.0703 45.718 53.0703H71.2788C71.5636 53.0703 71.7944 53.3011 71.7944 53.5859V64.0473C71.7944 64.3321 71.5634 64.5629 71.2788 64.5629Z"
          fill="#6583FE"
        />
        <path
          d="M61.4048 59.777C61.9354 59.777 62.3655 59.3469 62.3655 58.8162C62.3655 58.2856 61.9354 57.8555 61.4048 57.8555C60.8741 57.8555 60.444 58.2856 60.444 58.8162C60.444 59.3469 60.8741 59.777 61.4048 59.777Z"
          fill="#D2DEFF"
        />
        <path
          d="M58.4983 59.777C59.0289 59.777 59.459 59.3469 59.459 58.8162C59.459 58.2856 59.0289 57.8555 58.4983 57.8555C57.9676 57.8555 57.5375 58.2856 57.5375 58.8162C57.5375 59.3469 57.9676 59.777 58.4983 59.777Z"
          fill="#D2DEFF"
        />
        <path
          d="M55.5919 59.777C56.1225 59.777 56.5527 59.3469 56.5527 58.8162C56.5527 58.2856 56.1225 57.8555 55.5919 57.8555C55.0613 57.8555 54.6311 58.2856 54.6311 58.8162C54.6311 59.3469 55.0613 59.777 55.5919 59.777Z"
          fill="#D2DEFF"
        />
        <path
          d="M18.3369 81.6652C18.109 81.6652 17.8904 81.5746 17.7293 81.4136L15.8356 79.5199C15.4999 79.1842 15.4999 78.64 15.8356 78.3045L17.7293 76.4108C17.8906 76.2496 18.109 76.1592 18.3369 76.1592C18.5648 76.1592 18.7834 76.2498 18.9445 76.4108L20.8384 78.3045C20.9996 78.4657 21.0902 78.6842 21.0902 78.9123C21.0902 79.1404 20.9998 79.3588 20.8384 79.52L18.9445 81.4137C18.7834 81.5746 18.565 81.6652 18.3369 81.6652ZM17.6587 78.9121L18.3369 79.5905L19.0153 78.9121L18.3369 78.2337L17.6587 78.9121Z"
          fill="#6583FE"
        />
        <path
          d="M49.6687 10.6527C49.4408 10.6527 49.2222 10.5621 49.0612 10.401L47.1674 8.50733C47.0062 8.34611 46.9156 8.12766 46.9156 7.89958C46.9156 7.6715 47.0062 7.45305 47.1674 7.29183L49.0613 5.39811C49.3967 5.06261 49.9412 5.06261 50.2765 5.39811L52.1702 7.29183C52.5059 7.6275 52.5059 8.17166 52.1702 8.50716L50.2765 10.4009C50.1153 10.5621 49.8968 10.6527 49.6687 10.6527ZM48.9904 7.89958L49.6687 8.57797L50.347 7.89958L49.6687 7.22119L48.9904 7.89958Z"
          fill="#6583FE"
        />
        <path
          d="M71.7944 82.8529C71.5746 82.8529 71.3546 82.769 71.1868 82.6012L69.2929 80.7075C69.1317 80.5463 69.0411 80.3279 69.0411 80.0998C69.0411 79.8717 69.1315 79.6532 69.2929 79.492L71.1868 77.5983C71.348 77.4371 71.5665 77.3467 71.7944 77.3467C72.0223 77.3467 72.2409 77.4373 72.402 77.5983L74.2957 79.492C74.6314 79.8277 74.6314 80.3719 74.2957 80.7074L72.402 82.6011C72.2342 82.7688 72.0142 82.8529 71.7944 82.8529ZM71.116 80.0998L71.7944 80.7782L72.4726 80.0998L71.7944 79.4214L71.116 80.0998Z"
          fill="#FF7EB8"
        />
        <path
          d="M58.4983 12.5472C58.0235 12.5472 57.6389 12.1624 57.6389 11.6878C57.6389 11.1175 57.1748 10.6535 56.6044 10.6535C56.1297 10.6535 55.745 10.2687 55.745 9.79411C55.745 9.31956 56.1297 8.93473 56.6044 8.93473C57.1747 8.93473 57.6389 8.47067 57.6389 7.90039C57.6389 7.42584 58.0235 7.04102 58.4983 7.04102C58.973 7.04102 59.3576 7.42584 59.3576 7.90039C59.3576 8.47067 59.8215 8.93473 60.392 8.93473C60.8667 8.93473 61.2514 9.31956 61.2514 9.79411C61.2514 10.2687 60.8667 10.6535 60.392 10.6535C59.8217 10.6535 59.3576 11.1175 59.3576 11.6878C59.3576 12.1624 58.973 12.5472 58.4983 12.5472Z"
          fill="#97FFD2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6607"
          x1="4.97333"
          y1="8.08617"
          x2="75.3264"
          y2="82.5338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6607">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationVideo;
