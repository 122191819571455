import { SVGProps } from "types";

const Close = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.6466 15.9527C18.1153 16.4214 18.1153 17.1807 17.6466 17.6494C17.4141 17.8838 17.1066 18 16.7992 18C16.4917 18 16.185 17.8828 15.951 17.6485L11.9995 13.6991L8.04847 17.6475C7.81411 17.8838 7.50701 18 7.19991 18C6.89281 18 6.58608 17.8838 6.35154 17.6475C5.88282 17.1788 5.88282 16.4195 6.35154 15.9508L10.3037 11.9986L6.35154 8.04828C5.88282 7.57956 5.88282 6.82025 6.35154 6.35154C6.82025 5.88282 7.57956 5.88282 8.04828 6.35154L11.9995 10.3056L15.9517 6.35341C16.4204 5.8847 17.1798 5.8847 17.6485 6.35341C18.1172 6.82212 18.1172 7.58144 17.6485 8.05015L13.6963 12.0023L17.6466 15.9527Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Close;
