import { SVGProps } from "types";

const IllustrationWebTraffic = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M86.9044 50.173C84.8718 44.2244 80.3495 39.833 74.8986 37.7633C72.0747 36.6909 70.1985 34.0068 70.1571 30.9864C70.1562 30.9204 70.155 30.8542 70.1535 30.7881C69.8945 19.688 60.6165 10.7938 49.5153 10.9845C42.9678 11.0969 37.1767 14.3111 33.5472 19.2207C30.4217 23.4487 25.5567 26.0897 20.2991 26.0869C20.1447 26.0869 19.9902 26.0885 19.835 26.0919C9.19303 26.3246 0.143815 35.4553 0.00184618 46.0987C-0.149232 57.4308 8.99143 66.6642 20.2886 66.6642C29.0451 66.6642 36.5048 61.1166 39.3471 53.3444C40.0908 51.3108 42.2951 50.2208 44.3801 50.8055C44.4061 50.8127 44.4322 50.8201 44.4584 50.8273C46.366 51.3539 47.6308 53.1717 47.4748 55.1446C47.2656 57.7886 47.5746 60.5166 48.4713 63.1877C51.9983 73.6953 63.4567 79.4617 73.9935 76.0233C84.772 72.5059 90.5587 60.8667 86.9044 50.173Z"
        fill="url(#paint0_linear_2042_6608)"
      />
      <path
        opacity="0.1"
        d="M78.375 16.0986V64.9541C78.375 66.3772 77.22 67.5322 75.7969 67.5322H12.2031C10.78 67.5322 9.625 66.3772 9.625 64.9541V16.0986C9.625 14.6755 10.78 13.5205 12.2031 13.5205H75.7969C77.22 13.5205 78.375 14.6755 78.375 16.0986Z"
        fill="#2626BC"
      />
      <path
        d="M78.375 19.708V68.5635C78.375 69.9866 77.22 71.1416 75.7969 71.1416H12.2031C10.78 71.1416 9.625 69.9866 9.625 68.5635V19.708C9.625 18.2849 10.78 17.1299 12.2031 17.1299H75.7969C77.22 17.1299 78.375 18.2849 78.375 19.708Z"
        fill="white"
      />
      <path
        d="M15.8875 25.4092H72.1124V61.5626H15.8875V25.4092Z"
        fill="#D2DEFF"
      />
      <path
        d="M78.375 25.4093V19.708C78.375 18.2842 77.2207 17.1299 75.7969 17.1299H12.2031C10.7793 17.1299 9.625 18.2842 9.625 19.708V25.4093H78.375Z"
        fill="#6583FE"
      />
      <path
        d="M15.311 21.2568V21.2819C15.311 21.7566 15.6959 22.1413 16.1704 22.1413C16.645 22.1413 17.0298 21.7566 17.0298 21.2819V21.2568C17.0298 20.7821 16.645 20.3975 16.1704 20.3975C15.6959 20.3975 15.311 20.7821 15.311 21.2568Z"
        fill="#2626BC"
      />
      <path
        d="M18.6326 21.2568V21.2819C18.6326 21.7566 19.0174 22.1413 19.4919 22.1413C19.9665 22.1413 20.3513 21.7566 20.3513 21.2819V21.2568C20.3513 20.7821 19.9665 20.3975 19.4919 20.3975C19.0174 20.3975 18.6326 20.7821 18.6326 21.2568Z"
        fill="#2626BC"
      />
      <path
        d="M21.9539 21.2568V21.2819C21.9539 21.7566 22.3387 22.1413 22.8132 22.1413C23.2878 22.1413 23.6726 21.7566 23.6726 21.2819V21.2568C23.6726 20.7821 23.2878 20.3975 22.8132 20.3975C22.3387 20.3975 21.9539 20.7821 21.9539 21.2568Z"
        fill="#2626BC"
      />
      <path
        d="M78.375 21.5352C78.8495 21.5352 79.2344 21.1506 79.2344 20.6758V19.708C79.2344 17.8126 77.6923 16.2705 75.7969 16.2705H12.2031C10.3077 16.2705 8.76562 17.8126 8.76562 19.708V25.4093C8.76562 25.884 9.15045 26.2686 9.625 26.2686H77.5156V68.563C77.5156 69.5107 76.7446 70.2817 75.7969 70.2817H12.2031C11.2554 70.2817 10.4844 69.5107 10.4844 68.563V30.1429C10.4844 29.6682 10.0995 29.2835 9.625 29.2835C9.15045 29.2835 8.76562 29.6682 8.76562 30.1429V68.563C8.76562 70.4584 10.3077 72.0004 12.2031 72.0004H75.7969C77.6923 72.0004 79.2344 70.4584 79.2344 68.563V25.4093C79.2344 24.9346 78.8495 24.5499 78.375 24.5499H10.4844V19.708C10.4844 18.7603 11.2554 17.9893 12.2031 17.9893H75.7969C76.7446 17.9893 77.5156 18.7603 77.5156 19.708V20.6758C77.5156 21.1506 77.9005 21.5352 78.375 21.5352Z"
        fill="#2626BC"
      />
      <path
        d="M22.885 65.3149H20.135C19.8502 65.3149 19.6194 65.084 19.6194 64.7992V49.1816C19.6194 48.8968 19.8502 48.666 20.135 48.666H22.885C23.1698 48.666 23.4006 48.8968 23.4006 49.1816V64.7992C23.4006 65.084 23.1698 65.3149 22.885 65.3149Z"
        fill="#02FFB3"
      />
      <path
        d="M17.2625 65.3151H14.5125C14.2277 65.3151 13.9968 65.0842 13.9968 64.7994V58.3271C13.9968 58.0424 14.2277 57.8115 14.5125 57.8115H17.2625C17.5472 57.8115 17.7781 58.0424 17.7781 58.3271V64.7994C17.7781 65.0842 17.5472 65.3151 17.2625 65.3151Z"
        fill="#97FFD2"
      />
      <path
        d="M28.5076 65.3143H25.7576C25.4728 65.3143 25.2419 65.0835 25.2419 64.7987V56.4902C25.2419 56.2054 25.4728 55.9746 25.7576 55.9746H28.5076C28.7924 55.9746 29.0232 56.2054 29.0232 56.4902V64.7987C29.0232 65.0835 28.7924 65.3143 28.5076 65.3143Z"
        fill="#02FFB3"
      />
      <path
        d="M34.1301 65.3149H31.3801C31.0953 65.3149 30.8645 65.084 30.8645 64.7992V48.1504C30.8645 47.8656 31.0953 47.6348 31.3801 47.6348H34.1301C34.4149 47.6348 34.6458 47.8656 34.6458 48.1504V64.7992C34.6458 65.084 34.4149 65.3149 34.1301 65.3149Z"
        fill="#01ECA5"
      />
      <path
        d="M39.7524 65.3143H37.0024C36.7176 65.3143 36.4868 65.0835 36.4868 64.7987V50.3027C36.4868 50.0179 36.7176 49.7871 37.0024 49.7871H39.7524C40.0372 49.7871 40.2681 50.0179 40.2681 50.3027V64.7987C40.2681 65.0835 40.0372 65.3143 39.7524 65.3143Z"
        fill="#00D890"
      />
      <path
        d="M45.375 65.3149H42.625C42.3402 65.3149 42.1094 65.084 42.1094 64.7992V44.0254C42.1094 43.7406 42.3402 43.5098 42.625 43.5098H45.375C45.6598 43.5098 45.8906 43.7406 45.8906 44.0254V64.7992C45.8906 65.084 45.6598 65.3149 45.375 65.3149Z"
        fill="#FFC4DF"
      />
      <path
        d="M50.9976 65.3143H48.2476C47.9628 65.3143 47.7319 65.0835 47.7319 64.7987V51.334C47.7319 51.0492 47.9628 50.8184 48.2476 50.8184H50.9976C51.2824 50.8184 51.5132 51.0492 51.5132 51.334V64.7987C51.5132 65.0835 51.2824 65.3143 50.9976 65.3143Z"
        fill="#FFA8CF"
      />
      <path
        d="M56.6199 65.3149H53.8699C53.5851 65.3149 53.3542 65.084 53.3542 64.7992V42.9941C53.3542 42.7093 53.5851 42.4785 53.8699 42.4785H56.6199C56.9047 42.4785 57.1355 42.7093 57.1355 42.9941V64.7992C57.1355 65.084 56.9047 65.3149 56.6199 65.3149Z"
        fill="#FF7EB8"
      />
      <path
        d="M62.2424 65.3143H59.4924C59.2076 65.3143 58.9768 65.0835 58.9768 64.7987V45.1465C58.9768 44.8617 59.2076 44.6309 59.4924 44.6309H62.2424C62.5272 44.6309 62.7581 44.8617 62.7581 45.1465V64.7987C62.7581 65.0835 62.5272 65.3143 62.2424 65.3143Z"
        fill="#FF5BA8"
      />
      <path
        d="M67.865 65.3143H65.115C64.8302 65.3143 64.5994 65.0835 64.5994 64.7987V41.0215C64.5994 40.7367 64.8302 40.5059 65.115 40.5059H67.865C68.1498 40.5059 68.3806 40.7367 68.3806 41.0215V64.7987C68.3806 65.0835 68.1498 65.3143 67.865 65.3143Z"
        fill="#9FB0FE"
      />
      <path
        d="M73.4873 65.3149H70.7373C70.4525 65.3149 70.2217 65.084 70.2217 64.7992V32.6816C70.2217 32.3968 70.4525 32.166 70.7373 32.166H73.4873C73.7721 32.166 74.0029 32.3968 74.0029 32.6816V64.7992C74.0029 65.084 73.7721 65.3149 73.4873 65.3149Z"
        fill="#6583FE"
      />
      <path
        d="M25.668 33.4068C25.668 31.8326 24.3872 30.5518 22.813 30.5518C21.2388 30.5518 19.958 31.8326 19.958 33.4068C19.958 34.1831 20.2705 34.8871 20.7749 35.4024C20.2703 35.9177 19.958 36.6217 19.958 37.3981C19.958 37.8728 20.3428 38.2574 20.8174 38.2574C21.2919 38.2574 21.6768 37.8728 21.6768 37.3981C21.6768 36.7714 22.1865 36.2618 22.813 36.2618C23.4395 36.2618 23.9493 36.7716 23.9493 37.3981C23.9493 37.8728 24.3341 38.2574 24.8087 38.2574C25.2832 38.2574 25.668 37.8728 25.668 37.3981C25.668 36.6217 25.3556 35.9175 24.8511 35.4024C25.3557 34.8871 25.668 34.1831 25.668 33.4068ZM22.813 32.2705C23.4395 32.2705 23.9493 32.7803 23.9493 33.4068C23.9493 34.0333 23.4395 34.5429 22.813 34.5429C22.1865 34.5429 21.6768 34.0331 21.6768 33.4068C21.6769 32.7803 22.1865 32.2705 22.813 32.2705Z"
        fill="#4369FD"
      />
      <path
        d="M38.6637 33.8362H54.1292C54.6154 33.8362 55.0097 33.4419 55.0097 32.9557C55.0097 32.4695 54.6156 32.0752 54.1292 32.0752H38.6637C38.1775 32.0752 37.7832 32.4695 37.7832 32.9557C37.7832 33.4419 38.1773 33.8362 38.6637 33.8362Z"
        fill="#B7C5FF"
      />
      <path
        d="M30.0895 33.8362H34.3137C34.7999 33.8362 35.1942 33.4419 35.1942 32.9557C35.1942 32.4695 34.8001 32.0752 34.3137 32.0752H30.0895C29.6033 32.0752 29.209 32.4695 29.209 32.9557C29.209 33.4419 29.6031 33.8362 30.0895 33.8362Z"
        fill="#FFA8CF"
      />
      <path
        d="M30.0895 37.3284H54.129C54.6152 37.3284 55.0095 36.9341 55.0095 36.4479C55.0095 35.9617 54.6154 35.5674 54.129 35.5674H30.0895C29.6033 35.5674 29.209 35.9617 29.209 36.4479C29.209 36.9343 29.6031 37.3284 30.0895 37.3284Z"
        fill="#B7C5FF"
      />
      <path
        d="M21.0699 42.6315C21.6005 42.6315 22.0307 42.2014 22.0307 41.6707C22.0307 41.1401 21.6005 40.71 21.0699 40.71C20.5393 40.71 20.1091 41.1401 20.1091 41.6707C20.1091 42.2014 20.5393 42.6315 21.0699 42.6315Z"
        fill="#FFA8CF"
      />
      <path
        d="M23.9764 42.6315C24.507 42.6315 24.9372 42.2014 24.9372 41.6707C24.9372 41.1401 24.507 40.71 23.9764 40.71C23.4458 40.71 23.0156 41.1401 23.0156 41.6707C23.0156 42.2014 23.4458 42.6315 23.9764 42.6315Z"
        fill="#FFA8CF"
      />
      <path
        d="M26.8831 42.6315C27.4138 42.6315 27.8439 42.2014 27.8439 41.6707C27.8439 41.1401 27.4138 40.71 26.8831 40.71C26.3525 40.71 25.9224 41.1401 25.9224 41.6707C25.9224 42.2014 26.3525 42.6315 26.8831 42.6315Z"
        fill="#FFA8CF"
      />
      <path
        d="M65.4588 14.319C64.9843 14.319 64.5994 13.9343 64.5994 13.4596C64.5994 12.8895 64.1355 12.4256 63.5654 12.4256C63.0909 12.4256 62.7061 12.0409 62.7061 11.5662C62.7061 11.0915 63.0909 10.7069 63.5654 10.7069C64.1355 10.7069 64.5994 10.2431 64.5994 9.67285C64.5994 9.19813 64.9843 8.81348 65.4588 8.81348C65.9334 8.81348 66.3182 9.19813 66.3182 9.67285C66.3182 10.243 66.7821 10.7069 67.3522 10.7069C67.8267 10.7069 68.2116 11.0915 68.2116 11.5662C68.2116 12.0409 67.8267 12.4256 67.3522 12.4256C66.7821 12.4256 66.3182 12.8893 66.3182 13.4596C66.3182 13.9341 65.9334 14.319 65.4588 14.319Z"
        fill="#6583FE"
      />
      <path
        d="M31.9483 11.9772C31.4738 11.9772 31.0889 11.5925 31.0889 11.1178C31.0889 10.5477 30.625 10.0838 30.0549 10.0838C29.5804 10.0838 29.1956 9.69915 29.1956 9.22443C29.1956 8.74971 29.5804 8.36505 30.0549 8.36505C30.625 8.36505 31.0889 7.90134 31.0889 7.33105C31.0889 6.85634 31.4738 6.47168 31.9483 6.47168C32.4229 6.47168 32.8077 6.85634 32.8077 7.33105C32.8077 7.90116 33.2716 8.36505 33.8417 8.36505C34.3162 8.36505 34.7011 8.74971 34.7011 9.22443C34.7011 9.69915 34.3162 10.0838 33.8417 10.0838C33.2716 10.0838 32.8077 10.5475 32.8077 11.1178C32.8077 11.5924 32.423 11.9772 31.9483 11.9772Z"
        fill="#97FFD2"
      />
      <path
        d="M49.6226 79.7864C49.3947 79.7864 49.176 79.696 49.0148 79.5346L47.125 77.6448C46.7894 77.3093 46.7894 76.7652 47.125 76.4297L49.0148 74.5399C49.176 74.3787 49.3945 74.2881 49.6226 74.2881C49.8505 74.2881 50.0691 74.3785 50.2303 74.5399L52.1201 76.4297C52.4557 76.7652 52.4557 77.3093 52.1201 77.6448L50.2303 79.5346C50.0691 79.696 49.8505 79.7864 49.6226 79.7864ZM48.9481 77.0372L49.6226 77.7117L50.297 77.0372L49.6226 76.3628L48.9481 77.0372Z"
        fill="#FF7EB8"
      />
      <path
        d="M41.2992 81.5284C41.0792 81.5284 40.8594 81.4445 40.6914 81.2766L38.8017 79.387C38.6405 79.2258 38.55 79.0073 38.55 78.7794C38.55 78.5515 38.6406 78.3329 38.8017 78.1718L40.6914 76.2821C40.8527 76.1209 41.0711 76.0303 41.2992 76.0303C41.5273 76.0303 41.7457 76.1207 41.9069 76.2821L43.7967 78.1718C43.9579 78.3331 44.0483 78.5515 44.0483 78.7794C44.0483 79.0073 43.9577 79.2259 43.7965 79.3872L41.9068 81.2768C41.739 81.4443 41.519 81.5284 41.2992 81.5284ZM40.6249 78.7794L41.2992 79.4537L41.9736 78.7794L41.2992 78.105L40.6249 78.7794Z"
        fill="#6583FE"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2042_6608"
          x1="7.50316"
          y1="24.3364"
          x2="89.3141"
          y2="67.3504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IllustrationWebTraffic;
