import { SVGProps } from "types";

const Moon = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 12C3 6.47321 7.47768 2 12.9911 2C13.4982 2 14.317 2.07446 14.817 2.16723C15.2463 2.24656 15.3415 2.82036 14.9633 3.03509C12.5089 4.43304 11 7.02679 11 9.84821C11 14.7455 15.4513 18.4643 20.2991 17.5402C20.7259 17.4596 21.0259 17.9564 20.7504 18.2969C18.8884 20.5982 16.0714 22 12.9911 22C7.46875 22 3 17.5179 3 12Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default Moon;
