import { SVGProps } from "types";

const BoxSearchOption = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5429)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M12.4942 10.9635C12.7965 10.776 13.0005 10.4437 13.0005 10.0627C13.0005 9.477 12.5242 9 11.9377 9H11.3752C11.1682 9 11.0002 9.168 11.0002 9.375V11.625C11.0002 11.832 11.1682 12 11.3752 12C11.5822 12 11.7502 11.832 11.7502 11.625V11.28L12.36 11.8897C12.4335 11.9632 12.5287 12 12.6247 12C12.7207 12 12.8167 11.9633 12.8902 11.8905C13.0365 11.7443 13.0365 11.5065 12.8902 11.3603L12.4942 10.9635ZM11.7502 9.75H11.9377C12.1102 9.75 12.2505 9.89025 12.2505 10.0627C12.2505 10.2352 12.1102 10.3755 11.9377 10.3755H11.7502V9.75Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M9.10351 12H8.37451C8.16751 12 7.99951 11.832 7.99951 11.625C7.99951 11.418 8.16751 11.25 8.37451 11.25H9.10351C9.18226 11.25 9.24976 11.1637 9.24976 11.0625C9.24976 10.9612 9.18301 10.875 9.10351 10.875H8.89576C8.40151 10.875 7.99951 10.4542 7.99951 9.9375C7.99951 9.42075 8.40151 9 8.89576 9H9.62476C9.83176 9 9.99976 9.168 9.99976 9.375C9.99976 9.582 9.83176 9.75 9.62476 9.75H8.89576C8.81701 9.75 8.74951 9.83625 8.74951 9.9375C8.74951 10.0388 8.81626 10.125 8.89576 10.125H9.10351C9.59776 10.125 9.99976 10.5458 9.99976 11.0625C9.99976 11.5793 9.59776 12 9.10351 12Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M15.6255 11.9998H14.8755C14.3932 11.9998 14.0002 11.6075 14.0002 11.1245V9.87427C14.0002 9.39202 14.3925 8.99902 14.8755 8.99902H15.6255C15.8325 8.99902 16.0005 9.16702 16.0005 9.37402C16.0005 9.58102 15.8325 9.74902 15.6255 9.74902H14.8755C14.8065 9.74902 14.7502 9.80527 14.7502 9.87427V11.1245C14.7502 11.1935 14.8065 11.2498 14.8755 11.2498H15.6255C15.8325 11.2498 16.0005 11.4178 16.0005 11.6248C16.0005 11.8318 15.8325 11.9998 15.6255 11.9998Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M17.1255 7H6.8745C6.39225 7 6 7.393 6 7.87525V14.6252C6 15.1075 6.39225 15.5005 6.87525 15.5005H10.7048C10.6462 15.751 10.4903 16.1575 10.074 16.5737C10.0027 16.645 9.981 16.753 10.02 16.846C10.0582 16.9398 10.1498 17.0005 10.251 17.0005H13.7512C13.8525 17.0005 13.944 16.9398 13.9822 16.846C14.0205 16.7523 13.9995 16.645 13.9282 16.5737C13.5127 16.1582 13.353 15.7517 13.2908 15.5005H17.1262C17.6085 15.5005 18.0015 15.1082 18.0015 14.6252V7.87525C18 7.393 17.6077 7 17.1255 7ZM17.0002 13.5002H6.99975V8.0005H17.0002V13.5002Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5429">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSearchOption;
