import { SVGProps } from "types";

const BoxCheckmark = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5449)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M15.5003 11.0005H8.49976C8.22376 11.0005 7.99951 10.7762 7.99951 10.5002C7.99951 10.2242 8.22301 10 8.49976 10H15.5003C15.777 10 16.0005 10.2242 16.0005 10.5002C16.0005 10.7762 15.7763 11.0005 15.5003 11.0005Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M12 12.9998H8.49976C8.22376 12.9998 7.99951 12.7755 7.99951 12.4995C7.99951 12.2235 8.22376 12 8.49976 12H12C12.2768 12 12.5003 12.2242 12.5003 12.5002C12.5003 12.7762 12.2768 12.9998 12 12.9998Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M11.0003 15.0005H8.49976C8.22376 15.0005 7.99951 14.7762 7.99951 14.5002C7.99951 14.2242 8.22301 14 8.49976 14H11.0003C11.277 14 11.5005 14.2242 11.5005 14.5002C11.5005 14.7762 11.2763 15.0005 11.0003 15.0005Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M15.2505 12.5C13.734 12.5 12.5002 13.7337 12.5002 15.2502C12.5002 16.7667 13.734 17.9998 15.2505 17.9998C16.767 17.9998 18 16.766 18 15.2495C18 13.733 16.7662 12.5 15.2505 12.5ZM16.683 14.8362L15.3195 16.3362C15.228 16.4367 15.099 16.496 14.9632 16.4998C14.9587 16.4998 14.9542 16.4998 14.9497 16.4998C14.8185 16.4998 14.6925 16.448 14.5987 16.3558L13.8375 15.6058C13.641 15.4123 13.6387 15.095 13.8322 14.8985C14.025 14.7013 14.3415 14.699 14.5395 14.8932L14.9295 15.278L15.9435 14.1628C16.1302 13.9588 16.4467 13.9438 16.65 14.129C16.8532 14.3158 16.8682 14.6315 16.683 14.8362Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M16.5 6H7.5C6.675 6 6 6.675 6 7.5V15.5002C6 16.3252 6.675 17.0002 7.5 17.0002H11.94C11.7698 16.6905 11.6452 16.3552 11.5747 16.0005H7.5C7.22475 16.0005 6.99975 15.7755 6.99975 15.5002V8.5005H17.0002V11.9407C17.3805 12.1357 17.7203 12.396 18 12.711V7.5C18 6.675 17.325 6 16.5 6Z"
          fill="var(--color-heliotrope)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5449">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxCheckmark;
