import { SVGProps } from "types";

const BoxFolder = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5364)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-cornsilk)"
        />
        <path
          d="M17.0002 11.5H11.4998C10.9478 11.5 10.5 11.9478 10.5 12.4998V16.9998C10.5 17.5525 10.9478 18.0002 11.4998 18.0002H17.0002C17.5522 18.0002 18 17.5525 18 17.0005V12.5005C18 11.9477 17.5522 11.5 17.0002 11.5ZM16.7505 17.0005H11.7502C11.6122 17.0005 11.5005 16.8887 11.5005 16.7507V13.5002H17.001V16.75C17.0002 16.888 16.8885 17.0005 16.7505 17.0005Z"
          fill="var(--color-shandy)"
        />
        <path
          d="M9.1245 15H7.5C6.67275 15 6 14.3273 6 13.5V7.5C6 6.67275 6.67275 6 7.5 6H9.87525C10.008 6 10.1347 6.0525 10.2285 6.14625L11.082 6.99975H16.5C17.3273 6.99975 18 7.6725 18 8.49975V10.2548C18 10.5308 17.7765 10.755 17.4998 10.755C17.223 10.755 16.9995 10.5308 16.9995 10.2548V8.49975C16.9995 8.22375 16.7753 7.9995 16.4993 7.9995H10.8742C10.7415 7.9995 10.6147 7.947 10.521 7.85325L9.6675 6.99975H7.5C7.224 6.99975 6.99975 7.224 6.99975 7.5V13.5C6.99975 13.776 7.224 14.0002 7.5 14.0002H9.12525C9.402 14.0002 9.6255 14.2245 9.6255 14.5005C9.6255 14.7765 9.40125 15 9.1245 15Z"
          fill="var(--color-cyber-yellow)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5364">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxFolder;
