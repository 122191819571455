import { SVGProps } from "react";
import ArrowRight from "./ArrowRight";
import BoxAdd from "./BoxAdd";
import BoxAlgorithm from "./BoxAlgorithm";
import BoxApplication from "./BoxApplication";
import BoxBlocked from "./BoxBlocked";
import BoxBroadcast from "./BoxBroadcast";
import BoxBrowser from "./BoxBrowser";
import BoxBullets from "./BoxBullets";
import BoxCheckmark from "./BoxCheckmark";
import BoxClick from "./BoxClick";
import BoxCloudNetwork from "./BoxCloudNetwork";
import BoxComputerMouse from "./BoxComputerMouse";
import BoxConnected from "./BoxConnected";
import BoxConnection from "./BoxConnection";
import BoxDesktop from "./BoxDesktop";
import BoxDivision from "./BoxDivision";
import BoxEye from "./BoxEye";
import BoxFolder from "./BoxFolder";
import BoxHashtag from "./BoxHashtag";
import BoxHierarchy from "./BoxHierarchy";
import BoxHierarchyStructure from "./BoxHierarchyStructure";
import BoxHyperlink from "./BoxHyperlink";
import BoxKey from "./BoxKey";
import BoxKeyboard from "./BoxKeyboard";
import BoxLayout from "./BoxLayout";
import BoxLive from "./BoxLive";
import BoxLiveStreaming from "./BoxLiveStreaming";
import BoxLogIn from "./BoxLogIn";
import BoxLogOut from "./BoxLogOut";
import BoxMerging from "./BoxMerging";
import BoxMicrochip from "./BoxMicrochip";
import BoxMinimize from "./BoxMinimize";
import BoxMouse from "./BoxMouse";
import BoxNetwork from "./BoxNetwork";
import BoxOptions from "./BoxOptions";
import BoxOrganizationStructure from "./BoxOrganizationStructure";
import BoxPenTool from "./BoxPenTool";
import BoxProgram from "./BoxProgram";
import BoxProgramming from "./BoxProgramming";
import BoxProgrammingLanguage from "./BoxProgrammingLanguage";
import BoxResponsiveWebpage from "./BoxResponsiveWebpage";
import BoxScrollBar from "./BoxScrollBar";
import BoxSearchEngine from "./BoxSearchEngine";
import BoxSearchOption from "./BoxSearchOption";
import BoxSetting from "./BoxSetting";
import BoxSettings from "./BoxSettings";
import BoxShare from "./BoxShare";
import BoxSharing from "./BoxSharing";
import BoxShift from "./BoxShift";
import BoxSignIn from "./BoxSignIn";
import BoxSlide from "./BoxSlide";
import BoxSlider from "./BoxSlider";
import BoxSliderNavigation from "./BoxSliderNavigation";
import BoxSliding from "./BoxSliding";
import BoxSocialMedia from "./BoxSocialMedia";
import BoxSplit from "./BoxSplit";
import BoxSplitting from "./BoxSplitting";
import BoxSpreadsheet from "./BoxSpreadsheet";
import BoxTableCells from "./BoxTableCells";
import BoxTickMark from "./BoxTickMark";
import BoxView from "./BoxView";
import BoxWebBrowser from "./BoxWebBrowser";
import BoxWebDesign from "./BoxWebDesign";
import BoxWebDevelopment from "./BoxWebDevelopment";
import BoxWebLink from "./BoxWebLink";
import BoxWebProgramming from "./BoxWebProgramming";
import BoxWebpage from "./BoxWebpage";
import Check from "./Check";
import Close from "./Close";
import ComputerScreen from "./ComputerScreen";
import Contact from "./Contact";
import Error from "./Error";
import Facebook from "./Facebook";
import File from "./File";
import Home from "./Home";
import HourglassEnd from "./HourglassEnd";
import HourglassStart from "./HourglassStart";
import IllustrationApproved from "./IllustrationApproved";
import IllustrationBug from "./IllustrationBug";
import IllustrationCode from "./IllustrationCode";
import IllustrationCoding from "./IllustrationCoding";
import IllustrationColorPalette from "./IllustrationColorPalette";
import IllustrationDesign from "./IllustrationDesign";
import IllustrationDevelopment from "./IllustrationDevelopment";
import IllustrationDevices from "./IllustrationDevices";
import IllustrationDiagram from "./IllustrationDiagram";
import IllustrationDownload from "./IllustrationDownload";
import IllustrationError from "./IllustrationError";
import IllustrationFirewall from "./IllustrationFirewall";
import IllustrationFolder from "./IllustrationFolder";
import IllustrationFont from "./IllustrationFont";
import IllustrationHacked from "./IllustrationHacked";
import IllustrationHome from "./IllustrationHome";
import IllustrationImage from "./IllustrationImage";
import IllustrationLandingPage from "./IllustrationLandingPage";
import IllustrationLocation from "./IllustrationLocation";
import IllustrationLogin from "./IllustrationLogin";
import IllustrationMaintenance from "./IllustrationMaintenance";
import IllustrationNotFoundError from "./IllustrationNotFoundError";
import IllustrationOnline from "./IllustrationOnline";
import IllustrationPercentage from "./IllustrationPercentage";
import IllustrationProfile from "./IllustrationProfile";
import IllustrationQuality from "./IllustrationQuality";
import IllustrationSearchEngine from "./IllustrationSearchEngine";
import IllustrationServer from "./IllustrationServer";
import IllustrationSiteMap from "./IllustrationSiteMap";
import IllustrationSpeedTest from "./IllustrationSpeedTest";
import IllustrationSynchronization from "./IllustrationSynchronization";
import IllustrationUiDesign from "./IllustrationUiDesign";
import IllustrationUpload from "./IllustrationUpload";
import IllustrationVideo from "./IllustrationVideo";
import IllustrationWebDevelopment from "./IllustrationWebDevelopment";
import IllustrationWebTraffic from "./IllustrationWebTraffic";
import IllustrationWebsite from "./IllustrationWebsite";
import Instagram from "./Instagram";
import Link from "./Link";
import LinkedIn from "./LinkedIn";
import Location from "./Location";
import MoneyBag from "./MoneyBag";
import Moon from "./Moon";
import Send from "./Send";
import Sun from "./Sun";
import Union from "./Union";
import Upload from "./Upload";
import Vector from "./Vector";

const ICON_MAP = Object.freeze({
  "arrow-right": ArrowRight,
  check: Check,
  close: Close,
  contact: Contact,
  "computer-screen": ComputerScreen,
  facebook: Facebook,
  instagram: Instagram,
  "linked-in": LinkedIn,
  home: Home,
  moon: Moon,
  sun: Sun,
  union: Union,
  "illustration-not-found-error": IllustrationNotFoundError,
  "illustration-approved": IllustrationApproved,
  "illustration-bug": IllustrationBug,
  "illustration-code": IllustrationCode,
  "illustration-coding": IllustrationCoding,
  "illustration-color-palette": IllustrationColorPalette,
  "illustration-design": IllustrationDesign,
  "illustration-development": IllustrationDevelopment,
  "illustration-devices": IllustrationDevices,
  "illustration-diagram": IllustrationDiagram,
  "illustration-download": IllustrationDownload,
  "illustration-error": IllustrationError,
  "illustration-firewall": IllustrationFirewall,
  "illustration-folder": IllustrationFolder,
  "illustration-font": IllustrationFont,
  "illustration-hacked": IllustrationHacked,
  "illustration-home": IllustrationHome,
  "illustration-image": IllustrationImage,
  "illustration-landing-page": IllustrationLandingPage,
  "illustration-location": IllustrationLocation,
  "illustration-login": IllustrationLogin,
  "illustration-maintenance": IllustrationMaintenance,
  "illustration-online": IllustrationOnline,
  "illustration-percentage": IllustrationPercentage,
  "illustration-profile": IllustrationProfile,
  "illustration-quality": IllustrationQuality,
  "illustration-search-engine": IllustrationSearchEngine,
  "illustration-server": IllustrationServer,
  "illustration-site-map": IllustrationSiteMap,
  "illustration-speed-test": IllustrationSpeedTest,
  "illustration-synchronization": IllustrationSynchronization,
  "illustration-ui-design": IllustrationUiDesign,
  "illustration-upload": IllustrationUpload,
  "illustration-video": IllustrationVideo,
  "illustration-web-development": IllustrationWebDevelopment,
  "illustration-web-traffic": IllustrationWebTraffic,
  "illustration-website": IllustrationWebsite,
  "box-add": BoxAdd,
  "box-algorithm": BoxAlgorithm,
  "box-application": BoxApplication,
  "box-blocked": BoxBlocked,
  "box-broadcast": BoxBroadcast,
  "box-browser": BoxBrowser,
  "box-bullets": BoxBullets,
  "box-checkmark": BoxCheckmark,
  "box-click": BoxClick,
  "box-cloud-network": BoxCloudNetwork,
  "box-computer-mouse": BoxComputerMouse,
  "box-connected": BoxConnected,
  "box-connection": BoxConnection,
  "box-desktop": BoxDesktop,
  "box-division": BoxDivision,
  "box-eye": BoxEye,
  "box-folder": BoxFolder,
  "box-hashtag": BoxHashtag,
  "box-hierarchy": BoxHierarchy,
  "box-hierarchy-structure": BoxHierarchyStructure,
  "box-hyperlink": BoxHyperlink,
  "box-key": BoxKey,
  "box-keyboard": BoxKeyboard,
  "box-layout": BoxLayout,
  "box-live": BoxLive,
  "box-live-streaming": BoxLiveStreaming,
  "box-log-in": BoxLogIn,
  "box-log-out": BoxLogOut,
  "box-merging": BoxMerging,
  "box-microchip": BoxMicrochip,
  "box-minimize": BoxMinimize,
  "box-mouse": BoxMouse,
  "box-network": BoxNetwork,
  "box-options": BoxOptions,
  "box-organization-structure": BoxOrganizationStructure,
  "box-pen-tool": BoxPenTool,
  "box-program": BoxProgram,
  "box-programming": BoxProgramming,
  "box-programming-language": BoxProgrammingLanguage,
  "box-responsive-webpage": BoxResponsiveWebpage,
  "box-scroll-bar": BoxScrollBar,
  "box-search-engine": BoxSearchEngine,
  "box-search-option": BoxSearchOption,
  "box-setting": BoxSetting,
  "box-settings": BoxSettings,
  "box-share": BoxShare,
  "box-sharing": BoxSharing,
  "box-shift": BoxShift,
  "box-sign-in": BoxSignIn,
  "box-slide": BoxSlide,
  "box-slider": BoxSlider,
  "box-slider-navigation": BoxSliderNavigation,
  "box-sliding": BoxSliding,
  "box-social-media": BoxSocialMedia,
  "box-split": BoxSplit,
  "box-splitting": BoxSplitting,
  "box-spreadsheet": BoxSpreadsheet,
  "box-table-cells": BoxTableCells,
  "box-tick-mark": BoxTickMark,
  "box-view": BoxView,
  "box-web-browser": BoxWebBrowser,
  "box-web-design": BoxWebDesign,
  "box-web-development": BoxWebDevelopment,
  "box-web-link": BoxWebLink,
  "box-web-programming": BoxWebProgramming,
  "box-webpage": BoxWebpage,
  error: Error,
  vector: Vector,
  link: Link,
  send: Send,
  location: Location,
  "hourglass-start": HourglassStart,
  "hourglass-end": HourglassEnd,
  "money-bag": MoneyBag,
  upload: Upload,
  file: File,
});

export type IconName = keyof typeof ICON_MAP;

type IconProps = SVGProps<SVGSVGElement> & {
  name: IconName;
  className?: string;
};

const Icon = ({ name, ...props }: IconProps) => {
  const TheIcon = ICON_MAP[name];

  if (!TheIcon) return null;

  return <TheIcon {...props} />;
};

export default Icon;
