import classNames from "classnames";
import { ButtonHTMLAttributes, CSSProperties } from "react";
import Icon, { IconName } from "components/Icon";
import { Color, ClassNameArgument } from "types";
import styles from "./Button.module.css";

type ButtonVariant = "inverted" | "transparent";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: ClassNameArgument;
  color?: Color;
  icon?: IconName;
  iconPosition?: "before" | "after";
  variant?: ButtonVariant;
};

const Button = ({
  className,
  children,
  icon,
  color = "blue",
  iconPosition = "before",
  type = "button",
  variant,
  ...props
}: ButtonProps) => {
  const includeStyleAttribute =
    props.style || !["blue" satisfies Color].includes(color);

  return (
    <button
      className={classNames(styles.button, className)}
      data-variant={variant}
      data-icon-position={iconPosition}
      type={type}
      {...props}
      style={
        includeStyleAttribute
          ? ({
              ...props.style,
              "--bg-color": `var(--color-${color}-500)`,
              "--bg-color-hover": `var(--color-${color}-700)`,
              "--bg-color-inverted": `var(--color-${color}-100)`,
              "--bg-color-inverted-hover": `var(--color-${color}-200)`,
              "--fg-color-inverted": `var(--color-${color}-600)`,
              "--fg-color-inverted-hover": `var(--color-${color}-900)`,
              "--shadow-color-inverted": `var(--color-${color}-400)`,
            } as CSSProperties)
          : undefined
      }
    >
      {icon && <Icon name={icon} />}
      {children}
    </button>
  );
};

export default Button;
