import { SVGProps } from "types";

const BoxConnection = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5440)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-magnolia)"
        />
        <path
          d="M17.1255 8H16.0005C16.0005 8.38025 15.8602 8.735 15.6202 8.99975H17.0002V14.9998H6.99975V8.99975H8.37975C8.13975 8.735 7.9995 8.3795 7.9995 8H6.8745C6.3945 8 6 8.3945 6 8.8745V15.1243C6 15.6043 6.39525 15.9995 6.87525 15.9995H11.0002V16.9993H10.0005C9.7245 16.9993 9.50025 17.2235 9.50025 17.4995C9.50025 17.7755 9.72375 17.9998 9.99975 17.9998H14.0002C14.277 17.9998 14.5005 17.7755 14.5005 17.4995C14.5005 17.2235 14.277 16.9993 14.0002 16.9993H13.0005V15.9995H17.1255C17.6055 15.9995 18.0007 15.6043 18.0007 15.1243V8.8745C18 8.3945 17.6055 8 17.1255 8Z"
          fill="var(--color-heliotrope)"
        />
        <path
          d="M14.4997 6H12.9997C12.7972 6 12.615 6.1215 12.5377 6.30825C12.4605 6.495 12.5032 6.71025 12.6465 6.8535L13.1317 7.338L12.735 7.73475C12.5887 7.881 12.5887 8.11875 12.735 8.265C12.8812 8.41125 13.119 8.41125 13.2652 8.265L13.662 7.86825L14.1472 8.35275C14.2432 8.44875 14.3707 8.499 14.5005 8.499C14.565 8.499 14.6302 8.48625 14.6917 8.46075C14.8785 8.38425 15 8.202 15 8.00025V6.50025C15 6.2235 14.7765 6 14.4997 6Z"
          fill="var(--color-mauve)"
        />
        <path
          d="M10.8683 7.338L11.3535 6.8535C11.4967 6.71025 11.5395 6.49575 11.4622 6.30825C11.3842 6.1215 11.202 6 11.0002 6H9.50025C9.2235 6 9 6.2235 9 6.50025V8.00025C9 8.20275 9.1215 8.385 9.30825 8.46225C9.3705 8.48775 9.435 8.5005 9.4995 8.5005C9.63 8.5005 9.7575 8.4495 9.85275 8.35425L10.338 7.86975L11.625 9.15675V12.1268C11.625 12.3338 11.793 12.5018 12 12.5018C12.207 12.5018 12.375 12.3338 12.375 12.1268V9C12.375 8.901 12.3353 8.805 12.2655 8.7345L10.8683 7.338Z"
          fill="var(--color-mauve)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5440">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxConnection;
