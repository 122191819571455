import { SVGProps } from "types";

const ArrowRight = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.7068 12.7073L13.7066 17.7076C13.5128 17.9044 13.2566 18.0013 13.0003 18.0013C12.7441 18.0013 12.4884 17.9037 12.2934 17.7083C11.9028 17.3177 11.9028 16.6849 12.2934 16.2942L15.5879 13.0011H6C5.44778 13.0011 5 12.5542 5 12.0011C5 11.4479 5.44778 11.001 6 11.001H15.5879L12.294 7.70711C11.9034 7.31647 11.9034 6.68362 12.294 6.29298C12.6847 5.90234 13.3175 5.90234 13.7082 6.29298L18.7084 11.2932C19.0975 11.6854 19.0975 12.3167 18.7068 12.7073Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRight;
