import classNames from "classnames";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useEffect, useId, useState } from "react";
import { NAV_LINKS } from "common/constants";
import { windowIsDefined } from "common/utils";
import Button from "components/Button";
import Contacts from "components/Contacts";
import LogoImage from "components/LogoImage";
import Socials from "components/Socials";
import useScreenMediaQuery from "hooks/useScreenMediaQuery";
import styles from "./Header.module.css";

const ColorSchemeSelect = dynamic(() => import("components/ColorShemeSelect"), {
  ssr: false,
});

const SCROLLED_ATTRIBUTE = "data-is-on-hero";

export const Header = () => {
  const [navToggled, setNavToggled] = useState(false);

  const { t } = useTranslation("common");

  const [isOnMediumScreen] = useScreenMediaQuery(
    "md",
    (matches) => {
      if (matches) setNavToggled(false);
    },
    []
  );

  const [isOnSmallScreen] = useScreenMediaQuery("sm");
  const [isOnLargeScreen] = useScreenMediaQuery("lg");
  const [isOnExtraLargeScreen] = useScreenMediaQuery("xl");

  const router = useRouter();

  const headerId = useId();
  const navId = `${headerId}-nav`;

  useEffect(() => {
    if (!isOnMediumScreen) {
      document.body.toggleAttribute("data-no-scroll", navToggled);
    }
  }, [navToggled, isOnMediumScreen]);

  useEffect(() => {
    setNavToggled(false);
  }, [router.pathname]);

  useEffect(() => {
    if (!windowIsDefined()) {
      return;
    }

    const header = document.getElementById(headerId);
    const target = document.querySelector(".hero");

    header?.toggleAttribute(SCROLLED_ATTRIBUTE, false);

    if (!header || !target) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          header.toggleAttribute(SCROLLED_ATTRIBUTE, entry.isIntersecting);
        }
      },
      {
        threshold: isOnExtraLargeScreen
          ? 0.9
          : isOnLargeScreen
          ? 0.8
          : isOnMediumScreen
          ? 0.75
          : isOnSmallScreen
          ? 0.5
          : 0.9,
      }
    );

    observer.observe(target);

    return () => {
      observer.disconnect();
    };
  }, [
    headerId,
    router.pathname,
    isOnLargeScreen,
    isOnSmallScreen,
    isOnMediumScreen,
    isOnExtraLargeScreen,
  ]);

  const navIsVisible = isOnMediumScreen || navToggled;
  const mobileNavElementTabIndex = navIsVisible ? 0 : -1;

  return (
    <header
      className={styles["header"]}
      data-nav-toggled={navToggled}
      id={headerId}
    >
      <button
        aria-label="Toggle navigation menu"
        className={styles["toggle-button"]}
        title="Toggle navigation menu"
        type="button"
        onClick={() => setNavToggled((toggled) => !toggled)}
        aria-controls={navId}
        aria-expanded={navToggled}
      >
        <span />
        <span />
        <span />
      </button>
      <Link href="/" className={styles["logo"]} tabIndex={0}>
        <LogoImage className={styles["logo-image"]} width={100} height={64} />
      </Link>
      <nav className={styles["nav"]} id={navId}>
        <ul className={styles["nav-links"]}>
          {NAV_LINKS.map(({ key, href }) => (
            <li key={key} data-current={router.asPath.startsWith(href)}>
              <Link
                className={classNames(
                  styles["nav-link"],
                  "internal-link",
                  "inherit-color"
                )}
                href={href}
              >
                {t(key)}
              </Link>
            </li>
          ))}
          <li>
            <Link href="#contact-form" aria-labelledby="contact-form-button">
              <Button
                id="contact-form-button"
                icon="contact"
                className={styles["nav-links-contact-button"]}
                onClick={() => setNavToggled(false)}
                tabIndex={-1}
              >
                {t("contact-us")}
              </Button>
            </Link>
          </li>
        </ul>
        <ColorSchemeSelect className={styles["nav-color-scheme-select"]} />
        <hr />
        <Contacts
          className={styles["nav-contacts"]}
          tabIndex={mobileNavElementTabIndex}
        />
        <Socials
          className={styles["nav-socials"]}
          tabIndex={mobileNavElementTabIndex}
        />
      </nav>
    </header>
  );
};

export default Header;
