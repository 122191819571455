import { SVGProps } from "types";

const BoxScrollBar = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5408)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M15.1252 6.99975C15.3322 6.99975 15.5002 7.16775 15.5002 7.37475V16.6245C15.5002 16.8315 15.3322 16.9995 15.1252 16.9995H8.87475C8.66775 16.9995 8.49975 16.8315 8.49975 16.6245V7.37475C8.49975 7.16775 8.66775 6.99975 8.87475 6.99975H15.1252ZM15.1252 6H8.87475C8.1165 6 7.5 6.6165 7.5 7.37475V16.6245C7.5 17.3835 8.1165 18 8.87475 18H15.1252C15.8835 18 16.5 17.3835 16.5 16.6252V7.37475C16.5 6.6165 15.8835 6 15.1252 6Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M9.50025 8H12C12.276 8 12.5002 8.22425 12.5002 8.50025C12.5002 8.77625 12.276 8.99975 12 8.99975H9.50025C9.2235 8.99975 9 8.7755 9 8.4995C9 8.2235 9.2235 8 9.50025 8Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M13.2997 15.1493L12.3 15.8993C12.2115 15.966 12.1057 15.999 12 15.999C11.8942 15.999 11.7885 15.9653 11.7 15.8993L10.7002 15.1493C10.479 14.9836 10.4347 14.6701 10.6005 14.4496C10.7662 14.2298 11.0797 14.1841 11.3002 14.3498L12 14.8748L12.6997 14.3498C12.9217 14.1841 13.2345 14.2298 13.3995 14.4496C13.566 14.6701 13.521 14.9836 13.2997 15.1493Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M14.4998 12.9998H9.49952C9.22352 12.9998 8.99927 12.7755 8.99927 12.4995C8.99927 12.2235 9.22352 12 9.50027 12H14.5005C14.7765 12 15 12.2242 15 12.5002C15 12.7762 14.7765 12.9998 14.4998 12.9998Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M14.4998 10.9995H9.4995C9.2235 10.9995 9 10.7753 9 10.4993C9 10.2233 9.22425 9.99902 9.50025 9.99902H14.5005C14.7765 9.99902 15 10.2233 15 10.4993C15 10.7753 14.7765 10.9995 14.4998 10.9995Z"
          fill="var(--color-sky-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5408">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxScrollBar;
