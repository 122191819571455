/* eslint-disable */
const HttpBackend = require("i18next-http-backend/cjs");
const ChainedBackend = require("i18next-chained-backend").default;
const LocalStorageBackend = require("i18next-localstorage-backend").default;

const IS_PRODUCTION = process.env.NODE_ENV === "production";
const windowIsDefined = typeof window !== "undefined";

/** @type {import("next-i18next").UserConfig} */
module.exports = {
  backend: {
    backendOptions: [
      { expirationTime: IS_PRODUCTION ? 60 * 60 * 1000 : 0 },
      {
        /* loadPath: 'https:// somewhere else' */
      },
    ], // 1 hour
    backends: windowIsDefined ? [LocalStorageBackend, HttpBackend] : [],
  },
  i18n: {
    locales: ["en", "sk"],
    defaultLocale: "sk",
    defaultNS: "common",
    localeExtension: "json",
    localeStructure: "{{lng}}/{{ns}}",
    localePath: windowIsDefined
      ? "./public/locales"
      : require("path").resolve("./public/locales"),
  },
  serializeConfig: false,
  use: windowIsDefined ? [ChainedBackend] : [],
};
