import { noop } from "lodash";
import { createContext, useContext, useEffect } from "react";
import { SetState, StatusCode } from "types";

export type PageErrorContextType = {
  statusCode: StatusCode | null;
  setStatusCode: SetState<StatusCode | null, false>;
};

export const PageError = createContext<PageErrorContextType>({
  statusCode: null,
  setStatusCode: noop,
});

export const usePageError = () => useContext(PageError);

export const usePageErrorEffect = (statusCode: StatusCode) => {
  const { setStatusCode } = usePageError();

  useEffect(() => {
    setStatusCode(statusCode);

    return () => {
      setStatusCode(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
