import { SVGProps } from "types";

const ComputerScreen = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.1538 5H5.84615C4.82655 5 4 5.82655 4 6.84615V15.4615C4 16.4811 4.82655 17.3077 5.84615 17.3077H18.1538C19.1734 17.3077 20 16.4811 20 15.4615V6.84615C20 5.82655 19.1734 5 18.1538 5Z"
        fill="currentColor"
      />
      <path
        d="M14.4616 18.5391H9.53848C9.37527 18.5391 9.21874 18.6039 9.10334 18.7193C8.98793 18.8347 8.9231 18.9912 8.9231 19.1544C8.9231 19.3177 8.98793 19.4742 9.10334 19.5896C9.21874 19.705 9.37527 19.7698 9.53848 19.7698H14.4616C14.6248 19.7698 14.7813 19.705 14.8967 19.5896C15.0121 19.4742 15.0769 19.3177 15.0769 19.1544C15.0769 18.9912 15.0121 18.8347 14.8967 18.7193C14.7813 18.6039 14.6248 18.5391 14.4616 18.5391Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ComputerScreen;
