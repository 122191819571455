import { SVGProps } from "types";

const BoxSearchEngine = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5413)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M12.4942 11.4635C12.7965 11.276 13.0005 10.9437 13.0005 10.5627C13.0005 9.977 12.5242 9.5 11.9377 9.5H11.3752C11.1682 9.5 11.0002 9.668 11.0002 9.875V12.125C11.0002 12.332 11.1682 12.5 11.3752 12.5C11.5822 12.5 11.7502 12.332 11.7502 12.125V11.78L12.36 12.3898C12.4335 12.4633 12.5287 12.4993 12.6255 12.4993C12.7215 12.4993 12.8175 12.4625 12.891 12.3898C13.0372 12.2435 13.0372 12.0057 12.891 11.8595L12.4942 11.4635ZM11.7502 10.25H11.9377C12.1102 10.25 12.2505 10.3902 12.2505 10.5627C12.2505 10.7352 12.1102 10.8755 11.9377 10.8755H11.7502V10.25Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M9.10351 12.5H8.37451C8.16751 12.5 7.99951 12.332 7.99951 12.125C7.99951 11.918 8.16751 11.75 8.37451 11.75H9.10351C9.18226 11.75 9.24976 11.6637 9.24976 11.5625C9.24976 11.4612 9.18301 11.375 9.10351 11.375H8.89576C8.40151 11.375 7.99951 10.9542 7.99951 10.4375C7.99951 9.92075 8.40151 9.5 8.89576 9.5H9.62476C9.83176 9.5 9.99976 9.668 9.99976 9.875C9.99976 10.082 9.83176 10.25 9.62476 10.25H8.89576C8.81701 10.25 8.74951 10.3363 8.74951 10.4375C8.74951 10.5388 8.81626 10.625 8.89576 10.625H9.10351C9.59776 10.625 9.99976 11.0458 9.99976 11.5625C9.99976 12.0793 9.59776 12.5 9.10351 12.5Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M15.6255 12.4998H14.8755C14.3932 12.4998 14.0002 12.1075 14.0002 11.6245V10.3743C14.0002 9.89202 14.3925 9.49902 14.8755 9.49902H15.6255C15.8325 9.49902 16.0005 9.66702 16.0005 9.87402C16.0005 10.081 15.8325 10.249 15.6255 10.249H14.8755C14.8065 10.249 14.7502 10.3053 14.7502 10.3743V11.6245C14.7502 11.6935 14.8065 11.7498 14.8755 11.7498H15.6255C15.8325 11.7498 16.0005 11.9178 16.0005 12.1248C16.0005 12.3318 15.8325 12.4998 15.6255 12.4998Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M17.1255 7H6.8745C6.39225 7 6 7.393 6 7.87525V14.125C6 14.608 6.39225 15.0002 6.8745 15.0002H10.9995V16H9.99975C9.72375 16 9.4995 16.2242 9.4995 16.5002C9.4995 16.7762 9.72375 17.0005 9.99975 17.0005H14.0002C14.277 17.0005 14.5005 16.7762 14.5005 16.5002C14.5005 16.2242 14.277 16 14.0002 16H13.0005V15.0002H17.1255C17.6077 15.0002 18 14.608 18 14.125V7.87525C18 7.393 17.6077 7 17.1255 7ZM17.0002 14.0005H6.99975V8.0005H17.0002V14.0005Z"
          fill="var(--color-charged-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5413">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxSearchEngine;
