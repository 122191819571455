import { SVGProps } from "types";

const BoxConnected = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5396)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M16.5 6.99975C16.5 6.4485 16.0515 6 15.5002 6C14.949 6 14.5005 6.4485 14.5005 6.99975C14.5005 7.41825 14.7592 7.77675 15.1252 7.92525V9.10275L13.6005 10.7445C13.536 10.8142 13.5 10.905 13.5 11.0002V12.6255C13.5 12.8325 13.668 13.0005 13.875 13.0005C14.082 13.0005 14.25 12.8325 14.25 12.6255V11.148L15.7748 9.50625C15.8393 9.43725 15.8745 9.34575 15.8745 9.25125V7.92675C16.2413 7.77675 16.5 7.419 16.5 6.99975Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M8.87475 9.10275V7.92525C9.24075 7.77675 9.4995 7.41825 9.4995 6.99975C9.50025 6.4485 9.051 6 8.49975 6C7.9485 6 7.5 6.4485 7.5 6.99975C7.5 7.41825 7.75875 7.77675 8.12475 7.92525V9.24975C8.12475 9.34425 8.16075 9.43575 8.2245 9.50475L9.75 11.1473V12.6248C9.75 12.8317 9.918 12.9998 10.125 12.9998C10.332 12.9998 10.5 12.8317 10.5 12.6248V10.9995C10.5 10.905 10.464 10.8135 10.4002 10.7445L8.87475 9.10275Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M12 6C11.4487 6 11.0002 6.4485 11.0002 6.99975C11.0002 7.41825 11.259 7.77675 11.625 7.92525V12.6248C11.625 12.8317 11.793 12.9998 12 12.9998C12.207 12.9998 12.375 12.8317 12.375 12.6248V7.92525C12.741 7.77675 12.9997 7.41825 12.9997 6.99975C12.9997 6.4485 12.5512 6 12 6Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.22 8.00977C17.13 8.16952 17.01 8.31502 16.875 8.44477V8.99977H17.0002V14.4995H6.99975V8.99977H7.125V8.44477C6.99 8.31502 6.87 8.16952 6.78 8.00977C6.345 8.05477 6 8.42977 6 8.87452V15.6245C6 16.1045 6.3945 16.4998 6.8745 16.4998H10.704C10.6455 16.7503 10.4895 17.1568 10.0732 17.573C10.002 17.6443 9.98025 17.7523 10.0192 17.8453C10.0575 17.939 10.149 17.9998 10.2502 17.9998H13.7505C13.8517 17.9998 13.9433 17.939 13.9815 17.8453C14.0198 17.7515 13.9987 17.6443 13.9275 17.573C13.512 17.1575 13.3522 16.751 13.29 16.4998H17.1255C17.6055 16.4998 18 16.1045 18 15.6245V8.87452C18 8.42977 17.655 8.05477 17.22 8.00977Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5396">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxConnected;
