import { SVGProps } from "types";

const IllustrationWebDevelopment = (props: SVGProps) => {
  return (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2042_6598)">
        <path
          d="M53.2941 15.3575C49.6108 6.30503 40.7006 -0.0636297 30.3099 0.000479654C17.0542 0.0821203 6.15531 10.7743 5.83373 24.0262C5.67509 30.5643 8.06432 36.5423 12.0786 41.0389C15.2788 44.6235 16.8848 49.3448 16.7229 54.1475C16.7014 54.7858 16.6981 55.4288 16.7139 56.0759C17.1374 73.504 32.0258 88.0105 49.459 88C67.5288 87.9892 82.1737 73.3373 82.1737 55.2652C82.1737 42.0181 74.3046 30.61 62.9861 25.4581C58.5746 23.4501 55.1208 19.8469 53.2941 15.3575Z"
          fill="url(#paint0_linear_2042_6598)"
        />
        <path
          opacity="0.1"
          d="M73.959 57.515H69.4256V11.7953C69.4256 10.3713 68.2682 9.21387 66.8442 9.21387H23.8188C22.3948 9.21387 21.2374 10.3713 21.2374 11.7953V24.8239H16.7038C15.7539 24.8239 14.9839 25.5939 14.9839 26.5438V34.7638C14.9839 35.7137 15.7539 36.4837 16.7038 36.4837H21.2372V76.2046C21.2372 77.6285 22.3946 78.7859 23.8186 78.7859H66.8441C68.268 78.7859 69.4255 77.6285 69.4255 76.2046V69.1747H73.9588C74.9088 69.1747 75.6788 68.4047 75.6788 67.4547V59.235C75.6788 58.285 74.9088 57.515 73.959 57.515Z"
          fill="#2626BC"
        />
        <path
          d="M69.4256 15.4066V79.8159C69.4256 81.2399 68.2682 82.3973 66.8442 82.3973H23.8187C22.3947 82.3973 21.2373 81.2399 21.2373 79.8159V15.4066C21.2373 13.9826 22.3947 12.8252 23.8187 12.8252H66.8442C68.2682 12.8252 69.4256 13.9826 69.4256 15.4066Z"
          fill="white"
        />
        <path
          d="M69.4256 63.1914V74.8499H64.3127C63.3634 74.8499 62.5929 74.0811 62.5929 73.1301V64.9112C62.5929 63.9602 63.3634 63.1914 64.3127 63.1914H69.4256Z"
          fill="#D2DEFF"
        />
        <path
          d="M28.0699 32.2198V40.4387C28.0699 41.3897 27.2994 42.1602 26.3501 42.1602H21.2372V30.5H26.3501C27.2996 30.5 28.0699 31.2705 28.0699 32.2198Z"
          fill="#D2DEFF"
        />
        <path
          d="M69.4252 21.1166V15.4077C69.4252 13.982 68.2695 12.8262 66.8437 12.8262H23.8191C22.3934 12.8262 21.2377 13.9819 21.2377 15.4077V21.1166H69.4252Z"
          fill="#6583FE"
        />
        <path
          d="M69.4253 21.9756H26.1144C25.6398 21.9756 25.255 21.5908 25.255 21.1162C25.255 20.6417 25.6398 20.2568 26.1144 20.2568H69.4253C69.9001 20.2568 70.2847 20.6417 70.2847 21.1162C70.2847 21.5908 69.8999 21.9756 69.4253 21.9756Z"
          fill="#2626BC"
        />
        <path
          d="M27.7915 17.8433C27.317 17.8433 26.9321 17.4585 26.9321 16.9839V16.959C26.9321 16.4844 27.317 16.0996 27.7915 16.0996C28.2661 16.0996 28.6509 16.4844 28.6509 16.959V16.9839C28.6509 17.4586 28.2662 17.8433 27.7915 17.8433Z"
          fill="#2626BC"
        />
        <path
          d="M31.1173 17.8433C30.6428 17.8433 30.2579 17.4585 30.2579 16.9839V16.959C30.2579 16.4844 30.6428 16.0996 31.1173 16.0996C31.5919 16.0996 31.9767 16.4844 31.9767 16.959V16.9839C31.9767 17.4586 31.5919 17.8433 31.1173 17.8433Z"
          fill="#2626BC"
        />
        <path
          d="M34.443 17.8433C33.9684 17.8433 33.5836 17.4585 33.5836 16.9839V16.959C33.5836 16.4844 33.9684 16.0996 34.443 16.0996C34.9175 16.0996 35.3024 16.4844 35.3024 16.959V16.9839C35.3024 17.4586 34.9175 17.8433 34.443 17.8433Z"
          fill="#2626BC"
        />
        <path
          d="M66.8437 83.2561H23.8191C21.9218 83.2561 20.3782 81.7125 20.3782 79.8152V40.46C20.3782 39.9854 20.763 39.6006 21.2375 39.6006C21.7121 39.6006 22.0969 39.9854 22.0969 40.46V79.8154C22.0969 80.765 22.8695 81.5376 23.8191 81.5376H66.8437C67.7932 81.5376 68.5659 80.765 68.5659 79.8154V73.1337C68.5659 72.6592 68.9506 72.2744 69.4253 72.2744C69.9 72.2744 70.2847 72.6592 70.2847 73.1337V79.8154C70.2847 81.7125 68.7411 83.2561 66.8437 83.2561Z"
          fill="#2626BC"
        />
        <path
          d="M69.4253 57.2497C68.9506 57.2497 68.5659 56.8648 68.5659 56.3903V15.4077C68.5659 14.4581 67.7933 13.6855 66.8437 13.6855H23.8191C22.8695 13.6855 22.0969 14.4581 22.0969 15.4077V28.0887C22.0969 28.5632 21.7121 28.948 21.2375 28.948C20.763 28.948 20.3782 28.5632 20.3782 28.0887V15.4077C20.3782 13.5104 21.9218 11.9668 23.8191 11.9668H66.8437C68.7411 11.9668 70.2847 13.5104 70.2847 15.4077V56.3903C70.2847 56.8648 69.8998 57.2497 69.4253 57.2497Z"
          fill="#2626BC"
        />
        <path
          d="M64.5362 35.8138V52.7812C64.5362 53.0666 64.304 53.2971 64.0203 53.2971H26.6424C26.3587 53.2971 26.1265 53.0666 26.1265 52.7812V35.8138C26.1265 35.5283 26.3587 35.2979 26.6424 35.2979H64.0203C64.304 35.2979 64.5362 35.5282 64.5362 35.8138Z"
          fill="#FFA8CF"
        />
        <path
          d="M28.0699 35.2979V40.4383C28.0699 41.3893 27.2993 42.1598 26.3501 42.1598H26.1265V35.8138C26.1265 35.5283 26.3587 35.2979 26.6424 35.2979H28.0699Z"
          fill="#FF7EB8"
        />
        <path
          d="M60.5226 31.6229C60.3028 31.6229 60.0826 31.539 59.9148 31.3711C59.7471 31.2033 59.6632 30.9833 59.6632 30.7633C59.6632 30.5435 59.7471 30.3235 59.915 30.1557C60.1101 29.9607 60.2175 29.7015 60.2175 29.4256C60.2175 29.1499 60.1101 28.8907 59.915 28.6957C59.7471 28.5279 59.6632 28.3079 59.6632 28.0881C59.6632 27.8681 59.7471 27.6481 59.9148 27.4803C60.2505 27.1447 60.7945 27.1445 61.1302 27.4803C61.3252 27.6754 61.5846 27.7827 61.8603 27.7827C62.136 27.7827 62.3952 27.6752 62.5901 27.4803C62.9257 27.1447 63.4697 27.1445 63.8054 27.4803C63.9733 27.6481 64.0572 27.8681 64.0572 28.0879C64.0572 28.3079 63.9733 28.5279 63.8056 28.6957C63.403 29.0982 63.403 29.7532 63.8056 30.1557C63.9733 30.3235 64.0572 30.5435 64.0572 30.7635C64.0572 30.9833 63.9733 31.2033 63.8054 31.3711C63.4701 31.7066 62.9257 31.7067 62.5901 31.3711C62.3952 31.1762 62.136 31.0687 61.8603 31.0687C61.5846 31.0687 61.3252 31.1762 61.1302 31.3712C60.9622 31.539 60.7424 31.6229 60.5226 31.6229Z"
          fill="#FF7EB8"
        />
        <path
          d="M53.4907 31.6229C53.2709 31.6229 53.0507 31.539 52.883 31.3711C52.7152 31.2033 52.6313 30.9833 52.6313 30.7633C52.6313 30.5435 52.7152 30.3235 52.8831 30.1557C53.0782 29.9607 53.1855 29.7015 53.1855 29.4256C53.1855 29.1499 53.0782 28.8907 52.8831 28.6957C52.7152 28.5279 52.6313 28.3079 52.6313 28.0881C52.6313 27.8681 52.7152 27.6481 52.883 27.4803C53.2186 27.1447 53.7626 27.1445 54.0983 27.4803C54.2934 27.6754 54.5527 27.7827 54.8284 27.7827C55.1041 27.7827 55.3633 27.6752 55.5582 27.4803C55.8939 27.1447 56.4379 27.1445 56.7735 27.4803C56.9415 27.6481 57.0253 27.8681 57.0253 28.0879C57.0253 28.3079 56.9415 28.5279 56.7737 28.6957C56.3712 29.0982 56.3712 29.7532 56.7737 30.1557C56.9415 30.3235 57.0253 30.5435 57.0253 30.7635C57.0253 30.9833 56.9415 31.2033 56.7735 31.3711C56.4382 31.7066 55.8939 31.7067 55.5582 31.3711C55.3633 31.1762 55.1041 31.0687 54.8284 31.0687C54.5527 31.0687 54.2934 31.1762 54.0983 31.3712C53.9306 31.539 53.7106 31.6229 53.4907 31.6229Z"
          fill="#FF7EB8"
        />
        <path
          d="M46.4587 31.6229C46.2389 31.6229 46.0187 31.539 45.851 31.3711C45.6832 31.2033 45.5994 30.9833 45.5994 30.7633C45.5994 30.5435 45.6832 30.3235 45.8512 30.1557C46.0462 29.9607 46.1535 29.7015 46.1535 29.4256C46.1535 29.1499 46.0462 28.8907 45.8512 28.6957C45.6832 28.5279 45.5994 28.3079 45.5994 28.0881C45.5994 27.8681 45.6832 27.6481 45.851 27.4803C46.1867 27.1447 46.7306 27.1445 47.0663 27.4803C47.4689 27.8829 48.124 27.8829 48.5266 27.4803C48.8619 27.1448 49.4062 27.1447 49.7419 27.4803C49.9096 27.6481 49.9935 27.8681 49.9935 28.0881C49.9935 28.3079 49.9096 28.5279 49.7417 28.6957C49.5466 28.8907 49.4394 29.1499 49.4394 29.4256C49.4394 29.7013 49.5466 29.9605 49.7417 30.1556C49.9096 30.3233 49.9935 30.5433 49.9935 30.7632C49.9935 30.9832 49.9096 31.2031 49.7419 31.3709C49.4062 31.7066 48.8622 31.7067 48.5266 31.3709C48.1242 30.9685 47.4694 30.9684 47.0663 31.3709C46.8986 31.539 46.6786 31.6229 46.4587 31.6229Z"
          fill="#FF7EB8"
        />
        <path
          d="M35.9579 58.4432H26.9885C26.5127 58.4432 26.1272 58.0575 26.1272 57.582C26.1272 57.1064 26.5127 56.7207 26.9885 56.7207H35.9579C36.4337 56.7207 36.8192 57.1064 36.8192 57.582C36.8192 58.0575 36.4337 58.4432 35.9579 58.4432Z"
          fill="#8399FE"
        />
        <path
          d="M40.1427 42.3925H33.7529C33.2771 42.3925 32.8916 42.0068 32.8916 41.5312C32.8916 41.0556 33.2771 40.6699 33.7529 40.6699H40.1427C40.6184 40.6699 41.0039 41.0556 41.0039 41.5312C41.0039 42.0068 40.6184 42.3925 40.1427 42.3925Z"
          fill="white"
        />
        <path
          d="M53.041 47.0956H33.7529C33.2771 47.0956 32.8916 46.7099 32.8916 46.2343C32.8916 45.7587 33.2771 45.373 33.7529 45.373H53.041C53.5168 45.373 53.9023 45.7587 53.9023 46.2343C53.9023 46.7099 53.5168 47.0956 53.041 47.0956Z"
          fill="white"
        />
        <path
          d="M53.041 50.3046H33.7529C33.2771 50.3046 32.8916 49.9189 32.8916 49.4433C32.8916 48.9677 33.2771 48.582 33.7529 48.582H53.041C53.5168 48.582 53.9023 48.9677 53.9023 49.4433C53.9023 49.9189 53.5168 50.3046 53.041 50.3046Z"
          fill="white"
        />
        <path
          d="M35.9579 64.2909H26.9885C26.5127 64.2909 26.1272 63.9052 26.1272 63.4296C26.1272 62.954 26.5127 62.5684 26.9885 62.5684H35.9579C36.4337 62.5684 36.8192 62.954 36.8192 63.4296C36.8192 63.9052 36.4337 64.2909 35.9579 64.2909Z"
          fill="#B7C5FF"
        />
        <path
          d="M35.9579 67.9882H26.9885C26.5127 67.9882 26.1272 67.6025 26.1272 67.1269C26.1272 66.6513 26.5127 66.2656 26.9885 66.2656H35.9579C36.4337 66.2656 36.8192 66.6513 36.8192 67.1269C36.8192 67.6026 36.4337 67.9882 35.9579 67.9882Z"
          fill="#B7C5FF"
        />
        <path
          d="M35.9579 71.6854H26.9885C26.5127 71.6854 26.1272 71.2997 26.1272 70.8242C26.1272 70.3486 26.5127 69.9629 26.9885 69.9629H35.9579C36.4337 69.9629 36.8192 70.3486 36.8192 70.8242C36.8192 71.2997 36.4337 71.6854 35.9579 71.6854Z"
          fill="#B7C5FF"
        />
        <path
          d="M35.9579 75.3837H26.9885C26.5127 75.3837 26.1272 74.998 26.1272 74.5224C26.1272 74.0468 26.5127 73.6611 26.9885 73.6611H35.9579C36.4337 73.6611 36.8192 74.0468 36.8192 74.5224C36.8192 74.9982 36.4337 75.3837 35.9579 75.3837Z"
          fill="#B7C5FF"
        />
        <path
          d="M49.3957 58.4432H40.4262C39.9505 58.4432 39.5649 58.0575 39.5649 57.582C39.5649 57.1064 39.9505 56.7207 40.4262 56.7207H49.3957C49.8714 56.7207 50.257 57.1064 50.257 57.582C50.2571 58.0575 49.8714 58.4432 49.3957 58.4432Z"
          fill="#8399FE"
        />
        <path
          d="M49.3957 64.2909H40.4262C39.9505 64.2909 39.5649 63.9052 39.5649 63.4296C39.5649 62.954 39.9505 62.5684 40.4262 62.5684H49.3957C49.8714 62.5684 50.257 62.954 50.257 63.4296C50.257 63.9052 49.8714 64.2909 49.3957 64.2909Z"
          fill="#B7C5FF"
        />
        <path
          d="M49.3957 67.9882H40.4262C39.9505 67.9882 39.5649 67.6025 39.5649 67.1269C39.5649 66.6513 39.9505 66.2656 40.4262 66.2656H49.3957C49.8714 66.2656 50.257 66.6513 50.257 67.1269C50.2571 67.6026 49.8714 67.9882 49.3957 67.9882Z"
          fill="#B7C5FF"
        />
        <path
          d="M49.3957 71.6854H40.4262C39.9505 71.6854 39.5649 71.2997 39.5649 70.8242C39.5649 70.3486 39.9505 69.9629 40.4262 69.9629H49.3957C49.8714 69.9629 50.257 70.3486 50.257 70.8242C50.257 71.2997 49.8714 71.6854 49.3957 71.6854Z"
          fill="#B7C5FF"
        />
        <path
          d="M49.3957 75.3837H40.4262C39.9505 75.3837 39.5649 74.998 39.5649 74.5224C39.5649 74.0468 39.9505 73.6611 40.4262 73.6611H49.3957C49.8714 73.6611 50.257 74.0468 50.257 74.5224C50.2571 74.9982 49.8714 75.3837 49.3957 75.3837Z"
          fill="#B7C5FF"
        />
        <path
          d="M62.8335 58.4432H53.8641C53.3883 58.4432 53.0028 58.0575 53.0028 57.582C53.0028 57.1064 53.3883 56.7207 53.8641 56.7207H62.8335C63.3093 56.7207 63.6948 57.1064 63.6948 57.582C63.6948 58.0575 63.3093 58.4432 62.8335 58.4432Z"
          fill="#8399FE"
        />
        <path
          d="M62.8335 64.2909H53.8641C53.3883 64.2909 53.0028 63.9052 53.0028 63.4296C53.0028 62.954 53.3883 62.5684 53.8641 62.5684H62.8335C63.3093 62.5684 63.6948 62.954 63.6948 63.4296C63.6948 63.9052 63.3093 64.2909 62.8335 64.2909Z"
          fill="#B7C5FF"
        />
        <path
          d="M62.8335 67.9882H53.8641C53.3883 67.9882 53.0028 67.6025 53.0028 67.1269C53.0028 66.6513 53.3883 66.2656 53.8641 66.2656H62.8335C63.3093 66.2656 63.6948 66.6513 63.6948 67.1269C63.6948 67.6026 63.3093 67.9882 62.8335 67.9882Z"
          fill="#B7C5FF"
        />
        <path
          d="M62.8335 71.6854H53.8641C53.3883 71.6854 53.0028 71.2997 53.0028 70.8242C53.0028 70.3486 53.3883 69.9629 53.8641 69.9629H62.8335C63.3093 69.9629 63.6948 70.3486 63.6948 70.8242C63.6948 71.2997 63.3093 71.6854 62.8335 71.6854Z"
          fill="#B7C5FF"
        />
        <path
          d="M62.8335 75.3837H53.8641C53.3883 75.3837 53.0028 74.998 53.0028 74.5224C53.0028 74.0468 53.3883 73.6611 53.8641 73.6611H62.8335C63.3093 73.6611 63.6948 74.0468 63.6948 74.5224C63.6948 74.9982 63.3093 75.3837 62.8335 75.3837Z"
          fill="#B7C5FF"
        />
        <path
          d="M38.4568 33.7273C38.4568 32.1524 37.1755 30.8711 35.6006 30.8711C34.0257 30.8711 32.7444 32.1524 32.7444 33.7273C32.7444 34.5042 33.057 35.2087 33.562 35.7242C33.057 36.2396 32.7444 36.9441 32.7444 37.721C32.7444 38.1955 33.1292 38.5804 33.6038 38.5804C34.0783 38.5804 34.4631 38.1955 34.4631 37.721C34.4631 37.0938 34.9734 36.5835 35.6006 36.5835C36.2278 36.5835 36.7381 37.0938 36.7381 37.721C36.7381 38.1955 37.1229 38.5804 37.5974 38.5804C38.072 38.5804 38.4568 38.1955 38.4568 37.721C38.4568 36.9441 38.1442 36.2396 37.6392 35.7242C38.1442 35.2089 38.4568 34.5042 38.4568 33.7273ZM35.6006 32.59C36.2278 32.59 36.7381 33.1003 36.7381 33.7275C36.7381 34.3547 36.2278 34.865 35.6006 34.865C34.9734 34.865 34.4631 34.3547 34.4631 33.7275C34.4633 33.1001 34.9734 32.59 35.6006 32.59Z"
          fill="#6583FE"
        />
        <path
          d="M73.959 72.7858H64.3133C63.3634 72.7858 62.5934 72.0158 62.5934 71.0658V62.8459C62.5934 61.896 63.3634 61.126 64.3133 61.126H73.959C74.9089 61.126 75.6789 61.896 75.6789 62.8459V71.0658C75.6787 72.0158 74.9087 72.7858 73.959 72.7858Z"
          fill="#FF7EB8"
        />
        <path
          d="M73.959 73.646H64.3133C62.8912 73.646 61.734 72.4889 61.734 71.0668V62.8469C61.734 61.4246 62.8911 60.2676 64.3133 60.2676H73.959C75.3811 60.2676 76.5383 61.4246 76.5383 62.8469V71.0668C76.5381 72.4889 75.3811 73.646 73.959 73.646ZM64.3133 61.9863C63.8388 61.9863 63.4528 62.3724 63.4528 62.8469V71.0668C63.4528 71.5414 63.8388 71.9272 64.3133 71.9272H73.959C74.4335 71.9272 74.8195 71.5412 74.8195 71.0668V62.8469C74.8195 62.3724 74.4335 61.9863 73.959 61.9863H64.3133Z"
          fill="#2626BC"
        />
        <path
          d="M67.9664 69.8865C67.823 69.8865 67.678 69.8505 67.5444 69.7753C67.1307 69.5417 66.9848 69.0171 67.2184 68.6036L69.5561 64.4636C69.7895 64.0501 70.3142 63.9042 70.7276 64.1376C71.1413 64.3712 71.2872 64.8957 71.0536 65.3092L68.7159 69.4492C68.558 69.7292 68.2667 69.8865 67.9664 69.8865Z"
          fill="white"
        />
        <path
          d="M26.3496 40.0954H16.704C15.754 40.0954 14.984 39.3254 14.984 38.3754V30.1555C14.984 29.2055 15.754 28.4355 16.704 28.4355H26.3496C27.2995 28.4355 28.0695 29.2055 28.0695 30.1555V38.3754C28.0694 39.3254 27.2994 40.0954 26.3496 40.0954Z"
          fill="#97FFD2"
        />
        <path
          d="M26.3496 40.9548H16.704C15.2817 40.9548 14.1246 39.7977 14.1246 38.3754V30.1555C14.1246 28.7332 15.2817 27.5762 16.704 27.5762H26.3496C27.7719 27.5762 28.9289 28.7332 28.9289 30.1555V38.3754C28.9287 39.7977 27.7719 40.9548 26.3496 40.9548ZM16.704 29.2949C16.2294 29.2949 15.8434 29.681 15.8434 30.1555V38.3754C15.8434 38.85 16.2294 39.236 16.704 39.236H26.3496C26.8241 39.236 27.2102 38.85 27.2102 38.3754V30.1555C27.2102 29.681 26.8241 29.2949 26.3496 29.2949H16.704Z"
          fill="#2626BC"
        />
        <path
          d="M20.357 37.1951C20.2137 37.1951 20.0686 37.1591 19.9351 37.0839C19.5213 36.8503 19.3754 36.3257 19.609 35.9122L21.9467 31.7722C22.1801 31.3587 22.7048 31.2128 23.1182 31.4462C23.5319 31.6798 23.6778 32.2043 23.4442 32.6178L21.1065 36.7578C20.9486 37.0376 20.6573 37.1951 20.357 37.1951Z"
          fill="#01ECA5"
        />
        <path
          d="M76.7203 54.7794C76.4924 54.7794 76.2738 54.6888 76.1128 54.5278L74.2182 52.633C73.8825 52.2974 73.8825 51.7532 74.2182 51.4177L76.1128 49.5231C76.4481 49.1876 76.9922 49.1876 77.3279 49.5231L79.2227 51.4177C79.3839 51.5789 79.4745 51.7974 79.4745 52.0254C79.4745 52.2535 79.3841 52.472 79.2227 52.6332L77.3279 54.5279C77.1669 54.6888 76.9482 54.7794 76.7203 54.7794ZM76.0411 52.0253L76.7203 52.7047L77.3998 52.0253L76.7203 51.3458L76.0411 52.0253Z"
          fill="#6583FE"
        />
        <path
          d="M10.9355 54.2452C10.7155 54.2452 10.4957 54.1613 10.3277 53.9936L8.43315 52.099C8.09748 51.7633 8.09748 51.2192 8.43315 50.8837L10.3277 48.9889C10.4889 48.8277 10.7074 48.7373 10.9355 48.7373C11.1636 48.7373 11.382 48.8279 11.5432 48.9889L13.438 50.8837C13.5992 51.0449 13.6896 51.2634 13.6896 51.4914C13.6896 51.7195 13.599 51.938 13.438 52.0992L11.5432 53.9938C11.3757 54.1613 11.1557 54.2452 10.9355 54.2452ZM10.2562 51.4911L10.9357 52.1705L11.6151 51.4911L10.9357 50.8117L10.2562 51.4911Z"
          fill="#97FFD2"
        />
        <path
          d="M15.6715 61.1974C15.4515 61.1974 15.2317 61.1135 15.0637 60.9457L13.169 59.0512C12.8333 58.7155 12.8333 58.1713 13.169 57.8358L15.0636 55.9411C15.2248 55.7799 15.4432 55.6895 15.6713 55.6895C15.8994 55.6895 16.1178 55.78 16.2791 55.9411L18.174 57.8357C18.3352 57.9969 18.4256 58.2153 18.4256 58.4434C18.4256 58.6715 18.335 58.8899 18.174 59.0512L16.2792 60.9457C16.1113 61.1133 15.8913 61.1974 15.6715 61.1974ZM14.9922 58.4432L15.6717 59.1227L16.3511 58.4432L15.6717 57.7638L14.9922 58.4432Z"
          fill="#6583FE"
        />
        <path
          d="M54.8284 10.3897C54.6085 10.3897 54.3884 10.3059 54.2208 10.1381L52.3262 8.24354C51.9905 7.90787 51.9905 7.36371 52.3262 7.02821L54.2208 5.13346C54.382 4.97224 54.6005 4.88184 54.8284 4.88184C55.0563 4.88184 55.2749 4.97241 55.4359 5.13346L57.3305 7.02821C57.6662 7.36388 57.6662 7.90804 57.3305 8.24354L55.4359 10.1381C55.2684 10.3057 55.0482 10.3897 54.8284 10.3897ZM54.1491 7.63562L54.8284 8.31487L55.5076 7.63562L54.8284 6.9562L54.1491 7.63562Z"
          fill="#FF7EB8"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2042_6598"
          x1="8.71831"
          y1="5.36539"
          x2="72.3717"
          y2="83.1637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDBED" />
          <stop offset="1" stopColor="#DCFDEE" />
        </linearGradient>
        <clipPath id="clip0_2042_6598">
          <rect width="88" height="88" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationWebDevelopment;
