import { SVGProps } from "types";

const BoxLiveStreaming = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5424)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-bubbles)"
        />
        <path
          d="M16.125 13.375C16.332 13.375 16.5 13.207 16.5 13C16.5 12.793 16.332 12.625 16.125 12.625H15.2497V12.25H16.125C16.332 12.25 16.5 12.082 16.5 11.875C16.5 11.668 16.332 11.5 16.125 11.5H15.1252C14.7802 11.5 14.5005 11.7805 14.5005 12.1248V13.8752C14.5005 14.2202 14.781 14.5 15.1252 14.5H16.125C16.332 14.5 16.5 14.332 16.5 14.125C16.5 13.918 16.332 13.75 16.125 13.75H15.2497V13.375H16.125Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M10.3748 14.5C10.1678 14.5 9.99976 14.332 9.99976 14.125V11.875C9.99976 11.668 10.1678 11.5 10.3748 11.5C10.5818 11.5 10.7498 11.668 10.7498 11.875V14.125C10.7498 14.332 10.5818 14.5 10.3748 14.5Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M16.5 6.5H7.5C6.67275 6.5 6 7.17275 6 8V16.0002C6 16.8275 6.67275 17.5002 7.5 17.5002H16.5C17.3273 17.5002 18 16.8275 18 16.0002V8C18 7.17275 17.3273 6.5 16.5 6.5ZM16.5 16.4998H7.5C7.224 16.4998 6.99975 16.2755 6.99975 15.9995V8.99975H17.0002V15.9995C17.0002 16.2755 16.776 16.4998 16.5 16.4998Z"
          fill="var(--color-charged-blue)"
        />
        <path
          d="M9.1245 14.5H7.875C7.668 14.5 7.5 14.332 7.5 14.125V11.875C7.5 11.668 7.668 11.5 7.875 11.5C8.082 11.5 8.25 11.668 8.25 11.875V13.75H9.12525C9.33225 13.75 9.50025 13.918 9.50025 14.125C9.50025 14.332 9.33225 14.5 9.1245 14.5Z"
          fill="var(--color-sky-blue)"
        />
        <path
          d="M12.75 14.5C12.5813 14.5 12.4335 14.3875 12.3885 14.2255L11.7638 11.9755C11.7083 11.776 11.8253 11.569 12.0248 11.5135C12.2243 11.4588 12.4313 11.575 12.4868 11.7745L12.75 12.724L13.014 11.7745C13.0695 11.575 13.2743 11.4588 13.476 11.5135C13.6755 11.569 13.7925 11.776 13.737 11.9755L13.1123 14.2255C13.0665 14.3883 12.9188 14.5 12.75 14.5Z"
          fill="var(--color-sky-blue)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5424">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxLiveStreaming;
