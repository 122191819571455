import { SVGProps } from "types";

const BoxTableCells = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5407)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M15.1253 6H11.4998V8.00025H15.1253C15.33 8.00025 15.5003 7.83 15.5003 7.62525V6.375C15.5003 6.17025 15.33 6 15.1253 6ZM8.49976 6.375V7.62525C8.49976 7.83 8.67001 8.00025 8.87476 8.00025H10.5V6H8.87476C8.67001 6 8.49976 6.17025 8.49976 6.375Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M15.1253 9H11.4998V11.0002H15.1253C15.33 11.0002 15.5003 10.83 15.5003 10.6252V9.375C15.5003 9.17025 15.33 9 15.1253 9ZM8.49976 9.375V10.6252C8.49976 10.83 8.67001 11.0002 8.87476 11.0002H10.5V9H8.87476C8.67001 9 8.49976 9.17025 8.49976 9.375Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M17.1255 8H16.4452C16.3905 8.18525 16.2952 8.35475 16.1752 8.50025C16.2952 8.645 16.3905 8.81525 16.4452 9.0005H17.0002V15.0005H6.99975V9.0005H7.55475C7.6095 8.81525 7.70475 8.64575 7.82475 8.50025C7.70475 8.3555 7.6095 8.18525 7.55475 8H6.8745C6.3945 8 6 8.3945 6 8.8745V15.1243C6 15.6043 6.39525 15.9995 6.87525 15.9995H11.0002V16.9993H10.0005C9.7245 16.9993 9.50025 17.2235 9.50025 17.4995C9.50025 17.7755 9.72375 17.9998 9.99975 17.9998H14.0002C14.277 17.9998 14.5005 17.7755 14.5005 17.4995C14.5005 17.2235 14.277 16.9993 14.0002 16.9993H13.0005V15.9995H17.1255C17.6055 15.9995 18.0007 15.6043 18.0007 15.1243V8.8745C18 8.3945 17.6055 8 17.1255 8Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5407">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxTableCells;
