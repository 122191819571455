import { SVGProps } from "types";

const BoxWebDevelopment = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5435)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M17.0002 6H11.4998C10.9478 6 10.5 6.44775 10.5 6.99975V11.4998C10.5 12.0518 10.9478 12.4995 11.4998 12.4995H17.0002C17.5522 12.4995 18 12.0518 18 11.4998V6.99975C18 6.44775 17.5522 6 17.0002 6ZM16.7505 11.4998H11.7502C11.6122 11.4998 11.5005 11.388 11.5005 11.25V8.00025H17.001V11.25C17.0002 11.388 16.8885 11.4998 16.7505 11.4998Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M13.3515 13.3266C13.1182 13.1804 12.8092 13.2494 12.6622 13.4834C12.3195 14.0271 12.1103 14.6309 12.0353 15.2496H8.967C8.865 14.3954 8.5005 13.5771 7.9095 12.9036C7.323 12.2399 6.99975 11.3856 6.99975 10.4999C6.99975 8.90464 8.0655 7.47514 9.59175 7.02439C9.8565 6.94639 10.008 6.66814 9.93 6.40339C9.852 6.13864 9.57525 5.98789 9.309 6.06514C7.35975 6.64039 6 8.46439 6 10.4999C6 11.6301 6.4125 12.7191 7.1595 13.5651C7.70175 14.1831 8.00025 14.9556 8.00025 15.7401V16.7504C8.00025 17.4396 8.5605 18.0006 9.2505 18.0006H11.7502C12.4395 18.0006 13.0005 17.4396 13.0005 16.7504V15.7401C13.0005 15.1401 13.176 14.5439 13.5082 14.0166C13.6552 13.7826 13.5848 13.4744 13.3515 13.3266Z"
          fill="var(--color-orange-soda)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5435">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxWebDevelopment;
