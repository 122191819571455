import { SVGProps } from "types";

const BoxProgrammingLanguage = (props: SVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2255_5406)">
        <path
          d="M19.5 24H4.5C2.0145 24 0 21.9855 0 19.5V4.5C0 2.0145 2.0145 0 4.5 0H19.5C21.9855 0 24 2.0145 24 4.5V19.5C24 21.9855 21.9855 24 19.5 24Z"
          fill="var(--color-misty-rose)"
        />
        <path
          d="M17.1255 7H6.8745C6.39225 7 6 7.393 6 7.87525V14.6252C6 15.1075 6.39225 15.5005 6.87525 15.5005H10.7048C10.6462 15.751 10.4903 16.1575 10.074 16.5737C10.0027 16.645 9.981 16.753 10.02 16.846C10.0582 16.9398 10.1498 17.0005 10.251 17.0005H13.7512C13.8525 17.0005 13.944 16.9398 13.9822 16.846C14.0205 16.7523 13.9995 16.645 13.9282 16.5737C13.5127 16.1582 13.353 15.7517 13.2908 15.5005H17.1262C17.6085 15.5005 18.0015 15.1082 18.0015 14.6252V7.87525C18 7.393 17.6077 7 17.1255 7ZM17.0002 13.5002H6.99975V8.0005H17.0002V13.5002Z"
          fill="var(--color-orange-soda)"
        />
        <path
          d="M9.50025 12.5005C9.372 12.5005 9.2445 12.4518 9.147 12.3543C8.952 12.1593 8.952 11.8428 9.147 11.647L10.293 10.5003L9.14625 9.3535C8.95125 9.1585 8.95125 8.842 9.14625 8.64625C9.34125 8.45125 9.65775 8.45125 9.8535 8.64625L11.3535 10.1463C11.5485 10.3413 11.5485 10.6578 11.3535 10.8535L9.8535 12.3535C9.756 12.4518 9.62775 12.5005 9.50025 12.5005Z"
          fill="var(--color-congo-pink)"
        />
        <path
          d="M14.4998 12.5005H12.4995C12.2235 12.5005 12 12.2762 12 12.0002C12 11.7242 12.2235 11.5 12.5002 11.5H14.5005C14.7765 11.5 15 11.7242 15 12.0002C15 12.2762 14.7765 12.5005 14.4998 12.5005Z"
          fill="var(--color-congo-pink)"
        />
      </g>
      <defs>
        <clipPath id="clip0_2255_5406">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BoxProgrammingLanguage;
